import React, { useState, useEffect, useRef, useMemo } from 'react';
import { IoIosArrowBack, IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { LuPackage, LuPackagePlus, LuSearch, LuText } from 'react-icons/lu';
import { motion } from 'framer-motion';
import { vietReplies } from './stg/vietReplies';
import { exportReasonArray } from './stg/exportReasonArray';
import { termArray } from './stg/termArray';
import {
  MdOutlineClear,
  MdOutlineDesignServices,
  MdOutlineDocumentScanner,
  MdOutlineGeneratingTokens,
  MdTrackChanges,
} from 'react-icons/md';
import { v1 as uuid } from 'uuid';
import firebase from 'firebase/compat/app';
import { auth, db } from '../../server/firebase';
import { useNavigate } from 'react-router-dom';
import {
  TbCalendarQuestion,
  TbFileInvoice,
  TbLayoutSidebarLeftCollapse,
  TbLayoutSidebarRightCollapse,
  TbLayoutSidebarRightExpand,
  TbPencilUp,
  TbPinnedOff,
  TbPrompt,
} from 'react-icons/tb';
import WordGenerating from './stg/WordGenerating';
import skydartLogo from '../../skydartLogo.png';
import { FaArrowsTurnRight, FaRegFlag, FaSortDown, FaSortUp } from 'react-icons/fa6';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { BiLabel, BiPackage, BiRefresh } from 'react-icons/bi';
import { BsPatchQuestion, BsTrash } from 'react-icons/bs';
import { CiPause1, CiViewList } from 'react-icons/ci';
import { CircularProgress, LinearProgress, TextareaAutosize } from '@mui/material';
import {
  IoAddSharp,
  IoBookmarksOutline,
  IoCopyOutline,
  IoCreateOutline,
  IoDocumentOutline,
  IoSearchOutline,
} from 'react-icons/io5';
import { GoMultiSelect, GoPackage, GoSearch } from 'react-icons/go';
import { countries } from '../../staff/api/countries';
import { serviceArray } from '../../staff/api/services';
import { usProvince } from '../../staff/api/usProvince';
import { parseDate } from '@datepicker-react/hooks';
import { FaLongArrowAltRight } from 'react-icons/fa';
import OutsideClickHandler from 'react-outside-click-handler';
import { FcCheckmark } from 'react-icons/fc';
import { VscPinned, VscRemote } from 'react-icons/vsc';
import { confirmReplies } from './stg/confirmReplies';
import { weightDescription } from './stg/weightDescription';
import { dimDescription } from './stg/dimDescription';
import { LiaTruckLoadingSolid } from 'react-icons/lia';
import { PiQuotesThin } from 'react-icons/pi';
import ShipScriptGuide from './stg/ShipScriptGuide';
import ShipScriptKeyboard from './stg/ShipScriptKeyboard';
import { supportiveSentences } from './stg/supportiveSentences';
import { doxConfirm } from './stg/doxConfirm';

export default function TextBooking({ user }) {
  const [loading, setLoading] = useState(false);
  const [loadingShipment, setLoadingShipment] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [loadingSuccess, setLoadingSuccess] = useState(false);
  const [filteredCountry, setFilteredCountry] = useState(countries);
  const [searchPending, setSearchPending] = useState('');
  const [filteredState, setFilteredState] = useState(usProvince);
  const [filteredService, setFilteredService] = useState(serviceArray);
  const [displayPending, setDisplayPending] = useState(false);
  const [displaySidebar, setDisplaySidebar] = useState(true);
  const [displayHelp, setDisplayHelp] = useState(false);
  const [loadingContent, setLoadingContent] = useState('Đang tạo vận đơn');
  const [isMultiColor, setIsMultiColor] = useState(true);
  const [isFontStyle, setIsFontStyle] = useState(false);

  const [scrolls, setScrolls] = useState(0);

  let textareaRef = useRef(null);
  let countryRef = useRef(null);
  let stateRef = useRef(null);
  let serviceRef = useRef(null);
  let scrollRef = useRef(null);
  const [isAutoScroll, setIsAutoScroll] = useState(true);
  const observerRef = useRef(null);
  const scrollTimeout = useRef(null);
  const [messages, setMessages] = useState([]);
  const [isComposing, setIsComposing] = useState(false);
  const [currentChatId, setCurrentChatId] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [otherChat, setOtherChat] = useState([]);
  const [todayChat, setTodayChat] = useState([]);
  const [pinnedChat, setPinnedChat] = useState([]);
  const [yesterdayChat, setYesterdayChat] = useState([]);
  const [pendingShipment, setPendingShipment] = useState([]);
  const [displayCountry, setDisplayCountry] = useState(false);
  const [displayState, setDisplayState] = useState(false);
  const [displayService, setDisplayService] = useState(false);
  const [displayKeyboard, setDisplayKeyboard] = useState(false);
  const [toCountry, setToCountry] = useState('United States');
  const [toCode, setToCode] = useState('US');
  const [toState, setToState] = useState('CA');
  const [service, setService] = useState('');
  const [service1, setService1] = useState('SDH');
  const [shipmentType, setShipmentType] = useState('WPX');
  const [isOpen, setIsOpen] = useState(true);
  const [isTyping, setIsTyping] = useState(true);
  const today = new Date();
  const options = { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' };

  const formattedDate = today.toLocaleDateString('en-GB', options);
  const navigate = useNavigate();

  const handleScroll = (e) => {
    const scrollY = e.target.scrollTop;
    setScrolls(scrollY);
  };
  function removeAccents(str) {
    var AccentsMap = [
      'aàảãáạăằẳẵắặâầẩẫấậ',
      'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
      'dđ',
      'DĐ',
      'eèẻẽéẹêềểễếệ',
      'EÈẺẼÉẸÊỀỂỄẾỆ',
      'iìỉĩíị',
      'oòỏõóọôồổỗốộơờởỡớợ',
      'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
      'uùủũúụưừửữứự',
      'UÙỦŨÚỤƯỪỬỮỨỰ',
      'yỳỷỹýỵ',
      'YỲỶỸÝỴ',
    ];
    for (var i = 0; i < AccentsMap.length; i++) {
      var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
      var char = AccentsMap[i][0];
      str = str.replace(re, char);
    }
    return str;
  }
  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (!scrollContainer) return;

    // Function to check if user is at the bottom
    const isAtBottom = () => {
      return Math.abs(scrollContainer.scrollHeight - scrollContainer.scrollTop - scrollContainer.clientHeight) < 5;
    };

    // Handle user manual scrolling
    const handleScroll = () => {
      if (scrollTimeout.current) clearTimeout(scrollTimeout.current);

      // If the user scrolls up, stop auto-scroll
      if (!isAtBottom()) {
        setIsAutoScroll(false);
      } else {
        setIsAutoScroll(true);
      }

      // Detect if user is idle (not scrolling) for 2 seconds
      scrollTimeout.current = setTimeout(() => {
        if (isAtBottom()) {
          setIsAutoScroll(true);
        }
      }, 2000);
    };

    scrollContainer.addEventListener('scroll', handleScroll);

    if (isGenerating) {
      // MutationObserver to detect content changes
      observerRef.current = new MutationObserver(() => {
        if (isAutoScroll) {
          scrollContainer.scrollTo({
            top: scrollContainer.scrollHeight,
            behavior: 'smooth',
          });
        }
      });

      observerRef.current.observe(scrollContainer, { childList: true, subtree: true });
    }

    return () => {
      if (observerRef.current) observerRef.current.disconnect();
      scrollContainer.removeEventListener('scroll', handleScroll);
      if (scrollTimeout.current) clearTimeout(scrollTimeout.current);
    };
  }, [isGenerating, isAutoScroll]);
  const columns = useMemo(() => [
    {
      Header: 'Description of goods',
      minWidth: 200,
      accessor: 'itemCommonity',
      active: true,
      id: 0,
      key: 'itemCommonity',
      context: false,
    },
    {
      Header: 'Local name',
      minWidth: 100,
      accessor: 'itemVietnamCommonity',
      active: true,
      id: 1,
      key: 'itemVietnamCommonity',
      context: false,
    },
    {
      Header: 'Brand name',
      minWidth: 100,
      accessor: 'itemBrand',
      active: true,
      id: 2,
      key: 'itemBrand',
      context: false,
    },
    {
      Header: 'Packing type',
      minWidth: 100,
      accessor: 'itemPackType',
      active: true,
      id: 3,
      key: 'itemPackType',
      context: false,
    },
    {
      Header: 'Ingredients',
      minWidth: 100,
      accessor: 'itemIngredients',
      active: true,
      id: 4,
      key: 'itemIngredients',
      context: false,
    },
    {
      Header: 'Usage',
      minWidth: 100,
      accessor: 'itemPurpose',
      active: true,
      id: 5,
      key: 'itemPurpose',
      context: false,
    },
    {
      Header: 'County of origin',
      minWidth: 100,
      accessor: 'itemCountryOrigin',
      active: true,
      id: 6,
      key: 'itemCountryOrigin',
      context: false,
    },
    {
      Header: 'Manufacture name',
      minWidth: 135,
      accessor: 'itemManufact',
      active: true,
      id: 7,
      key: 'itemManufact',
      context: false,
    },
    {
      Header: 'Manufacture address',
      minWidth: 200,
      accessor: 'itemManufactAddress',
      active: true,
      id: 8,
      key: 'itemManufactAddress',
      context: false,
    },
  ]);
  const normalizeText = (text) => {
    return text.replace(/([\p{Emoji_Presentation}\p{Extended_Pictographic}])\s?/gu, '').trim();
  };

  const parseShippingDetails = async (input) => {
    setLoading(true);
    const refNumber = 'SKD';
    const newDates = new Date();
    const dates = (newDates.getDate() < 10 ? '0' : '') + newDates.getDate();
    const months = (newDates.getMonth() + 1 < 10 ? '0' : '') + (newDates.getMonth() + 1);
    const years = newDates.getFullYear().toString().slice(-2);
    const wrapper = `15${months}${dates}`;
    const max = 900000;
    const newHawb = refNumber + wrapper + Math.floor(100000 + Math.random() * max);

    let newDate = new Date();
    let date = (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();
    let month = (newDate.getMonth() + 1 < 10 ? '0' : '') + (newDate.getMonth() + 1);
    let year = newDate.getFullYear();
    const datePicker = `${year}-${month}-${date}`;
    const monthName = months[newDate.getMonth()];
    const traceTime = `${date} ${monthName} ${year}`;
    const hour = (newDate.getHours() < 10 ? '0' : '') + newDate.getHours();
    const min = (newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes();
    const dateTime = `${hour}:${min}`;
    const result = {
      account: '786009',
      pickupScheduled: '',
      deliverySign: '',
      currency: 'USD',
      service: service || '',
      timestamp: '',
      perform: 'New',
      status: 'Pending',
      id: (Math.random() + 1).toString(36).substring(7),
      desc: [],
      controller: user.controller,
      eamFormat: '',
      shipmentName: '',
      customHawb: '',
      important: '',
      airline: 'Pending',
      mawb: 'Pending',
      pack: 'Pending',
      etd: 'Pending',
      agent: 'Pending',
      subStatus: 'Pending',
      sin: 'Null',
      note: 'Null',
      code: toCode || '',
      hawb: newHawb,
      uid: user.uid,
      makh: user.makh,
      paymentStatus: '',
      phoneCode: '',
      shipmentType: shipmentType || 'WPX',
      fromCountry: user.country,
      exporter: removeAccents(user.exporter),
      fromCity: removeAccents(user.city),
      fromState: removeAccents(user.state),
      fromTown: removeAccents(user.town),
      fromAddress: removeAccents(user.address),
      fromAddress2: removeAccents(user.address2),
      fromAddress3: removeAccents(user.address3),
      fromPostal: user.postal,
      fromPhone: user.phone,
      fromName: removeAccents(user.displayName),
      toPhone2: '',
      fromEmail: user.email,
      dateTime: new Date(),
      toCountry: toCountry || '',
      importer: '',
      toCity: '',
      toState: toState || '',
      stateLabel: '',
      toTown: '', // Tách thành phố từ địa chỉ
      toAddress: '',
      toAddress2: '.',
      toAddress3: '',
      stateCode: '',
      toPostal: '', // Tách mã bưu điện
      toPhone: '',
      toEmail: '',
      toName: '',
      createAt: datePicker,
      time: dateTime,
      invoiceValue: [],
      packageValue: [],
      totalCharge: '',
      reweight: '',
      weight: '',
      totalPackage: '',
      totalItem: '',
      eori: '',
      otherTerm: '',
      description: '',
      term: '',
      transportation: '',
      totalItemValue: '',
      totalInvoiceValue: '',
      usage: 'GIFT',
      rate: '',
      fsc: '',
      fscValue: '',
      addressCorrection: '',
      direct: '',
      surcharge: '',
      surcharge2: '',
      surcharge3: '',
      remote: '',
      vat: 'No',
      vatValue: '',
      eamService: '',
      skydartZone: '',
      plan: user.plan || 'Pending',
      booked: 'false',
    };

    // Dynamic Regex Patterns
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;
    const phoneRegex = /\b(\+?\d{1,3}[-.\s]?)?(\d{2,4}[-.\s]?){2,4}\d\b/;
    const postalCodeRegex = /\b[A-Z]?\d[A-Z]?\s?\d[A-Z]?\d\b|\b\d{3,10}(-\d{3,4})?\b/g;
    const stateRegex = /,\s*([A-Z]{2,3}|[A-Za-z\s]+)(?:\s*,|$)/;
    const eoriRegex = /(?:\beori:|\bIOSS:|\bTIN:)\s*([^,;]+)/i;
    const transportRegex = /(?:\btransport:|\btransportation:)\s*([^,;]+)/i;
    const termRegex = /\b(EXW|FOB|CNF|CIF)\b/i;
    const cityRegex = /(?:,\s*)([A-Za-z\s]+?)(?=,\s[A-Z]{2}\b)/; // Matches city before state
    const shipmentTypeRegex = /\b(WPX|DOX)\b/i;
    const doxRegex = /\b(gửi\s*(chứng từ|hồ sơ|giấy tờ)|hồ sơ|giấy tờ|chứng từ)\b/i;
    const usageRegex =
      /\b(GIFT|gift|e-commerce selling|E-COMMERCE SELLING|RETURNED FOR MAINTENANCE|returned for maintenance|SAMPLE|sample|COMMERCIAL|commercial)\b/i;
    // const addressRegex = /\b\d{1,10}\s[A-Za-z0-9\s.,'-]+,\s[A-Za-z\s]+,\s[A-Z]{2,3}\s?\d{4,5},\s[A-Za-z]+/;
    // const addressRegex = /\b\d{1,10}\s[A-Za-zÀ-Ỹà-ỹ0-9\s.,'-]+(?:\n|\s*,\s*)\d{4,6}(?:\s*[A-Za-z\s]*)?,\s*[A-Za-z\s]+/;
    const addressRegex = /\b\d{1,10}\s[A-Za-z0-9\s.,'-]+,\s*\d{4,6}\s[A-Za-z\s]+(?:\n[A-Za-z]+)?/;

    const multilineAddressRegex =
      /(?:Người nhận:.*?,\s*[\w.-]+@[\w.-]+\.\w+,\s*\d{9,15},\s*)?(\d{1,10}\s[A-Za-z0-9\s.,'-]+),\s*([A-Za-z\s]+),\s*([A-Z]{2,3})?\s*(\d{4,5})?,?\s*([A-Za-z]+)/;
    const importerRegex =
      /(?:\bto:|\bimporter:|\bImporter:|\bIMPORTER:|\bTO:\s*|\bship\s?to:\s*|\bSHIP\s?TO:|\bTO:|\bgửi\s?đến:|\bGửi\s?đến|\bGỬI\s?ĐẾN)\s*([^,;]+)/i;
    const contactNameRegex =
      /(?:\brecipient:|\bngười\s?nhận:|\breceiver:|\battn:|\btoName:\s*|\bcontact:\s*|\bliên\s?hệ:)\s*([^,;]+)/i;
    const countryLabels = countries.map((country) => country.label);
    const countryPattern = countryLabels.map((label) => label.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|');
    const countryRegex = new RegExp(`\\b(${countryPattern})\\b`, 'i');

    const countryCodes = countries.map((country) => country.code);
    const countryCodePattern = countryCodes.map((label) => label.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|');
    const countryCodeRegex = new RegExp(`\\b(${countryCodePattern})\\b`, 'i');

    const countryLabelsVietnam = countries.map((country) => country.label_vn);
    const countryPatternVietnam = countryLabelsVietnam
      .map((label) => label.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
      .join('|');
    const countryRegexVietnam = new RegExp(`\\b(${countryPatternVietnam})\\b`, 'i');

    const serviceLabels = serviceArray.map((service) => {
      return {
        proxy: service.proxy,
        label: service.label,
      };
    });
    const servicePattern = serviceLabels
      .flatMap(({ proxy, label }) => [
        proxy?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
        label?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
      ])
      .filter(Boolean) // Remove any undefined/null values
      .join('|');

    // Create the regex
    const serviceRegex = new RegExp(`\\b(${servicePattern})\\b`, 'i');
    let foundMatch = false;
    let importerFound = false;
    let missingContent = [];
    const parsePackages = (input) => {
      input = normalizeText(input);
      // Additional regex patterns for even more flexible formatss
      const oldFormatRegex = /(\d+(?:\.\d+)?)\s*Kg\s*\/\s*Dim\s*(\d+)[xX](\d+)(?:[xX](\d+))?\s*cm?/gi;

      const newFormatRegex = /(?:Dim\s?)?(\d+)[xXcm]*(\d+)[xXcm]*(\d+)?\s?[-/]?\s?(\d+\.?\d*)\s?(?:Kg|kg)/gi;
      const sizeWeightRegex =
        /Size[:|=]?\s?(\d+)[xX](\d+)[xX](\d+)\s?(?:cm)?\s?,\s?Weight[:|=]?\s?(\d+\.?\d*)\s?(?:Kg|kg)/gi;
      const dimWeightRegex =
        /Dim[:|=]?\s?(\d+)\s?[xX]\s?(\d+)\s?[xX]\s?(\d+)\s?(?:cm)?,?\s?Weight[:|=]?\s?(\d+\.?\d*)\s?(?:Kg|kg)/gi;
      const packageRegex =
        /Package\s?(\d+):\s?(\d+)[xX](\d+)[xX](\d+)\s?(?:cm)?\s?,?\s?Weight\s?(\d+\.?\d*)\s?(?:Kg|kg)/gi;
      const dimensionsWeightRegex =
        /Dimensions[:|=]?\s?(\d+)\s?[xX]\s?(\d+)\s?[xX]\s?(\d+)\s?(?:cm)?\s?;\s?Weight[:|=]?\s?(\d+\.?\d*)\s?(?:Kg|kg)/gi;
      const lhwdRegex =
        /L:\s?(\d+)\s?x\s?W:\s?(\d+)\s?x\s?H:\s?(\d+)\s?cm\s?,?\s?Weight[:|=]?\s?(\d+\.?\d*)\s?(?:Kg|kg)/gi;
      const drcRegex =
        /D:\s?(\d+)\s?x\s?R:\s?(\d+)\s?x\s?C:\s?(\d+)\s?cm\s?,?\s?Nặng[:|=]?\s?(\d+\.?\d*)\s?(?:Kg|kg)/gi;
      const pipeFormatRegex = /(\d+)\s?x\s?(\d+)\s?x\s?(\d+)\s?(?:cm)\s?\|\s?Weight:\s?(\d+\.?\d*)\s?(?:Kg|kg)/gi;
      const packageDimensionsWeightRegex =
        /Package\s?Dimensions:\s?(\d+)[xX](\d+)[xX](\d+)\s?(?:cm),\s?Weight:\s?(\d+\.?\d*)\s?(?:kg|Kg)/gi;
      const dimensionsWithWeightRegex =
        /Dimensions:\s?(\d+)[xX](\d+)[xX](\d+)\s?cm\s?,?\s?Weight\s?(\d+\.?\d*)\s?(?:kg|Kg)/gi;
      const compactFormatRegex = /(\d+)\s?x\s?(\d+)\s?x\s?(\d+)\s?\|\s?(\d+\.?\d*)\s?(?:kg|Kg)/gi;
      const paranthesesFormatRegex = /\((\d+)[xX](\d+)[xX](\d+)\s?cm\)\s?and\s?Weight\s?(\d+\.?\d*)\s?(?:Kg|kg)/gi;

      // Array of regex patterns
      const regexPatterns = [
        oldFormatRegex,
        newFormatRegex,
        sizeWeightRegex,
        dimWeightRegex,
        packageRegex,
        dimensionsWeightRegex,
        lhwdRegex,
        drcRegex,
        pipeFormatRegex,
        packageDimensionsWeightRegex,
        dimensionsWithWeightRegex,
        compactFormatRegex,
        paranthesesFormatRegex,
      ];

      // Loop through the patterns to find the match
      const matches = [];
      for (const regex of regexPatterns) {
        const matched = [...input.matchAll(regex)];
        if (matched.length > 0) {
          matches.push(...matched);
        }
      }

      // 🛠 Lọc ra các dòng chỉ chứa thông tin kiện hàng
      const validPackages = matches.filter((match) => {
        if (!match || !match[0]) return false;

        const inputText = match[0];

        // Loại bỏ các dòng chứa từ khóa không liên quan
        const excludeKeywords = ['invoice:', 'chứng từ:', 'Manufacture:', 'NSX:', 'Brand:'];
        return !excludeKeywords.some((keyword) => inputText.includes(keyword));
      });

      // 🔥 Xử lý chỉ các dòng hợp lệ
      return validPackages
        .map((match) => {
          if (!match || !match[0]) return null;

          const inputText = match[0];

          // 🔹 Chỉ bắt trọng lượng nếu có đơn vị "Kg"
          const weightRegex = /(?:Weight[:=]?\s*)?(\d+\.?\d*)\s?(?:Kg|kg)/i;
          const inlineWeightRegex = /-\s*(\d+\.?\d*)\s*kg/i;

          let packageWeight = 0;
          const weightMatch = inputText.match(weightRegex) || inputText.match(inlineWeightRegex);
          if (weightMatch) {
            packageWeight = parseFloat(weightMatch[1]);
          }

          let packageLength = 0,
            packageWidth = 0,
            packageHeight = 0;

          // 🛠 Regex sửa lỗi nhận diện sai
          const dimensionsRegex1 = /(\d+)\s*[xX]\s*(\d+)\s*[xX]\s*(\d+)\s*cm?\s*(?:\/\s*(\d+)\s*kg)?/i;
          const dimensionsRegex2 = /L:\s*(\d+)\s*x\s*W:\s*(\d+)\s*x\s*H:\s*(\d+)/i;
          const dimensionsRegex3 = /D:\s*(\d+)\s*x\s*R:\s*(\d+)\s*x\s*C:\s*(\d+)/i;
          const dimensionsRegex4 = /^\s*(\d+)\s*Kg\s*\/\s*Dim\s*(\d+)\s*x\s*(\d+)\s*x\s*(\d+)\s*cm\s*$/i;
          const inlineDimensionsRegex = /(\d+)\s*x\s*(\d+)\s*x\s*(\d+)/i;

          const dimensionsMatch1 = inputText.match(dimensionsRegex1);
          const dimensionsMatch2 = inputText.match(dimensionsRegex2);
          const dimensionsMatch3 = inputText.match(dimensionsRegex3);
          const dimensionsMatch4 = inputText.match(dimensionsRegex4);
          const inlineDimensionsMatch = inputText.match(inlineDimensionsRegex);

          if (dimensionsMatch1) {
            packageWidth = parseInt(dimensionsMatch1[1], 10);
            packageHeight = parseInt(dimensionsMatch1[2], 10);
            packageLength = parseInt(dimensionsMatch1[3], 10);
            if (dimensionsMatch1[4]) {
              packageWeight = parseFloat(dimensionsMatch1[4]);
            }
          } else if (dimensionsMatch2) {
            packageLength = parseInt(dimensionsMatch2[1], 10);
            packageWidth = parseInt(dimensionsMatch2[2], 10);
            packageHeight = parseInt(dimensionsMatch2[3], 10);
          } else if (dimensionsMatch3) {
            packageLength = parseInt(dimensionsMatch3[1], 10);
            packageWidth = parseInt(dimensionsMatch3[2], 10);
            packageHeight = parseInt(dimensionsMatch3[3], 10);
          } else if (dimensionsMatch4) {
            packageLength = parseInt(dimensionsMatch4[2], 10);
            packageWidth = parseInt(dimensionsMatch4[3], 10);
            packageHeight = parseInt(dimensionsMatch4[4], 10);
          } else if (inlineDimensionsMatch) {
            packageLength = parseInt(inlineDimensionsMatch[1], 10);
            packageWidth = parseInt(inlineDimensionsMatch[2], 10);
            packageHeight = parseInt(inlineDimensionsMatch[3], 10);
          } else {
            console.log('⚠ Không tìm thấy kích thước trong:', inputText);
          }

          // 📦 Tính charge kiện hàng
          let dimCharge = (packageLength * packageWidth * packageHeight) / 5000;
          const packageCharge = dimCharge < packageWeight ? Math.ceil(packageWeight) : Math.ceil(dimCharge);

          return {
            packageLength,
            packageWidth,
            packageHeight,
            packageWeight,
            packageCharge,
          };
        })
        .filter(Boolean);
    };

    // Parse mặt hàng và số lượng (dùng dấu chấm phẩy hoặc dấu chấm)
    const processInvoiceItems = (input) => {
      input = normalizeText(input);
      const itemsRegex =
        /(?:INVOICE|Invoice|invoice|Hoá đơn|hoá đơn|HOÁ ĐƠN|Hoá Đơn|Chứng từ|CHỨNG TỪ|chứng từ|Chứng Từ):\s*([\s\S]+?)(?=\n\s*\w+:|$)/i;
      const itemsMatch = input.match(itemsRegex);
      if (!itemsMatch) return [];

      const invoiceContent = itemsMatch[1]?.trim();

      // **Correctly extract and split all items**
      const items = invoiceContent.match(/[^,\[]+\(\d+\)\s*\[[^\]]+\]/g) || [];

      return items.map((item, index) => {
        const mainMatch = item.match(/^(.+?)\s*\((\d+)\)\s*\[(.*?)\]$/);
        if (!mainMatch) return null;

        const itemFullName = mainMatch[1]?.trim(); // e.g., "Belt - Thắt lưng"
        const quantity = parseInt(mainMatch[2], 10);
        const details = mainMatch[3];

        // **Extract English & Vietnamese names for all items**
        let itemCommonity = itemFullName;
        let itemVietnamCommonity = '';

        if (itemFullName.includes('-')) {
          const splitNames = itemFullName.split('-').map((s) => s.trim());
          itemCommonity = splitNames[0]; // ✅ English name
          itemVietnamCommonity = splitNames[1] || ''; // ✅ Vietnamese name
        }

        const getField = (regex) => {
          const match = details.match(regex);
          return match ? match[1]?.trim() : '';
        };

        return {
          id: uuid(),
          isSelectItem: false,
          itemCommonity, // ✅ English name for every item
          itemVietnamCommonity, // ✅ Vietnamese name for every item
          itemQuantity: quantity,
          itemUnitPrice: parseFloat(getField(/(\d+\.?\d*)\s*\/\s*\w+/)) || 0,
          itemUnit: getField(/\/(\w+)/),
          itemManufact: getField(/(?:NSX:|Manufacture:)\s*([^,]+)/i),
          itemManufactAddress: getField(/,\s*([^\/]+)\//),
          itemHsCode: getField(/\/(\d+)\//),
          itemIngredients: getField(/(?:Thành phần:|Ingredients:)\s*([^\/]+)/i),
          itemBrand: getField(/(?:Nhãn hiệu:|Brand:)\s*([^\/]+)/i),
          itemCountryOrigin: 'Vietnam',
          itemPackType: getField(/\b(BAG|BOX|Carton|Wooden Pack)\b/i),
          itemPackType2: '',
          itemPnc: '',
          itemOtherUnit: '',
          itemPurpose: getField(/(?:use for:|dùng cho:|mục đích:|sử dụng:|dùng để:|mục đích sử dụng:)\s*([^\/]+)/i),
          itemFda: getField(/(?:FDA:|fda:)\s*([^\/]+)/i),
          itemValue: quantity * parseFloat(getField(/(\d+)\s*\/\s*\w+/)) || 0,
          itemUnit2: '',
          itemNo: index + 1,
        };
      });
    };
    result.packageValue = parsePackages(input);
    result.invoiceValue = processInvoiceItems(input);
    if (result.packageValue.length === 0) {
      missingContent.push({
        label: 'packageValue',
        id: 11,
        value: 'Thiếu thông tin kiện hàng ?',
      });
    }

    if (result.invoiceValue.length === 0) {
      missingContent.push({
        label: 'invoiceValue',
        id: 12,
        value: 'Không có thông tin về mặt hàng, hoá đơn, chứng từ,...',
      });
    }
    // extract country
    const countryMatch = input.match(countryRegex);

    const countryMatchVietnam = input.match(countryRegexVietnam);
    const countryMatchCode = input.match(countryCodeRegex);
    if (countryMatch) {
      if (!foundMatch) {
        const matchedCountry = countries.find(
          (country) => country.label.toLowerCase() === countryMatch[1].toLowerCase()
        );
        if (matchedCountry) {
          result.toCountry = matchedCountry.label;
          result.code = matchedCountry.code;
          foundMatch = true;
        }
      }
    } else if (countryMatchVietnam) {
      if (!foundMatch) {
        const matchedCountry = countries.find(
          (country) => country.label_vn.toLowerCase() === countryMatchVietnam[1].toLowerCase()
        );
        if (matchedCountry) {
          result.toCountry = matchedCountry.label;
          result.code = matchedCountry.code;
          foundMatch = true;
        }
      }
    } else if (countryMatchCode) {
      if (!foundMatch) {
        const matchedCountry = countries.find(
          (country) => country.code.toLowerCase() === countryMatchCode[1].toLowerCase()
        );
        if (matchedCountry) {
          result.toCountry = matchedCountry.label;
          result.code = matchedCountry.code;
          foundMatch = true;
        }
      }
    } else {
      missingContent.push({
        label: 'country',
        id: 0,
        value:
          'Bạn có thể cho tôi biết bạn đang muốn vận chuyển đơn hàng của mình đến nước nào được không vì tôi không tìm thấy quốc gia điểm đến của bạn ?',
      });
    }
    // Extract Email
    const emailMatch = input.match(emailRegex);
    if (emailMatch) {
      result.toEmail = emailMatch[0];
    } else {
      missingContent.push({
        label: 'email',
        id: 8,
        value: 'Thiếu thông tin email người nhận',
      });
    }
    // extract service
    const serviceMatch = input.match(serviceRegex);
    if (serviceMatch) {
      const matchingService = serviceLabels.find(
        ({ proxy, label }) => serviceMatch[0] === proxy || serviceMatch[0] === label
      );
      if (matchingService) {
        result.service = matchingService.label;
      }
    } else {
      missingContent.push({
        label: 'service',
        id: 7,
        value: 'Thiếu thông tin dịch vụ',
      });
    }
    // extract importer
    const importerMatch = input.match(importerRegex);
    if (importerMatch) {
      result.importer = importerMatch[1]?.trim();
      importerFound = true;
    } else {
      missingContent.push({
        label: 'importer',
        id: 1,
        value: 'Bạn có thể cho tôi biết tên đơn vị, tổ chức hoặc công ty nơi bạn muốn vận chuyển đến là gì không ?',
      });
    }

    // Extract Phone Number
    const phoneMatch = input.match(phoneRegex);
    if (phoneMatch) {
      result.toPhone = phoneMatch[0];
    } else {
      missingContent.push({
        label: 'phone',
        id: 6,
        value: 'Bạn đang thiếu số điện thoại',
      });
    }
    const shipmentTypeMatch = input.match(shipmentTypeRegex);
    if (shipmentTypeMatch) result.shipmentType = shipmentTypeMatch[0];
    const doxTypeMatch = input.match(doxRegex);
    if (doxTypeMatch) result.shipmentType = 'DOX';
    // Extract Postal Code
    const postalCodeMatch = input.match(postalCodeRegex);
    if (postalCodeMatch) {
      result.toPostal = postalCodeMatch[1];
    } else {
      missingContent.push({
        label: 'postal',
        id: 5,
        value: 'Bạn đang thiếu mã bưu chính',
      });
    }

    // Extract State
    const stateMatch = input.match(stateRegex);
    if (stateMatch) {
      result.toState = stateMatch[1];
      result.toTown = stateMatch[1];
    } else {
      missingContent.push({
        label: 'state',
        id: 4,
        value:
          'Có vẻ như bạn đang bị thiếu xót thông tin cho thành phố, quận huyện hoặc tiểu bang nào đó, hãy bổ sung ngay để tiến hành tạo vận nhé!',
      });
    }

    const termMatch = input.match(termRegex);
    if (termMatch) result.term = termMatch[1]?.trim();

    const usageMatch = input.match(usageRegex);
    if (usageMatch) result.usage = usageMatch[1]?.trim();

    // Extract City
    const cityMatch = input.match(cityRegex);
    if (cityMatch) result.toCity = cityMatch[1]?.trim();
    // Extract eori, usage,transport
    const eoriMatch = input.match(eoriRegex);
    if (eoriMatch) {
      result.eori = eoriMatch[1]?.trim();
    } else {
      missingContent.push({
        label: 'state',
        id: 10,
        value: 'Chưa có mã EORI',
      });
    }
    const transportMatch = input.match(transportRegex);
    if (transportMatch) {
      result.transportation = transportMatch[1]?.trim();
    } else {
      missingContent.push({
        label: 'state',
        id: 9,
        value: 'Chưa cung cấp phụ phí vận chuyển',
      });
    }
    // Extract Address
    const addressMatch = input.match(addressRegex);
    const matchSecondary = input.match(multilineAddressRegex);
    if (addressMatch) {
      result.toAddress = addressMatch[0]?.trim().slice(0, 30);
      result.toAddress2 = addressMatch[0]?.trim().slice(31, 60);
      result.toAddress3 = addressMatch[0]?.trim().slice(61, 90);
    } else if (matchSecondary) {
      const foundAddress = `${matchSecondary[1]?.trim()}, ${matchSecondary[2]?.trim()}, ${
        matchSecondary[3] ? matchSecondary[3]?.trim() + ', ' : ''
      }${matchSecondary[4]?.trim()}`;
      result.toAddress = foundAddress?.trim().slice(0, 30);
      result.toAddress2 = foundAddress?.trim().slice(31, 60);
      result.toAddress3 = foundAddress?.trim().slice(61, 90);
    } else {
      missingContent.push({
        label: 'address',
        id: 2,
        value:
          'Bạn chưa nhập địa chỉ, xin vui lòng nhập đầy đủ địa chỉ tại điểm nhận hàng và hãy kiểm tra thật kĩ để tránh sai xót không đáng có nhé!',
      });
    }

    // Extract Contact Name
    const contactNameMatch = input.match(contactNameRegex);
    if (contactNameMatch) {
      result.toName = contactNameMatch[1]?.trim();
      if (!importerFound) {
        result.importer = contactNameMatch[1]?.trim();
      }
    } else {
      missingContent.push({
        label: 'contact',
        id: 3,
        value:
          'Tôi không tìm thấy người sẽ nhận kiện hàng khi tới nơi từ yêu cầu của bạn, hãy cung cấp nó để tôi có thể lên đơn cho bạn nhé',
      });
    }

    // Generate Full Address
    result.fullAddress = `${result.toAddress}, ${result.toTown}, ${result.toState}, ${result.postalCode}`;
    const myMessage = {
      sender: user.uid,
      id: uuid(),
      text: input,
      subText: '',
      isSeen: false,
      shipment: {},
      shippingType: result.shipmentType,
      currentStep: 0,
      timestamp: new Date(),
    };
    setMessages((prev) => [...prev, myMessage]);
    textareaRef.current.value = '';

    // let subText = [];
    // let errLevel =
    //   missingContent?.length === 0
    //     ? 'no-error'
    //     : missingContent?.length !== 0 && missingContent?.length < 3
    //       ? 'very low'
    //       : missingContent?.length > 3 && missingContent?.length < 7
    //         ? 'low'
    //         : 'no-error';

    // if (errLevel === 'no-error') {
    //   subText = [
    //     {
    //       label: '',
    //       value:
    //         result?.shipmentType === 'DOX'
    //           ? doxConfirm[Math.floor(Math.random() * doxConfirm?.length)].replace('USA', result?.toCountry)
    //           : `Để khởi tạo lô hàng từ ${result?.fromCountry} đi ${result?.toCountry} bao gồm ${result?.packageValue?.length} kiện hàng và ${result?.invoiceValue?.length} mặt hàng dựa theo yêu cầu của bạn, chúng ta cần chuẩn bị những thông tin sau:`,
    //     },
    //     { label: '', value: confirmReplies[Math.floor(Math.random() * confirmReplies?.length)] },
    //     { label: '', value: weightDescription[Math.floor(Math.random() * weightDescription?.length)] },
    //     { label: '', value: dimDescription[Math.floor(Math.random() * dimDescription?.length)] },
    //     { label: '', value: supportiveSentences[Math.floor(Math.random() * supportiveSentences?.length)] },
    //   ];
    // } else {
    //   subText = missingContent;
    // }
    const subText = [
      {
        label: '',
        value:
          result?.shipmentType === 'DOX'
            ? doxConfirm[Math.floor(Math.random() * doxConfirm?.length)].replace('USA', result?.toCountry)
            : `Để khởi tạo lô hàng từ ${result?.fromCountry} đi ${result?.toCountry} bao gồm ${result?.packageValue?.length} kiện hàng và ${result?.invoiceValue?.length} mặt hàng dựa theo yêu cầu của bạn, chúng ta cần chuẩn bị những thông tin sau:`,
      },
      { label: '', value: confirmReplies[Math.floor(Math.random() * confirmReplies?.length)] },
      { label: '', value: weightDescription[Math.floor(Math.random() * weightDescription?.length)] },
      { label: '', value: dimDescription[Math.floor(Math.random() * dimDescription?.length)] },
      { label: '', value: supportiveSentences[Math.floor(Math.random() * supportiveSentences?.length)] },
    ];
    const response = await getChatbotResponse(input);
    const botMessage = {
      sender: 'bot',
      text: response,
      timestamp: new Date(),
      subText: subText,
      // errLevel,
      shippingType: result.shipmentType,
      shipment: result,
      isSeen: false,
      id: uuid(),
      currentStep: 0,
    };
    setMessages((prev) => [...prev, botMessage]);
    if (currentChatId === '') {
      const newChatId = uuid();
      const newChat = {
        chatName: input,
        chatId: newChatId,
        inbox: [...messages, myMessage, botMessage],
        timestamp: new Date(),
        pinned: false,
      };
      setCurrentChatId(newChatId);
      const newData = [newChat, ...chatHistory];
      setChatHistory(newData);
      localStorage.setItem('myChat', JSON.stringify(newData));
    } else {
      const updatedChat = chatHistory.map((sd) => {
        if (sd.chatId === currentChatId) {
          sd.inbox = [...sd.inbox, myMessage, botMessage];
        }
        return sd;
      });
      setChatHistory(updatedChat);
      localStorage.setItem('myChat', JSON.stringify(updatedChat));
    }
    return result;
  };

  const getChatbotResponse = (message) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(vietReplies[Math.floor(Math.random() * vietReplies?.length)]);
        setLoading(false);
        setIsGenerating(true);
      }, 2000); // Simulate response delay
    });
  };
  const handleEffectComplete = (messageId) => {
    const newMessage = messages.map((sd) => {
      if (sd.id === messageId) {
        sd.currentStep = sd.currentStep + 1;
      }
      return sd;
    });
    setMessages(newMessage);
  };
  const handleEffectCompleteLast = (messageId) => {
    const newMessage = messages.map((sd) => {
      if (sd.id === messageId) {
        if (!sd.isSeen) {
          sd.currentStep = sd.currentStep + 1;
          sd.isSeen = true;
          setIsGenerating(false);
        }
      }
      return sd;
    });
    const updatedChat = chatHistory.map((sd7) => {
      if (sd7.chatId === currentChatId) {
        sd7.inbox = newMessage;
      }
      return sd7;
    });
    setChatHistory(updatedChat);
    localStorage.setItem('myChat', JSON.stringify(updatedChat));
    setMessages(newMessage);
  };

  useEffect(() => {
    const waitingShipment = JSON.parse(localStorage.getItem('myPendingShipment')) || [];
    const recentChat = JSON.parse(localStorage.getItem('myChat')) || [];
    setChatHistory(recentChat);
    setPendingShipment(waitingShipment);
  }, []);

  useEffect(() => {
    const filterChats = () => {
      const isToday = (dateString) => {
        const inputDate = new Date(dateString);
        const today = new Date();

        return (
          inputDate.getDate() === today.getDate() &&
          inputDate.getMonth() === today.getMonth() &&
          inputDate.getFullYear() === today.getFullYear()
        );
      };
      const isYesterday = (dateString) => {
        const inputDate = new Date(dateString);
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1); // Go back 1 day

        return (
          inputDate.getDate() === yesterday.getDate() &&
          inputDate.getMonth() === yesterday.getMonth() &&
          inputDate.getFullYear() === yesterday.getFullYear()
        );
      };

      const unPinnedChat = chatHistory.filter((chat) => {
        return !chat.pinned;
      });
      const todayChat1 = unPinnedChat.filter((chat) => {
        return isToday(chat.timestamp);
      });
      const yesterdayChat1 = unPinnedChat.filter((chat) => {
        return isYesterday(chat.timestamp);
      });
      const pinnedChat1 = chatHistory.filter((chat) => {
        return chat.pinned;
      });
      const otherChat1 = unPinnedChat.filter((chat) => {
        return !chat.pinned && !isYesterday(chat.timestamp) && !isToday(chat.timestamp);
      });

      setTodayChat(todayChat1);
      setYesterdayChat(yesterdayChat1);
      setPinnedChat(pinnedChat1);
      setOtherChat(otherChat1);
    };

    filterChats();
  }, [chatHistory]);

  const handleRequestModel = async () => {
    if (loading) return;
    if (isGenerating) return;
    if (textareaRef.current.value.trim() !== '') {
      setLoading(true);
      setLoadingContent('Đang suy nghĩ...');
      setLoading(false);
      setLoadingContent('');
      parseShippingDetails(textareaRef.current.value.trim());
    }
  };
  const handleFilter = () => {
    const searchValue = countryRef.current?.value?.toLowerCase() || '';
    const filtered = countries.filter((s) => s.label.toLowerCase().startsWith(searchValue));
    setFilteredCountry(filtered);
  };
  const handleFilterState = () => {
    const searchValue = stateRef.current?.value?.toLowerCase() || '';
    const filtered = usProvince.filter((s) => s.code.toLowerCase().startsWith(searchValue));
    setFilteredState(filtered);
  };
  const handleFilterService = () => {
    const searchValue = serviceRef.current?.value?.toLowerCase() || '';
    const filtered = serviceArray.filter((s) => s.proxy.toLowerCase().startsWith(searchValue));
    setFilteredService(filtered);
  };

  const handleSelectInvoiceItem = (id, invoiceId) => {
    const updatedData = messages.map((item) => {
      if (item.id === id) {
        const newInvoice = item.shipment?.invoiceValue?.map((iv) => {
          if (iv.id === invoiceId) {
            iv.isSelectItem = !iv.isSelectItem;
          }
          return iv;
        });
        return {
          ...item,
          shipment: {
            ...item.shipment,
            invoiceValue: newInvoice,
          },
        };
      } else {
        return item;
      }
    });
    setMessages(updatedData);
  };

  const handleSaveShipmentData = (updatedMessage) => {
    const newChat = chatHistory.map((sd) => {
      if (sd.chatId === currentChatId) {
        sd.inbox = updatedMessage;
        sd.lastUpdated = new Date();
      }
      return sd;
    });
    setChatHistory(newChat);
    localStorage.setItem('myChat', JSON.stringify(newChat));
  };

  const handleChildInputChange = (id, field, value) => {
    const updatedData = messages.map((item) => {
      if (item.id === id) {
        if (field === 'shipmentName') {
          return { ...item, shipment: { ...item.shipment, shipmentName: value } };
        } else if (field === 'importer') {
          return { ...item, shipment: { ...item.shipment, importer: value } };
        } else if (field === 'toAddress') {
          return { ...item, shipment: { ...item.shipment, toAddress: value } };
        } else if (field === 'toAddress2') {
          return { ...item, shipment: { ...item.shipment, toAddress2: value } };
        } else if (field === 'toAddress3') {
          return { ...item, shipment: { ...item.shipment, toAddress3: value } };
        } else if (field === 'toTown') {
          return { ...item, shipment: { ...item.shipment, toTown: value } };
        } else if (field === 'toCity') {
          return { ...item, shipment: { ...item.shipment, toCity: value } };
        } else if (field === 'toState') {
          return { ...item, shipment: { ...item.shipment, toState: value } };
        } else if (field === 'toPostal') {
          return { ...item, shipment: { ...item.shipment, toPostal: value } };
        } else if (field === 'toPhone') {
          return { ...item, shipment: { ...item.shipment, toPhone: value } };
        } else if (field === 'toName') {
          return { ...item, shipment: { ...item.shipment, toName: value } };
        } else if (field === 'toEmail') {
          return { ...item, shipment: { ...item.shipment, toEmail: value } };
        } else if (field === 'toCountry') {
          return { ...item, shipment: { ...item.shipment, toCountry: value } };
        } else if (field === 'service') {
          return { ...item, shipment: { ...item.shipment, service: value } };
        } else if (field === 'shipmentType') {
          return { ...item, shipment: { ...item.shipment, shipmentType: value } };
        } else if (field === 'usage') {
          return { ...item, shipment: { ...item.shipment, usage: value } };
        } else if (field === 'transportation') {
          return { ...item, shipment: { ...item.shipment, transportation: value } };
        } else if (field === 'term') {
          return { ...item, shipment: { ...item.shipment, term: value } };
        } else if (field === 'customHawb') {
          return { ...item, shipment: { ...item.shipment, customHawb: value } };
        } else if (field === 'eori') {
          return { ...item, shipment: { ...item.shipment, eori: value } };
        } else if (field === 'packageValue') {
          return { ...item, shipment: { ...item.shipment, packageValue: value } };
        }
      }
      return item;
    });

    setMessages(updatedData); // Set the new state with updated data
    handleSaveShipmentData(updatedData);
  };
  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // Reset the height
      textarea.style.height = `${textarea.scrollHeight}px`; // Adjust to fit content
    }
  };
  const addNewLine = () => {
    const textarea = textareaRef.current;

    if (textarea) {
      const caretPosition = textarea.selectionStart;

      // Get the current text value
      const textBeforeCaret = textarea.value.substring(0, caretPosition);
      const textAfterCaret = textarea.value.substring(caretPosition);

      // Insert newline at the caret position
      textarea.value = textBeforeCaret + '\n' + textAfterCaret;

      // Reset the caret position to the next line after the inserted newline
      textarea.selectionStart = textarea.selectionEnd = caretPosition + 1;

      // Call adjustHeight() to resize the textarea
      adjustHeight();
    }
  };
  const handleKeyDown2 = (event) => {
    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault();
      addNewLine();
    } else if (event.key === 'Enter' && !isComposing) {
      event.preventDefault();
      parseShippingDetails(textareaRef.current.value);
    }
  };
  const handleCompositionStart = () => {
    setIsComposing(true);
  };

  const handleCompositionEnd = () => {
    setIsComposing(false);
  };

  const handleDeleteShipment = (messageId) => {
    const updatedMessages = messages.filter((s) => {
      return s.id !== messageId;
    });
    setMessages(updatedMessages);
    const updatedChat = chatHistory.map((sd7) => {
      if (sd7.chatId === currentChatId) {
        sd7.inbox = updatedMessages;
      }
      return sd7;
    });
    setChatHistory(updatedChat);
    localStorage.setItem('myChat', JSON.stringify(updatedChat));
  };
  const handleDeleteSingleShipment = (hawbValue, messageId, chatId) => {
    if (pendingShipment.length === 1) {
      setDisplayPending(false);
    }
    const newShipment = pendingShipment?.filter((sd) => {
      return sd.hawb !== hawbValue;
    });
    setPendingShipment(newShipment);
    const newChat = chatHistory.map((s) => {
      if (s.chatId === chatId) {
        s.inbox = s.inbox.map((sd) => {
          if (sd.id === messageId) {
            sd.isPending = false;
          }
          return sd;
        });
      }
      return s;
    });
    setChatHistory(newChat);
    if (currentChatId === chatId) {
      setMessages(
        messages.map((sd7) => {
          if (sd7.id === messageId) {
            sd7.isPending = false;
          }
          return sd7;
        })
      );
    }
    localStorage.setItem('myChat', JSON.stringify(newChat));
    localStorage.setItem('myPendingShipment', JSON.stringify(newShipment));
  };
  const handleAddSingleShipment = (shipmentData, messageId) => {
    setDisplayPending(true);
    const newShipment = [
      {
        ...shipmentData,
        belongTo: currentChatId,
        belongToMessage: messageId,
        isNew: false,
        bookingSuccess: false,
      },
      ...pendingShipment,
    ];
    setPendingShipment([
      {
        ...shipmentData,
        belongTo: currentChatId,
        belongToMessage: messageId,
        isNew: true,
        bookingSuccess: false,
      },
      ...pendingShipment,
    ]);
    const mergeShipment = newShipment.map((s) => {
      return {
        ...s,
        isNew: false,
      };
    });
    localStorage.setItem('myPendingShipment', JSON.stringify(mergeShipment));
    const updatedMessages = messages.map((sd) => {
      if (sd.id === messageId) {
        sd.isPending = true;
        sd.shipment.belongTo = currentChatId;
      }
      return sd;
    });
    setMessages(updatedMessages);
    const updatedChat = chatHistory.map((sd7) => {
      if (sd7.chatId === currentChatId) {
        sd7.inbox = updatedMessages;
      }
      return sd7;
    });
    setChatHistory(updatedChat);
    localStorage.setItem('myChat', JSON.stringify(updatedChat));
  };

  const handleBookSingleShipment = (shipmentData, messageId, bookingType) => {
    if (loadingShipment) return;
    setLoadingShipment(true);
    let newDate = new Date();
    let date = (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();
    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    let month = (newDate.getMonth() + 1 < 10 ? '0' : '') + (newDate.getMonth() + 1);
    let year = newDate.getFullYear();
    const datePicker = `${year}-${month}-${date}`;
    const monthName = months[newDate.getMonth()];
    const traceTime = `${date} ${monthName} ${year}`;
    const hour = (newDate.getHours() < 10 ? '0' : '') + newDate.getHours();
    const min = (newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes();
    const dateTime = `${hour}:${min}`;
    db.collection('shipments')
      .doc(shipmentData.hawb)
      .set({
        ...shipmentData,
        desc: firebase.firestore.FieldValue.arrayUnion({
          stat: 'Shipment booked, waiting for confirmation by Skydart ',
          time: datePicker + 'T' + dateTime + ':00',
          location: 'VIETNAM - VIETNAM - HCM',
        }),
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        createAt: datePicker,
        // pickupScheduled: `${sd.dateInput}T${sd.timeInput}:00`,
        perform: 'New',
      })
      .then(() => {
        const updatedMessages = messages.map((sd) => {
          if (sd.id === messageId) {
            sd.isBooking = true;
          }
          return sd;
        });
        const updatedChat = chatHistory.map((sd7) => {
          if (sd7.chatId === currentChatId) {
            sd7.inbox = updatedMessages;
          }
          return sd7;
        });
        handleDeleteSingleShipment(shipmentData.hawb, shipmentData.belongToMessage, shipmentData.belongTo);
        setMessages(updatedMessages);
        setChatHistory(updatedChat);
        localStorage.setItem('myChat', JSON.stringify(updatedChat));
        setLoadingSuccess(true);
        setLoadingContent('Tạo vận đơn thành công');
      });
  };

  const handleBookMultipleShipment = (bookingType) => {
    let newDate = new Date();
    let date = (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();
    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    let month = (newDate.getMonth() + 1 < 10 ? '0' : '') + (newDate.getMonth() + 1);
    let year = newDate.getFullYear();
    const datePicker = `${year}-${month}-${date}`;
    const monthName = months[newDate.getMonth()];
    const traceTime = `${date} ${monthName} ${year}`;
    const hour = (newDate.getHours() < 10 ? '0' : '') + newDate.getHours();
    const min = (newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes();
    const dateTime = `${hour}:${min}`;
    setPendingShipment(
      pendingShipment.forEach((sd) => {
        sd.loading = true;
        db.collection('shipments')
          .doc(sd.hawb)
          .set({
            ...sd,
            desc: firebase.firestore.FieldValue.arrayUnion({
              stat: 'Shipment booked, waiting for confirmation by Skydart ',
              time: datePicker + 'T' + dateTime + ':00',
              location: 'VIETNAM - VIETNAM - HCM',
            }),
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            createAt: datePicker,
            pickupScheduled: `${sd.dateInput}T${sd.timeInput}:00`,
            perform: bookingType === 'draft' ? 'Draft' : 'New',
          })
          .then(() => {
            const newPending = pendingShipment.map((sd1) => {
              if (sd1.hawb === sd.hawb) {
                sd1.bookingSuccess = true;
              }
              return sd1;
            });
            setPendingShipment(newPending);
            localStorage.setItem('myPendingShipment', JSON.stringify(newPending));
          });
        return sd;
      })
    );
  };

  useEffect(() => {
    if (loadingSuccess) {
      const timeout = setTimeout(() => {
        setLoadingSuccess(false);
        setLoadingShipment(false);
      }, 5000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [loadingSuccess]);

  const handleDeleteAllChat = () => {
    const freshChat = [];
    setChatHistory(freshChat);
    setMessages([]);
    setCurrentChatId('');
    localStorage.setItem('myChat', JSON.stringify(freshChat));
  };
  const handleDeleteSingleChat = (chatId) => {
    const newChat = chatHistory.filter((s) => {
      return s.chatId !== chatId;
    });
    setChatHistory(newChat);
    localStorage.setItem('myChat', JSON.stringify(newChat));
  };
  const handleDeleteAllShipment = () => {
    const freshShipment = [];
    setPendingShipment(freshShipment);
    localStorage.setItem('myPendingShipment', JSON.stringify(freshShipment));
    const updatedData = chatHistory.map((chat) => {
      pendingShipment.map((sd) => {
        if (chat.chatId === sd.belongTo) {
          chat.inbox = chat.inbox.map((ib) => {
            if ((ib.id = sd.belongToMessage)) {
              ib.isPending = false;
            }
            return ib;
          });
        }
        return sd;
      });
      return chat;
    });
    setChatHistory(updatedData);
    setDisplayPending(false);
    localStorage.setItem('myChat', JSON.stringify(updatedData));
  };

  const handlePinnedChat = (chatId) => {
    const newChat = chatHistory.map((sd) => {
      if (sd.chatId === chatId) {
        sd.pinned = !sd.pinned;
      }
      return sd;
    });
    setChatHistory(newChat);
    localStorage.setItem('myChat', JSON.stringify(newChat));
  };

  return (
    <div className="bg-white absolute top-0 right-0 left-0 bottom-0 z-[9999]">
      {isOpen && (
        <ShipScriptGuide
          handleShowGuide={() => setIsOpen(!isOpen)}
          user={user}
          handleShowKeyboard={() => setDisplayKeyboard(true)}
        />
      )}
      {displayKeyboard && (
        <ShipScriptKeyboard
          reset={() => {
            setDisplayKeyboard(false);
          }}
          user={user}
          countries={countries}
          serviceArray={serviceArray}
          exportReasonArray={exportReasonArray}
          handleShowHelp={() => setIsOpen(true)}
        />
      )}
      {loadingShipment && (
        <div className="fixed bottom-10 left-5 bg-white w-[370px] flex z-[10000] shadow-[rgba(100,100,111,0.2)_0px_7px_29px_0px] p-3 rounded-[18px] items-center loading-slice">
          <div className="flex-shrink-0">
            {!loadingSuccess && <CircularProgress size={25} />}
            {loadingSuccess && <FcCheckmark size={25} />}
          </div>
          <div className="w-full mx-3">
            <div className="flex items-center">
              <p className="font-medium  text-zinc-600">{loadingContent}</p>
            </div>
            {!loadingSuccess && <LinearProgress style={{ borderRadius: 9999, width: '100%', marginTop: 2 }} />}
          </div>
        </div>
      )}
      <div className="flex">
        <div
          className={`${
            displaySidebar ? 'w-[280px]' : 'w-0'
          } transition-[75s] h-[100vh] overflow-x-hidden overflow-y-auto border-r-[1px] border-l-0 border-y-0 flex-col border-zinc-100 flex-shrink-0 hidden sm:flex`}
        >
          <div className="p-3 pb-0 flex items-center   justify-between sticky top-0 bg-white z-10">
            <div className="group">
              <p className="w-[200px] overflow-hidden text-ellipsis whitespace-nowrap ml-2.5 font-semibold text-black text-lg">
                {user.exporter}
              </p>
              <div className="absolute shadow-[rgba(0,0,0,0.1)_0px_4px_12px] bg-white z-[9999] p-3 break-words w-full rounded-[9px] retriveShipment hidden group-hover:block">
                <p>{user.exporter}</p>
                <p className="text-[11px] text-zinc-500">{user.uid}</p>
                <p className="font-semibold">{user.makh}</p>
                <p className="text-[14px] text-zinc-700">{user.country}</p>
                <p className="text-[14px] text-zinc-700">{user.address}</p>
                <p className="text-[14px] text-zinc-700">{user.city}</p>
                <p className="text-[14px] text-zinc-700">{user.state}</p>
                <p className="text-[14px] text-zinc-700">{user.town}</p>
                <p className="text-[14px] text-zinc-700">{user.postal}</p>
                <div className="flex items-center gap-1 my-2.5">
                  <div
                    className="p-1.5 px-4 rounded-full bg-zinc-100 select-none cursor-pointer hover:bg-zinc-200 active:bg-zinc-300 transition-[98s]"
                    onClick={() => {
                      navigate('/');
                    }}
                  >
                    <p>Trang chủ</p>
                  </div>
                  <div
                    className="p-1.5 px-4 rounded-full bg-zinc-100 select-none cursor-pointer hover:bg-zinc-200 active:bg-zinc-300 transition-[98s]"
                    onClick={() => {
                      auth.signOut();
                      navigate('/');
                    }}
                  >
                    <p>Đăng xuất</p>
                  </div>
                </div>
                <p className="text-[13px] text-zinc-500">Skydart Express © 2025</p>
              </div>
            </div>
            <LuPackagePlus
              size={35}
              className="p-1 cursor-pointer hover:bg-zinc-100 rounded-[10px] active:bg-zinc-200 text-slate-600 hover:text-black active:scale-95"
              onClick={() => {
                setMessages([]);
                setCurrentChatId('');
              }}
            />
          </div>
          <div className="p-3 pt-1">
            <div
              className="p-1.5 flex items-center hover:bg-zinc-100 active:bg-zinc-200 rounded-[8px] cursor-pointer select-none transition-[98s]"
              onClick={() => {
                navigate('/');
              }}
            >
              <img
                src={skydartLogo}
                alt=""
                loading="lazy"
                className="w-[28px] h-[28px] object-contain rounded-full border-slate-300 border-solid border-[1px] bg-white flex-shrink-0"
              />
              <p className="font-semibold text-[darkblue] mx-2 whitespace-nowrap select-none text-[15px]">
                SKYDART EXPRESS
              </p>
            </div>
            <div
              className="p-1.5 flex items-center hover:bg-zinc-100 active:bg-zinc-200 rounded-[8px] cursor-pointer select-none transition-[98s]"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <TbCalendarQuestion size={28} className="p-0.5" />
              <p className=" mx-2 whitespace-nowrap select-none text-[15px]">Sử dụng ShipScript ?</p>
            </div>
            <div
              className={`p-1.5 flex items-center hover:bg-zinc-100 active:bg-zinc-200 rounded-[8px] cursor-pointer ${
                displayPending ? 'bg-zinc-200 hover:bg-zinc-300' : ''
              } select-none transition-[98s]`}
              onClick={() => {
                setDisplayPending(!displayPending);
              }}
            >
              <IoBookmarksOutline size={28} className="p-1" />
              <p className=" mx-2 whitespace-nowrap select-none text-[15px]">Hàng chờ</p>
              {pendingShipment?.length !== 0 && (
                <div
                  className={`min-w-[28px] ml-auto h-[28px] rounded-full  flex items-center justify-center text-[14px]  font-semibold ${
                    isMultiColor
                      ? 'bg-gradient-to-tr from-green-600 to-[darkblue] text-white'
                      : 'bg-zinc-100 text-black'
                  }`}
                >
                  <p>{pendingShipment?.length}</p>
                </div>
              )}
            </div>
            <div
              className="p-1.5 flex items-center hover:bg-zinc-100 active:bg-zinc-200 rounded-[8px] cursor-pointer select-none transition-[98s]"
              onClick={() => {
                navigate('/shipments');
              }}
            >
              <GoPackage size={28} className="p-1" />
              <p className=" mx-2 whitespace-nowrap select-none text-[15px]">Quản lý vận đơn</p>
            </div>
            <div
              className="p-1.5 flex items-center hover:bg-zinc-100 active:bg-zinc-200 rounded-[8px] cursor-pointer select-none transition-[98s]"
              onClick={() => {
                navigate('/create/text-generating/booking-template');
              }}
            >
              <PiQuotesThin size={28} className="p-1" />
              <p className=" mx-2 whitespace-nowrap select-none text-[15px]">Vận đơn mẫu</p>
            </div>
            {pinnedChat?.length !== 0 && (
              <div>
                <div className="flex items-center justify-between">
                  <p className="text-[13px] font-semibold text-slate-500 mb-1 mt-3">Ghim</p>
                  {pinnedChat.length > 0 && (
                    <p
                      className="text-[15px] mr-1 hover:underline select-none cursor-pointer active:opacity-50"
                      onClick={handleDeleteAllChat}
                    >
                      Xoá hết
                    </p>
                  )}
                </div>
                {pinnedChat.map((_, index) => (
                  <div
                    className={`p-[7px] pr-[10px]  hover:bg-zinc-100 rounded-[6px] flex items-center select-none cursor-pointer transition-[98s]  hover:text-black active:text-black active:bg-zinc-200 group relative ${
                      _.chatId === currentChatId ? 'bg-zinc-100 text-black' : 'text-slate-700'
                    }`}
                    onClick={() => {
                      setMessages(_.inbox);
                      setCurrentChatId(_.chatId);
                    }}
                  >
                    {currentChatId !== _.chatId && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="p-1 rounded-full bg-zinc-100 w-[28px] h-[28px] flex-shrink-0"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
                        />
                      </svg>
                    )}
                    {currentChatId === _.chatId && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="p-1 rounded-full bg-zinc-100 w-[28px] h-[28px] flex-shrink-0"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                        />
                      </svg>
                    )}

                    <p className="w-full ml-2 overflow-hidden whitespace-nowrap  text-[15px]">{_.chatName}</p>
                    <div className="absolute right-1.5 group-hover:flex items-center gap-0.5 hidden">
                      <TbPinnedOff
                        size={32}
                        className="p-1.5 hover:bg-zinc-300 rounded-full bg-white border-slate-300 border-solid border-[1px] active:bg-zinc-400 active:scale-90"
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePinnedChat(_.chatId);
                        }}
                      />
                      <MdOutlineClear
                        size={32}
                        className="p-1.5 hover:bg-zinc-300 rounded-full bg-white border-slate-300 border-solid border-[1px] active:bg-zinc-400 active:scale-90"
                        onClick={(e) => {
                          e.stopPropagation();
                          setMessages([]);
                          setCurrentChatId('');
                          handleDeleteSingleChat(_.chatId);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
            {todayChat?.length !== 0 && (
              <div>
                <div className="flex items-center justify-between">
                  <p className="text-[13px] font-semibold text-slate-500 mb-1 mt-3">Hôm nay</p>
                  {pinnedChat?.length < 1 && (
                    <p
                      className="text-[15px] mr-1 hover:underline select-none cursor-pointer active:opacity-50"
                      onClick={handleDeleteAllChat}
                    >
                      Xoá hết
                    </p>
                  )}
                </div>
                {todayChat.map((_, index) => (
                  <div
                    className={`p-[7px] pr-[10px]  hover:bg-zinc-100 rounded-[6px] flex items-center select-none cursor-pointer transition-[98s]  hover:text-black active:text-black active:bg-zinc-200 group relative ${
                      _.chatId === currentChatId ? 'bg-zinc-100 text-black' : 'text-slate-700'
                    }`}
                    onClick={() => {
                      setMessages(_.inbox);
                      setCurrentChatId(_.chatId);
                    }}
                  >
                    {currentChatId !== _.chatId && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="p-1 rounded-full bg-zinc-100 w-[28px] h-[28px] flex-shrink-0"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
                        />
                      </svg>
                    )}
                    {currentChatId === _.chatId && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="p-1 rounded-full bg-zinc-100 w-[28px] h-[28px] flex-shrink-0"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                        />
                      </svg>
                    )}

                    <p className="w-full ml-2 overflow-hidden whitespace-nowrap  text-[15px]">{_.chatName}</p>
                    <div className="absolute right-1.5 group-hover:flex items-center gap-0.5 hidden">
                      <VscPinned
                        size={32}
                        className="p-1.5 hover:bg-zinc-300 rounded-full bg-white border-slate-300 border-solid border-[1px] active:bg-zinc-400 active:scale-90"
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePinnedChat(_.chatId);
                        }}
                      />
                      <MdOutlineClear
                        size={32}
                        className="p-1.5 hover:bg-zinc-300 rounded-full bg-white border-slate-300 border-solid border-[1px] active:bg-zinc-400 active:scale-90"
                        onClick={(e) => {
                          e.stopPropagation();
                          setMessages([]);
                          setCurrentChatId('');
                          handleDeleteSingleChat(_.chatId);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
            {yesterdayChat?.length !== 0 && (
              <div className="mt-5">
                <div className="flex items-center justify-between">
                  <p className="text-[13px] font-semibold text-slate-500 mb-1 mt-3">Hôm qua</p>
                  {chatHistory?.length > 1 && pinnedChat?.length < 1 && todayChat?.length < 1 && (
                    <p
                      className="text-[15px] mr-1 hover:underline select-none cursor-pointer active:opacity-50"
                      onClick={handleDeleteAllChat}
                    >
                      Xoá hết
                    </p>
                  )}
                </div>
                {yesterdayChat.map((_, index) => (
                  <div
                    className={`p-[7px] pr-[10px]  hover:bg-zinc-100 rounded-[6px] flex items-center select-none cursor-pointer transition-[98s]  hover:text-black active:text-black active:bg-zinc-200 group relative ${
                      _.chatId === currentChatId ? 'bg-zinc-100 text-black' : 'text-slate-700'
                    }`}
                    onClick={() => {
                      setMessages(_.inbox);
                      setCurrentChatId(_.chatId);
                    }}
                  >
                    {currentChatId !== _.chatId && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="p-1 rounded-full bg-zinc-100 w-[28px] h-[28px] flex-shrink-0"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
                        />
                      </svg>
                    )}
                    {currentChatId === _.chatId && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="p-1 rounded-full bg-zinc-100 w-[28px] h-[28px] flex-shrink-0"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                        />
                      </svg>
                    )}
                    <p className="w-full ml-2 overflow-hidden whitespace-nowrap  text-[15px]">{_.chatName}</p>
                    <div className="absolute right-1.5 group-hover:flex items-center gap-0.5 hidden">
                      <VscPinned
                        size={32}
                        className="p-1.5 hover:bg-zinc-300 rounded-full bg-white border-slate-300 border-solid border-[1px] active:bg-zinc-400 active:scale-90"
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePinnedChat(_.chatId);
                        }}
                      />
                      <MdOutlineClear
                        size={32}
                        className="p-1.5 hover:bg-zinc-300 rounded-full bg-white border-slate-300 border-solid border-[1px] active:bg-zinc-400 active:scale-90"
                        onClick={(e) => {
                          e.stopPropagation();
                          setMessages([]);
                          setCurrentChatId('');
                          handleDeleteSingleChat(_.chatId);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
            {otherChat?.length !== 0 && (
              <div className="mt-5">
                <div className="flex items-center justify-between">
                  <p className="text-[13px] font-semibold text-slate-500 mb-1 mt-3">Lịch sử booking</p>
                  {chatHistory?.length > 1 &&
                    pinnedChat?.length < 1 &&
                    todayChat?.length < 1 &&
                    yesterdayChat?.length < 1 && (
                      <p className="text-[15px] mr-1 hover:underline select-none cursor-pointer active:opacity-50">
                        Xoá hết
                      </p>
                    )}
                </div>
                {otherChat.map((_, index) => (
                  <div
                    className={`p-[7px] pr-[10px]  hover:bg-zinc-100 rounded-[6px] flex items-center  cursor-pointer tr-[98s] text-slate-700 hover:text-black active:text-black active:bg-zinc-200 group relative ${
                      _.chatId === currentChatId ? 'bg-zin text-blackc-100text-slate-700' : ''
                    }`}
                    onClick={() => {
                      setMessages(_.inbox);
                      setCurrentChatId(_.chatId);
                    }}
                  >
                    {currentChatId !== _.chatId && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="p-1 rounded-full bg-zinc-100 w-[28px] h-[28px] flex-shrink-0"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
                        />
                      </svg>
                    )}
                    {currentChatId === _.chatId && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="p-1 rounded-full bg-zinc-100 w-[30px] h-[30px] flex-shrink-0"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                        />
                      </svg>
                    )}
                    <p className="w-full ml-2 overflow-hidden whitespace-nowrap  text-[15px]">{_.chatName}</p>
                    <div className="absolute right-1.5 group-hover:flex items-center gap-0.5 hidden">
                      <VscPinned
                        size={32}
                        className="p-1.5 hover:bg-zinc-300 rounded-full bg-white border-slate-300 border-solid border-[1px] active:bg-zinc-400 active:scale-90"
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePinnedChat(_.chatId);
                        }}
                      />
                      <MdOutlineClear
                        size={32}
                        className="p-1.5 hover:bg-zinc-300 rounded-full bg-white border-slate-300 border-solid border-[1px] active:bg-zinc-400 active:scale-90"
                        onClick={(e) => {
                          e.stopPropagation();
                          setMessages([]);
                          setCurrentChatId('');
                          handleDeleteSingleChat(_.chatId);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="mt-auto sticky bottom-0 bg-white z-10 ">
            <div className="flex items-center p-3">
              <p className="text-zinc-500 text-[15px]">Tài khoản hãng ({chatHistory?.length})</p>
              <div
                className={`p-1.5  hover:opacity-70 ml-auto font-bold px-3.5 select-none rounded-full cursor-pointer active:scale-95 ${
                  isMultiColor ? 'bg-gradient-to-tr from-green-600 to-[darkblue] text-white' : 'bg-zinc-100 text-black'
                }`}
                onClick={() => {
                  setDisplayKeyboard(!displayKeyboard);
                }}
              >
                <p>Mục lục</p>
              </div>
            </div>
          </div>
        </div>
        <div
          ref={scrollRef}
          className="w-full overflow-auto h-[100vh] workroom-notebook-center1  bg-zinc-50 flex flex-col"
          style={{
            backgroundPosition: `center ${scrolls}px`,
          }}
          onScroll={handleScroll}
        >
          <div
            className={`sm:flex hidden items-center p-2 gap-1.5 sticky top-0 z-10 ${
              scrolls > 0 ? 'bg-zinc-50/70' : ''
            }`}
          >
            {displaySidebar && (
              <TbLayoutSidebarLeftCollapse
                size={42}
                className="p-1.5 rounded-[8px] bg-white shadow-[rgba(0,0,0,0.05)_0px_0px_0px_1px] hover:bg-zinc-100 active:bg-zinc-200 cursor-pointer"
                onClick={() => {
                  setDisplaySidebar(false);
                }}
              />
            )}
            {!displaySidebar && (
              <IoBookmarksOutline
                size={42}
                className={`p-[9px] rounded-[8px]  shadow-[rgba(0,0,0,0.05)_0px_0px_0px_1px] hover:bg-zinc-100 active:bg-zinc-200 cursor-pointer ${
                  displayPending ? 'bg-zinc-200' : 'bg-white'
                }`}
                onClick={() => {
                  setDisplayPending(!displayPending);
                }}
              />
            )}
            {!displaySidebar && (
              <TbLayoutSidebarRightCollapse
                size={42}
                className="p-1.5 rounded-[8px] bg-white shadow-[rgba(0,0,0,0.05)_0px_0px_0px_1px] hover:bg-zinc-100 active:bg-zinc-200 cursor-pointer"
                onClick={() => {
                  setDisplaySidebar(true);
                }}
              />
            )}
            <div className="p-2 flex items-center rounded-[8px] hover:bg-zinc-200/50 pl-3.5 pr-2.5 active:bg-zinc-200 select-none cursor-pointer">
              <p className="font-semibold  text-xl">ShipScript 1.0</p>
              <FaSortDown size={25} className="mt-[-10px] ml-1" />
            </div>
            <div className="flex items-center ml-auto gap-3">
              <p className="text-[20px] mr-2.5">{formattedDate}</p>
              <div className="flex items-center gap-2.5">
                <div className="checkbox-wrapper-7">
                  <input
                    className="tgl tgl-ios"
                    id="cb2-7"
                    type="checkbox"
                    checked={isMultiColor}
                    onChange={(e) => {
                      setIsMultiColor(e.target.checked);
                    }}
                  />
                  <label className="tgl-btn" htmlFor="cb2-7"></label>
                </div>
                <p className="text-slate-600 text-[15px] font-semibold">Đa sắc</p>
              </div>
              <div className="flex items-center gap-2.5">
                <div className="checkbox-wrapper-7">
                  <input
                    className="tgl tgl-ios"
                    id="cb2-10"
                    type="checkbox"
                    checked={isFontStyle}
                    onChange={(e) => {
                      setIsFontStyle(e.target.checked);
                    }}
                  />
                  <label className="tgl-btn" htmlFor="cb2-10"></label>
                </div>
                <p className="text-slate-600 text-[15px] font-semibold">Chữ kiểu</p>
              </div>
              <div className="flex items-center gap-1.5">
                <div
                  className="p-2  hover:bg-zinc-300/40 rounded-full cursor-pointer active:scale-90"
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  <BsPatchQuestion size={30} className="p-[3px]" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="w-[800px] flex items-center justify-center">
              <WordGenerating
                isMultiColor={isMultiColor}
                isFontStyle={isFontStyle}
                messages={messages}
                user={user}
                handleChildInputChange={handleChildInputChange}
                loading={loading}
                handleSelectInvoiceItem={handleSelectInvoiceItem}
                formattedDate={formattedDate}
                handleBookSingleShipment={handleBookSingleShipment}
                handleDeleteShipment={handleDeleteShipment}
                handleAddSingleShipment={handleAddSingleShipment}
                handleDeleteSingleShipment={handleDeleteSingleShipment}
                currentChatId={currentChatId}
                handleEffectComplete={handleEffectComplete}
                handleEffectCompleteLast={handleEffectCompleteLast}
                serviceArray={serviceArray}
                exportReasonArray={exportReasonArray}
                termArray={termArray}
              />
            </div>
          </div>
          {messages.length === 0 && (
            <div className="flex items-center justify-center mt-[70px] mb-5">
              <div className="text-3xl font-semibold ">
                <p className=" text-center">Xin chào, tôi là ShipScript</p>
                <p
                  className={`text-center tracking-wide ${
                    isMultiColor
                      ? 'text-transparent bg-clip-text bg-gradient-to-r from-emerald-500 to-blue-700'
                      : 'text-zinc-700'
                  }`}
                >
                  Mô hình khởi tạo và xử lý vận đơn bằng văn bản
                </p>
                <p className="text-[16px] font-normal text-zinc-600 text-center">
                  Hãy để tôi rút ngắn quá trình vận chuyển của bạn chỉ với 1 dòng lệnh duy nhất!
                </p>
              </div>
            </div>
          )}
          <div
            className={`sticky bottom-0 z-[9999] w-full flex  justify-center items-center flex-col ${
              messages.length !== 0 ? 'mt-auto' : ''
            }   ${scrolls !== 0 ? 'bg-gradient-to-t from-zinc-50 via-zinc-50/20 to-zinc-50/20' : ''}`}
          >
            <div className="w-[800px] rounded-[20px] relative bg-white m-2 border-zinc-300 border-solid border-[1px] p-2 mb-0">
              {displayService && (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setDisplayService(false);
                  }}
                >
                  <div
                    className={`absolute bg-white left-5  w-[325px]  h-[200px] z-10 overflow-y-auto shadow-[rgba(0,0,139,0.15)_0px_0px_5px_0px,rgba(0,0,0,0.1)_0px_0px_1px_0px] ${
                      messages.length !== 0
                        ? 'top-[-201px] text-country rounded-tr-[20px] rounded-tl-[20px]'
                        : 'bottom-[-201px] text-country1 rounded-br-[20px] rounded-bl-[20px]'
                    }`}
                  >
                    <div className=" p-2 flex items-center sticky top-0 bg-white z-[9999]">
                      <div className="relative flex items-center">
                        <IoSearchOutline size={22} className="absolute left-[7px]" />
                        <input
                          type="text"
                          ref={serviceRef}
                          onChange={handleFilterService}
                          placeholder="Search"
                          autoComplete="off"
                          className="p-[5px] px-3 text-[15px] pl-[36px] bg-zinc-100 rounded-full border-zinc-200 border-solid border-[1px] label-input-text1 focus:bg-white w-[245px] label-input-text1"
                        />
                      </div>
                      <div
                        className="ml-auto w-[35px] h-[35px] rounded-full flex items-center bg-zinc-100  cursor-pointer hover:bg-zinc-200 active:bg-zinc-300 active:scale-90 justify-center"
                        onClick={() => {
                          setDisplayService(false);
                        }}
                      >
                        <MdOutlineClear size={25} />
                      </div>
                    </div>
                    <div className={`flex px-2 flex-col`}>
                      {filteredService.map((c, index) => (
                        <div className="relative">
                          <div
                            className="flex items-center p-1.5 rounded-[8px] hover:bg-zinc-100 cursor-pointer active:bg-zinc-200"
                            onClick={() => {
                              setDisplayService(false);
                              setService(c.label);
                              setService1(c.proxy);
                            }}
                          >
                            <div className="w-[23px] h-[23px] flex items-center justify-center bg-white border-zinc-300 border-solid border-[1px] rounded-full">
                              {service1 === c.proxy && (
                                <div className="w-[12px] h-[12px] rounded-full bg-sky-600 flex items-center justify-center"></div>
                              )}
                            </div>
                            <p
                              className={`text-[15px] ml-2 ${
                                service1 === c.proxy ? 'text-sky-700' : 'text-zinc-600 hover:text-black'
                              } font-medium`}
                            >
                              {c.proxy}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </OutsideClickHandler>
              )}
              {displayState && (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setDisplayState(false);
                  }}
                >
                  <div
                    className={`absolute bg-white left-5  w-[325px]  h-[200px] z-10  overflow-y-auto shadow-[rgba(0,0,139,0.15)_0px_0px_5px_0px,rgba(0,0,0,0.1)_0px_0px_1px_0px] ${
                      messages.length !== 0
                        ? 'top-[-201px] text-country rounded-tr-[20px] rounded-tl-[20px]'
                        : 'bottom-[-201px] text-country1 rounded-br-[20px] rounded-bl-[20px]'
                    }`}
                  >
                    <div className=" p-2 flex items-center sticky top-0 bg-white z-[9999]">
                      <div className="relative flex items-center">
                        <IoSearchOutline size={22} className="absolute left-[7px]" />
                        <input
                          type="text"
                          ref={stateRef}
                          onChange={handleFilterState}
                          placeholder="Search"
                          autoComplete="off"
                          className="p-[5px] px-3 text-[15px] pl-[36px] bg-zinc-100 rounded-full border-zinc-200 border-solid border-[1px] label-input-text1 focus:bg-white w-[245px] label-input-text1"
                        />
                      </div>
                      <div
                        className="ml-auto w-[35px] h-[35px] rounded-full flex items-center bg-zinc-100  cursor-pointer hover:bg-zinc-200 active:bg-zinc-300 active:scale-90 justify-center"
                        onClick={() => {
                          setDisplayState(false);
                        }}
                      >
                        <MdOutlineClear size={25} />
                      </div>
                    </div>
                    <div className={`flex px-2 flex-col`}>
                      {filteredState.map((c, index) => (
                        <div className="relative">
                          <div
                            className="flex items-center p-1.5 rounded-[8px] hover:bg-zinc-100 cursor-pointer active:bg-zinc-200"
                            onClick={() => {
                              setDisplayState(false);
                              setToState(c.code);
                            }}
                          >
                            <div className="w-[23px] h-[23px] flex items-center justify-center bg-white border-zinc-300 border-solid border-[1px] rounded-full">
                              {toState === c.code && (
                                <div className="w-[12px] h-[12px] rounded-full bg-sky-600 flex items-center justify-center"></div>
                              )}
                            </div>
                            <p
                              className={`text-[15px] ml-2 ${
                                toState === c.code ? 'text-sky-700' : 'text-zinc-600 hover:text-black'
                              } font-medium`}
                            >
                              {c.label} ({c.code})
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </OutsideClickHandler>
              )}
              {displayCountry && (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setDisplayCountry(false);
                  }}
                >
                  <div
                    className={`absolute bg-white left-5  w-[325px]  h-[200px] z-10  overflow-y-auto shadow-[rgba(0,0,139,0.15)_0px_0px_5px_0px,rgba(0,0,0,0.1)_0px_0px_1px_0px]  ${
                      messages.length !== 0
                        ? 'top-[-201px] text-country rounded-tr-[20px] rounded-tl-[20px]'
                        : 'bottom-[-201px] text-country1 rounded-br-[20px] rounded-bl-[20px]'
                    }`}
                  >
                    <div className=" p-2 flex items-center sticky top-0 bg-white z-[9999]">
                      <div className="relative flex items-center">
                        <IoSearchOutline size={22} className="absolute left-[7px]" />
                        <input
                          type="text"
                          ref={countryRef}
                          onChange={handleFilter}
                          placeholder="Search"
                          autoComplete="off"
                          className="p-[5px] px-3 text-[15px] pl-[36px] bg-zinc-100 rounded-full border-zinc-200 border-solid border-[1px] label-input-text1 focus:bg-white w-[245px] label-input-text1"
                        />
                      </div>
                      <div
                        className="ml-auto w-[35px] h-[35px] rounded-full flex items-center bg-zinc-100  cursor-pointer hover:bg-zinc-200 active:bg-zinc-300 active:scale-90 justify-center"
                        onClick={() => {
                          setDisplayCountry(false);
                        }}
                      >
                        <MdOutlineClear size={25} />
                      </div>
                    </div>
                    <div className={`flex px-2 flex-col`}>
                      {filteredCountry.map((c, index) => (
                        <div className="relative">
                          <div
                            className="flex items-center p-1.5 rounded-[8px] hover:bg-zinc-100 cursor-pointer active:bg-zinc-200"
                            onClick={() => {
                              setToCountry(c.label);
                              setToCode(c.code);
                              setDisplayCountry(false);
                              setFilteredCountry(countries);
                            }}
                          >
                            <div className="w-[23px] h-[23px] flex items-center justify-center bg-white border-zinc-300 border-solid border-[1px] rounded-full">
                              {toCountry === c.label && (
                                <div className="w-[12px] h-[12px] rounded-full bg-sky-600 flex items-center justify-center"></div>
                              )}
                            </div>
                            <p
                              className={`text-[15px] ml-2 ${
                                toCountry === c.label ? 'text-sky-700' : 'text-zinc-600 hover:text-black'
                              } font-medium`}
                            >
                              {c.label}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </OutsideClickHandler>
              )}
              <TextareaAutosize
                placeholder="Vui lòng nhập chi tiết kiện hàng của bạn"
                className="w-full text-[16px] p-2 pb-10 outline-none resize-none min-h-[35px]"
                ref={textareaRef}
                disabled={isGenerating ? true : false}
                onKeyDown={handleKeyDown2}
                onCompositionStart={handleCompositionStart}
                onCompositionEnd={handleCompositionEnd}
                style={{ boxSizing: 'border-box' }}
              />
              <div className="items-center absolute bottom-1.5 gap-1 w-full hidden sm:flex">
                {messages.length !== 0 && (
                  <div className="bg-zinc-100 hover:bg-zinc-200 active:scale-90 cursor-pointer p-1.5 w-fit rounded-full  active:bg-zinc-300 transition-[95s]">
                    <IoAddSharp size={25} />
                  </div>
                )}

                <div
                  className={`p-1.5 flex items-center rounded-full  px-2.5 gap-2 transition-[95s] cursor-pointer select-none hover:bg-zinc-200 active:bg-zinc-300 ${
                    displayCountry ? 'bg-zinc-200' : 'bg-zinc-100'
                  }`}
                  onClick={() => {
                    setDisplayCountry(!displayCountry);
                  }}
                >
                  <p className="overflow-hidden text-ellipsis whitespace-nowrap max-w-[125px]">{toCountry}</p>
                  {messages.length !== 0 && (
                    <div>
                      {!displayCountry && <IoIosArrowUp size={22} />}
                      {displayCountry && <IoIosArrowDown size={22} />}
                    </div>
                  )}
                  {messages.length === 0 && (
                    <div>
                      {displayCountry && <IoIosArrowUp size={22} />}
                      {!displayCountry && <IoIosArrowDown size={22} />}
                    </div>
                  )}
                </div>
                {toCountry === 'United States' && (
                  <div
                    className={`p-1.5 flex items-center rounded-full  px-2.5 gap-2 transition-[95s] cursor-pointer select-none hover:bg-zinc-200 active:bg-zinc-300 ${
                      displayState ? 'bg-zinc-200' : 'bg-zinc-100'
                    }`}
                    onClick={() => {
                      setDisplayState(!displayState);
                    }}
                  >
                    <p>{toState || 'State'}</p>
                    {messages.length !== 0 && (
                      <div>
                        {!displayState && <IoIosArrowUp size={22} />}
                        {displayState && <IoIosArrowDown size={22} />}
                      </div>
                    )}
                    {messages.length === 0 && (
                      <div>
                        {displayState && <IoIosArrowUp size={22} />}
                        {!displayState && <IoIosArrowDown size={22} />}
                      </div>
                    )}
                  </div>
                )}
                <div className="flex items-center gap-1">
                  <div
                    className="p-1.5 flex items-center bg-zinc-100 rounded-full px-2 transition-[95s] hover:bg-zinc-200 active:bg-zinc-300 cursor-pointer select-none gap-1.5"
                    onClick={() => {
                      setShipmentType('WPX');
                    }}
                  >
                    <p className="font-medium ml-1">WPX</p>
                    <div className="w-[25px] h-[25px] bg-white rounded-full border-zinc-300 border-solid border-[1px] flex items-center justify-center">
                      {shipmentType === 'WPX' && <div className="w-[13px] h-[13px] bg-[darkblue] rounded-full"></div>}
                    </div>
                  </div>
                  <div
                    className="p-1.5 flex items-center bg-zinc-100 rounded-full px-2 transition-[95s] hover:bg-zinc-200 active:bg-zinc-300 cursor-pointer select-none gap-1.5"
                    onClick={() => {
                      setShipmentType('DOX');
                    }}
                  >
                    <p className="font-medium ml-1">DOX</p>
                    <div className="w-[25px] h-[25px] bg-white rounded-full border-zinc-300 border-solid border-[1px] flex items-center justify-center">
                      {shipmentType === 'DOX' && <div className="w-[13px] h-[13px] bg-[darkblue] rounded-full"></div>}
                    </div>
                  </div>
                  <div
                    className={`p-1.5 flex items-center  rounded-full px-2.5 transition-[95s] hover:bg-zinc-200 active:bg-zinc-300 cursor-pointer select-none gap-1.5 ${
                      displayService ? 'bg-zinc-200' : 'bg-zinc-100'
                    }`}
                    onClick={() => {
                      setDisplayService(!displayService);
                    }}
                  >
                    <p className="font-medium ml-1">{service1 || 'Service'}</p>
                    {messages.length !== 0 && (
                      <div>
                        {!displayService && <IoIosArrowUp size={22} />}
                        {displayService && <IoIosArrowDown size={22} />}
                      </div>
                    )}
                    {messages.length === 0 && (
                      <div>
                        {displayService && <IoIosArrowUp size={22} />}
                        {!displayService && <IoIosArrowDown size={22} />}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={`${
                    isMultiColor
                      ? 'bg-gradient-to-tr from-green-600 to-[darkblue] hover:opacity-70 text-white active:opacity-50'
                      : 'bg-zinc-100 text-black hover:bg-zinc-200 active:bg-zinc-300'
                  } active:scale-90 cursor-pointer ml-auto p-1.5 w-fit rounded-full  transition-[95s] mr-[18px]`}
                  onClick={handleRequestModel}
                >
                  {!isGenerating && !loading && <TbPencilUp size={28} />}
                  {isGenerating && !loading && <MdOutlineGeneratingTokens size={28} />}
                  {loading && <LiaTruckLoadingSolid size={28} />}
                </div>
              </div>
            </div>
            <div className="flex mb-2.5 mt-1 items-center justify-center">
              <p className="text-center text-[13px] text-black">
                ShipScript đề xuất: Hãy kiểm tra thật kĩ tất cả thông tin trước khi tạo đơn để tránh những bất trắc
                không đáng có.
              </p>
            </div>
          </div>
          {/* {messages.length === 0 && (
            <div className="mt-5 flex items-center justify-center">
              <div className="flex w-[700px] items-center justify-center flex-wrap gap-2.5">
                <div className="flex items-center p-2 bg-white rounded-full px-3.5 border-zinc-300 border-solid border-[1px] gap-1.5 text-[15px] select-none text-zinc-600 hover:text-black cursor-pointer hover:bg-zinc-100 active:bg-zinc-200 transition-[98s]">
                  <MdTrackChanges size={23} className="text-purple-500" />
                  <p>Tra cứu vận đơn</p>
                </div>
                <div className="flex items-center p-2 bg-white rounded-full px-3.5 border-zinc-300 border-solid border-[1px] gap-1.5 text-[15px] select-none text-zinc-600 hover:text-black cursor-pointer hover:bg-zinc-100 active:bg-zinc-200 transition-[98s]">
                  <TbFileInvoice size={23} className="text-pink-500" />
                  <p>Lấy nhãn</p>
                </div>
                <div
                  className="flex items-center p-2 bg-white rounded-full px-3.5 border-zinc-300 border-solid border-[1px] gap-1.5 text-[15px] select-none text-zinc-600 hover:text-black cursor-pointer hover:bg-zinc-100 active:bg-zinc-200 transition-[98s]"
                  onClick={() => {
                    navigate('/create/text-generating/booking-template');
                  }}
                >
                  <TbPrompt size={23} className="text-teal-500" />
                  <p>Vận đơn mẫu</p>
                </div>
              </div>
            </div>
          )} */}
        </div>
        <div
          className={`${
            displayPending ? 'w-[280px] overflow-y-auto ' : 'w-0 overflow-hidden'
          } h-[100vh] border-r-0 border-l-[1px] border-y-0 border-zinc-100 flex-shrink-0 transition-[75s]  hidden sm:flex sm:flex-col`}
        >
          {displayPending && (
            <div className="p-3 transition-[75s] flex items-center  border-b-[1px] border-zinc-300 border-solid border-t-0 border-x-0 justify-between sticky top-0 bg-white z-10 retriveShipment">
              <p className="w-[200px] overflow-hidden text-ellipsis whitespace-nowrap font-semibold text-black">
                Hàng chờ
              </p>
              <GoMultiSelect
                size={35}
                className="p-1 cursor-pointer hover:bg-zinc-100 rounded-[10px] active:bg-zinc-200 text-slate-600 hover:text-black active:scale-95 ml-auto"
              />
              <TbLayoutSidebarRightCollapse
                size={35}
                className="p-1 cursor-pointer hover:bg-zinc-100 rounded-[10px] active:bg-zinc-200 text-slate-600 hover:text-black active:scale-95 ml-1.5"
                onClick={() => {
                  setDisplayPending(!displayPending);
                }}
              />
            </div>
          )}
          {displayPending && (
            <div className="p-3 transition-[75s] retriveShipment">
              <p className="text-[12px] mb-2">
                Có <span className="text-black">{pendingShipment?.length}</span> đơn chờ khởi tạo
              </p>
              {pendingShipment?.length !== 0 && (
                <div className="relative mb-3 flex items-center">
                  <GoSearch size={20} className="absolute left-2 text-zinc-500" />
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchPending}
                    onChange={(e) => setSearchPending(e.target.value)}
                    className="p-1 w-full border-zinc-400 border-solid border-[1px] rounded-full px-[35px] label-input-text1 focus:border-zinc-200 text-[15px]"
                  />
                </div>
              )}
              {pendingShipment?.length === 0 && (
                <div className="h-full flex items-center justify-center flex-col">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={`w-[45px] h-[45px] p-1.5 rounded-full ${
                      isMultiColor ? 'text-white bg-gradient-to-tr from-green-600 to-[darkblue]' : 'bg-zinc-100'
                    }`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 13.5V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 9.75V10.5"
                    />
                  </svg>
                  <p className="text-zinc-600 mt-2">Không có lô hàng nào đang chờ</p>
                  <div
                    className="p-[7px] rounded-full bg-zinc-100 px-4 hover:bg-zinc-200 active:bg-zinc-300 select-none cursor-pointer mt-2"
                    onClick={() => {
                      setCurrentChatId('');
                      setMessages([]);
                      setDisplayPending(false);
                    }}
                  >
                    <p>Tạo vận đơn ngay</p>
                  </div>
                </div>
              )}
              {pendingShipment?.length !== 0 &&
                pendingShipment
                  .filter((s) => {
                    if (s.hawb.toLowerCase().includes(searchPending.toLowerCase())) {
                      return s;
                    }
                  })
                  .map((_, index) => {
                    const packaging = _?.packageValue?.map((element) => {
                      let dimCharge = 0;
                      // Calculate dimensional charge
                      dimCharge = (element.packageWidth * element.packageHeight * element.packageLength) / 5000;

                      // Determine the package charge based on dimensional weight or actual weight
                      element.packageCharge = Math.max(dimCharge, element.packageWeight || 0);

                      // Rounding logic
                      if (element.packageCharge >= 30) {
                        element.packageCharge = Math.ceil(element.packageCharge); // Round up to the nearest whole number
                      } else if (element.packageCharge > 0) {
                        element.packageCharge = Math.ceil(element.packageCharge * 2) / 2; // Round up to the nearest 0.5
                      } else {
                        element.packageCharge = 0; // Handle invalid or zero weights
                      }

                      // Return the updated package details
                      return {
                        packageLength: element.packageLength,
                        packageWidth: element.packageWidth,
                        packageHeight: element.packageHeight,
                        packageWeight: element.packageWeight,
                        packageCharge: element.packageCharge,
                        packNo: element.packNo,
                      };
                    });
                    const finalCharge = packaging?.reduce((a, v) => (a = +a + +v?.packageCharge), 0);
                    const finalGross = packaging?.reduce((a, v) => (a = +a + +v?.packageWeight), 0);
                    return (
                      <div
                        className={`flex hover:bg-zinc-100 active:bg-zinc-200 group relative cursor-pointer rounded-[5px]`}
                      >
                        <div className="flex flex-col items-start mt-1">
                          <div className="flex items-center">
                            <div
                              className={`w-[15px] h-[15px] rounded-full border-solid border-[1px]  bg-white flex items-center justify-center ${
                                _.isNew ? 'border-[darkblue]' : 'border-zinc-400'
                              }`}
                            >
                              {_.isNew && !_?.bookingSuccess && (
                                <div className="w-[7px] h-[7px] rounded-full bg-[darkblue]"></div>
                              )}
                              {_?.bookingSuccess && <div className="w-[7px] h-[7px] rounded-full bg-emerald-600"></div>}
                            </div>
                            <div className={`w-[15px] h-[1px] ${_.isNew ? 'bg-[darkblue]' : 'bg-zinc-400'}`}></div>
                          </div>
                          <hr className={`h-full w-[1px] ml-[6.5px] ${_.isNew ? 'bg-[darkblue]' : 'bg-zinc-400'}`} />
                        </div>
                        <div className="ml-1.5">
                          <p className={`text-[15px] ${_?.bookingSuccess ? 'line-through text-emerald-500' : ''}`}>
                            {_.hawb}
                          </p>
                          <p className="text-[11px] text-zinc-600">
                            To <span className="font-semibold text-black">{_.code}</span> / {_.shipmentType} /{' '}
                            {_.packageValue?.length} kiện / {finalCharge?.toFixed(2)} KG
                          </p>
                        </div>
                        <div className="absolute right-1 top-1 group-hover:flex hidden items-center">
                          <IoCreateOutline
                            size={30}
                            className="p-[5px] rounded-full hover:bg-zinc-200 cursor-pointer active:scale-90"
                            onClick={() => {
                              handleBookSingleShipment(_, _.belongToMessage);
                            }}
                          />
                          <BsTrash
                            size={30}
                            className="p-[5px] rounded-full hover:bg-zinc-200 cursor-pointer active:scale-90"
                            onClick={() => {
                              handleDeleteSingleShipment(_.hawb, _.belongToMessage, _.belongTo);
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
            </div>
          )}
          {!displayPending && (
            <div className="sticky retriveShipment bg-white z-10 mt-auto bottom-0 p-3 border-t-[1px] border-solid border-zinc-300 transition-[75s]">
              <p className="text-[12px]">
                <span className="text-[darkblue] font-semibold underline italic">Xin lưu ý:</span> Hãy chắc chắn rằng
                bạn đã hiểu rõ và chấp thuận với công thức tính cước cũng như các chính sách vận chuyển quốc tế chung
                của chúng tôi trước khi tạo vận đơn để tránh những bất trắc và các tình huống đáng tiếc có thể xảy ra
                trong quá trình vận chuyển đơn hàng đến tay người nhận cuối cùng.
              </p>
              {pendingShipment?.length > 0 && (
                <div className="flex items-center mt-2.5">
                  <div
                    className="p-[5px] bg-zinc-100 rounded-full px-3.5 cursor-pointer select-none hover:bg-zinc-200 border-solid border-[1px] border-zinc-400 active:bg-zinc-300 transition-[98s]"
                    onClick={() => handleBookMultipleShipment('new')}
                  >
                    <p>Book tất cả</p>
                  </div>
                  <div
                    className="p-[5px] ml-1 bg-rose-50 rounded-full px-3.5 cursor-pointer select-none hover:bg-rose-100 active:bg-rose-200 text-rose-500 transition-[98s] border-solid border-[1px] border-rose-300"
                    onClick={handleDeleteAllShipment}
                  >
                    <p>Xoá tất cả</p>
                  </div>
                </div>
              )}
              <p className="mt-2.5 text-[15px] text-zinc-500">Skydart Express © 2025</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
