import React, { useState, useEffect, useRef } from 'react';

export default function TitleWordEffect({ text, onComplete, isSeen, isMono }) {
  const [displayedCharacters, setDisplayedCharacters] = useState([]);
  const hasRunOnce = useRef(false);
  useEffect(() => {
    if (!isSeen && !hasRunOnce.current) {
      let currentIndex = 0;
      const interval = setInterval(() => {
        if (currentIndex < text.length) {
          const nextChar = text[currentIndex];
          setDisplayedCharacters((prev) => [...prev, { char: nextChar, color: 'black' }]);
          currentIndex++;
        } else {
          clearInterval(interval);
          if (onComplete) {
            onComplete();
          }
        }
      }, 15);

      return () => clearInterval(interval);
    } else {
      setDisplayedCharacters(
        text.split('').map((t) => {
          return {
            char: t,
            color: 'black',
          };
        })
      );
    }
  }, [text, isSeen]);
  return (
    <div className="tracking-wide">
      {displayedCharacters.map((item, index) => (
        <span
          key={index}
          style={{
            color: 'rgb(39,39,42)',
          }}
        >
          {item.char}
        </span>
      ))}
    </div>
  );
}
