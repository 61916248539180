export const vietReplies = [
  'Cảm ơn bạn đã đặt hàng! Lô hàng của bạn đang được xử lý với sự quan tâm chu đáo.',
  'Gói hàng của bạn chuẩn bị bước vào cuộc hành trình thú vị và an toàn và nhanh chóng!',
  'Chúng tôi đang chăm sóc lô hàng của bạn như chăm sóc một báu vật. Hãy yên tâm!',
  'Đặt hàng thành công! Gói hàng của bạn sẽ được nâng niu trên mọi chặng đường.',
  'Hãy thư giãn và để chúng tôi xử lý gói hàng của bạn với tình yêu thương.',
  'Gói hàng của bạn giờ đã là thành viên của gia đình chúng tôi—hãy an tâm!',
  'Hãy nghĩ gói hàng của bạn như một vị khách VIP—dịch vụ hàng đầu đang chờ đón!',
  'Quyết định đúng đắn khi chọn chúng tôi! Chúng tôi sẽ làm bạn ấn tượng.',
  'Gói hàng của bạn là ngôi sao sáng nhất hôm nay—hãy để điều kỳ diệu xảy ra!',
  'Cảm ơn bạn đã tin tưởng giao phó gói hàng. Chúng tôi hứa sẽ chăm sóc chu đáo.',
  'Chúng tôi đang trải thảm đỏ cho gói hàng của bạn. Hãy yên tâm và tận hưởng!',
  'Gói hàng của bạn đã bước vào thiên đường giao nhận—đảm bảo chính xác và an toàn.',
  'Đặt hàng thành công! Chúng tôi sẽ giao nhanh hơn bạn nghĩ.',
  'Cảm ơn bạn đã đặt hàng! Chúng tôi sẽ xử lý nhanh chóng và hiệu quả.',
  'Lô hàng của bạn giống như một nhiệm vụ bí mật—được thực hiện một cách kín đáo và an toàn.',
  'Chúng tôi đang chăm sóc gói hàng của bạn như chăm sóc một kho báu quý giá.',
  'Cảm ơn bạn đã chọn chúng tôi! Cuộc hành trình của gói hàng sẽ trơn tru và nhanh chóng.',
  'Từ khi đặt hàng đến khi giao hàng, chúng tôi luôn sẵn sàng làm bạn hài lòng.',
  'Gói hàng của bạn đang trong những bàn tay an toàn nhất. Chúng tôi là ninja của ngành giao nhận!',
  'Đặt hàng đã xác nhận! Chúng tôi sẽ mang đến nụ cười cùng với gói hàng của bạn.',
  'Gói hàng của bạn đang bắt đầu một hành trình tuyệt vời, chúng tôi sẽ đồng hành cùng bạn!',
  'Cảm ơn bạn! Chúng tôi sẽ giao gói hàng của bạn nhanh và hiệu quả nhất có thể.',
  'Đặt hàng của bạn làm chúng tôi rất vui, và chúng tôi sẽ đảm bảo bạn hài lòng!',
  'Chúng tôi xem gói hàng của bạn như món quà quý giá nhất, hãy tin tưởng!',
  'Cảm ơn bạn đã lựa chọn chúng tôi. Hãy để chúng tôi mang đến dịch vụ tốt nhất!',
  'Mỗi gói hàng đều là một câu chuyện, chúng tôi sẽ giúp câu chuyện của bạn có kết thúc đẹp.',
  'Hãy nghĩ rằng gói hàng của bạn là ngôi sao hôm nay, và chúng tôi sẽ giữ ánh sáng đó!',
  'Cảm ơn bạn! Chúng tôi sẽ làm mọi thứ để gói hàng của bạn đến đúng giờ và nguyên vẹn.',
  'Chúng tôi xử lý gói hàng của bạn như cách chăm sóc một viên ngọc quý—tỉ mỉ và chu đáo!',
  'Lô hàng của bạn như thành viên gia đình chúng tôi, chúng tôi sẽ chăm sóc tận tình!',
  'Đặt hàng thành công! Chúng tôi sẵn sàng mang lại nụ cười qua dịch vụ của mình!',
  'Cảm ơn Quý khách đã tin tưởng đặt hàng! Đơn hàng của Quý khách đang được xử lý một cách cẩn thận và chuyên nghiệp.',
  'Gói hàng của Quý khách đã sẵn sàng cho hành trình vận chuyển. Chúng tôi cam kết giao đến đúng hẹn và an toàn nhất có thể.',
  'Chúng tôi đang chuẩn bị để đơn hàng của Quý khách được vận chuyển một cách trơn tru, nhanh chóng và chính xác.',
  'Đơn hàng đã được tiếp nhận! Chúng tôi sẽ theo dõi chặt chẽ và cập nhật thông tin khi có tiến triển mới nhất.',
  'Gói hàng của Quý khách sẽ được xử lý cẩn thận, đảm bảo đến nơi một cách an toàn và nguyên vẹn.',
  'Cảm ơn Quý khách! Đơn hàng của Quý khách đang được ưu tiên xử lý để sớm đến tay người nhận.',
  'Chúng tôi trân trọng từng đơn hàng và cam kết mang đến trải nghiệm vận chuyển tốt nhất cho Quý khách.',
  'Hành trình giao hàng của Quý khách đã bắt đầu! Chúng tôi sẽ đảm bảo mọi thứ diễn ra đúng kế hoạch.',
  'Mỗi đơn hàng đều quan trọng với chúng tôi. Chúng tôi sẽ theo dõi sát sao để đảm bảo dịch vụ tốt nhất.',
  'Cảm ơn Quý khách đã lựa chọn dịch vụ của chúng tôi! Gói hàng đang trên đường đến với Quý khách.',
  'Gói hàng của Quý khách được xử lý với độ chính xác cao nhất. Chúng tôi sẽ cập nhật khi có thông tin mới!',
  'Quý khách có thể hoàn toàn yên tâm! Đơn hàng sẽ được vận chuyển với sự quan tâm đặc biệt.',
  'Hãy tin tưởng rằng chúng tôi đang làm việc hết mình để đơn hàng của Quý khách đến đúng thời gian cam kết.',
  'Cảm ơn Quý khách đã đặt hàng! Chúng tôi cam kết mang đến dịch vụ vận chuyển đáng tin cậy nhất.',
  'Gói hàng của Quý khách đang được đóng gói cẩn thận và chuẩn bị cho quá trình vận chuyển.',
  'Chúng tôi luôn đặt sự hài lòng của Quý khách lên hàng đầu. Đơn hàng sẽ sớm đến tay Quý khách!',
  'Mỗi kiện hàng đều được xử lý với sự tỉ mỉ, nhằm mang lại sự an tâm cho Quý khách.',
  'Chúng tôi trân trọng từng đơn hàng và sẽ đảm bảo rằng Quý khách nhận được dịch vụ tốt nhất.',
  'Giao hàng nhanh chóng, an toàn và đúng hẹn là tiêu chí hàng đầu của chúng tôi dành cho Quý khách.',
  'Đơn hàng của Quý khách đã được tiếp nhận và xử lý ngay lập tức để tránh bất kỳ sự chậm trễ nào.',
  'Mỗi đơn hàng đều là một cam kết chất lượng. Chúng tôi sẽ thực hiện đúng những gì đã hứa.',
  'Hãy yên tâm, đơn hàng của Quý khách đang trên đường đến và chúng tôi sẽ hỗ trợ mọi lúc khi cần thiết.',
  'Cảm ơn Quý khách! Chúng tôi sẽ theo sát đơn hàng để đảm bảo mọi thứ diễn ra theo đúng kế hoạch.',
  'Đơn hàng của Quý khách đã được xác nhận. Chúng tôi sẽ thông báo ngay khi có tiến triển mới nhất!',
  'Với sự chuyên nghiệp và tận tâm, chúng tôi cam kết mang lại trải nghiệm vận chuyển tốt nhất cho Quý khách.',
  'Chúng tôi đã tiếp nhận đơn hàng và đang làm việc để giao hàng nhanh nhất có thể.',
  'Hãy yên tâm, Quý khách đã chọn đúng dịch vụ đáng tin cậy! Chúng tôi sẽ chăm sóc đơn hàng của Quý khách.',
  'Mỗi kiện hàng đều được xử lý với sự cẩn trọng tối đa để đảm bảo an toàn và nguyên vẹn.',
  'Chúng tôi hiểu rằng đơn hàng của Quý khách quan trọng. Vì vậy, chúng tôi sẽ theo dõi sát sao cho đến khi hàng đến nơi.',
  'Chúng tôi cam kết giao hàng đúng hẹn và luôn hỗ trợ Quý khách trong suốt quá trình vận chuyển.',
  'Đơn hàng của Quý khách đã được chuẩn bị xong và sẵn sàng rời kho. Chúng tôi sẽ cập nhật khi có thông tin mới.',
  'Gói hàng của Quý khách là ưu tiên hàng đầu của chúng tôi. Hãy yên tâm trong suốt quá trình giao nhận!',
  'Chúng tôi cam kết mang lại dịch vụ giao hàng hiệu quả, đúng tiến độ và không để Quý khách phải chờ lâu.',
  'Hành trình của đơn hàng đã bắt đầu! Chúng tôi sẽ giám sát kỹ lưỡng để đảm bảo chất lượng dịch vụ.',
  'Quý khách có thể hoàn toàn tin tưởng rằng gói hàng sẽ được xử lý với sự quan tâm tối đa.',
  'Đơn hàng của Quý khách đang được vận chuyển với quy trình chuyên nghiệp nhất để đảm bảo an toàn tuyệt đối.',
  'Chúng tôi rất vui khi được phục vụ Quý khách! Hãy theo dõi đơn hàng và liên hệ nếu cần thêm hỗ trợ.',
  'Hãy để chúng tôi mang đến trải nghiệm giao hàng nhanh chóng và thuận tiện nhất cho Quý khách.',
  'Cảm ơn Quý khách đã sử dụng dịch vụ của chúng tôi! Gói hàng của Quý khách sẽ được giao một cách an toàn.',
  'Chúng tôi luôn đặt sự hài lòng của Quý khách lên hàng đầu. Đơn hàng sẽ đến sớm nhất có thể!',
  'Từ lúc tiếp nhận đến khi giao hàng, chúng tôi cam kết hỗ trợ Quý khách từng bước trong quá trình vận chuyển.',
  'Chúng tôi luôn theo dõi sát sao từng đơn hàng để đảm bảo mọi thứ diễn ra suôn sẻ và đúng lịch trình.',
  'Cảm ơn Quý khách đã tin tưởng! Chúng tôi sẽ cập nhật ngay khi có thông tin mới về đơn hàng.',
  'Hành trình giao hàng của Quý khách đã bắt đầu! Chúng tôi cam kết đảm bảo an toàn tuyệt đối.',
  'Gói hàng của Quý khách đang trên đường đến. Hãy yên tâm vì chúng tôi đang theo dõi từng bước một!',
  'Mỗi kiện hàng đều được chúng tôi bảo vệ cẩn thận để đảm bảo giao đến Quý khách trong tình trạng tốt nhất.',
  'Chúng tôi cam kết cung cấp dịch vụ giao hàng chính xác, kịp thời và đáng tin cậy.',
  'Chúng tôi luôn đồng hành cùng Quý khách trong suốt quá trình vận chuyển, đảm bảo hàng đến đúng thời gian dự kiến.',
  // Add more unique replies here until 500 entries
];
