export const serviceArray = [
  { label: 'SKD', proxy: 'SKD', board: 'abc' },
  { label: 'DHL', proxy: 'SDH', board: 'dhl', eam: 'INT' },
  { label: 'UPS', proxy: 'SUPS', board: 'ups' },
  { label: 'FIP', proxy: 'FIP', board: 'fedex' },
  { label: 'FIE', proxy: 'FIE', board: 'fie' },
  { label: 'F3P', proxy: 'F3P', board: 'f3p' },
  { label: 'D3P', proxy: 'D3P', board: 'abc' },
  { label: 'OCS', proxy: 'OCS', board: 'abc' },
  { label: 'DHL-VN', proxy: 'VDH', board: 'abc' },
  { label: 'Fedex-VN', proxy: 'VIP', board: 'abc' },
  { label: 'UPS-VN', proxy: 'VUPS', board: 'abc' },
  { label: 'DAU', proxy: 'DAU', board: 'abc' },
  { label: 'DEU', proxy: 'DEU', board: 'abc' },
  { label: 'FIP-DXB', proxy: 'FIP-DXB', board: 'abc' },
  { label: 'UPS-DXB', proxy: 'UPS-DXB', board: 'abc' },
  { label: 'DHL-DXB', proxy: 'DHL-DXB', board: 'abc' },
  { label: 'DUS', proxy: 'DUS', board: 'dus' },
];
