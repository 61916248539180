import Masonry from '@mui/lab/Masonry';
import React, { useState, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { BsBoxSeam } from 'react-icons/bs';
import { CiTextAlignLeft } from 'react-icons/ci';
import { FaQuestion } from 'react-icons/fa6';
import { GrLocation } from 'react-icons/gr';
import { IoIosSearch } from 'react-icons/io';
import { MdOutlineClear } from 'react-icons/md';
import { RiArrowDownSLine } from 'react-icons/ri';
import { addressArray } from './addressArray';
import { dimensionArray } from './dimensionArray';

export default function ShipScriptKeyboard({
  reset,
  user,
  countries,
  serviceArray,
  exportReasonArray,
  handleShowHelp,
}) {
  const [seeMoreCountry, setSeeMoreCountry] = useState(10);
  const [seeMoreService, setSeeMoreService] = useState(4);
  const [currentCountry, setCurrentCountry] = useState(countries);
  const [loadingContent, setLoadingContent] = useState('');

  const handleLoadMoreCountry = () => {
    setSeeMoreCountry((prev) => prev + 20);
  };
  const handleSearchCountry = (text) => {
    if (text.trim() !== '') {
      const newCountry = countries.filter((s) => {
        return s.label.toLowerCase().startsWith(text.toLowerCase());
      });
      setCurrentCountry(newCountry);
    } else {
      setCurrentCountry(countries);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/80 flex items-center justify-center z-[10000] p-4 retriveShipment">
      <div className="bg-white w-[90%] overflow-auto h-[90%] rounded-[20px] relative">
        <div className="px-5 p-2 bg-zinc-100 rounded-tr-[20px] rounded-tl-[20px] sticky  top-0 z-[9999] flex items-center">
          <p className="font-medium">
            <span className="text-zinc-600">Mục lục & từ khoá ShipScript</span> | {user.exporter} | {user.uid}
          </p>
          <div
            className="w-[35px] h-[35px] flex items-center justify-center rounded-full hover:bg-zinc-200 active:bg-zinc-300 cursor-pointer transition-[95s] ml-auto"
            onClick={() => {
              reset();
              handleShowHelp();
            }}
          >
            <FaQuestion size={20} className="p-[1px]" />
          </div>
          <div
            className="w-[35px] h-[35px] flex items-center justify-center rounded-full hover:bg-zinc-200 active:bg-zinc-300 cursor-pointer transition-[95s] ml-1"
            onClick={() => reset()}
          >
            <MdOutlineClear size={20} />
          </div>
        </div>
        <div className="p-2">
          <div className="flex gap-1">
            <div className=" p-2 w-[50%] sticky left-0 top-[59px] max-h-[calc(100vh_-_11rem)] overflow-auto">
              <p className="text-[15px] p-1">Danh sách các quốc gia:</p>
              <div className="relative flex items-center my-1 mb-2">
                <IoIosSearch size={23} className="absolute text-zinc-500 left-2 " />
                <input
                  type="text"
                  className="border-solid border-zinc-300 border-[1px] p-1 rounded-full text-[14px] px-[20px] pl-[32px] w-[70%] outline-none label-input-text1 "
                  placeholder="Tìm kiếm quốc gia"
                  onBlur={(e) => handleSearchCountry(e.target.value)}
                />
              </div>
              {currentCountry.map((c, index) => {
                return (
                  <div className="m-1 bg-zinc-50 flex items-center justify-between py-1 rounded-[5px]">
                    <div>
                      <p className="font-semibold tracking-wide leading-5">
                        {c.label} ({c.code})
                      </p>
                      <p className="text-[13px] text-zinc-500">{c.label_vn}</p>
                    </div>
                    <div className="mr-3 flex-shrink-0">
                      <CopyToClipboard onCopy={() => {}} text={c.label}>
                        <p className="text-[15px] cursor-pointer select-none hover:underline hover:opacity-70 active:opacity-50">
                          Sao chép
                        </p>
                      </CopyToClipboard>
                    </div>
                  </div>
                );
              })}
              {/* <div className="p-2 flex items-center justify-center bg-zinc-100 rounded-[8px] cursor-pointer hover:bg-zinc-200 active:bg-zinc-300 transition-[95s] select-none font-medium mt-2 sticky bottom-0 z-10">
                <p>Xem thêm</p>
                <RiArrowDownSLine size={28} />
              </div> */}
            </div>
            <Masonry columns={2} spacing={2}>
              <div className=" p-2 border-b-[1px] border-solid border-zinc-300">
                <p className="text-[15px] p-1">Danh sách các dịch vụ mà chúng tôi cung cấp:</p>
                {serviceArray.map((s, index) => {
                  return (
                    <div className="m-1  flex items-center justify-between py-1 rounded-[5px]">
                      <div>
                        <p className="font-semibold tracking-wide leading-5">{s.proxy}</p>
                        <p className="text-[13px] text-zinc-500">{s.label}</p>
                      </div>
                      <div className="mr-3">
                        <CopyToClipboard onCopy={() => {}} text={s.proxy}>
                          <p className="text-[15px] cursor-pointer select-none hover:underline hover:opacity-70 active:opacity-50">
                            Sao chép
                          </p>
                        </CopyToClipboard>
                      </div>
                    </div>
                  );
                })}
                {/* <div className="p-2 flex items-center justify-center bg-zinc-100 rounded-[8px] cursor-pointer hover:bg-zinc-200 active:bg-zinc-300 transition-[95s] select-none font-medium mt-2">
                  <p>Xem thêm</p>
                  <RiArrowDownSLine size={28} />
                </div> */}
              </div>
              <div className=" p-2 border-b-[1px] border-solid border-zinc-300">
                <p className="text-[15px] p-1">Lý do gửi hàng:</p>
                {exportReasonArray.map((s, index) => {
                  return (
                    <div className="m-1  flex items-center justify-between py-1 rounded-[5px]">
                      <div>
                        <p className="font-semibold tracking-wide leading-5">{s.value}</p>
                        <p className="text-[13px] text-zinc-500">{s.label}</p>
                      </div>
                      <div className="mr-3 flex-shrink-0">
                        <CopyToClipboard onCopy={() => {}} text={s.value}>
                          <p className="text-[15px] cursor-pointer select-none hover:underline hover:opacity-70 active:opacity-50">
                            Sao chép
                          </p>
                        </CopyToClipboard>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className=" p-2 border-b-[1px] border-solid border-zinc-300">
                <p className="text-[15px] p-1">Loại hàng hoá & hình thức vận chuyển:</p>
                {[
                  { label: 'WPX', value: 'Worldwide Parcel Express' },
                  { label: 'DOX', value: 'Document' },
                ].map((s, index) => {
                  return (
                    <div className="m-1  flex items-center justify-between py-1 rounded-[5px]">
                      <div>
                        <p className="font-semibold tracking-wide leading-5">{s.label}</p>
                        <p className="text-[13px] text-zinc-500">{s.value}</p>
                      </div>
                      <div className="mr-3 flex-shrink-0">
                        <CopyToClipboard onCopy={() => {}} text={s.label}>
                          <p className="text-[15px] cursor-pointer select-none hover:underline hover:opacity-70 active:opacity-50">
                            Sao chép
                          </p>
                        </CopyToClipboard>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className=" p-2 border-b-[1px] border-solid border-zinc-300">
                <p className="text-[15px] p-1">
                  Đóng gói: (Chỉ áp dụng với chứng từ của hàng{' '}
                  <span className="font-semibold text-[darkblue]">"WPX"</span>)
                </p>
                {['Bag', 'Box', 'Carton', 'Wooden Pack'].map((s, index) => {
                  return (
                    <div className="m-1  flex items-center justify-between py-1 rounded-[5px]">
                      <div>
                        <p className="font-semibold tracking-wide leading-5">{s}</p>
                      </div>
                      <div className="mr-3 flex-shrink-0">
                        <CopyToClipboard onCopy={() => {}} text={s}>
                          <p className="text-[15px] cursor-pointer select-none hover:underline hover:opacity-70 active:opacity-50">
                            Sao chép
                          </p>
                        </CopyToClipboard>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className=" p-2 border-b-[1px] border-solid border-zinc-300">
                <p className="text-[15px] p-1">
                  Đơn vị: (Chỉ áp dụng với chứng từ của hàng{' '}
                  <span className="font-semibold text-[darkblue]">"WPX"</span>)
                </p>
                {['PCS', 'PAIR', 'KG', 'SET', 'BAG'].map((s, index) => {
                  return (
                    <div className="m-1  flex items-center justify-between py-1 rounded-[5px]">
                      <div>
                        <p className="font-semibold tracking-wide leading-5">{s}</p>
                      </div>
                      <div className="mr-3 flex-shrink-0">
                        <CopyToClipboard onCopy={() => {}} text={s}>
                          <p className="text-[15px] cursor-pointer select-none hover:underline hover:opacity-70 active:opacity-50">
                            Sao chép
                          </p>
                        </CopyToClipboard>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className=" p-2  rounded-[8px] bg-zinc-50">
                <p className="text-[18px] p-1 font-semibold text-black">
                  Chứng từ: (Chỉ áp dụng với <span className="font-semibold text-[darkblue]">WPX</span>)
                </p>
                <div className="p-1 py-2 text-[15px] whitespace-pre-wrap border-b-[1px] border-solid border-zinc-300 flex items-start">
                  <div className="w-[32px] h-[32px] rounded-full flex items-center justify-center bg-zinc-100 flex-shrink-0 mr-2">
                    <CiTextAlignLeft size={23} className="p-0.5" />
                  </div>
                  <p className="">{`chứng từ: [Tên hàng hóa] ([Số lượng]) [Giá tiền/đơn vị tính/NSX: [Nhà sản xuất], [Địa chỉ sản xuất]/[Mã sản xuất]/Thành phần: [Thành phần]/Nhãn hiệu: [Thương hiệu]/[Mã chứng nhận]/[Loại đóng gói]/use for: [Mục đích sử dụng] ], transport: [Chi phí vận chuyển], [Mục đích gửi], eori: [Mã EORI nếu có], Gửi đến: [Tên công ty/đơn vị nhận hàng]`}</p>
                </div>
                <div className="p-1 py-2 text-[15px] whitespace-pre-wrap  flex items-start">
                  <div className="w-[32px] h-[32px] rounded-full flex items-center justify-center bg-zinc-100 flex-shrink-0 mr-2">
                    <CiTextAlignLeft size={23} className="p-0.5" />
                  </div>
                  <p className="">{`invoice: [Item Name] ([Quantity]) [[Unit Price/Currency]/Manufacturer: [Manufacturer],[Address]/[Date]/
Ingredients: [Ingredients]/ Brand: [Brand]/ FDA: [FDA Code]/ [Packaging]/ Use for: [Usage] ],
[Item Name] ([Quantity]) [[Unit Quantity]/Manufacturer: [Manufacturer],[Address]/[Date]/
Ingredients: [Ingredients]/ Brand: [Brand]/ [Packaging]/ Use for: [Usage] ],`}</p>
                </div>
              </div>
              <div className=" p-2  rounded-[8px] bg-zinc-50">
                <p className="text-[18px] p-1 font-semibold text-black">
                  Kiện hàng: (Chỉ áp dụng với <span className="font-semibold text-[darkblue]">WPX</span>)
                </p>
                {dimensionArray.map((dim) => (
                  <div className="p-1 py-2 text-[15px] whitespace-pre-wrap border-b-[1px] border-solid border-zinc-300 flex items-start">
                    <div className="w-[32px] h-[32px] rounded-full flex items-center justify-center bg-zinc-100 flex-shrink-0 mr-2">
                      <BsBoxSeam size={23} className="p-0.5" />
                    </div>
                    <p className="mt-1">{dim}</p>
                  </div>
                ))}
              </div>
              <div className=" p-2  rounded-[8px] bg-zinc-50">
                <p className="text-[18px] p-1 font-semibold text-black">Địa chỉ mẫu:</p>
                {addressArray.map((text) => (
                  <div className="p-1 py-2 text-[15px] whitespace-pre-wrap border-b-[1px] border-solid border-zinc-300 flex items-start">
                    <div className="w-[32px] h-[32px] rounded-full flex items-center justify-center bg-zinc-100 flex-shrink-0 mr-2">
                      <GrLocation size={23} className="p-0.5" />
                    </div>
                    <p className="mt-1">{text}</p>
                  </div>
                ))}
              </div>
              <div className=" p-2 border-b-[1px] border-solid border-zinc-300">
                <p className="text-[15px] p-1">Từ khoá:</p>
                {[
                  { label: 'eori:', value: 'Mã EORI' },
                  { label: 'IOSS:', value: 'Mã IOSS' },
                  { label: 'TIN:', value: 'Mã TIN' },
                  { label: 'transport:', value: 'Phụ phí vận chuyển' },
                  { label: 'transportation:', value: 'Phụ phí vận chuyển' },
                ].map((s, index) => {
                  return (
                    <div className="m-1  flex items-center justify-between py-1 rounded-[5px]">
                      <div>
                        <p className="font-semibold tracking-wide leading-5">{s.label}</p>
                        <p className="text-[13px] text-zinc-500">{s.value}</p>
                      </div>
                      <div className="mr-3 flex-shrink-0">
                        <CopyToClipboard onCopy={() => {}} text={s.label}>
                          <p className="text-[15px] cursor-pointer select-none hover:underline hover:opacity-70 active:opacity-50">
                            Sao chép
                          </p>
                        </CopyToClipboard>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Masonry>
          </div>
        </div>
      </div>
    </div>
  );
}
