import { CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { CiLocationOn } from 'react-icons/ci';
import { FaHome, FaSearch, FaShippingFast } from 'react-icons/fa';
import { HiMiniWallet } from 'react-icons/hi2';
import { SlSettings } from 'react-icons/sl';

import { FaPlaneDeparture, FaPrint, FaSortDown } from 'react-icons/fa6';
import { FcOk } from 'react-icons/fc';
import { FiPackage } from 'react-icons/fi';
import { IoAddSharp, IoCalendar, IoDocumentText, IoDocumentTextOutline, IoFolderOpenOutline } from 'react-icons/io5';
import { LuFilter } from 'react-icons/lu';
import { useNavigate, useParams } from 'react-router-dom';
import skydartLogo from '../../../skydartLogo.png';
import { MdFindInPage, MdOutlineBuild, MdOutlineClear, MdOutlineEventNote, MdTransitEnterexit } from 'react-icons/md';
import { SiDocsdotrs } from 'react-icons/si';
import { db } from '../../../server/firebase';
import { useReactToPrint } from 'react-to-print';
import { TbLayoutSidebarLeftCollapse, TbLayoutSidebarRightCollapse } from 'react-icons/tb';
import OutsideClickHandler from 'react-outside-click-handler';
import { IoIosAddCircleOutline } from 'react-icons/io';

export default function TrackingResult() {
  const [loadingTracking, setLoadingTracking] = useState(true);
  const { hawb, uid } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showProcess, setShowProcess] = useState(true);
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [showTracking, setShowTracking] = useState(true);
  const [showDetail, setShowDetail] = useState(true);
  const [selectedShipments, setSelectedShipments] = useState([]);
  const [searchShipment, setSearchShipment] = useState('');
  const [searchBy, setSearchBy] = useState('HAWB');
  const [searchType, setSearchType] = useState('');
  const [searchSin, setSearchSin] = useState('');
  const [searchCountry, setSearchCountry] = useState('');
  const [searchContact, setSearchContact] = useState('');
  const [currentShipment, setCurrentShipment] = useState({});
  const [currentTracking, setCurrentTracking] = useState([]);
  const [shipments, setShipments] = useState([]);
  const [showSidebar, setShowSidebar] = useState(true);
  const [showShipment, setShowShipment] = useState(false);
  const [showPrint, setShowPrint] = useState(false);
  const [estimatedDelivery, setEstimatedDelivery] = useState('');
  const apiUrl = 'https://api.ship24.com/public/v1/tracking/search';
  const apiKey = 'apik_raV6hsDLxCdSzMiJhg1AFvKp2jieYt'; //
  const fetchData = (providedTracking) => {
    setLoadingTracking(true);
    const requestData = {
      trackingNumber: providedTracking,
    };
    setTimeout(async () => {
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${apiKey}`,
          },
          body: JSON.stringify(requestData),
        });

        if (response.ok) {
          const data = await response.json();
          console.log(data);
          // Handle the data returned from the API
          setEstimatedDelivery(data.data.trackings[0].shipment.delivery.estimatedDeliveryDate);
          setCurrentTracking(
            data.data.trackings[0].events.length === 0
              ? [{ status: 'Shipment does not linked or has been delivered for a long time', location: '' }]
              : data.data.trackings[0].events
          );
          setLoadingTracking(false);
        } else {
          // Handle the error
          setLoadingTracking(false);
          setCurrentTracking([{ status: 'Shipment information received', location: '' }]);
          //   navigate('/');
        }
      } catch (error) {
        setCurrentTracking([{ status: 'Shipment information received', location: '' }]);
        console.error('Error fetching data', error);
      }
    }, 1500);
  };
  const trackArray = hawb.split(/[,]+/);
  const fetchShipment = () => {
    setLoading(true);
    setLoadingTracking(true);
    db.collection('shipments')
      .where('hawb', 'in', trackArray)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          alert('Shipments are not exists, please try another tracking number');
          navigate('/');
        } else {
          setTimeout(() => {
            setLoading(false);
            let data = snapshot.docs.map((doc) => doc.data());
            setShipments(data);
            setCurrentShipment(data[0]);
            fetchData(data[0]?.sin);
          }, 1500);
        }
      });
  };

  useEffect(() => {
    if (hawb) {
      fetchShipment();
    }
  }, [hawb]);

  const formatDateTime = (inputDateTime) => {
    const dateTime = new Date(inputDateTime);
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return dateTime.toLocaleDateString('en-US', options);
  };
  const trackingResultRef = useRef(null);
  const trackingEventRef = useRef(null);
  const printTrackingResult = useReactToPrint({
    content: () => trackingResultRef.current,
  });
  const printTrackingEvent = useReactToPrint({
    content: () => trackingEventRef.current,
  });

  const finalShipment = shipments.filter((s) => {
    return (
      s.hawb.toLowerCase().includes(searchShipment.toLowerCase()) &&
      s.shipmentType.toLowerCase().includes(searchType.toLowerCase()) &&
      s.sin.toLowerCase().includes(searchSin.toLowerCase()) &&
      s.toCountry.toLowerCase().startsWith(searchCountry.toLowerCase()) &&
      s.toName.toLowerCase().includes(searchContact.toLowerCase())
    );
  });

  const wpxShipment = shipments.filter((s) => {
    return s.shipmentType === 'WPX';
  });
  const doxShipment = shipments.filter((s) => {
    return s.shipmentType === 'DOX';
  });

  const fwdTrackingCss = uid
    ? 'w-full h-full overflow-auto fixed top-0 right-0 bottom-0 left-0 z-[9999] bg-white'
    : 'w-full h-full overflow-auto';

  return (
    <div className={fwdTrackingCss}>
      {/* <div className="w-[200px] h-[200px] bg-rose-500 text-white" onClick={fetchData}>
        <p>Test</p>
      </div> */}
      <div className="m-3.5 flex items-center">
        {showSidebar && (
          <div
            className={`bg-white w-[65px] ${
              uid ? 'h-[calc(100vh_-_2.5rem)]' : 'h-[calc(100vh_-_5.5rem)]'
            } mr-4 shadow-[rgba(0,0,0,0.1)_0px_4px_6px_-1px,rgba(0,0,0,0.06)_0px_2px_4px_-1px] rounded-[10px]`}
          >
            <div className="flex items-center flex-col">
              <div className="w-[32px] h-[32px] rounded-[10px] flex items-center justify-center hover:bg-slate-200 active:bg-slate-300 cursor-pointer mt-2 ">
                <IoAddSharp size={30} />
              </div>
              <div
                className="w-[32px] h-[32px] rounded-[10px] flex items-center justify-center hover:bg-slate-200 active:bg-slate-300 cursor-pointer mt-2"
                onClick={() => {
                  navigate('/');
                }}
              >
                <FaHome size={27} />
              </div>
              <div className="relative">
                <div
                  className={`w-[32px] h-[32px] rounded-[10px] flex items-center justify-center hover:bg-slate-200 active:bg-slate-300 cursor-pointer mt-2 ${
                    searchType === '' ? 'bg-emerald-50 text-emerald-500' : ''
                  }`}
                  onClick={() => {
                    setSearchType('');
                  }}
                >
                  <IoFolderOpenOutline size={27} />
                </div>
                {shipments.length !== 0 && (
                  <div className="absolute z-10 right-[-13px] top-[-3px] bg-rose-600 text-white font-bold text-[10px] p-[3px] min-w-[20px] rounded-full flex items-center justify-center">
                    <p>{shipments.length}</p>
                  </div>
                )}
              </div>
              <div className="relative">
                <div
                  className={`w-[32px] h-[32px] rounded-[10px] flex items-center justify-center hover:bg-slate-200 active:bg-slate-300 cursor-pointer mt-2 ${
                    searchType === 'WPX' ? 'bg-emerald-50 text-emerald-500' : ''
                  }`}
                  onClick={() => {
                    setSearchType('WPX');
                  }}
                >
                  <FiPackage size={27} />
                </div>
                {wpxShipment.length !== 0 && (
                  <div className="absolute z-10 right-[-13px] top-[-3px] bg-rose-600 text-white font-bold text-[10px] p-[3px] min-w-[20px] rounded-full flex items-center justify-center">
                    <p>{wpxShipment.length}</p>
                  </div>
                )}
              </div>
              <div className="relative">
                <div
                  className={`w-[32px] h-[32px] rounded-[10px] flex items-center justify-center hover:bg-slate-200 active:bg-slate-300 cursor-pointer mt-2 ${
                    searchType === 'DOX' ? 'bg-emerald-50 text-emerald-500' : ''
                  }`}
                  onClick={() => {
                    setSearchType('DOX');
                  }}
                >
                  <IoDocumentTextOutline size={27} />
                </div>
                {doxShipment.length !== 0 && (
                  <div className="absolute z-10 right-[-13px] top-[-3px] bg-rose-600 text-white font-bold text-[10px] p-[3px] min-w-[20px] rounded-full flex items-center justify-center">
                    <p>{doxShipment.length}</p>
                  </div>
                )}
              </div>
              <div className="relative mt-auto">
                <div
                  className={`w-[32px] h-[32px] rounded-[10px] flex items-center justify-center hover:bg-slate-200 active:bg-slate-300 cursor-pointer mt-2 `}
                >
                  <SlSettings size={27} />
                </div>
                {doxShipment.length !== 0 && (
                  <div className="absolute z-10 right-[-13px] top-[-3px] bg-rose-600 text-white font-bold text-[10px] p-[3px] min-w-[20px] rounded-full flex items-center justify-center">
                    <p>{doxShipment.length}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {!showSidebar && (
          <div
            className={`bg-white ${
              uid ? 'h-[calc(100vh_-_2.5rem)]' : 'h-[calc(100vh_-_5.5rem)]'
            } shadow-[rgba(0,0,0,0.1)_0px_4px_6px_-1px,rgba(0,0,0,0.06)_0px_2px_4px_-1px] rounded-[10px] overflow-auto w-[52px]`}
          >
            <div className="p-2 flex items-center">
              <div
                className="w-[32px] h-[32px] rounded-[10px] bg-slate-100 border-solid border-slate-200 border-[1px] hover:bg-slate-200 active:bg-slate-300 active:scale-90 cursor-pointer"
                onClick={() => {
                  setShowSidebar(!showSidebar);
                }}
              >
                <TbLayoutSidebarRightCollapse size={29} />
              </div>
            </div>
          </div>
        )}
        {showSidebar && (
          <div
            className={`bg-white w-[500px] ${
              uid ? 'h-[calc(100vh_-_2.5rem)]' : 'h-[calc(100vh_-_5.5rem)]'
            } shadow-[rgba(0,0,0,0.1)_0px_4px_6px_-1px,rgba(0,0,0,0.06)_0px_2px_4px_-1px] rounded-[10px] overflow-auto`}
          >
            <div className="p-2 flex items-center">
              <p className="text-xl font-semibold text-slate-600">Tracking delivery</p>
              <div
                className="w-[32px] h-[32px] rounded-[10px] bg-slate-100 border-solid border-slate-200 border-[1px] hover:bg-slate-200 active:bg-slate-300 active:scale-90 cursor-pointer ml-auto"
                onClick={() => {
                  setShowSidebar(!showSidebar);
                }}
              >
                <TbLayoutSidebarLeftCollapse size={29} />
              </div>
            </div>
            <div className="flex items-center sticky top-0 bg-white z-10">
              <div className="flex items-center relative m-1.5">
                <FaSearch className="absolute left-2 text-slate-500" />
                <input
                  type="text"
                  value={
                    searchBy === 'HAWB'
                      ? searchShipment
                      : searchBy === 'Receipient'
                        ? searchContact
                        : searchBy === 'REF'
                          ? searchSin
                          : searchBy === 'Country'
                            ? searchCountry
                            : ''
                  }
                  onChange={(e) => {
                    if (searchBy === 'HAWB') {
                      setSearchShipment(e.target.value);
                    } else if (searchBy === 'Receipient') {
                      setSearchContact(e.target.value);
                    } else if (searchBy === 'REF') {
                      setSearchSin(e.target.value);
                    } else if (searchBy === 'Country') {
                      setSearchCountry(e.target.value);
                    }
                  }}
                  placeholder="Search HAWB..."
                  className="p-2 rounded-[10px] bg-slate-100 pl-[30px] newBooking-search w-[291px] focus:bg-white border-slate-300 border-solid border-[1px]"
                />
              </div>
              <OutsideClickHandler
                onOutsideClick={() => {
                  setShowFilterOption(false);
                }}
              >
                <div className="relative">
                  <div
                    className={`w-[42px] border-slate-200 border-solid border-[1px] cursor-pointer h-[42px] rounded-[10px] flex items-center justify-center ${
                      showFilterOption ? 'bg-black text-white' : 'bg-slate-100 hover:bg-slate-200 active:bg-slate-300'
                    }`}
                    onClick={() => {
                      setShowFilterOption(!showFilterOption);
                    }}
                  >
                    <LuFilter size={23} />
                  </div>
                  {showFilterOption && (
                    <div className="absolute z-10 bg-white rounded-[10px] w-[230px] right-0 shadow-[rgba(60,64,67,0.15)_0px_1px_2px_0px,rgba(60,64,67,0.15)_0px_1px_3px_1px] mt-2">
                      <div
                        className="p-1.5 cursor-pointer hover:bg-slate-100 active:bg-slate-300 select-none m-1 rounded-[9px] px-3 flex items-center justify-between"
                        onClick={() => {
                          setSearchBy('HAWB');
                          setShowFilterOption(false);
                        }}
                      >
                        <p>By HAWB</p>
                        {searchBy === 'HAWB' && <FcOk size={23} />}
                      </div>
                      <div
                        className="p-1.5 cursor-pointer hover:bg-slate-100 active:bg-slate-300 select-none m-1 rounded-[9px] px-3 flex items-center justify-between"
                        onClick={() => {
                          setSearchBy('Receipient');
                          setShowFilterOption(false);
                        }}
                      >
                        <p>By Receipient Name</p>
                        {searchBy === 'Receipient' && <FcOk size={23} />}
                      </div>
                      <div
                        className="p-1.5 cursor-pointer hover:bg-slate-100 active:bg-slate-300 select-none m-1 rounded-[9px] px-3 flex items-center justify-between"
                        onClick={() => {
                          setSearchBy('REF');
                          setShowFilterOption(false);
                        }}
                      >
                        <p>By Ref Number</p>
                        {searchBy === 'REF' && <FcOk size={23} />}
                      </div>
                      <div
                        className="p-1.5 cursor-pointer hover:bg-slate-100 active:bg-slate-300 select-none m-1 rounded-[9px] px-3 flex items-center justify-between"
                        onClick={() => {
                          setSearchBy('Country');
                          setShowFilterOption(false);
                        }}
                      >
                        <p>By Country</p>
                        {searchBy === 'Country' && <FcOk size={23} />}
                      </div>
                    </div>
                  )}
                </div>
              </OutsideClickHandler>
            </div>
            {loading &&
              trackArray?.map((item) => (
                <div className="flex flex-col">
                  <div className="m-2 rounded-[10px] bg-slate-100">
                    <div className="p-2">
                      <div className="flex items-start">
                        <CircularProgress size={35} style={{ marginTop: 4 }} />
                        <div className="ml-1.5 mt-1">
                          <p className="text-lg font-semibold leading-4">{item}</p>
                          <p className="text-slate-500 bg-slate-200 h-[20px] rounded-full text-[15px] mt-[2px]"></p>
                        </div>
                        {/* <div className="ml-auto mr-1">
                      <p className="font-semibold bg-[darkblue] text-white p-0.5 px-1.5 rounded-[9px]">200.00 KG</p>
                    </div> */}
                        <div className="ml-auto mr-1">
                          <p className="font-semibold bg-[darkblue] text-white p-0.5 px-1.5 rounded-[9px]">Loading</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {!loading &&
              finalShipment.map((element) => (
                <div className="flex flex-col">
                  <div
                    className={`m-2 rounded-[10px] transition-[0.1s] cursor-pointer  ${
                      currentShipment?.hawb !== element?.hawb
                        ? 'bg-slate-100 hover:bg-slate-200 active:bg-slate-300'
                        : 'bg-[darkblue] text-white'
                    }`}
                    onClick={() => {
                      if (currentShipment.hawb === element.hawb) return;
                      setCurrentShipment(element);
                      fetchData(element.sin);
                    }}
                  >
                    <div className="p-2">
                      <div className="flex items-start">
                        {element.shipmentType === 'WPX' && <FiPackage size={35} className="mt-1" />}
                        {element.shipmentType === 'DOX' && <IoDocumentText size={35} className="mt-1" />}
                        <div className="ml-1.5 mt-1">
                          <p className="text-lg font-semibold leading-4">{element?.hawb}</p>
                          <p className="text-slate-500 rounded-full text-[15px] mt-[2px]">REF: {element.sin}</p>
                        </div>
                        {/* <div className="ml-auto mr-1">
                      <p className="font-semibold bg-[darkblue] text-white p-0.5 px-1.5 rounded-[9px]">200.00 KG</p>
                    </div> */}
                        <div className="ml-auto mr-1">
                          <p className="font-semibold bg-[darkblue] text-white p-0.5 px-1.5 rounded-[9px]">
                            {element.totalCharge} KG
                          </p>
                        </div>
                      </div>
                      <div className="flex mt-1 mx-0.5">
                        <p>Status: {element.status}</p>
                        <p className="ml-auto underline mr-1.5 text-[purple]">
                          {element.service === 'DHL' ? 'SDH' : element.service}
                        </p>
                      </div>
                      <div className="flex mt-1">
                        <div className="w-[150px] ml-0.5 mr-2">
                          <p className="leading-4 mt-1 text-slate-500 text-[15px] break-words">Sender:</p>
                          <p className="font-semibold w-full break-words">{element.exporter}</p>
                        </div>
                        <div className="w-[150px]">
                          <p className="leading-4 mt-1 text-slate-500 text-[15px]">Receiver:</p>
                          <p className="font-semibold w-full break-words">{element.toName}</p>
                        </div>
                      </div>
                      <div className="flex mt-1">
                        <div className="w-[150px]">
                          <p className="leading-4 mt-1 text-slate-500 text-[15px]">Ship from:</p>
                          <p className="font-semibold w-full break-words">{element.fromCity}</p>
                        </div>
                        <div className="w-[150px]">
                          <p className="leading-4 mt-1 text-slate-500 text-[15px]">Ship to:</p>
                          <p className="font-semibold w-full break-words">{element.toCountry}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
        <div
          className={`bg-white w-full ml-4 shadow-[rgba(0,0,0,0.1)_0px_4px_6px_-1px,rgba(0,0,0,0.06)_0px_2px_4px_-1px] ${
            uid ? 'h-[calc(100vh_-_2.5rem)]' : 'h-[calc(100vh_-_5.5rem)]'
          } rounded-[10px] overflow-auto`}
        >
          <div className="flex p-3 items-start sticky top-0 z-10 bg-white border-solid border-[1px] border-b-emerald-500 border-t-0 border-r-0 border-l-0">
            <div className="mr-auto">
              <OutsideClickHandler
                onOutsideClick={() => {
                  setShowShipment(false);
                }}
              >
                <div className="relative">
                  <div
                    className="flex items-center border-slate-300 border-solid border-[1px] p-1 rounded-[9px] cursor-pointer hover:bg-slate-100 active:bg-slate-200 px-3"
                    onClick={() => {
                      setShowShipment(!showShipment);
                    }}
                  >
                    <p className="text-slate-500">
                      Skydart tracking number:{' '}
                      <span className="text-[darkblue] underline">{currentShipment?.hawb}</span>
                    </p>
                    <FaSortDown size={23} className="mt-[-10px] ml-2" />
                  </div>
                  {showShipment && (
                    <div className="absolute bg-white mt-1.5 w-full shadow-[rgba(60,64,67,0.15)_0px_1px_2px_0px,rgba(60,64,67,0.15)_0px_1px_3px_1px] rounded-[10px]">
                      {shipments.map((item) => (
                        <div
                          className="p-1.5 hover:bg-slate-100 active:bg-slate-200 flex items-center m-0.5 rounded-[10px] cursor-pointer"
                          onClick={() => {
                            if (currentShipment?.hawb === item.hawb) setShowShipment(false);
                            setCurrentShipment(item);
                            fetchData(item.sin);
                            setShowShipment(false);
                          }}
                        >
                          <FiPackage size={28} />
                          <div className="ml-1.5">
                            <p>{item.hawb}</p>
                            <p className="text-[13px] text-slate-500 leading-3">{item.totalCharge} KG</p>
                          </div>
                          <div className="w-[20px] h-[20px] rounded-full bg-white border-solid border-slate-300 border-[1px] flex items-center justify-center ml-auto">
                            <div className="w-[12px] h-[12px] flex items-center justify-center rounded-full bg-sky-600"></div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </OutsideClickHandler>
              <p className="text-slate-500 ml-1">
                REF number: <span className="font-bold">{currentShipment?.sin?.replace(/\s/g, '')}</span>
              </p>
            </div>
            <OutsideClickHandler
              onOutsideClick={() => {
                setShowPrint(false);
              }}
            >
              <div className="relative">
                <div
                  className="p-1.5 rounded-[10px] text-[darkblue] flex items-center ml-auto px-3 hover:bg-slate-100 active:bg-slate-200 select-none cursor-pointer"
                  onClick={() => {
                    setShowPrint(!showPrint);
                  }}
                  style={{ border: '1px solid darkblue' }}
                >
                  <FaPrint size={25} />
                  <p className="mx-2 text-lg">Print</p>
                  <FaSortDown size={25} className="mt-[-10px]" />
                </div>
                {showPrint && (
                  <div className="absolute bg-white shadow-[rgba(60,64,67,0.15)_0px_1px_2px_0px,rgba(60,64,67,0.15)_0px_1px_3px_1px] right-0 mt-2 rounded-[10px] w-[230px]">
                    <div
                      className="p-1.5 px-3 flex items-center hover:bg-slate-100 active:bg-slate-200 cursor-pointer select-none"
                      onClick={() => {
                        printTrackingResult();
                      }}
                    >
                      <MdFindInPage size={25} />
                      <p className="mx-1.5">Print tracking result</p>
                    </div>
                    <div
                      className="p-1.5 px-3 flex items-center hover:bg-slate-100 active:bg-slate-200 cursor-pointer select-none"
                      onClick={printTrackingEvent}
                    >
                      <MdOutlineEventNote size={25} />
                      <p className="mx-1.5">Print tracking events</p>
                    </div>
                  </div>
                )}
              </div>
            </OutsideClickHandler>
          </div>
          {!loadingTracking && currentTracking && (
            <div className="tracking-printing" ref={trackingResultRef}>
              <div className="p-3 border-solid border-[1px] border-b-emerald-500 border-t-0 border-r-0 border-l-0">
                <div
                  className="flex items-center justify-between cursor-pointer"
                  onClick={() => {
                    setShowProcess(!showProcess);
                  }}
                >
                  <div className="flex items-center">
                    <MdOutlineBuild size={23} />
                    <p className="text-xl font-semibold ml-2">Process events</p>
                  </div>
                  <div className="w-[30px] h-[30px] rounded-[10px] bg-slate-200 flex items-center justify-center mr-1 hover:bg-slate-300 active:bg-slate-300 active:scale-90 cursor-pointer">
                    <FaSortDown size={25} className="mt-[-9.5px]" />
                  </div>
                </div>
                {showProcess && (
                  <div className=" bg-slate-100 p-1 mt-2 rounded-[5px]">
                    {currentShipment?.desc?.map((status) => (
                      <div className="m-[10px] my-[2px] bg-white border-solid border-[1px] border-slate-200 rounded-[5px] flex items-center">
                        <div
                          className="w-[31%] flex items-center p-2 rounded-l-[5px] text-[darkblue]"
                          style={{ border: '1px solid rgb(100,100,100)' }}
                        >
                          <IoCalendar size={23} />
                          <p className="w-full overflow-hidden text-ellipsis text-[darkblue] whitespace-nowrap text-center mx-1.5">
                            {formatDateTime(status?.time)}
                          </p>
                        </div>

                        <div className="flex items-center">
                          <img
                            src={skydartLogo}
                            alt=""
                            loading="lazy"
                            className="w-[25px] h-[25px] object-contain mx-2"
                          />
                          <p className="">{status?.stat}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div
                className="p-3 border-solid border-[1px] border-b-emerald-500 border-t-0 border-r-0 border-l-0"
                ref={trackingEventRef}
              >
                <div
                  className="flex items-center justify-between cursor-pointer"
                  onClick={() => {
                    setShowTracking(!showTracking);
                  }}
                >
                  <div className="flex items-center">
                    <FaPlaneDeparture size={23} />
                    <p className="text-xl font-semibold ml-2">Tracking events</p>
                  </div>
                  <div className="w-[30px] h-[30px] rounded-[10px] bg-slate-200 flex items-center justify-center mr-1 hover:bg-slate-300 active:bg-slate-300 active:scale-90 cursor-pointer">
                    <FaSortDown size={25} className="mt-[-9.5px]" />
                  </div>
                </div>
                {showTracking && (
                  <div className=" bg-slate-100 p-1 mt-2 rounded-[5px] flex flex-col">
                    {currentTracking &&
                      currentTracking.map((item) => (
                        <div className="m-[10px] my-[9px]">
                          <div className="bg-white border-solid border-[1px] border-slate-200 rounded-[5px] flex items-center">
                            <div
                              className={`w-[31%] flex items-center p-2 rounded-l-[5px] text-[darkblue] ${
                                item?.status !== 'Delivered'
                                  ? 'border-slate-700 border-solid border-[1px]'
                                  : 'border-emerald-500 border-dashed border-2'
                              }`}
                            >
                              <IoCalendar size={23} />
                              <p className="w-full overflow-hidden text-ellipsis text-[darkblue] whitespace-nowrap text-center mx-1.5">
                                {formatDateTime(item.occurrenceDatetime)}
                              </p>
                            </div>
                            <div className="flex items-center w-[69%]">
                              {item?.status === 'Delivered' && <FcOk size={25} className="ml-2" />}
                              {item?.status !== 'Delivered' && <MdTransitEnterexit size={25} className="ml-2" />}

                              <p
                                className={`overflow-hidden w-full text-ellipsis whitespace-nowrap px-2 ${
                                  item?.status === 'Delivered' ? 'text-green-600 font-semibold' : ''
                                }`}
                              >
                                {item?.status}
                              </p>
                            </div>
                          </div>
                          <div className="w-full flex items-end">
                            <div className="flex flex-col">
                              <hr className="h-[37px] w-[1px] ml-3 bg-black" />
                              <div className="w-[12px] h-[12px] rounded-full bg-white border-black border-solid border-[1px] flex items-center justify-center ml-1.5">
                                <div className="w-[7px] h-[7px] rounded-full bg-black flex items-center justify-center"></div>
                              </div>
                            </div>
                            <hr className="w-[100%] mb-1 h-[2px] bg-black" />
                            <div className="bg-white m-1.5 rounded-[10px] flex items-center w-full shadow-[rgba(0,0,0,0.1)_0px_4px_6px_-1px,rgba(0,0,0,0.06)_0px_2px_4px_-1px] text-center">
                              <CiLocationOn size={27} className="ml-1 text-[darkblue]" />

                              <p className="skydart-main-primary-left-header mr-2 ml-1.5 w-full overflow-hidden whitespace-nowrap text-ellipsis">
                                {item.location || 'VIETNAM - VIETNAM'}
                              </p>
                            </div>
                            <hr className="w-[100%] mb-1 h-[2px] bg-black" />
                            <div className="flex flex-col ml-auto">
                              <hr className="h-[37px] w-[1px] bg-black ml-1.5" />
                              <div className="w-[12px] h-[12px] rounded-full bg-white border-black border-solid border-[1px] flex items-center justify-center mr-1.5">
                                <div className="w-[7px] h-[7px] rounded-full bg-black flex items-center justify-center"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
              <div className="p-3">
                <div
                  className="flex items-center justify-between cursor-pointer"
                  onClick={() => {
                    setShowDetail(!showDetail);
                  }}
                >
                  <div className="flex items-center">
                    <SiDocsdotrs size={23} />
                    <p className="ml-2 text-xl font-semibold">Shipment info</p>
                  </div>
                  <div className="w-[30px] h-[30px] rounded-[10px] bg-slate-200 flex items-center justify-center mr-1 hover:bg-slate-300 active:bg-slate-300 active:scale-90 cursor-pointer">
                    <FaSortDown size={25} className="mt-[-9.5px]" />
                  </div>
                </div>
                {showDetail && (
                  <div className="p-3">
                    <div className="flex items-center mb-2">
                      <p className="text-slate-500">Destination: </p>
                      <img
                        src={`https://flagsapi.com/${currentShipment?.code}/shiny/64.png`}
                        className="w-[28px] h-[28px] mx-2"
                      />
                      <p className="text-2xl font-semibold">
                        {currentShipment?.toCountry}, {currentShipment?.toCity}
                      </p>
                    </div>
                    <p className="text-xl">{currentShipment?.totalCharge} KG</p>
                    <p className="text-xl">{currentShipment?.type}</p>
                    <div className="">
                      <p>{currentShipment?.packageValue?.length} Pieces</p>
                      <p className="text-slate-400 text-[13px] mt-2">Delivery schedule:</p>
                      {currentTracking && currentTracking[0].status === 'Delivered' && (
                        <p className="text-2xl font-semibold text-[darkblue]">
                          Estimated delivery datetime:{' '}
                          <span className="text-green-600">
                            Delivered at {formatDateTime(currentTracking[0].occurrenceDatetime)}
                          </span>
                        </p>
                      )}
                      {currentTracking && currentTracking[0].status !== 'Delivered' && (
                        <p className="text-2xl font-semibold text-[darkblue]">
                          Estimated delivery datetime:{' '}
                          <span className="underline">
                            {estimatedDelivery === null ? '3 - 7 business days' : formatDateTime(estimatedDelivery)}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                )}
                <div className="mt-2.5 text-[blue] cursor-pointer hover:underline w-fit active:opacity-50 select-none">
                  <p className="text-lg">Download tracking result</p>
                </div>
              </div>
            </div>
          )}
          {loadingTracking && (
            <div className="flex items-center justify-center w-full h-full">
              <CircularProgress />
              <p className="font-semibold text-slate-600 tracking-wide ml-3.5">Loading, getting tracking result...</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
