export const formatArray = [
  {
    content: `Tôi muốn gửi hàng đi Canada, địa chỉ điểm đến là 123456 THE W3E ST, ELMONTE, CA, 91732, liên hệ: Nguyen Van Canh, nguyenvan.c@gmail.com, 2000123456, bao gồm 3 kiện hàng mỗi kiện như sau: 30x20x20 - 20kg, 40x40x10 - 2kg, 34x20x43 - 10kg, SDH WPX, hoá đơn: Thắt lưng (5) [2/pcs/NSX:Deedword,5b Pho Quang/230922/Thành phần: 100% Cotton/ Nhãn hiệu: Gucci/ FDA: ???/ BAG/ dùng cho: lam dep anh oi ], Giày (14) [10/pcs/Manufacture:Skydart Express,32 Lam Son/502303/Thành phần: 100% thep/ Brand: LV/fda: ???/ BOX/ mục đích: dưỡng da cho đẹp mắt], transport:20, GIFT, eori: ???, Gửi đến: Costco`,
  },
  {
    content: `23 Kg / Dim 25x14x22cm
D: 25 x R: 14 x C: 22 cm, Nặng: 35 Kg 
L: 25 x W: 14 x H: 22 cm, Weight: 15 Kg 
Người nhận: Nguyen Van A,n.a@gmail.com, 0123456789;
123456 THE W3E ST, ELMONTE, CA, 72038
USA,
invoice: Thắt lưng (5) [2/pcs/NSX:Deedword,5b Pho Quang/230922/Thành phần: 100% Cotton/ Nhãn hiệu: Gucci/ FDA: iowejfiqwfoiw iwoej/ BAG/ use for: lam dep anh oi ], Giày (14) [10/pcs/Manufacture:Skydart Express,32 Lam Son/502303/Ingredients: 100% thep/ Brand: LV/ BOX/ mục đích: dưỡng da cho đẹp mắt],
SDH WPX EXW eori: ???, transport:20, GIFT, gửi đến: Skydart Express`,
  },
  {
    content: `Receiver: Ngo Thi B,ngo.b@gmail.com, 0239-209-318,
123456 THE W3E ST, ELMONTE, CA, 72038
Australia,
GIFT,
invoice: Belt (5) [2/pcs/NSX:Deedword,5b Ba Dinh/230922/Ingredients: 100% Cotton/ Brand: H&M/ FDA: iowejfiqwfoiw iwoej/ BAG/ use for: beauty beauty ], Shoes (14) [10/pcs/Manufacture:Skydart Express,32 Lam Son/502303/Ingredients: 100% thep/ Brand: LV/ BOX/ use for: dưỡng da cho đẹp mắt],
L: 25 x W: 14 x H: 22 cm, Weight: 15 Kg,
L: 25 x W: 14 x H: 22 cm, Weight: 50 Kg, 
SDH WPX EXW, TO: Skydart Express`,
  },
  {
    content: `123456 THE W3E ST, ELMONTE, CA, 91732, USA, liên hệ: Nguyen Van Canh, nguyenvan.c@gmail.com, 30x20x20 - 20kg, 40x40x10 - 2kg, 34x20x43 - 10kg, SDH WPX, Chứng từ: Thắt lưng (5) [2/pcs/NSX:Deedword,5b Pho Quang/230922/Thành phần: 100% Cotton/ Nhãn hiệu: Gucci/ FDA: ???/ BAG/ dùng cho: lam dep anh oi ], Giày (14) [10/pcs/Manufacture:Skydart Express,32 Lam Son/502303/Thành phần: 100% thep/ Brand: LV/fda: ???/ BOX/ mục đích: dưỡng da cho đẹp mắt], transport:20, GIFT, eori:???, WPX, FIP, to:Skydart Express`,
  },
  {
    content: `📦 23 Kg / 📏 Dim 25x14x22cm  
  📏 D: 25 x R: 14 x C: 22 cm, 📦 Nặng: 35 Kg  
  📏 L: 25 x W: 14 x H: 22 cm, 📦 Weight: 15 Kg  
  
  👤 Người nhận: Nguyen Van A, ✉️ n.a@gmail.com, 📞 0123456789;  
  🏠 123456 THE W3E ST, ELMONTE, CA, 72038  
  USA  
  
  🧾 chứng từ: Thắt lưng (5) [2/pcs/NSX:Deedword,5b Pho Quang/230922/Thành phần: 100% Cotton/ Nhãn hiệu: Gucci/ FDA: ???/ BAG/ use for: lam dep anh oi ], Giày (14) [10/pcs/Manufacture:Skydart Express,32 Lam Son/502303/Ingredients: 100% thep/ Brand: LV/ BOX/ mục đích: dưỡng da cho đẹp mắt],  
  
  📌 SDH WPX EXW eori: ???, 🚛 transport:20, 🎁 GIFT, 📮 gửi đến: Skydart Express `,
  },
];
