import Masonry from '@mui/lab/Masonry';
import { TextField } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import WordGeneratorEffect from './components/WordGeneratorEffect';
import { useState, useEffect, useRef } from 'react';
import { HiArrowsExpand } from 'react-icons/hi';
import { BsQrCode } from 'react-icons/bs';
import TitleWordEffect from './components/TitleWordEffect';
export default function DoxScript({
  chat,
  handleChildInputChange,
  serviceArray,
  exportReasonArray,
  handleDeleteShipment,
  handleAddSingleShipment,
  handleDeleteSingleShipment,
  handleBookSingleShipment,
  type,
  skydartLogo,
  currentStep,
  isMultiColor,
  handleEffectCompleteLast,
  handleEffectComplete,
  isFontStyle,
}) {
  const [seeMore, setSeeMore] = useState(false);
  const [weight, setWeight] = useState(0.5);
  const navigate = useNavigate();
  return (
    <div>
      <div className="pb-2.5">
        <WordGeneratorEffect
          isSeen={chat.isSeen}
          isFontStyle={isFontStyle}
          text={chat?.subText[0].value}
          delay={50}
          onComplete={() => {
            handleEffectComplete(chat.id);
          }}
          isColor={true}
          isMultiColor={isMultiColor}
        />
      </div>
      {currentStep > 0 && (
        <div
          className={`bg-white border-zinc-300 border-solid border-[1px] w-full ${
            seeMore ? '' : chat.isBooking ? '' : 'h-[380px]'
          } overflow-hidden  rounded-[17px] shadow-[rgba(0,0,0,0.1)_0px_4px_12px] cursor-pointer relative doxScript-box transition-[95s]`}
          onClick={() => {
            if (chat.isBooking) return;
            setSeeMore(!seeMore);
          }}
        >
          <div className=" bg-white p-2.5 pb-1 px-4 flex items-center border-b-[1px] border-solid border-zinc-200">
            {currentStep > 0 && (
              <div className="font-medium text-zinc-500 font-mono">
                <TitleWordEffect
                  isSeen={chat.isSeen}
                  text={`Khởi tạo chứng từ (DOX): ${chat.shipment.hawb}`}
                  onComplete={() => {
                    handleEffectComplete(chat.id);
                  }}
                />
              </div>
            )}
            <div className="w-[30px] h-[30px] rounded-full cursor-pointer flex items-center justify-center hover:bg-zinc-100 active:bg-zinc-200 ml-auto text-zinc-400">
              <HiArrowsExpand size={23} />
            </div>
          </div>
          <div className="flex items-start m-2 gap-3 mx-3.5">
            <div className="w-[370px]">
              {currentStep > 1 && (
                <div className="flex rounded-[6px] overflow-hidden w-[370px] border-solid flex-shrink-0 border-[1px] border-black bg-white fadebox-stg">
                  <div className="w-full border-r-[1px] border-solid border-black">
                    <div>
                      <div className="bg-blue-300 p-0.5 px-1.5 border-b-[1px] border-solid border-black mb-0.5">
                        <p className="text-[7px] font-semibold">From (Exporter)</p>
                      </div>
                      <div className="px-1.5 flex items-start text-[6px]">
                        <p>
                          Name / Company: <span className="font-semibold">{chat.shipment.exporter}</span>
                        </p>
                      </div>
                      <div className="flex items-start text-[6px] px-1.5">
                        <p>
                          Address:{' '}
                          <span className="font-semibold">
                            {chat.shipment.fromAddress}, {chat.shipment.fromAddress2}, {chat.shipment.fromAddress3}
                          </span>
                        </p>
                      </div>
                      <div className="px-1.5 text-[6px] flex items-start">
                        <p>
                          City: <span className="font-semibold">{chat.shipment.fromCity}</span>
                        </p>
                      </div>
                      <div className="px-1.5 text-[6px] flex items-start">
                        <p>
                          Town: <span className="font-semibold">{chat.shipment.fromTown}</span>
                        </p>
                      </div>
                      <div className="px-1.5 text-[6px] flex items-start">
                        <p>
                          State: <span className="font-semibold">{chat.shipment.fromState}</span>
                        </p>
                      </div>
                      <div className="px-1.5 text-[6px] flex items-start">
                        <p>
                          Postal code: <span className="font-semibold"></span>
                          {chat.shipment.fromPostal}
                        </p>
                      </div>
                      <div className="px-1.5 text-[6px] flex items-start">
                        <p>
                          Country: <span className="font-semibold">{chat.shipment.fromCountry}</span>
                        </p>
                      </div>
                      <div className="px-1.5 text-[6px] flex items-start">
                        <p>
                          Telephone: <span className="font-semibold">{chat.shipment.fromPhone}</span>
                        </p>
                      </div>
                      <div className="px-1.5 text-[6px] flex items-start">
                        <p>
                          Contact name: <span className="font-semibold">{chat.shipment.fromName}</span>
                        </p>
                      </div>
                      <div className="px-1.5 text-[6px] flex items-start">
                        <p>
                          Email address: <span className="font-semibold">{chat.shipment.fromEmail}</span>
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="p-0.5 bg-blue-300 px-1.5 border-b-[1px] border-solid border-black border-t-[1px]">
                        <p className="text-[7px] font-semibold">To (Importer)</p>
                      </div>
                      <div className="px-1.5 flex items-start text-[6px]">
                        <p>
                          Name / Company: <span className="font-semibold">{chat.shipment.importer}</span>
                        </p>
                      </div>
                      <div className="flex items-start text-[6px] px-1.5">
                        <p>
                          Address:{' '}
                          <span className="font-semibold">
                            {chat.shipment.toAddress}, {chat.shipment.toAddress2}, {chat.shipment.toAddress3}
                          </span>
                        </p>
                      </div>
                      <div className="px-1.5 text-[6px] flex items-start">
                        <p>
                          City: <span className="font-semibold">{chat.shipment.toCity}</span>
                        </p>
                      </div>
                      <div className="px-1.5 text-[6px] flex items-start">
                        <p>
                          Town: <span className="font-semibold">{chat.shipment.toTown}</span>
                        </p>
                      </div>
                      <div className="px-1.5 text-[6px] flex items-start">
                        <p>
                          State: <span className="font-semibold">{chat.shipment.toState}</span>
                        </p>
                      </div>
                      <div className="px-1.5 text-[6px] flex items-start">
                        <p>
                          Postal code: <span className="font-semibold">{chat.shipment.toPostal}</span>
                        </p>
                      </div>
                      <div className="px-1.5 text-[6px] flex items-start">
                        <p>
                          Country: <span className="font-semibold">{chat.shipment.toCountry}</span>
                        </p>
                      </div>
                      <div className="px-1.5 text-[6px] flex items-start">
                        <p>
                          Telephone: <span className="font-semibold">{chat.shipment.toPhone}</span>
                        </p>
                      </div>
                      <div className="px-1.5 text-[6px] flex items-start">
                        <p>
                          Contact name: <span className="font-semibold">{chat.shipment.toName}</span>
                        </p>
                      </div>
                      <div className="px-1.5 text-[6px] flex items-start">
                        <p>
                          Email address: <span className="font-semibold">{chat.shipment.toEmail}</span>
                        </p>
                      </div>
                    </div>
                    <div className="border-t-[1px] border-x-0 border-solid border-black">
                      <div className="flex">
                        <div className="w-[40%] border-r-[1px] border-solid border-black">
                          <p className="font-semibold text-[7px] whitespace-nowrap px-1.5">Shipper's account</p>
                          <p className="text-[5px] px-1.5">{chat.shipment.fromEmail}</p>
                          <div className="bg-blue-300 flex items-center mt-0.5 border-t-[1px] border-solid border-black px-0.5">
                            <p className="text-[7px]">Credit</p>
                            <div className="w-[7px] h-[7px] rounded-[5px] bg-white border-zinc-300 border-solid border-[1px] ml-auto"></div>
                          </div>
                          <div className="bg-blue-300 flex items-center px-0.5 border-t-[1px] border-solid border-black">
                            <p className="text-[7px]">Prepaid</p>
                            <div className="w-[7px] h-[7px] rounded-[5px] bg-white border-zinc-300 border-solid border-[1px] ml-auto"></div>
                          </div>
                        </div>
                        <div className="w-[60%]">
                          <p className="p-0.5 text-[6px]">Shipper's Agreement And Signature</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="flex item-start mt-1 w-full">
                      <div className="w-full">
                        <div className="flex items-center w-full">
                          <img src={skydartLogo} alt="" loading="lazy" className="w-[30px] h-[30px] object-contain" />
                          <div>
                            <p className="text-[12px] font-semibold leading-3 text-[darkblue]">SKYDART</p>
                            <p className="text-[12px] font-semibold leading-3 text-[darkblue]">EXPRESS</p>
                          </div>
                          <BsQrCode size={30} className="ml-auto mr-2" />
                        </div>
                        <p className="text-black text-[6px] font-bold ml-1">Document and parcel worldwide express</p>
                      </div>
                    </div>
                    <div className="w-full flex items-center justify-center mt-2.5">
                      <p className="text-[darkblue] text-[6px]">https://skydart.com.vn</p>
                    </div>
                    <div className="border-y-[1px] border-x-0 border-solid border-black flex items-center text-[9px] mt-1">
                      <div className="w-[50px] bg-blue-300 text-center p-1 flex items-center justify-center border-r-[1px] border-solid border-black">
                        <p>Waybill</p>
                      </div>
                      <div className="w-full p-1 flex items-center justify-center">
                        <p>{chat.shipment.hawb}</p>
                      </div>
                    </div>
                    <div className="bg-blue-300 flex items-center p-0.5 border-b-[1px] border-solid border-black">
                      <p className="text-center text-[5px] w-[33%]">Packages amount</p>
                      <p className="text-center text-[5px] w-[33%]">Gross weight</p>
                      <p className="text-center text-[5px] w-[33%]">Chargeable weight</p>
                    </div>
                    <div className="bg-white flex items-center border-b-[1px] border-solid border-black">
                      <p className="text-center text-[8px] w-[33%] border-r-[1px] border-solid border-black p-0.5">1</p>
                      <p className="text-center text-[8px] w-[33%] p-0.5 border-r-[1px] border-solid border-black">
                        {weight?.toFixed(2)} KG
                      </p>
                      <p className="text-center text-[8px] w-[33%] p-0.5 ">{weight?.toFixed(2)} KG</p>
                    </div>
                    <div className="bg-blue-300 flex items-center p-0.5 border-b-[1px] border-solid border-black">
                      <p className=" text-[5px] ml-0.5 ">Full description of goods</p>
                    </div>
                    <div className="p-1 border-solid border-black border-b-[1px] flex flex-wrap overflow-y-auto font-medium gap-1 h-[83.5px]">
                      {type === 'WPX' ? (
                        chat.shipment?.invoiceValue?.map((s, index) => (
                          <div key={index}>
                            <h5 className="text-[8px]">{s.itemCommonity + ',' || ''}</h5>
                          </div>
                        ))
                      ) : (
                        <div>
                          <h5 className="text-[8px]">Document</h5>
                        </div>
                      )}
                    </div>
                    <div className="flex">
                      <div className="text-[7px] p-0.5">
                        <p>Pick up by:</p>
                        <p className="mt-2">Shipment date:</p>
                      </div>
                      <div className="ml-auto w-[50px] bg-blue-300 border-l-[1px] border-solid border-black flex items-center justify-center font-semibold h-[43px]">
                        <p>{chat.shipment.shipmentType}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {currentStep > 1 && (
              <div>
                <WordGeneratorEffect
                  isSeen={chat.isSeen}
                  isFontStyle={isFontStyle}
                  text={chat.text}
                  delay={50}
                  isColor={true}
                  onComplete={() => {
                    handleEffectComplete(chat.id);
                  }}
                  isMultiColor={isMultiColor}
                />
                <div className="mt-5 flex   gap-1.5 flex-col">
                  <div
                    className={`retriveShipment1 p-[1px] text-center rounded-full active:scale-95 ${
                      chat.isBooking
                        ? 'cursor-not-allowed  bg-zinc-200'
                        : 'cursor-pointer bg-gradient-to-tr from-green-600 to-[darkblue]'
                    }`}
                    onClick={() => {
                      if (chat.isBooking) return;
                      handleBookSingleShipment(
                        {
                          ...chat.shipment,
                          totalCharge: weight.toFixed(2),
                          reweight: weight.toFixed(2),
                          weight: weight.toFixed(2),
                          totalPackage: 1,
                          totalItem: 1,
                          totalItemValue: '0.00',
                          totalInvoiceValue: '0.00',
                          description: 'Document',
                          invoiceValue: [],
                          packageValue: [],
                        },
                        chat.id
                      );
                    }}
                  >
                    <div
                      className={`p-2  px-4 rounded-full hover:bg-zinc-100 active:bg-zinc-200 select-none  transition-[98s] ${
                        chat.isBooking ? 'bg-zinc-200' : 'bg-white'
                      }`}
                    >
                      <p
                        className={` ${
                          chat.isBooking
                            ? 'text-zinc-500'
                            : 'font-bold text-transparent bg-clip-text bg-gradient-to-tr from-emerald-500 to-blue-600'
                        }`}
                      >
                        {chat.isBooking ? 'Đã tạo đơn' : 'Tạo vận đơn'}
                      </p>
                    </div>
                  </div>
                  {!chat.isBooking && (
                    <div
                      className="bg-gradient-to-tr from-lime-500 to-teal-500 text-center p-[1px] rounded-full retriveShipment1 active:scale-95 cursor-pointer"
                      onClick={() => {
                        if (chat.isPending) {
                          handleDeleteSingleShipment(chat.shipment.hawb, chat.id, chat.shipment.belongTo);
                        } else {
                          handleAddSingleShipment(chat.shipment, chat.id);
                        }
                      }}
                    >
                      <div className="p-2 bg-white px-4 rounded-full hover:bg-zinc-100 active:bg-zinc-200 select-none transition-[98s]">
                        <p className="font-bold text-transparent bg-clip-text bg-gradient-to-tr from-lime-500 to-teal-500">
                          {chat.isPending ? 'Huỷ khỏi hàng chờ' : 'Thêm vào hàng chờ'}
                        </p>
                      </div>
                    </div>
                  )}
                  {chat.isBooking && (
                    <div
                      className="retriveShipment1 bg-gradient-to-tr from-lime-500 to-teal-500 p-[1px] rounded-full active:scale-95 text-center"
                      onClick={() => {
                        navigate(`/shipment/detail/${chat.shipment.hawb}`);
                      }}
                    >
                      <div className="p-2 bg-white px-4 rounded-full hover:bg-zinc-100 active:bg-zinc-200 select-none cursor-pointer transition-[98s]">
                        <p className="font-bold text-transparent bg-clip-text bg-gradient-to-tr from-lime-500 to-teal-500">
                          Quản lý vận đơn
                        </p>
                      </div>
                    </div>
                  )}
                  <div
                    className="retriveShipment1 bg-gradient-to-tr from-rose-500 to-pink-500 p-[1px] text-center rounded-full active:scale-95 cursor-pointer select-none"
                    onClick={() => {
                      handleDeleteShipment(chat.id);
                    }}
                  >
                    <div className="p-2 bg-white px-4 rounded-full hover:bg-zinc-100 active:bg-zinc-200 select-none cursor-pointer transition-[98s]">
                      <p className="font-bold text-transparent bg-clip-text bg-gradient-to-tr from-pink-500 to-rose-500">
                        Xoá đơn
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {currentStep > 2 && (
            <div className="p-3 px-3.5 pt-2">
              <WordGeneratorEffect
                isSeen={chat.isSeen}
                isFontStyle={isFontStyle}
                text={`Xin lưu ý: ${chat?.subText[1].value}`}
                delay={50}
                isColor={true}
                onComplete={() => {
                  handleEffectComplete(chat.id);
                }}
                isMultiColor={isMultiColor}
              />
            </div>
          )}
          {!chat.isBooking && currentStep > 3 && (
            <div
              className="p-4 pt-0"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Masonry columns={2} spacing={0} key={chat.id}>
                <TextField
                  label="Weight"
                  placeholder="Enter Document Gross"
                  autoComplete="off"
                  type="number"
                  defaultValue={weight}
                  onBlur={(e) => {
                    if (+e.target.value < 0.5) {
                      alert('Chứng từ không thể nhẹ hơn 0.5kg');
                      setWeight(0.5);
                    } else if (+e.target.value > 2) {
                      alert('Chứng từ không thể nặng hơn 2kg');
                      setWeight(0.5);
                    } else {
                      setWeight(+e.target.value);
                    }
                  }}
                  size="small"
                  fullWidth
                  variant="filled"
                />
                <select
                  value={chat.shipment.usage}
                  onChange={(e) => handleChildInputChange(chat.id, 'usage', e.target.value)}
                  className="p-2 word-generating-select-box"
                >
                  <option value="">Select an export reason</option>
                  {exportReasonArray?.map((item) => (
                    <option value={item.value}>{item.label}</option>
                  ))}
                </select>
                <TextField
                  label="Shipment name"
                  placeholder="Enter a name for this shipment"
                  autoComplete="off"
                  defaultValue={chat.shipment.shipmentName}
                  onBlur={(e) => handleChildInputChange(chat.id, 'shipmentName', e.target.value)}
                  size="small"
                  fullWidth
                  variant="filled"
                />
                <TextField
                  label="Custom HAWB"
                  placeholder="Enter a customer bill number"
                  autoComplete="off"
                  defaultValue={chat.shipment.customHawb}
                  onBlur={(e) => handleChildInputChange(chat.id, 'customHawb', e.target.value)}
                  size="small"
                  fullWidth
                  variant="filled"
                />
                <select
                  disabled
                  value={chat.shipment.shipmentType}
                  onChange={(e) => handleChildInputChange(chat.id, 'shipmentType', e.target.value)}
                  className="p-2 word-generating-select-box"
                >
                  <option value="">Select a service</option>
                  <option value="WPX">WPX</option>
                  <option value="DOX">DOX</option>
                </select>
                <select
                  value={chat.shipment.service}
                  onChange={(e) => handleChildInputChange(chat.id, 'service', e.target.value)}
                  className="p-2 word-generating-select-box"
                >
                  <option value="">Select a service</option>
                  {serviceArray?.map((item) => (
                    <option value={item.label}>{item.proxy}</option>
                  ))}
                </select>
                <TextField
                  label="Destination (Country)"
                  placeholder="Enter a country"
                  autoComplete="off"
                  size="small"
                  defaultValue={chat.shipment.toCountry}
                  onBlur={(e) => handleChildInputChange(chat.id, 'toCountry', e.target.value)}
                  fullWidth
                  maxRows={3}
                  multiline
                  variant="filled"
                />
                <TextField
                  label="Company (importer)"
                  placeholder="Enter a company name"
                  autoComplete="off"
                  size="small"
                  defaultValue={chat.shipment.importer}
                  onBlur={(e) => handleChildInputChange(chat.id, 'importer', e.target.value)}
                  fullWidth
                  maxRows={3}
                  multiline
                  variant="filled"
                />
                <TextField
                  label="Address 1"
                  placeholder="Enter primary address"
                  autoComplete="off"
                  size="small"
                  fullWidth
                  defaultValue={chat.shipment.toAddress}
                  onBlur={(e) => handleChildInputChange(chat.id, 'toAddress', e.target.value)}
                  maxRows={5}
                  multiline
                  variant="filled"
                />
                <TextField
                  label="Address 2"
                  placeholder="Enter secondary address"
                  autoComplete="off"
                  size="small"
                  fullWidth
                  maxRows={5}
                  multiline
                  defaultValue={chat.shipment.toAddress2}
                  onBlur={(e) => handleChildInputChange(chat.id, 'toAddress2', e.target.value)}
                  variant="filled"
                />
                <TextField
                  label="Address 3"
                  placeholder="Enter third address"
                  autoComplete="off"
                  size="small"
                  fullWidth
                  maxRows={5}
                  multiline
                  defaultValue={chat.shipment.toAddress3}
                  onBlur={(e) => handleChildInputChange(chat.id, 'toAddress3', e.target.value)}
                  variant="filled"
                />

                <TextField
                  label="Town"
                  placeholder="Enter a town name"
                  autoComplete="off"
                  size="small"
                  fullWidth
                  variant="filled"
                  defaultValue={chat.shipment.toTown}
                  onBlur={(e) => handleChildInputChange(chat.id, 'toTown', e.target.value)}
                />
                <TextField
                  label="City"
                  placeholder="Enter a city name"
                  autoComplete="off"
                  size="small"
                  defaultValue={chat.shipment.toCity}
                  onBlur={(e) => handleChildInputChange(chat.id, 'toCity', e.target.value)}
                  fullWidth
                  variant="filled"
                />
                <TextField
                  label="State"
                  defaultValue={chat.shipment.toState}
                  onBlur={(e) => handleChildInputChange(chat.id, 'toState', e.target.value)}
                  placeholder="Enter a state name"
                  autoComplete="off"
                  size="small"
                  fullWidth
                  variant="filled"
                />
                <TextField
                  label="Postal code"
                  defaultValue={chat.shipment.toPostal}
                  onBlur={(e) => handleChildInputChange(chat.id, 'toPostal', e.target.value)}
                  placeholder="Enter a postal code"
                  autoComplete="off"
                  size="small"
                  fullWidth
                  variant="filled"
                />
                <TextField
                  label="Telephone"
                  defaultValue={chat.shipment.toPhone}
                  onBlur={(e) => handleChildInputChange(chat.id, 'toPhone', e.target.value)}
                  placeholder="Enter receiver phone number"
                  autoComplete="off"
                  size="small"
                  fullWidth
                  variant="filled"
                />
                <TextField
                  label="Contact name"
                  placeholder="Enter recipient name"
                  autoComplete="off"
                  defaultValue={chat.shipment.toName}
                  onBlur={(e) => handleChildInputChange(chat.id, 'toName', e.target.value)}
                  size="small"
                  fullWidth
                  variant="filled"
                />
                <TextField
                  label="Email address"
                  placeholder="Enter recipient email"
                  autoComplete="off"
                  defaultValue={chat.shipment.toEmail}
                  onBlur={(e) => handleChildInputChange(chat.id, 'toEmail', e.target.value)}
                  size="small"
                  fullWidth
                  variant="filled"
                />
              </Masonry>
            </div>
          )}
          {currentStep > 3 && (
            <div className="p-3 px-3.5 pt-2">
              <WordGeneratorEffect
                isSeen={chat.isSeen}
                isFontStyle={isFontStyle}
                text='Khi nhấn vào "Tạo vận đơn", anh chị đã đồng ý với các chính sách và công thức tính cước vận chuyển quốc tế của chúng tôi.'
                delay={50}
                onComplete={() => {
                  handleEffectComplete(chat.id);
                }}
                isColor={true}
                isMultiColor={isMultiColor}
              />
            </div>
          )}
          {!seeMore && !chat.isBooking && currentStep > 1 && (
            <div className="absolute bottom-0 bg-white/60 z-2 flex items-center p-3 justify-center w-full text-black retriveShipment">
              <p className="text-[15px] font-medium">Xem thêm</p>
            </div>
          )}
        </div>
      )}
      {currentStep > 4 && (
        <div className=" pt-4">
          <WordGeneratorEffect
            isSeen={chat.isSeen}
            isFontStyle={isFontStyle}
            text={chat?.subText[4].value || 'Nếu bạn cần hỗ trợ thêm điều gì, hãy cho tôi biết nhé!'}
            delay={50}
            onComplete={() => {
              handleEffectCompleteLast(chat.id);
            }}
            isColor={true}
            isMultiColor={isMultiColor}
          />
        </div>
      )}
    </div>
  );
}
