import { CircularProgress, InputAdornment, Switch, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { BiSave, BiWorld } from 'react-icons/bi';
import { FaFileInvoice, FaMinus, FaRegFlag, FaSortDown } from 'react-icons/fa6';
import { FcHighPriority, FcOk } from 'react-icons/fc';
import { FiRefreshCw } from 'react-icons/fi';
import { GoPackage, GoTrash } from 'react-icons/go';
import { IoIosSearch } from 'react-icons/io';
import { IoAdd, IoCreate } from 'react-icons/io5';
import { LuPackageOpen, LuSave, LuText } from 'react-icons/lu';
import { MdOutlineClear } from 'react-icons/md';
import { PiSealQuestion } from 'react-icons/pi';
import { RiBillLine } from 'react-icons/ri';
import OutsideClickHandler from 'react-outside-click-handler';
import { countries } from '../../staff/api/countries';
import { exportReason } from '../../staff/api/exportReason';
import { serviceArray } from '../../staff/api/services';
import { useNavigate } from 'react-router-dom';
export default function MultiShipment({
  multiShipments,
  handleChange,
  handleShowShipmentCountry,
  handleCloseShipmentCountry,
  handleChangeShipmentFilterCountryType,
  handleChangeShipmentCountry,
  handleShowShipmentType,
  handleCloseShipmentType,
  handleChangeShipmentType,
  handleAddMulti,
  handleShowPackaging,
  getFormattedDate,
  handleBookSingleShipment,
  handleRemoveShipment,
  handleBookMultipleShipment,
  handleRefreshMulti,
}) {
  const [loading, setLoading] = useState(false);
  const [searchCountry, setSearchCountry] = useState('');
  const navigate = useNavigate();
  const borderColor = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '20px',
      backgroundColor: 'white',
      '& fieldset': {
        borderColor: '#6a5acd',
      },
      '&:hover fieldset': {
        borderColor: '#8a2be2', // Slightly lighter purple on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: '#9370db', // Lighter purple when focused
        boxShadow: '0 0 5px rgba(148, 0, 211, 0.4)', // Soft purple glow when focused
      },
    },
    '& .MuiInputLabel-root': {
      color: '#8a2be2', // Purple color for the label
      '&.Mui-focused': {
        color: '#9370db', // Lighter purple label when focused
      },
    },
    '& input': {
      color: 'purple', // Deep purple text color
    },
  };
  const borderColor1 = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '20px',
      backgroundColor: 'white', // Light bluish-purple background for non-focused state
      '& fieldset': {
        borderColor: '#6a5acd', // Darker purple border when not focused
      },
      '&:hover fieldset': {
        borderColor: '#8a2be2', // Slightly lighter purple on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: '#9370db', // Lighter purple when focused
        boxShadow: '0 0 5px rgba(148, 0, 211, 0.4)', // Soft purple glow when focused
      },
    },
    '& .MuiInputLabel-root': {
      color: '#8a2be2', // Purple color for the label
      '&.Mui-focused': {
        color: '#9370db', // Lighter purple label when focused
      },
    },
    '& input': {
      color: 'darkslategray', // Deep purple text color
    },
  };

  return (
    <div className="fixed bottom-0 left-[320px] z-[9999] bg-[rgba(255,255,255,0.85)] p-2.5  rounded-tr-[15px] rounded-tl-[15px] w-[1000px]">
      <div className="flex items-center mb-2">
        <div className="flex items-start">
          <p className="font-bold text-3xl px-1.5 text-black tracking-wider  rounded-[6px]">Multiship</p>
          <p className="font-semibold text-slate-600 text-[12px] ml-1">{getFormattedDate()}</p>
        </div>
        <div className="flex items-center ml-auto gap-1.5">
          <div
            className="p-1.5 flex items-center bg-white border-solid border-slate-400 border-[1px] rounded-[6px] font-semibold text-slate-600 select-none cursor-pointer hover:bg-slate-100 active:bg-slate-200 transition-[98s] hover:text-black"
            onClick={() => {
              handleBookMultipleShipment('new');
            }}
          >
            <IoCreate size={23} />
            <p className="mx-1.5">Book all shipment</p>
          </div>
          <div
            className="p-1.5 flex items-center bg-white border-solid border-slate-400 border-[1px] rounded-[6px] font-semibold text-slate-600 select-none cursor-pointer hover:bg-slate-100 active:bg-slate-200 transition-[98s] hover:text-black"
            onClick={() => {
              handleAddMulti();
            }}
          >
            <IoAdd size={23} />
            <p className="mx-1">Add shipment</p>
          </div>
          <div
            className="p-1.5 flex items-center bg-white border-solid border-slate-400 border-[1px] rounded-[6px] font-semibold text-slate-600 select-none cursor-pointer hover:bg-slate-100 active:bg-slate-200 transition-[98s] hover:text-black"
            onClick={() => {
              handleRefreshMulti();
            }}
          >
            <FiRefreshCw size={23} />
          </div>
          <div
            className="p-1.5 flex items-center bg-white border-solid border-slate-400 border-[1px] rounded-[6px] font-semibold text-slate-600 select-none cursor-pointer hover:bg-slate-100 active:bg-slate-200 transition-[98s] hover:text-black"
            onClick={() => {
              handleBookMultipleShipment('draft');
            }}
          >
            <LuSave size={23} />
          </div>

          <div
            className="p-1.5 flex items-center bg-white border-solid border-slate-400 border-[1px] rounded-[6px] font-semibold text-slate-600 select-none cursor-pointer hover:bg-slate-100 active:bg-slate-200 transition-[98s] hover:text-black"
            onClick={() => {
              navigate(-1);
            }}
          >
            <MdOutlineClear size={23} />
          </div>
        </div>
      </div>
      <div className="flex gap-2.5">
        {multiShipments.map((item, index) => (
          <div className="relative">
            {item.loading && (
              <div className="absolute top-0 right-0 bottom-0 left-0 bg-[rgba(0,0,0,0.55)] text-white flex items-center justify-center z-[10000] rounded-[12px] flex-col create-shipment-fade">
                <div className="bg-white w-[40px] h-[40px] flex items-center justify-center rounded-full mb-1">
                  {item.loadingContent === 'Booking your shipment...' && (
                    <CircularProgress size={25} style={{ color: 'black' }} />
                  )}
                  {item.loadingContent.includes('success!') && <FcOk size={25} />}
                </div>
                <p
                  className={`text-center font-semibold ${
                    item.loadingContent === 'Booking your shipment...'
                      ? ''
                      : item.loadingContent.includes('Something')
                        ? 'text-rose-500'
                        : 'text-green-400'
                  }`}
                >
                  {item.loadingContent}
                </p>
                {item.loadingContent !== 'Booking your shipment...' && (
                  <div className="flex items-center flex-col gap-1">
                    <div
                      className="p-1.5 rounded-[6px] bg-emerald-500 px-4 hover:bg-emerald-600 cursor-pointer text-white font-semibold select-none active:bg-emerald-700 transition-[95s] mt-1"
                      onClick={() => {
                        handleRemoveShipment(item.hawb);
                      }}
                    >
                      <p>Finish booking</p>
                    </div>
                  </div>
                )}
              </div>
            )}
            <form
              className={`w-[320px] multi-box h-[400px] bg-white   border-solid border-[1px] border-blue-100 rounded-[8px] overflow-y-auto`}
              onSubmit={(e) => {
                handleBookSingleShipment(item.hawb, e, 'new');
              }}
            >
              <div className="px-2.5 py-1.5 flex items-center bg-[#eeeeff] rounded-tl-[8px] rounded-tr-[8px] multi-box-header rounded-[15px] sticky top-0 z-[9999] ">
                <p className="text-[darkblue] font-semibold cursor-pointer hover:underline active:opacity-70">
                  {item.hawb}
                </p>
                <div className="ml-auto relative">
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      handleCloseShipmentType(item.hawb);
                    }}
                  >
                    <div className="ml-auto relative">
                      <div
                        className="p-1.5 flex items-center bg-[#dadaff] rounded-full px-2.5 text-slate-600 font-medium select-none cursor-pointer hover:bg-[#d4d4ff] active:opacity-70 active:scale-95"
                        onClick={() => {
                          handleShowShipmentType(item.hawb);
                        }}
                      >
                        <p className="mx-1">{item.shipmentType}</p>
                        <FaSortDown size={20} className="mt-[-10px]" />
                      </div>
                      {item.isTypeSelect && (
                        <div className="absolute bg-white w-[140px] rounded-[12px] right-0 mt-[5px] shadow-[rgba(0,0,0,0.15)_0px_2px_8px] p-1">
                          <div
                            className="p-1.5 hover:bg-slate-100 flex items-center rounded-[12px] cursor-pointer select-none font-semibold text-slate-600 active:bg-slate-200"
                            onClick={() => {
                              handleChangeShipmentType(item.hawb, 'WPX');
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-[23px] h-[23px]"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
                              />
                            </svg>
                            <p className="font-bold ml-1.5">WPX</p>
                          </div>
                          <div
                            className="p-1.5 hover:bg-slate-100 flex items-center rounded-[12px] cursor-pointer select-none font-semibold text-slate-600 active:bg-slate-200"
                            onClick={() => {
                              handleChangeShipmentType(item.hawb, 'DOX');
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-[23px] h-[23px]"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                              />
                            </svg>

                            <p className="font-bold ml-1.5">DOX</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </OutsideClickHandler>
                </div>
                {multiShipments.length > 1 && (
                  <GoTrash
                    size={34}
                    className="p-1.5 cursor-pointer rounded-full hover:bg-[#d4d4ff] ml-1   active:scale-90 text-slate-600 bg-[#dadaff] active:opacity-70"
                    onClick={() => {
                      handleRemoveShipment(item.hawb);
                    }}
                  />
                )}
              </div>
              <div className="m-2 mt-2 flex flex-col gap-1.5 multi-box-info">
                <p className="mb-1 mt-1 px-1.5 text-slate-500">
                  {item?.packageValue?.length} pcs,{' '}
                  <span className="px-1 bg-blue-50 text-[darkblue] font-semibold rounded-[2px]">
                    {item?.totalCharge || '0.00'} KG
                  </span>{' '}
                  - {item?.invoiceValue?.length} items,
                  <span className="px-1 bg-green-50 text-green-600 font-semibold rounded-[2px]">
                    {item?.totalInvoiceValue || '0.00'} USD
                  </span>
                </p>
                <select
                  className="p-2 px-3 cursor-pointer bg-slate-100 rounded-[6px] outline-none hover:bg-slate-200 active:bg-slate-300 transitin-[98s] w-fit"
                  value={item.service}
                  name="service"
                  required
                  onChange={(e) => handleChange(index, e)}
                >
                  <option value="">Select a service</option>
                  {serviceArray.map((el) => (
                    <option value={el.proxy}>{el.proxy}</option>
                  ))}
                </select>
                {item.service === 'D3P' && (
                  <div className="flex items-center gap-1.5">
                    <input
                      type="date"
                      required
                      className="p-1.5 bg-slate-100 rounded-[6px] px-3"
                      name="dateInput"
                      value={item.dateInput}
                      onChange={(e) => {
                        handleChange(index, e);
                      }}
                    />
                    <input
                      type="time"
                      required
                      className="p-1.5 bg-slate-100 rounded-[6px] px-3"
                      name="timeInput"
                      value={item.timeInput}
                      onChange={(e) => {
                        handleChange(index, e);
                      }}
                    />
                  </div>
                )}
                <select
                  className="p-2 px-3 cursor-pointer bg-slate-100 rounded-[6px] outline-none hover:bg-slate-200 active:bg-slate-300 transitin-[98s]"
                  required
                  name="usage"
                  value={item.usage}
                  onChange={(e) => handleChange(index, e)}
                >
                  <option value="">Reason for your export</option>
                  {exportReason.map((el) => (
                    <option value={el.label}>{el.label}</option>
                  ))}
                </select>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    handleCloseShipmentCountry(item.hawb);
                    const filterCountry = countries.filter((s) => {
                      return s.label === item.toCountry;
                    });
                    handleChangeShipmentCountry(
                      item.hawb,
                      item.toCountry,
                      filterCountry[0]?.code || '',
                      filterCountry[0]?.phoneCode || '',
                      filterCountry[0]?.skydartZone || ''
                    );
                  }}
                >
                  <div className="relative">
                    <TextField
                      label="Country"
                      size="small"
                      required
                      autoComplete="off"
                      name="toCountry"
                      inputProps={{
                        autoComplete: 'new-password',
                      }}
                      fullWidth
                      value={item.toCountry}
                      onChange={(e) => handleChange(index, e)}
                      style={{ marginTop: 2 }}
                      onClick={() => {
                        handleShowShipmentCountry(item.hawb);
                      }}
                      variant="outlined"
                      sx={borderColor}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {item.code && (
                              <img
                                src={`https://flagsapi.com/${item.code.toUpperCase()}/shiny/64.png`}
                                className="w-[32px] h-[25px] object-cover"
                                loading="lazy"
                              />
                            )}
                            {!item.code && <BiWorld size={32} className="p-1 text-purple-500" />}
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <FaSortDown size={28} className="text-[purple] mt-[-13px]" />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Enter your destination"
                    />
                    {item.isCountrySelect && (
                      <div className="absolute z-10 bg-white/90 backdrop-blur-lg border border-[rgba(128,90,213,0.3)] w-full shadow-[rgba(128,90,213,0.4)_0px_4px_12px,_rgba(128,90,213,0.2)_0px_2px_6px] mt-[5px] p-2 rounded-[12px] max-h-[150px] overflow-auto">
                        <div className="flex items-center">
                          <div className="relative flex items-center">
                            <IoIosSearch size={18} className="text-purple-500 absolute left-1.5" />
                            <input
                              type="text"
                              placeholder="Search..."
                              autoComplete="off"
                              className="p-1 outline-none pl-7 bg-white pr-2.5 border-purple-300 border-solid border-[1px] rounded-[10px] text-[13px]"
                              name="searchDestination"
                              value={item.searchDestination}
                              onChange={(e) => handleChange(index, e)}
                            />
                          </div>
                          <div className="ml-auto flex items-center bg-slate-100 rounded-[7px] p-1 px-1.5 gap-1">
                            <div
                              className={`p-1 ${
                                item.showCountryType === 'flag' ? 'bg-white' : ''
                              } flex items-center justify-center rounded-[5px] hover:bg-slate-200 active:bg-slate-300 cursor-pointer`}
                              onClick={() => {
                                handleChangeShipmentFilterCountryType(item.hawb, 'flag');
                              }}
                            >
                              <FaRegFlag size={18} />
                            </div>
                            <div
                              className={`p-1 ${
                                item.showCountryType !== 'flag' ? 'bg-white' : ''
                              } flex items-center justify-center rounded-[5px] hover:bg-slate-200 active:bg-slate-300 cursor-pointer`}
                              onClick={() => {
                                handleChangeShipmentFilterCountryType(item.hawb, 'text');
                              }}
                            >
                              <LuText size={18} />
                            </div>
                          </div>
                        </div>
                        <div
                          className={`flex flex-wrap gap-1 mt-1.5 ${
                            item.showCountryType === 'flag' ? 'items-center' : 'flex-col'
                          }`}
                        >
                          {countries
                            .filter((s) => {
                              if (s.label?.toLowerCase().startsWith(item.searchDestination?.toLowerCase())) {
                                return s;
                              }
                            })
                            .map((c) => (
                              <div className="relative">
                                <div className="group">
                                  {item.showCountryType === 'flag' && (
                                    <img
                                      loading="lazy"
                                      draggable={false}
                                      onClick={() => {
                                        handleChangeShipmentCountry(
                                          item.hawb,
                                          c.label,
                                          c.code,
                                          c.phoneCode,
                                          c.skydartZone
                                        );
                                      }}
                                      src={`https://flagsapi.com/${c.code.toUpperCase()}/shiny/64.png`}
                                      className="w-[32px] h-[32px] cursor-pointer  hover:bg-slate-200 active:scale-90 active:bg-slate-300 p-0.5 rounded-[5px] "
                                    />
                                  )}
                                  <div className="group-hover:block hidden absolute bottom-[-25px] left-0  max-w-[250px] text-[13px] font-semibold text-slate-600 px-2 bg-white z-10 whitespace-nowrap shadow-[rgba(0,0,0,0.09)_0px_3px_12px] rounded-[2px]">
                                    {c.label}
                                  </div>
                                </div>
                                {item.showCountryType !== 'flag' && (
                                  <div
                                    className="flex items-center p-1.5 rounded-[8px] hover:bg-slate-100 cursor-pointer active:bg-slate-200"
                                    onClick={() => {
                                      handleChangeShipmentCountry(
                                        item.hawb,
                                        c.label,
                                        c.code,
                                        c.phoneCode,
                                        c.skydartZone
                                      );
                                    }}
                                  >
                                    <div className="w-[23px] h-[23px] flex items-center justify-center bg-white border-slate-300 border-solid border-[1px] rounded-full">
                                      {item.toCountry === c.label && (
                                        <div className="w-[12px] h-[12px] rounded-full bg-[darkblue] flex items-center justify-center"></div>
                                      )}
                                    </div>
                                    <p
                                      className={`text-[15px] ml-2 ${
                                        item.toCountry === c.label
                                          ? 'text-[darkblue]'
                                          : 'text-slate-600 hover:text-black'
                                      } font-medium`}
                                    >
                                      {c.label}
                                    </p>
                                  </div>
                                )}
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                </OutsideClickHandler>
                <TextField
                  label="Company"
                  required
                  size="small"
                  name="importer"
                  variant="outlined"
                  multiline
                  value={item.importer}
                  onChange={(e) => handleChange(index, e)}
                  style={{ marginTop: 2 }}
                  sx={borderColor1}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <FcHighPriority size={20} />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter importer company"
                />
                <TextField
                  label={`Address 1 - ${item.toAddress?.length}/30`}
                  required
                  autoComplete="off"
                  size="small"
                  name="toAddress"
                  inputProps={{
                    autoComplete: 'new-password',
                    maxLength: 30,
                  }}
                  value={item.toAddress}
                  onChange={(e) => handleChange(index, e)}
                  variant="outlined"
                  style={{ marginTop: 2 }}
                  sx={borderColor1}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <FcHighPriority size={20} />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter your address"
                />
                <TextField
                  label={`Address 2 - ${item.toAddress2?.length}/30`}
                  required
                  autoComplete="off"
                  size="small"
                  name="toAddress2"
                  inputProps={{
                    autoComplete: 'new-password',
                    maxLength: 30,
                  }}
                  value={item.toAddress2}
                  onChange={(e) => handleChange(index, e)}
                  variant="outlined"
                  style={{ marginTop: 2 }}
                  sx={borderColor1}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <FcHighPriority size={20} />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter your address"
                />
                <TextField
                  label={`Address 3 - ${item.toAddress3?.length}/30`}
                  autoComplete="off"
                  size="small"
                  name="toAddress3"
                  inputProps={{
                    autoComplete: 'new-password',
                    maxLength: 30,
                  }}
                  value={item.toAddress3}
                  onChange={(e) => handleChange(index, e)}
                  variant="outlined"
                  style={{ marginTop: 2 }}
                  sx={borderColor1}
                  placeholder="Enter your address"
                />
                <TextField
                  label="Town"
                  autoComplete="off"
                  size="small"
                  required
                  name="toTown"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                  value={item.toTown}
                  onChange={(e) => handleChange(index, e)}
                  style={{ marginTop: 2 }}
                  sx={borderColor1}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <FcHighPriority size={20} />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter your town"
                />
                <TextField
                  label="City"
                  required
                  autoComplete="off"
                  size="small"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  name="toCity"
                  variant="outlined"
                  value={item.toCity}
                  onChange={(e) => handleChange(index, e)}
                  style={{ marginTop: 2 }}
                  sx={borderColor1}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <FcHighPriority size={20} />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter your city"
                />
                <TextField
                  label="State"
                  required
                  autoComplete="off"
                  size="small"
                  name="toState"
                  value={item.toState}
                  onChange={(e) => handleChange(index, e)}
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  variant="outlined"
                  style={{ marginTop: 2 }}
                  sx={borderColor1}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <FcHighPriority size={20} />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter your state"
                />
                <TextField
                  label="Postal code"
                  autoComplete="off"
                  required
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  size="small"
                  name="toPostal"
                  value={item.toPostal}
                  onChange={(e) => handleChange(index, e)}
                  variant="outlined"
                  style={{ marginTop: 2 }}
                  sx={borderColor1}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <FcHighPriority size={20} />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter your postal code"
                />
                <TextField
                  label="Phone number"
                  required
                  autoComplete="off"
                  size="small"
                  name="toPhone"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  value={item.toPhone}
                  onChange={(e) => handleChange(index, e)}
                  variant="outlined"
                  style={{ marginTop: 2 }}
                  sx={borderColor1}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <FcHighPriority size={20} />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter your phone number"
                />
                <TextField
                  label="Contact name"
                  required
                  autoComplete="off"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  size="small"
                  name="toName"
                  variant="outlined"
                  value={item.toName}
                  onChange={(e) => handleChange(index, e)}
                  style={{ marginTop: 2 }}
                  sx={borderColor1}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <FcHighPriority size={20} />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter your contact name"
                />
                <TextField
                  label="Email address"
                  required
                  autoComplete="off"
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  size="small"
                  name="toEmail"
                  variant="outlined"
                  value={item.toEmail}
                  onChange={(e) => handleChange(index, e)}
                  style={{ marginTop: 2 }}
                  sx={borderColor1}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <FcHighPriority size={20} />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter importer email"
                />
                <TextField
                  label="Transport value"
                  autoComplete="off"
                  size="small"
                  name="transportation"
                  variant="outlined"
                  value={item.transportation}
                  onChange={(e) => handleChange(index, e)}
                  style={{ marginTop: 2 }}
                  sx={borderColor1}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <select
                          className="bg-slate-100 p-1 rounded-[3px] hover:bg-slate-200 active:bg-slate-300 cursor-pointer outline-none"
                          value={item.usage}
                          name="usage"
                          onChange={(e) => handleChange(index, e)}
                        >
                          <option value="">Term</option>
                          <option value="EXW">EXW</option>
                          <option value="FOB">FOB</option>
                          <option value="CNF">CNF</option>
                          <option value="CIF">CIF</option>
                        </select>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter transport value"
                />
                <TextField
                  label="IOSS / TIN / EORI Number / ID Tax Number"
                  autoComplete="off"
                  size="small"
                  value={item.eori}
                  onChange={(e) => handleChange(index, e)}
                  name="eori"
                  variant="outlined"
                  style={{ marginTop: 2 }}
                  sx={borderColor1}
                  placeholder="Enter importer email"
                />
              </div>
              <div className="sticky p-2.5 bottom-0 bg-white z-10 shadow-[rgba(0,0,0,0.25)_0px_2px_8px] ">
                <div
                  className="p-1.5 bg-white border-slate-400 border-[1px] border-solid px-2.5 rounded-[6px] font-semibold text-slate-600 cursor-pointer hover:bg-slate-100 active:bg-slate-200 select-none flex items-center gap-1.5 mb-2 justify-center"
                  onClick={() => {
                    handleShowPackaging(item.hawb);
                  }}
                >
                  <LuPackageOpen size={23} />
                  <p>Packaging and Invoice</p>
                </div>
                <div className="flex items-center">
                  {item.totalInvoiceValue !== '' &&
                    item.totalCharge !== '' &&
                    item.description !== '' &&
                    item.shipmentType === 'WPX' && <p className="text-[14px] text-teal-500 ">Ready to ship</p>}
                  {item.totalInvoiceValue === '' &&
                    item.totalCharge === '' &&
                    item.description === '' &&
                    item.shipmentType === 'WPX' && <p className="text-[14px] text-rose-600">Not ready</p>}
                  <div className="flex items-center gap-1 ml-auto">
                    {item.totalInvoiceValue !== '' &&
                      item.totalCharge !== '' &&
                      item.description !== '' &&
                      item.shipmentType === 'WPX' && (
                        <button
                          className="p-1 bg-emerald-50 text-emerald-600 font-semibold px-3.5 rounded-[6px] cursor-pointer hover:bg-emerald-100 active:bg-emerald-200 select-none active:scale-90 border-emerald-400 border-solid border-[1px]"
                          type="submit"
                        >
                          <p>Book</p>
                        </button>
                      )}
                    {item.totalInvoiceValue === '' &&
                      item.totalCharge === '' &&
                      item.description === '' &&
                      item.shipmentType === 'WPX' && (
                        <div className="p-1 bg-emerald-50 text-emerald-600 font-semibold px-3.5 rounded-[6px] cursor-not-allowed hover:bg-emerald-100 active:bg-emerald-200 select-none  border-emerald-400 border-solid border-[1px] opacity-50">
                          <p>Book</p>
                        </div>
                      )}
                    <div
                      className="p-1 bg-slate-50 text-slate-600 font-semibold px-3.5 rounded-[6px] cursor-pointer hover:bg-slate-100 active:bg-slate-200 select-none active:scale-90 border-slate-200 border-solid border-[1px] "
                      onClick={(e) => {
                        handleBookSingleShipment(item.hawb, e, 'draft');
                      }}
                    >
                      <p>Draft</p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        ))}
      </div>
    </div>
  );
}
