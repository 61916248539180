export const confirmReplies = [
  'Anh chị vui lòng kiểm tra thông tin và xác nhận lại trước khi khởi tạo đơn hàng. Một thông tin chính xác sẽ đảm bảo rằng hàng hóa được vận chuyển đến đúng nơi và đúng người nhận.',
  'Hãy dành chút thời gian để kiểm tra kỹ thông tin vận đơn trước khi gửi. Điều này sẽ giúp tránh những sai sót không đáng có và đảm bảo đơn hàng đến đúng địa chỉ mong muốn.',
  'Kiểm tra kỹ thông tin đơn hàng không chỉ giúp quá trình vận chuyển diễn ra suôn sẻ mà còn tránh những rủi ro có thể xảy ra trong quá trình giao nhận.',
  'Chúng tôi khuyến khích anh chị kiểm tra lại thông tin trước khi tạo đơn hàng. Điều này sẽ đảm bảo rằng mọi thứ đều chính xác, giúp đơn hàng của anh chị đến nơi an toàn và nhanh chóng.',
  'Thông tin chính xác là yếu tố quan trọng để đảm bảo hàng hóa được giao đúng thời gian và địa điểm. Anh chị vui lòng kiểm tra cẩn thận trước khi hoàn tất đơn hàng.',
  'Để đơn hàng được xử lý nhanh chóng và hiệu quả, anh chị vui lòng kiểm tra kỹ thông tin trước khi gửi. Sự cẩn thận của anh chị sẽ giúp chúng tôi phục vụ tốt hơn.',
  'Chúng tôi luôn sẵn sàng hỗ trợ, nhưng anh chị hãy giúp chúng tôi bằng cách kiểm tra kỹ thông tin trước khi tạo đơn hàng, đảm bảo mọi thứ đúng ngay từ bước đầu tiên.',
  'Việc kiểm tra kỹ thông tin đơn hàng không chỉ đảm bảo quá trình vận chuyển chính xác mà còn giúp anh chị yên tâm hơn về hành trình của gói hàng.',
  'Hãy chắc chắn rằng mọi thông tin về địa chỉ và người nhận đều đúng. Đây là cách tốt nhất để đảm bảo đơn hàng đến nơi một cách trọn vẹn.',
  'Anh chị vui lòng kiểm tra lại thông tin vận đơn trước khi xác nhận. Sự chính xác trong từng chi tiết nhỏ sẽ giúp đơn hàng của anh chị an toàn và đúng hẹn.',
  'Trước khi gửi, anh chị nên kiểm tra lại thông tin vận đơn. Một chút cẩn thận lúc này sẽ giúp tránh những phiền toái không cần thiết về sau.',
  'Hãy kiểm tra kỹ thông tin vận chuyển trước khi hoàn tất đơn hàng. Điều này không chỉ giúp đảm bảo chính xác mà còn tiết kiệm thời gian cho cả hai bên.',
  'Chúng tôi mong anh chị kiểm tra lại thông tin đơn hàng một cách cẩn thận. Đây là bước quan trọng để đơn hàng của anh chị được giao đến đúng nơi và đúng thời điểm.',
  'Để tránh những sai sót không đáng có, anh chị vui lòng kiểm tra lại thông tin giao nhận. Sự chính xác là nền tảng của một hành trình vận chuyển suôn sẻ.',
  'Anh chị hãy kiểm tra kỹ thông tin trước khi gửi để đảm bảo đơn hàng đến đúng người nhận và đúng địa chỉ. Chúng tôi luôn đồng hành cùng anh chị trong việc này.',
  'Một chút cẩn thận trong việc kiểm tra thông tin vận chuyển sẽ giúp đơn hàng của anh chị đến nơi an toàn và đúng thời gian. Chúng tôi luôn sẵn sàng hỗ trợ khi cần.',
  'Đừng quên kiểm tra thông tin trước khi tạo đơn hàng. Đây là cách để đảm bảo rằng món hàng của anh chị sẽ đến tay người nhận một cách chính xác nhất.',
  'Hãy dành chút thời gian kiểm tra lại thông tin vận chuyển trước khi gửi. Một thông tin chính xác là bước đầu để gói hàng của anh chị đi đúng hướng.',
  'Để đảm bảo đơn hàng không gặp trục trặc trong quá trình vận chuyển, anh chị vui lòng kiểm tra kỹ thông tin trước khi xác nhận.',
  'Hãy kiểm tra lại thông tin đơn hàng thật kỹ càng. Đây là cách tốt nhất để anh chị an tâm và gói hàng đến nơi đúng như mong muốn.',
];
