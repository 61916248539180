import React, { useState, useRef, useEffect } from 'react';
import { BsBoxFill, BsSendArrowUp } from 'react-icons/bs';
import { CiLocationOn } from 'react-icons/ci';
import { FaArrowRightLong, FaDownload } from 'react-icons/fa6';
import { FcApproval, FcOk } from 'react-icons/fc';
import firebase from 'firebase/compat/app';
import spacepackage from './spacepackage.jpeg';
import { useNavigate } from 'react-router-dom';
import { FiPackage } from 'react-icons/fi';
import { GoDotFill } from 'react-icons/go';
import { IoCalendarOutline, IoDocumentText } from 'react-icons/io5';
import { MdNoEncryptionGmailerrorred, MdStickyNote2 } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { db } from '../../server/firebase';
import { CircularProgress } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useReactToPrint } from 'react-to-print';
import Inv2 from '../../components/Inv2';
import ShippingMark from '../../components/ShippingMark';
import StaffBill2 from '../../components/StaffBill2';
import OutsideClickHandler from 'react-outside-click-handler';
export default function ShipmentDetail({ user }) {
  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingCreate1, setLoadingCreate1] = useState(false);
  const [err, setErr] = useState(false);
  const [success, setSuccess] = useState(false);
  const [counter, setCounter] = useState(10);
  const [shipmentData, setShipmentData] = useState({});
  const { hawb } = useParams();
  useEffect(() => {
    setLoading(true);
    if (hawb) {
      setTimeout(() => {
        db.collection('shipments')
          .doc(hawb)
          .get()
          .then((doc) => {
            if (doc.exists) {
              //   console.log(doc.data);
              setShipmentData(doc.data());
              setLoading(false);
            } else {
              setErr(true);
              setLoading(false);
            }
          });
      }, 2000);
    }
  }, [hawb]);
  let labelRef = useRef();
  let invoiceRef = useRef();
  let shippingMarkRef = useRef();
  let printAllRef = useRef();
  let iframeRef = useRef();
  const printLabel = useReactToPrint({
    content: () => labelRef.current,
  });
  const handlePrintInternationalLabel = () => {
    // const iframe = iframeRef.current;
    // iframe.src = shipmentData?.labelUrl;
    // iframe.onload = () => {
    //   iframe.contentWindow.print();
    // };
    const printWindow = window.open(shipmentData.labelUrl, '_blank', 'width=800,height=600');
    printWindow.onload = () => {
      printWindow.print();
    };
  };
  const printInvoice = useReactToPrint({
    content: () => invoiceRef.current,
  });
  const printShippingMark = useReactToPrint({
    content: () => shippingMarkRef.current,
  });
  const printAll = useReactToPrint({
    content: () => printAllRef.current,
  });

  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [currentHawb, setCurrentHawb] = useState('');

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  function formatDate(isoDate) {
    const date = new Date(isoDate);

    const options = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };

    // Format the date using Intl.DateTimeFormat
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    return formattedDate.replace(',', ''); // Replace the comma to match the desired format
  }

  function formatDate1(isoDate) {
    const today = new Date();

    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(today.getDate()).padStart(2, '0');
    const year = today.getFullYear();
    const date = new Date(isoDate || `${month}-${day}-${year}`);

    const options = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    };

    // Format the date using Intl.DateTimeFormat
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    return formattedDate.replace(',', ''); // Replace the comma to match the desired format
  }

  const handleShowCreate = () => {
    setLoadingCreate(true);
    setTimeout(() => {
      setLoadingCreate(false);
      setSuccess(true);
    }, 2000);
  };

  const handleCreate = (bookingType) => {
    setLoadingCreate1(bookingType === 'auto' ? false : true);
    const refNumber = 'SKD';
    const newDates = new Date();
    const dates = (newDates.getDate() < 10 ? '0' : '') + newDates.getDate();
    const months = (newDates.getMonth() + 1 < 10 ? '0' : '') + (newDates.getMonth() + 1);
    const years = newDates.getFullYear().toString().charAt(2);
    const yearLength = newDates.getFullYear().toString().charAt(3);
    const max = 900000;
    const wrapper = `${years}${yearLength}${months}${dates}`;
    const newHawb = refNumber + wrapper + Math.floor(100000 + Math.random() * max);
    setCurrentHawb(newHawb);

    let newDate = new Date();
    let date = (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();
    let months1 = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    let month = (newDate.getMonth() + 1 < 10 ? '0' : '') + (newDate.getMonth() + 1);
    let year = newDate.getFullYear();
    const datePicker = `${year}-${month}-${date}`;
    const monthName = months1[newDate.getMonth()];
    const traceTime = `${date} ${monthName} ${year}`;
    const hour = (newDate.getHours() < 10 ? '0' : '') + newDate.getHours();
    const min = (newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes();
    const dateTime = `${hour}:${min}`;
    db.collection('shipments')
      .doc(newHawb)
      .set({
        hawb: newHawb,
        ...shipmentData,
        desc: firebase.firestore.FieldValue.arrayUnion({
          stat: 'Shipment booked, waiting for confirmation by Skydart ',
          time: datePicker + 'T' + dateTime + ':00',
          location: 'VIETNAM - VIETNAM - HCM',
        }),
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        createAt: datePicker,
        perform: 'New',
      })
      .then(() => {
        if (bookingType === 'auto') {
          return;
        } else {
          navigate('/shipments');
        }
      });
  };
  useEffect(() => {
    if (success) {
      // Start a countdown when success is true
      const interval = setInterval(() => {
        setCounter((prev) => prev - 1); // Decrease counter by 1 every second
      }, 1000);

      const timeout = setTimeout(() => {
        setSuccess(false);
        setCounter(10); // Reset counter
        handleCreate('auto');
      }, 10000);

      return () => {
        clearInterval(interval);
        clearTimeout(timeout);
      };
    }
  }, [success]);
  return (
    <div className="">
      {success && (
        <OutsideClickHandler
          onOutsideClick={() => {
            setSuccess(false);
          }}
        >
          <div className="fixed bottom-5 left-4 z-[9999]">
            <div className="p-2.5 px-3 rounded-[2px] text-white flex items-center bg-black/80">
              <FcOk size={25} />
              <p className="mx-2">Shipment has been booked</p>
              <div className="flex items-center ml-2.5">
                <div
                  className="p-1.5 px-3 bg-black w-[120px] text-center hover:opacity-70 active:opacity-50 cursor-pointer select-none "
                  onClick={() => {
                    setSuccess(false);
                  }}
                >
                  <p>Undo ({counter})</p>
                </div>
                <div
                  className="p-1.5 px-4 bg-white bg-black/50 text-purple-400  hover:text-purple-300 active:scale-95 cursor-pointer select-none flex items-center gap-1"
                  onClick={handleCreate('action')}
                >
                  {loadingCreate1 && <CircularProgress size={20} style={{ color: 'purple' }} />}
                  <p>Manage shipment</p>
                </div>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      )}
      {loadingCreate && (
        <div className="fixed top-0 right-0 bottom-0 left-0 bg-[rgba(255,255,255,0.75)] flex items-center justify-center z-[9999] retriveShipment">
          <div className="w-[350px] h-[200px] bg-white rounded-[20px] p-5 flex items-center shadow-[rgba(0,0,0,0.25)_0px_1px_2px_0px] justify-center flex-col retriveShipment1">
            <CircularProgress style={{ color: 'darkblue' }} />
            <p className="text-center text-slate-600 mt-2.5">Loading shipment data...</p>
          </div>
        </div>
      )}
      <div className="p-[13.3px] bg-white flex items-center border-b-[1px] border-t-0 border-x-0 border-slate-300 border-solid">
        <p className="text-[17px] text-slate-600">
          Searching for shipment: <span className="text-emerald-600 tracking-wider font-bold ">{hawb}</span>
        </p>
      </div>

      <div className="h-[calc(100vh_-_4rem)] overflow-y-auto">
        {shipmentData?.uid !== user.uid && (
          <div className="p-2 bg-rose-600 text-white w-full flex items-center gap-2.5">
            <div>
              <MdNoEncryptionGmailerrorred size={25} />
            </div>
            <div>
              <p className="leading-4">Oops..., some thing is happening</p>
              <p className="text-[12px] font-bold leading-4 tracking-wider">
                You dont have permission to view detail or action on this shipment. Only account that create this
                shipment that can view all the detail and make action. If you think this is an error, please contact our
                team immediately to find solution.
              </p>
            </div>
          </div>
        )}
        <div className="bg-white m-3 rounded-[17px] shadow-[rgba(0,0,0,0.1)_0px_1px_2px_0px] p-1.5">
          <div className="p-3 flex items-start">
            {!loading && (
              <div className="flex items-center font-bold tracking-wide text-3xl gap-2.5 text-[darkblue]">
                {shipmentData?.shipmentType === 'DOX' && <IoDocumentText size={50} className="p-0.5" />}
                {shipmentData?.shipmentType === 'WPX' && <BsBoxFill size={50} className="p-0.5 " />}
                <p className="">{shipmentData?.shipmentType}</p>
                <GoDotFill size={15} />
                <p className="">{shipmentData?.totalCharge} Kg</p>
                {shipmentData?.shipmentType === 'WPX' && <GoDotFill size={15} />}
                {shipmentData?.shipmentType === 'WPX' && (
                  <p className="">{shipmentData?.packageValue.length || '0'} pcs</p>
                )}
              </div>
            )}
            {loading && (
              <div className="flex items-center gap-2.5">
                <CircularProgress size={50} className="p-0.5 " style={{ color: 'darkblue' }} />
                <Stack spacing={1}>
                  <Skeleton variant="rounded" width={330} height={38} />
                </Stack>
              </div>
            )}
            <div className="flex items-center ml-auto gap-1">
              {shipmentData?.uid === user.uid && (
                <div
                  className="p-1.5 hover:underline cursor-pointer active:opacity-50 select-none"
                  onClick={handleShowCreate}
                >
                  <p>Create a copy</p>
                </div>
              )}
              {shipmentData?.uid === user.uid && (
                <div
                  className="p-1.5 hover:underline cursor-pointer active:opacity-50 select-none"
                  onClick={() => {
                    navigate(`/tracking-result/${shipmentData?.hawb}/${user.uid}`);
                  }}
                >
                  <p>Track this shipment</p>
                </div>
              )}
              <div
                className="p-1.5 hover:underline cursor-pointer active:opacity-50 select-none"
                onClick={() => {
                  navigate('/contact');
                }}
              >
                <p>Report an error</p>
              </div>
            </div>
          </div>
          <div className="px-3.5 pb-0 flex items-center justify-between">
            <p className="font-semibold text-lg">Contact detail</p>
            {!loading && (
              <div className="flex items-center gap-2">
                <p className="px-2.5 bg-emerald-600 text-white font-semibold">{shipmentData?.status}</p>
                <div className="p-1.5 flex items-center gap-2 bg-slate-200 px-3 rounded-[8px]">
                  <IoCalendarOutline size={23} />
                  <p>Created at: {formatDate1(shipmentData?.createAt) || ''}</p>
                </div>
              </div>
            )}
          </div>
          <div className="flex relative">
            {shipmentData?.uid === user.uid && (
              <div className="absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center">
                <div className="w-[45px] h-[45px] bg-white rounded-full border-slate-400 border-solid border-[1px] flex items-center justify-center">
                  <FaArrowRightLong size={25} className="px-1 bg-emerald-600 text-white rounded-full" />
                </div>
              </div>
            )}
            {shipmentData?.uid === user.uid && (
              <div className="w-full border-slate-400 border-solid border-[1px] m-3 p-2.5 px-4 rounded-[12px]">
                <div className="flex items-center gap-1.5 mb-1 text-slate-600">
                  <BsSendArrowUp size={20} />
                  <p className="font-semibold text-[15px]">Ship from</p>
                </div>
                {!loading && (
                  <div>
                    <div className="flex">
                      <div className="flex items-center w-full">
                        <img src={`https://flagsapi.com/VN/shiny/64.png`} className="w-[28px] h-[28px] object-cover" />
                        <p className="w-full font-medium text-[18px] break-words ml-1.5">{shipmentData?.fromCountry}</p>
                      </div>
                      <p className="w-full font-medium break-words text-[18px]">{shipmentData.exporter}</p>
                    </div>
                    {shipmentData?.uid === user.uid && (
                      <div className="flex">
                        <p className="w-full text-[15px] break-words">{shipmentData.fromAddress}</p>
                        <p className="w-full  break-words text-[15px]">{shipmentData?.fromEmail}</p>
                      </div>
                    )}
                    <div className="flex">
                      <p className="w-full text-[15px] break-words">
                        P.{shipmentData?.fromState}, {shipmentData?.fromTown}, {shipmentData?.fromCity}
                      </p>
                      {shipmentData?.uid === user.uid && (
                        <p className="w-full  break-words text-[15px]">{shipmentData?.fromPhone}</p>
                      )}
                    </div>
                    <div className="flex">
                      <p className="w-full  break-words text-[15px]">{shipmentData?.fromName}</p>
                      <p className="w-full  break-words text-[15px]">{shipmentData?.fromPostal}</p>
                    </div>
                  </div>
                )}

                {loading && (
                  <div className="mt-2">
                    <Stack spacing={1}>
                      {/* For variant="text", adjust the height via font-size */}
                      <Skeleton variant="rounded" width={210} height={25} />
                      <Skeleton variant="rounded" width={450} height={25} />
                      <Skeleton variant="rounded" width={350} height={25} />
                      <Skeleton variant="rounded" width={300} height={25} />
                    </Stack>
                  </div>
                )}
              </div>
            )}
            <div className="w-full border-slate-400 border-solid border-[1px] m-3 p-2.5 px-4 rounded-[12px]">
              <div className="flex items-center gap-1.5 mb-1 text-slate-600">
                <FcApproval size={20} />
                <p className="font-semibold text-[15px]">Ship to</p>
              </div>
              {!loading && (
                <div>
                  <div className="flex">
                    <div className="flex items-center w-full">
                      <img
                        src={`https://flagsapi.com/${shipmentData?.code?.toUpperCase()}/shiny/64.png`}
                        className="w-[28px] h-[28px] object-cover"
                      />
                      <p className="w-full font-medium text-[18px] break-words ml-1.5">{shipmentData?.toCountry}</p>
                    </div>
                    <p className="w-full font-medium break-words text-[18px]">{shipmentData?.importer}</p>
                  </div>
                  {shipmentData?.uid === user.uid && (
                    <div className="flex">
                      <p className="w-full text-[15px] break-words">{shipmentData?.toAddress}</p>
                      <p className="w-full  break-words text-[15px]">{shipmentData?.toEmail}</p>
                    </div>
                  )}
                  <div className="flex">
                    <p className="w-full text-[15px] break-words">
                      {shipmentData?.toState}, {shipmentData?.toTown}, {shipmentData?.toCity}
                    </p>
                    {shipmentData.uid === user.uid && (
                      <p className="w-full  break-words text-[15px]">{shipmentData?.toPhone}</p>
                    )}
                  </div>
                  {shipmentData.uid === user.uid && (
                    <div className="flex">
                      <p className="w-full  break-words text-[15px]">{shipmentData?.toName}</p>
                      <p className="w-full  break-words text-[15px]">{shipmentData?.toPostal}</p>
                    </div>
                  )}
                </div>
              )}
              {loading && (
                <div className="mt-2">
                  <Stack spacing={1}>
                    {/* For variant="text", adjust the height via font-size */}
                    <Skeleton variant="rounded" width={450} height={25} />
                    <Skeleton variant="rounded" width={320} height={25} />
                    <Skeleton variant="rounded" width={285} height={25} />
                    <Skeleton variant="rounded" width={150} height={25} />
                  </Stack>
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="m-3 flex items-start">
            <div className="bg-white rounded-[17px] shadow-[rgba(0,0,0,0.1)_0px_1px_2px_0px] p-1.5 w-full">
              <div className="p-3.5 pb-0 flex items-center justify-between">
                <p className="font-semibold text-lg">Shipment detail</p>
              </div>
              <div className="flex relative">
                <div className="w-full border-slate-400 border-solid border-[1px] m-3 p-2.5 px-4 rounded-[12px]">
                  {!loading && (
                    <div>
                      <div className="flex items-start">
                        {shipmentData.uid === user.uid && (
                          <div className="w-full">
                            <p className="w-full text-slate-500 text-[14px]">Dimensions</p>
                            {shipmentData?.packageValue?.map((dim) => (
                              <div className="mb-1.5">
                                <div className="flex items-center w-full">
                                  <p>
                                    {dim.packageLength}cm x {dim.packageWidth}cm x {dim.packageHeight}cm / 5000
                                  </p>
                                  <p className="ml-auto mr-5">Gross: {dim.packageWeight} kg</p>
                                </div>
                                <p className="font-bold text-[11px] text-emerald-600 leading-3 bg-emerald-50 w-fit">
                                  Charged: {dim.packageCharge} kg
                                </p>
                              </div>
                            ))}
                            <p className="w-full text-slate-500 text-[14px] mt-2">Additional addresses</p>
                            {shipmentData?.toAddress2 && (
                              <div className="flex items-center">
                                <CiLocationOn size={23} />
                                <p className="ml-1.5">{shipmentData?.toAddress2}</p>
                              </div>
                            )}
                            {shipmentData?.toAddress3 && (
                              <div className="flex items-center">
                                <CiLocationOn size={23} />
                                <p className="ml-1.5">{shipmentData?.toAddress3}</p>
                              </div>
                            )}
                          </div>
                        )}
                        <div
                          className={`${
                            shipmentData?.uid === user.uid ? 'w-[450px]' : 'w-full'
                          } multi-box rounded-[12px] p-2.5`}
                        >
                          <p className="text-lg">Shipment status</p>
                          <Box>
                            <Stepper orientation="vertical" activeStep={shipmentData?.desc?.length - 1}>
                              {shipmentData?.desc?.map((desc, index) => (
                                <Step key={index}>
                                  <StepLabel
                                    optional={
                                      index === shipmentData.description?.length - 1 ? (
                                        <Typography variant="caption">Last step</Typography>
                                      ) : null
                                    }
                                    // style={{}}
                                  >
                                    <p className="text-[darkblue] mx-2">{formatDate(desc.time)}</p>
                                    <p className="my-0.5 mx-2 text-[17px]">{desc.stat}</p>
                                    <p className="text-[13px] text-slate-600 mx-2">at {desc.location}</p>
                                    {index === 4 && (
                                      <p className="text-[13px] text-slate-600 mx-2">
                                        shipped to{' '}
                                        <span className="text-black">
                                          {shipmentData?.toCountry} - {shipmentData?.toCity}
                                        </span>
                                      </p>
                                    )}
                                  </StepLabel>
                                </Step>
                              ))}
                            </Stepper>
                          </Box>
                        </div>
                      </div>
                    </div>
                  )}
                  {loading && (
                    <Stack spacing={1}>
                      {/* For variant="text", adjust the height via font-size */}
                      <Skeleton variant="rounded" width={100} height={15} />
                      <Skeleton variant="rounded" width={500} height={25} />
                      <Skeleton variant="rounded" width={350} height={25} />
                      <Skeleton variant="rounded" width={300} height={25} />
                      <Skeleton variant="rounded" width={400} height={25} />
                      <Skeleton variant="rounded" width={250} height={25} />
                    </Stack>
                  )}
                </div>
              </div>
              {shipmentData?.uid === user.uid && (
                <div className="p-3.5 pb-0 flex items-center justify-between">
                  <p className="font-semibold text-lg">Description of goods</p>
                </div>
              )}
              {shipmentData?.uid === user.uid && (
                <div className="flex relative">
                  <div className="w-full border-slate-400 border-solid border-[1px] m-3 p-2.5 px-4 rounded-[12px]">
                    {!loading && (
                      <div>
                        <div className="flex">
                          <div className="w-full">
                            {shipmentData?.invoiceValue?.map((item) => (
                              <div className="my-2 border-b-[1px] border-slate-400 border-t-0 border-x-0 border-solid">
                                <div className="flex items-start w-full">
                                  <p className="text-lg break-words">{item.itemCommonity}</p>
                                  <p className="ml-auto">${item.itemValue.toFixed(2)}</p>
                                </div>
                                <div className="flex items-center justify-between">
                                  <p className="font-bold text-[11px] text-slate-600 leading-3">
                                    {item.itemVietnamCommonity}
                                  </p>
                                  <p className="font-bold text-[11px] text-slate-600 leading-3">
                                    Qty: {item.itemQuantity}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                    {loading && (
                      <Stack spacing={1}>
                        <Skeleton variant="rounded" width={600} height={30} />
                        <Skeleton variant="rounded" width={400} height={30} />
                        <Skeleton variant="rounded" width={350} height={30} />
                        <Skeleton variant="rounded" width={420} height={30} />
                        <Skeleton variant="rounded" width={230} height={30} />
                      </Stack>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="w-[440px] ml-2">
              <div className="m-1 p-3.5 bg-sky-50 border-[darkblue] border-solid border-[1px] text-sky-700 rounded-[12px]">
                <MdStickyNote2 size={30} className="p-1.5 bg-sky-600 text-white rounded-full" />
                <p className="mt-1.5">
                  <span className="font-semibold">Xin lưu ý:</span> Quý khách chỉ có thể thao tác và xem chi tiết nếu
                  như lô hàng được tạo bởi tài khoản này. Hãy thông cảm cho chúng tôi vì để đảm bảo tính bảo mật của tất
                  cả khách hàng và nhà vận chuyển.
                </p>
              </div>
              {shipmentData?.uid === user.uid && (
                <div className="m-1 mt-5 p-3.5 bg-white border-slate-400 border-solid border-[1px] rounded-[12px]">
                  {!loading && (
                    <div>
                      <p>Documents</p>
                      <div
                        className="flex items-center text-slate-500 my-1.5 cursor-pointer hover:text-sky-600 active:opacity-50"
                        onClick={printLabel}
                      >
                        <p className="text-[15px] font-medium">Airway bill</p>
                        <FaDownload size={20} className="ml-auto" />
                      </div>
                      <div
                        className="flex items-center text-slate-500 my-1.5 cursor-pointer hover:text-sky-600 active:opacity-50"
                        onClick={printShippingMark}
                      >
                        <p className="text-[15px] font-medium">Shipping mark</p>
                        <FaDownload size={20} className="ml-auto" />
                      </div>
                      <div
                        className="flex items-center text-slate-500 my-1.5 cursor-pointer hover:text-sky-600 active:opacity-50"
                        onClick={handlePrintInternationalLabel}
                      >
                        <p className="text-[15px] font-medium">International label</p>
                        <FaDownload size={20} className="ml-auto" />
                      </div>
                      <div
                        className="flex items-center text-slate-500 my-1.5 cursor-pointer hover:text-sky-600 active:opacity-50"
                        onClick={printInvoice}
                      >
                        <p className="text-[15px] font-medium">Custom invoice</p>
                        <FaDownload size={20} className="ml-auto" />
                      </div>
                      <div
                        className="flex items-center text-purple-600 my-1.5 cursor-pointer hover:text-purple-600 active:opacity-50 hover:underline"
                        onClick={printAll}
                      >
                        <p className="text-[15px] font-medium">Print all</p>
                        <FaDownload size={20} className="ml-auto" />
                      </div>
                    </div>
                  )}
                  {loading && (
                    <div>
                      <p className="mb-1.5">Documents</p>
                      <Stack spacing={1}>
                        <Skeleton variant="rounded" width={200} height={18} />
                        <Skeleton variant="rounded" width={250} height={18} />
                        <Skeleton variant="rounded" width={150} height={18} />
                        <Skeleton variant="rounded" width={180} height={18} />
                        <Skeleton variant="rounded" width={230} height={18} />
                      </Stack>
                    </div>
                  )}
                </div>
              )}
              {loading && (
                <div className="m-1 my-5">
                  <Stack>
                    <Skeleton variant="rounded" height={170} />
                  </Stack>
                </div>
              )}
              {!loading && (
                <div className="m-1 my-5 h-[170px] overflow-hidden rounded-[12px] relative group select-none border-purple-500 border-solid border-[1px]">
                  <img
                    src={spacepackage}
                    loading="lazy"
                    className="w-full h-full transition-[95s] object-cover rounded-[12px] group-hover:scale-110"
                  />
                  <div className="absolute top-0 right-0 bottom-0 left-0  flex">
                    <div className="mt-auto w-full">
                      <div
                        className="bg-black/75 text-purple-400 font-semibold rounded-[8px] cursor-pointer hover:bg-black active:scale-95 p-2.5 text-center border-t-[1px] border-b-0 border-x-0 border-purple-600 border-solid"
                        onClick={() => {
                          navigate('/create');
                        }}
                      >
                        <p>Ship now!</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div ref={printAllRef}>
        <div className="skydart-booking-hawb1" ref={labelRef}>
          <div className="printing3">
            <StaffBill2
              hawb={shipmentData?.hawb}
              exporter={shipmentData?.exporter}
              fromAddress={shipmentData?.fromAddress}
              fromAddress2={shipmentData?.fromAddress2}
              fromAddress3={shipmentData?.fromAddress3}
              fromState={shipmentData?.fromState}
              fromTown={shipmentData?.fromTown}
              fromCity={shipmentData?.fromCity}
              fromPostal={shipmentData?.fromPostal}
              fromCountry={shipmentData?.fromCountry}
              fromPhone={shipmentData?.fromPhone}
              fromName={shipmentData?.fromName}
              fromEmail={shipmentData?.fromEmail}
              importer={shipmentData?.importer}
              toAddress={shipmentData?.toAddress}
              toAddress2={shipmentData?.toAddress2}
              toAddress3={shipmentData?.toAddress3}
              toState={shipmentData?.toState}
              toTown={shipmentData?.toTown}
              toCity={shipmentData?.toCity}
              toPostal={shipmentData?.toPostal}
              toCountry={shipmentData?.toCountry}
              toPhone={shipmentData?.toPhone}
              toName={shipmentData?.toName}
              toEmail={shipmentData?.toEmail}
              amount={shipmentData?.totalPackage}
              weight={shipmentData?.weight}
              chargeable={shipmentData?.totalCharge}
              type={shipmentData?.shipmentType}
              date={shipmentData?.createAt}
              exportSelected={[]}
              description={shipmentData?.description}
            />
          </div>
        </div>
        {shipmentData?.shipmentType === 'WPX' && (
          <div className="skydart-booking-hawb1" ref={invoiceRef}>
            <Inv2
              invoice={shipmentData?.invoiceValue}
              hawb={shipmentData?.hawb}
              datePicker={shipmentData?.createAt}
              fromCountry={shipmentData?.fromCountry}
              toCountry={shipmentData?.toCountry}
              usage={shipmentData?.usage}
              exporter={shipmentData?.exporter}
              fromAddress={shipmentData?.fromAddress}
              fromAddress2={shipmentData?.fromAddress2}
              fromAddress3={shipmentData?.fromAddress3}
              fromTown={shipmentData?.fromTown}
              fromState={shipmentData?.fromState}
              fromCity={shipmentData?.fromCity}
              fromPostal={shipmentData?.fromPostal}
              fromName={shipmentData?.fromName}
              fromPhone={shipmentData?.fromPhone}
              fromEmail={shipmentData?.fromEmail}
              mst={shipmentData?.mst}
              importer={shipmentData?.importer}
              toAddress={shipmentData?.toAddress}
              toAddress2={shipmentData?.toAddress2}
              toAddress3={shipmentData?.toAddress3}
              toState={shipmentData?.toState}
              toPostal={shipmentData?.toPostal}
              toTown={shipmentData?.toTown}
              toCity={shipmentData?.toCity}
              toName={shipmentData?.toName}
              toPhone={shipmentData?.toPhone}
              toEmail={shipmentData?.toEmail}
              eori={shipmentData?.eori}
              transportation={shipmentData?.transportation}
              totalGross={shipmentData?.weight}
              totalPackage={shipmentData?.totalPackage}
              totalItemValue={shipmentData?.totalItemValue}
              totalInvoiceValue={shipmentData?.totalInvoiceValue}
              exportSelected={[]}
            />
          </div>
        )}
        {shipmentData?.shipmentType === 'WPX' && (
          <div className="skydart-booking-hawb1" ref={shippingMarkRef}>
            {shipmentData?.packageValue?.map((item, index) => (
              <div className="printing4">
                <ShippingMark
                  no={index + 1}
                  date={shipmentData?.createAt}
                  length={item.packageLength}
                  width={item.packageWidth}
                  height={item.packageHeight}
                  total={shipmentData?.packageValue.length}
                  hawb={shipmentData?.hawb}
                  weight={item.packageWeight}
                  chargeable={item.packageCharge}
                  importer={shipmentData?.importer}
                  address={shipmentData?.toAddress}
                  address2={shipmentData?.toAddress2}
                  address3={shipmentData?.toAddress3}
                  city={shipmentData?.toCity}
                  state={shipmentData?.toState}
                  town={shipmentData?.toTown}
                  postal={shipmentData?.toPostal}
                  country={shipmentData?.toCountry}
                  phone={shipmentData?.toPhone}
                  contact={shipmentData?.toName}
                />
              </div>
            ))}
            {/* {selectPrint.map((s) => {
                        let package = s.packageValue;
                        return <>
                        </>
                    })} */}
          </div>
        )}
        <iframe ref={iframeRef} style={{ display: 'none' }} title="Print Frame"></iframe>
      </div>
    </div>
  );
}
