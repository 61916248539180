export const dimensionArray = [
  '5.2 Kg / Dim 20x30x40 cm',
  'Dim 20x30x40 - 5.2 Kg',
  'Size: 20x30x40 cm, Weight: 5.2 Kg',
  'Size=15x25x35, Weight=10Kg',
  'Dim: 20x30x40 cm, Weight: 5.2 Kg',
  'Dim=15X25X35, Weight=10Kg',
  'Package: 20x30x40 cm, Weight 5.2 Kg',
  'Dimensions=15X25X35 cm; Weight=10Kg',
  'L: 20 x W: 30 x H: 40 cm, Weight: 5.2 Kg',
  'D: 15 x R: 25 x C: 35 cm, Nặng=10Kg',
  '20 x 30 x 40 cm | Weight: 5.2 Kg',
  'Package Dimensions: 15X25X35 cm, Weight: 10Kg',
  'Dimensions: 20x30x40 cm, Weight 5.2 Kg',
  '15 x 25 x 35 | 10Kg',
  '(20x30x40 cm) and Weight 5.2 Kg',
];
