export const weightDescription = [
  'Hãng vận chuyển sẽ kiểm tra hai loại trọng lượng: trọng lượng thực tế, bao gồm cả bao bì và pallet.',
  'Trọng lượng thực tế là toàn bộ trọng lượng của lô hàng, tính cả bao bì và pallet, thường đo bằng kilôgam (kg).',
  'Khi vận chuyển, trọng lượng thực được tính là tổng khối lượng lô hàng cùng với bao bì và pallet.',
  'Các hãng sẽ so sánh trọng lượng thực tế, tức toàn bộ trọng lượng lô hàng, bao gồm cả bao bì và pallet.',
  'Trọng lượng thực tế của lô hàng bao gồm khối lượng của hàng hóa và bất kỳ bao bì hoặc pallet nào.',
  'Trong vận chuyển, trọng lượng thực thường được tính bằng cách cân tất cả, từ hàng hóa đến bao bì và pallet.',
  'Hãng vận chuyển sẽ tính trọng lượng thực, là khối lượng toàn bộ lô hàng, kể cả bao bì và pallet.',
  'Toàn bộ trọng lượng của lô hàng, bao gồm cả bao bì và pallet, được gọi là trọng lượng thực tế.',
  'Trọng lượng thực tế là tổng khối lượng vật lý của lô hàng, tính cả bao bì và pallet.',
  'Các hãng vận chuyển xác định trọng lượng thực bằng cách cân toàn bộ lô hàng, kể cả bao bì và pallet.',
  'Khi đo trọng lượng thực tế, hãng vận chuyển sẽ tính luôn cả khối lượng bao bì và pallet.',
  'Trọng lượng thực tế là tổng khối lượng vật lý của lô hàng, bao gồm tất cả bao bì và pallet kèm theo.',
  'Hãng vận chuyển kiểm tra trọng lượng thực, là toàn bộ trọng lượng hàng hóa và bao bì.',
  'Trọng lượng thực được tính từ tất cả mọi thứ, bao gồm hàng hóa, bao bì và pallet.',
  'Trong vận chuyển, trọng lượng thực là toàn bộ khối lượng vật lý của lô hàng, kể cả bao bì.',
  'Trọng lượng thực tế bao gồm khối lượng hàng hóa và toàn bộ bao bì, pallet đi kèm.',
  'Hãng vận chuyển đo trọng lượng thực bằng cách cân tất cả, từ hàng hóa đến bao bì và pallet.',
  'Toàn bộ trọng lượng của lô hàng, bao gồm bao bì và pallet, được gọi là trọng lượng thực tế.',
  'Khi vận chuyển, hãng sẽ so sánh trọng lượng thực tế với các loại trọng lượng khác.',
  'Trọng lượng thực bao gồm mọi thứ: hàng hóa, bao bì và cả pallet đi kèm.',
  'Khi đo trọng lượng thực, hãng vận chuyển tính cả bao bì và pallet để xác định tổng khối lượng.',
  'Trọng lượng thực là tổng trọng lượng của lô hàng, bao gồm cả vật liệu đóng gói và pallet.',
  'Hãng sẽ tính trọng lượng thực tế bằng cách cân tất cả, kể cả bao bì và pallet.',
  'Trọng lượng thực tế được đo bằng toàn bộ khối lượng hàng hóa và bao bì đi kèm.',
  'Trong vận chuyển, trọng lượng thực là toàn bộ trọng lượng vật lý của lô hàng, thường đo bằng kg.',
  'Trọng lượng thực tế bao gồm tổng khối lượng hàng hóa, bao bì và bất kỳ pallet nào đi kèm.',
  'Hãng vận chuyển đo trọng lượng thực bằng cách cân toàn bộ lô hàng, bao gồm hàng hóa và bao bì.',
  'Trọng lượng thực được xác định dựa trên tổng trọng lượng hàng hóa và vật liệu đóng gói.',
  'Khi cân lô hàng, hãng vận chuyển tính trọng lượng thực bằng tổng khối lượng hàng hóa và pallet.',
  'Trọng lượng thực tế là toàn bộ khối lượng vật lý của lô hàng, kể cả bao bì và vật liệu đóng gói.',
  'Hãng vận chuyển sẽ tính toàn bộ trọng lượng hàng hóa, bao gồm cả bao bì và pallet, để ra trọng lượng thực.',
  'Trọng lượng thực được tính bằng cách cân tổng khối lượng lô hàng, bao bì và các pallet đi kèm.',
  'Trong vận chuyển, trọng lượng thực là tổng trọng lượng của hàng hóa và tất cả vật liệu đóng gói.',
  'Hãng sẽ cân tất cả, từ hàng hóa đến bao bì và pallet, để xác định trọng lượng thực.',
  'Toàn bộ trọng lượng lô hàng, bao gồm hàng hóa và bao bì, được gọi là trọng lượng thực.',
  'Khi đo trọng lượng thực tế, hãng vận chuyển tính luôn cả bao bì và pallet trong tổng khối lượng.',
  'Trọng lượng thực bao gồm tất cả mọi thứ: hàng hóa, bao bì và các vật liệu hỗ trợ khác.',
  'Trong vận chuyển, trọng lượng thực là toàn bộ trọng lượng vật lý của lô hàng và bao bì.',
  'Hãng vận chuyển đo trọng lượng thực bằng cách cân tất cả: hàng hóa, bao bì và các pallet.',
  'Trọng lượng thực tế được tính bằng tổng khối lượng hàng hóa, bao bì và các vật liệu đóng gói khác.',
  'Trọng lượng thực là tổng trọng lượng vật lý của lô hàng, tính cả bao bì và các pallet.',
  'Hãng vận chuyển sẽ so sánh trọng lượng thực, bao gồm hàng hóa và vật liệu đóng gói, với các trọng lượng khác.',
  'Toàn bộ trọng lượng của lô hàng, tính cả bao bì và pallet, là trọng lượng thực tế.',
  'Trọng lượng thực tế là tổng khối lượng lô hàng, bao gồm cả các vật liệu đóng gói và pallet.',
  'Hãng vận chuyển tính trọng lượng thực bằng cách cân toàn bộ lô hàng, kể cả bao bì và pallet.',
  'Trọng lượng thực bao gồm toàn bộ khối lượng hàng hóa và bất kỳ vật liệu đóng gói nào đi kèm.',
  'Khi đo trọng lượng thực, hãng sẽ cân tất cả: hàng hóa, bao bì và các pallet đi kèm.',
  'Trọng lượng thực tế là tổng trọng lượng vật lý của lô hàng, bao gồm cả bao bì và pallet.',
  'Hãng vận chuyển sẽ tính trọng lượng thực bằng cách đo toàn bộ khối lượng lô hàng, tính cả bao bì và vật liệu đóng gói.',
];
