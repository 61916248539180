import Masonry from '@mui/lab/Masonry';
import { TextField } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Alert, Checkbox, Snackbar } from '@mui/material';
import { IoIosArrowBack } from 'react-icons/io';
import { formatArray } from './stg/formatArray';
import skydartLogo from '../../skydartLogo.png';
import { useNavigate } from 'react-router-dom';
export default function ScriptTemplate({ user }) {
  const navigate = useNavigate();
  const [formats, setFormats] = useState([]);
  const [text, setText] = useState('');
  const [displayCopy, setDisplayCopy] = useState(false);
  const [highlights, setHighlights] = useState([]);
  const [scrolls, setScrolls] = useState(0);
  const contentEditableRef = useRef(null);
  let scrollRef = useRef(null);
  const handleScroll = (e) => {
    const scrollY = e.target.scrollTop;
    setScrolls(scrollY);
  };
  useEffect(() => {
    if (contentEditableRef.current) {
      if (text.trim() === '') {
        contentEditableRef.current.setAttribute('data-placeholder', 'Hãy nhập gì đó...');
      } else {
        contentEditableRef.current.removeAttribute('data-placeholder');
      }
    }
  }, [text]);

  const saveSelection = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      return selection.getRangeAt(0);
    }
    return null;
  };

  const restoreSelection = (range) => {
    if (range) {
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  const handleInput = (e) => {
    const range = saveSelection();
    setText(e.target.innerText);
    restoreSelection(range);
  };

  const handleMouseUp = () => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const selectedText = selection.toString();
    if (selectedText.trim()) {
      const value = prompt('Enter value for highlighted text (e.g., Date, Person, Status):', '');
      if (value !== null) {
        const color = prompt('Enter highlight color (e.g., yellow, red, blue):', 'yellow');
        setHighlights([...highlights, { text: selectedText, value, color }]);
      }
    }
  };

  const applyHighlights = () => {
    if (!contentEditableRef.current) return;
    let html = text;
    highlights.forEach(({ text, color }) => {
      const regex = new RegExp(`(${text})`, 'gi');
      html = html.replace(
        regex,
        `<span style="background-color: ${color}; border-radius: 4px; padding: 2px 4px;">$1</span>`
      );
    });
    contentEditableRef.current.innerHTML = html;
  };

  useEffect(() => {
    const range = saveSelection();
    applyHighlights();
    restoreSelection(range);
  }, [highlights]);
  function resetCopy(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setDisplayCopy(false);
  }
  return (
    <div
      className="h-[100vh] overflow-auto workroom-notebook-center1 bg-zinc-50 p-7 "
      style={{
        backgroundPosition: `center ${scrolls}px`,
      }}
      onScroll={handleScroll}
      ref={scrollRef}
    >
      {displayCopy && (
        <Snackbar
          open={displayCopy}
          autoHideDuration={5000}
          onClose={resetCopy}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert elevation={5} variant="filled" onClose={resetCopy} severity="success">
            Sao chép thành công
          </Alert>
        </Snackbar>
      )}
      <div className="">
        <div className="flex items-center">
          <div
            className="flex items-center bg-zinc-100 p-2 px-4  cursor-pointer select-none hover:bg-zinc-200 active:bg-zinc-300 transition-[98s] w-fit rounded-full border-solid border-zinc-300 border-[1px] "
            onClick={() => {
              navigate(-1);
            }}
          >
            <IoIosArrowBack size={20} />
            <p className="text-[15px] mx-1">Quay lại</p>
          </div>
          <p className="ml-auto text-xl font-semibold tracking-wide">Shipper: {user.exporter || user.displayName}</p>
        </div>
        <div className="mt-4 ml-0.5 flex items-center">
          <img
            src={skydartLogo}
            alt=""
            loading="lazy"
            className="w-[30px] h-[30px] rounded-full bg-white object-contain mr-2.5"
          />
          <p className="text-3xl font-bold tracking-wider bg-gradient-to-r from-emerald-500 to-blue-700 bg-clip-text text-transparent">
            ShipScript Format, ship faster and smarter than ever
          </p>
        </div>
        <p className="text-[20px] ml-0.5">
          Anh chị có thể áp dụng khung mẫu đến với người gửi người nhận hàng trực tiếp để có thể tối ưu thời gian vận
          chuyển của mình hơn!
        </p>
      </div>
      <div className="flex mt-2 gap-3.5">
        {/* <div className="w-[350px] flex-shrink-0  rounded-[12px]">
          <p className="text-[14px] text-semibold">Định dạng mẫu</p>
          <div
            ref={contentEditableRef}
            contentEditable
            suppressContentEditableWarning
            onInput={handleInput}
            onMouseUp={handleMouseUp}
            className="bg-white text-gray-900 border border-gray-300 p-6 min-h-[150px] w-full rounded-[12px]  focus:outline-none text-lg tracking-wide leading-relaxed z-10 relative before:absolute before:left-6 before:top-6 before:text-gray-400 before:content-[attr(data-placeholder)] empty:before:block mt-1"
          ></div>

          <div className="mt-8 w-full z-10">
            <h3 className="text-xl font-semibold  pb-3 mb-5 text-zinc-900">Cá nhân hoá format</h3>
            <ul className="list-none space-y-4">
              {highlights.map((h, index) => (
                <li
                  key={index}
                  className={`bg-white text-gray-900 p-4 rounded-2xl  flex flex-col gap-1.5 border-l-[16px]  border-solid border-zinc-200`}
                >
                  <span style={{ backgroundColor: h.color }} className="px-3 py-1 rounded-lg text-black font-semibold">
                    {h.text}
                  </span>
                  <span className="text-lg font-medium">➝ {h.value}</span>
                </li>
              ))}
            </ul>
          </div>
        </div> */}
        <div className="w-full    rounded-[12px]">
          <div className="bg-white/20 h-full p-4 rounded-[12px]">
            <Masonry spacing={1.5} columns={2}>
              {formatArray.map((text, index) => (
                <div className="p-2.5 bg-white/50  ">
                  <p className=" text-xl font-bold tracking-wide text-black">Format {index + 1}</p>
                  <p className="text-lg my-1 whitespace-pre-wrap">{text.content}</p>
                  <CopyToClipboard
                    onCopy={() => {
                      setDisplayCopy(true);
                    }}
                    text={text.content}
                  >
                    <p className="text-lg text-[darkblue] font-semibold tracking-wide hover:opacity-70 active:opacity-50 cursor-pointer select-none hover:underline w-fit">
                      Sao chép
                    </p>
                  </CopyToClipboard>
                </div>
              ))}
            </Masonry>
          </div>
        </div>
      </div>
    </div>
  );
}
