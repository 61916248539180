import React, { useState, useEffect, useRef } from 'react';
import MultiShipment from './MultiShipment';
import { v1 as uuid } from 'uuid';
import skydartLogo from '../../skydartLogo.png';
import multipacking from './multipacking.jpg';
import firebase from 'firebase/compat/app';
import PackageItem from './PackageItem';
import { IoAdd } from 'react-icons/io5';
import { BiRefresh } from 'react-icons/bi';
import { MdOutlineClear } from 'react-icons/md';
import { IoMdAdd } from 'react-icons/io';
import InvoiceItem from './InvoiceItem';
import { db } from '../../server/firebase';
import { Switch } from '@mui/material';
export default function CustomerMulti({ user }) {
  const [multiShipments, setMultiShipments] = useState([]);
  const [selectedShipments, setSelectedShipments] = useState([]);
  const [displayPackaging, setDisplayPackaging] = useState(false);
  const [currentShipment, setCurrentShipment] = useState({});
  const [currentStatus, setCurrentStatus] = useState('');
  const [isAnimated, setIsAnimated] = useState(true);
  const [packageValue, setPackageValue] = useState([
    {
      packageLength: 0,
      packageWidth: 0,
      packageHeight: 0,
      packageWeight: 0,
      packageCharge: 0,
      packNo: 1,
      pack: '',
      confirm: false,
      floatingPositions: [...Array(6)].map(() => ({
        top: `${Math.random() * 100}%`,
        left: `${Math.random() * 100}%`,
      })),
    },
  ]);
  const [invoiceValue, setInvoiceValue] = useState([
    {
      id: uuid(),
      isSelectItem: false,
      itemCommonity: '',
      itemVietnamCommonity: '',
      itemCountryOrigin: 'Vietnam',
      itemManufactAddress: '',
      itemPackType: '',
      itemPackType2: '',
      itemHsCode: '',
      itemPnc: '',
      itemUnit: '',
      itemOtherUnit: '',
      itemPurpose: '',
      itemManufact: '',
      itemUnitPrice: 0,
      itemQuantity: 0,
      itemValue: 0,
      itemBrand: '',
      itemIngredients: '',
      itemNo: 1,
      itemFda: '',
      itemUnit2: '',
    },
  ]);
  function removeAccents(str) {
    var AccentsMap = [
      'aàảãáạăằẳẵắặâầẩẫấậ',
      'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
      'dđ',
      'DĐ',
      'eèẻẽéẹêềểễếệ',
      'EÈẺẼÉẸÊỀỂỄẾỆ',
      'iìỉĩíị',
      'oòỏõóọôồổỗốộơờởỡớợ',
      'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
      'uùủũúụưừửữứự',
      'UÙỦŨÚỤƯỪỬỮỨỰ',
      'yỳỷỹýỵ',
      'YỲỶỸÝỴ',
    ];
    for (var i = 0; i < AccentsMap.length; i++) {
      var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
      var char = AccentsMap[i][0];
      str = str.replace(re, char);
    }
    return str;
  }
  const getFormattedDate = () => {
    const today = new Date();

    const day = today.getDate(); // Get day of the month
    const month = today.toLocaleString('en-US', { month: 'short' }); // Get abbreviated month
    const year = today.getFullYear(); // Get the year

    return `${day} ${month}, ${year}`;
  };

  const handleAddMulti = () => {
    if (multiShipments.length > 2) return;
    const refNumber = 'SKD';
    const newDates = new Date();
    const dates = (newDates.getDate() < 10 ? '0' : '') + newDates.getDate();
    const months = (newDates.getMonth() + 1 < 10 ? '0' : '') + (newDates.getMonth() + 1);
    const years = newDates.getFullYear().toString().charAt(2);
    const yearLength = newDates.getFullYear().toString().charAt(3);
    const max = 900000;
    const wrapper = `05${months}${dates}`;
    const newHawb = refNumber + wrapper + Math.floor(100000 + Math.random() * max);
    const newShipment = {
      loadingContent: 'Booking your shipment...',
      dateInput: '',
      timeInput: '',
      loading: false,
      isShow: false,
      isTypeSelect: false,
      isCountrySelect: false,
      searchDestination: '',
      showCountryType: 'flag',
      account: '786009',
      pickupScheduled: '',
      deliverySign: '',
      currency: 'USD',
      service: '',
      timestamp: '',
      perform: 'New',
      status: 'Pending',
      id: (Math.random() + 1).toString(36).substring(7),
      desc: [],
      controller: user.controller,
      eamFormat: '',
      important: '',
      airline: 'Pending',
      mawb: 'Pending',
      pack: 'Pending',
      etd: 'Pending',
      agent: 'Pending',
      subStatus: 'Pending',
      sin: 'Null',
      note: 'Null',
      perform: 'Null',
      code: '',
      hawb: newHawb,
      uid: user.uid,
      makh: user.makh,
      paymentStatus: '',
      phoneCode: '',
      shipmentType: 'WPX',
      fromCountry: user.country,
      exporter: removeAccents(user.exporter),
      fromCity: removeAccents(user.city),
      fromState: removeAccents(user.state),
      fromTown: removeAccents(user.town),
      fromAddress: removeAccents(user.address),
      fromAddress2: removeAccents(user.address2),
      fromAddress3: removeAccents(user.address3),
      value: '',
      fromPostal: '',
      fromPhone: '',
      fromName: removeAccents(user.displayName),
      toPhone2: '',
      fromEmail: user.email,
      dateTime: new Date(),
      toCountry: '',
      importer: '',
      toCity: '',
      toState: '',
      stateLabel: '',
      toTown: '',
      toAddress: '',
      toAddress2: '',
      toAddress3: '',
      stateCode: '',
      toPostal: '',
      toPhone: '',
      toName: '',
      toEmail: '',
      createAt: '',
      time: '',
      invoiceValue: [],
      packageValue: [],
      totalCharge: '',
      reweight: '',
      weight: '',
      totalPackage: '',
      totalItem: '',
      eori: '',
      otherTerm: '',
      description: '',
      term: '',
      transportation: '',
      totalItemValue: '',
      totalInvoiceValue: '',
      usage: '',
      rate: '',
      fsc: '',
      fscValue: '',
      addressCorrection: '',
      direct: '',
      surcharge: '',
      surcharge2: '',
      surcharge3: '',
      remote: '',
      vat: '',
      vatValue: '',
      eamService: '',
      skydartZone: '',
      plan: user.plan || 'Pending',
      booked: 'false',
    };
    setMultiShipments([...multiShipments, newShipment]);
  };

  const handleDeleteShipment = (hawbValue) => {};
  const handleShowShipmentType = (hawbValue) => {
    setSelectedShipments(
      multiShipments.map((sd) => {
        if (sd.hawb === hawbValue) {
          sd.isTypeSelect = !sd.isTypeSelect;
        }
        return sd;
      })
    );
  };
  const handleCloseShipmentType = (hawbValue) => {
    setSelectedShipments(
      multiShipments.map((sd) => {
        if (sd.hawb === hawbValue) {
          sd.isTypeSelect = false;
        }
        return sd;
      })
    );
  };
  const handleChangeShipmentType = (hawbValue, newValue) => {
    setSelectedShipments(
      multiShipments.map((sd) => {
        if (sd.hawb === hawbValue) {
          sd.isTypeSelect = false;
          sd.shipmentType = newValue;
        }
        return sd;
      })
    );
  };
  const handleShowShipmentCountry = (hawbValue) => {
    setSelectedShipments(
      multiShipments.map((sd) => {
        if (sd.hawb === hawbValue) {
          sd.isCountrySelect = true;
        }
        return sd;
      })
    );
  };
  const handleCloseShipmentCountry = (hawbValue) => {
    setSelectedShipments(
      multiShipments.map((sd) => {
        if (sd.hawb === hawbValue) {
          sd.isCountrySelect = false;
        }
        return sd;
      })
    );
  };
  const handleChangeShipmentCountry = (hawbValue, newCountry, newCode, newPhone, newZone) => {
    setSelectedShipments(
      multiShipments.map((sd) => {
        if (sd.hawb === hawbValue) {
          sd.isCountrySelect = false;
          sd.searchDestination = '';
          sd.toCountry = newCountry;
          sd.code = newCode;
          sd.phoneCode = newPhone;
          sd.skydartZone = newZone;
        }
        return sd;
      })
    );
  };
  const handleBookSingleShipment = (hawbValue, bookingType, e) => {
    e.preventDefault();
    let newDate = new Date();
    let date = (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();
    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    let month = (newDate.getMonth() + 1 < 10 ? '0' : '') + (newDate.getMonth() + 1);
    let year = newDate.getFullYear();
    const datePicker = `${year}-${month}-${date}`;
    const monthName = months[newDate.getMonth()];
    const traceTime = `${date} ${monthName} ${year}`;
    const hour = (newDate.getHours() < 10 ? '0' : '') + newDate.getHours();
    const min = (newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes();
    const dateTime = `${hour}:${min}`;
    setMultiShipments(
      multiShipments.map((sd) => {
        if (sd.hawb === hawbValue) {
          sd.loading = true;
          db.collection('shipments')
            .doc(hawbValue)
            .set({
              ...sd,
              desc: firebase.firestore.FieldValue.arrayUnion({
                stat: 'Shipment booked, waiting for confirmation by Skydart ',
                time: datePicker + 'T' + dateTime + ':00',
                location: 'VIETNAM - VIETNAM - HCM',
              }),
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              createAt: datePicker,
              pickupScheduled: `${sd.dateInput}T${sd.timeInput}:00`,
              perform: bookingType === 'draft' ? 'Draft' : 'New',
            })
            .then(() => {
              sd.loadingContent = `${bookingType === 'draft' ? 'Save shipment success!' : 'Booking shipment success!'}`;
            })
            .catch((err) => {
              alert(err.message);
              sd.loading = false;
            });
        }
        return sd;
      })
    );
  };

  const handleBookMultipleShipment = (bookingType) => {
    let newDate = new Date();
    let date = (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();
    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    let month = (newDate.getMonth() + 1 < 10 ? '0' : '') + (newDate.getMonth() + 1);
    let year = newDate.getFullYear();
    const datePicker = `${year}-${month}-${date}`;
    const monthName = months[newDate.getMonth()];
    const traceTime = `${date} ${monthName} ${year}`;
    const hour = (newDate.getHours() < 10 ? '0' : '') + newDate.getHours();
    const min = (newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes();
    const dateTime = `${hour}:${min}`;
    setMultiShipments(
      multiShipments.map((s) => {
        s.loading = true;
        db.collection('shipments')
          .doc(s.hawb)
          .set({
            ...s,
            desc: firebase.firestore.FieldValue.arrayUnion({
              stat: 'Shipment booked, waiting for confirmation by Skydart ',
              time: datePicker + 'T' + dateTime + ':00',
              location: 'VIETNAM - VIETNAM - HCM',
            }),
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            createAt: datePicker,
            pickupScheduled: `${s.dateInput}T${s.timeInput}:00`,
            perform: bookingType === 'draft' ? 'Draft' : 'New',
          })
          .then(() => {
            s.loadingContent = `${bookingType === 'draft' ? 'Save shipment success!' : 'Booking shipment success!'}`;
          })
          .catch((err) => {
            s.loadingContent = `Something just happened, ${err.message}`;
          });
        return s;
      })
    );
  };
  const handleChangeShipmentFilterCountryType = (hawbValue, newValue) => {
    setSelectedShipments(
      multiShipments.map((sd) => {
        if (sd.hawb === hawbValue) {
          sd.showCountryType = newValue;
        }
        return sd;
      })
    );
  };
  const handleRemoveShipment = (hawbValue) => {
    setMultiShipments(
      multiShipments.filter((s) => {
        return s.hawb !== hawbValue;
      })
    );
  };

  let handleChange = (i, e) => {
    setMultiShipments((prev) =>
      prev.map((shipment, index) => (index === i ? { ...shipment, [e.target.name]: e.target.value } : shipment))
    );
  };

  const handleShowPackaging = (hawbValue) => {
    setSelectedShipments(
      multiShipments.map((sd) => {
        if (sd.hawb === hawbValue) {
          setDisplayPackaging(!displayPackaging);
          setPackageValue(sd.packageValue || packageValue);
          setInvoiceValue(sd.invoiceValue || invoiceValue);
          setCurrentShipment(sd);
          setCurrentStatus('');
        }
        return sd;
      })
    );
  };

  const handleClosePackaging = () => {
    if (currentStatus === '') {
      setDisplayPackaging(false);
    } else {
      setCurrentStatus('');
    }
  };

  const onFinishPackaging = () => {
    setSelectedShipments(
      multiShipments.map((sd) => {
        if (sd.hawb === currentShipment.hawb) {
          let arr = [];
          const finalInvoiceValue1 = invoiceValue.map((s) => {
            const match = s.itemCommonity.match(/\(([^)]+)\)/);
            if (match) {
              return {
                ...s,
                itemCommonity: s.itemCommonity,
                itemIngredients: match[1],
              };
            } else if (s.itemIngredients) {
              return {
                ...s,
                itemCommonity: `${s.itemCommonity} (made of ${s.itemIngredients})`,
              };
            }
            return s;
          });
          finalInvoiceValue1.map((s) => {
            arr.push(s.itemCommonity);
            return s;
          });
          setDisplayPackaging(false);
          sd.packageValue = packageValue;
          sd.invoiceValue = finalInvoiceValue1;
          sd.weight = sd.shipmentType === 'DOX' ? 0 : finalGross.toFixed(2);
          sd.reweight = sd.shipmentType === 'DOX' ? 0 : finalCharge.toFixed(2);
          sd.totalCharge = sd.shipmentType === 'DOX' ? 0 : finalCharge.toFixed(2);
          sd.description = arr.toString();
          sd.totalItemValue = sd.shipmentType === 'DOX' ? '0.00' : finalInvoiceValue.toFixed(2).toString();
          sd.totalInvoiceValue = sd.shipmentType === 'DOX' ? '0.00' : totalInvoiceValue.toFixed(2).toString();
        }
        return sd;
      })
    );
  };
  const onClosePackaging = () => {
    setDisplayPackaging(false);
  };
  const handleAdd = () => {
    if (currentStatus === '') {
      handleAddPackage();
    } else {
      handleAddInvoice();
    }
  };
  const handleAddPackage = () => {
    const newPackage = {
      packageLength: 0,
      packageWidth: 0,
      packageHeight: 0,
      packageWeight: 0,
      packageCharge: 0,
      packNo: packageValue?.length + 1,
      pack: '',
      confirm: false,
      floatingPositions: [...Array(6)].map(() => ({
        top: `${Math.random() * 100}%`,
        left: `${Math.random() * 100}%`,
      })),
    };
    setPackageValue([...packageValue, newPackage]);
  };
  const handleAddInvoice = () => {
    const newInvoice = {
      id: uuid(),
      isSelectItem: false,
      itemCommonity: '',
      itemVietnamCommonity: '',
      itemCountryOrigin: 'Vietnam',
      itemManufactAddress: '',
      itemPackType: '',
      itemPackType2: '',
      itemHsCode: '',
      itemPnc: '',
      itemUnit: '',
      itemOtherUnit: '',
      itemPurpose: '',
      itemManufact: '',
      itemUnitPrice: 0,
      itemQuantity: 0,
      itemValue: 0,
      itemBrand: '',
      itemIngredients: '',
      itemNo: invoiceValue?.length + 1,
      itemFda: '',
      itemUnit2: '',
    };
    setInvoiceValue([...invoiceValue, newInvoice]);
  };
  const packaging = packageValue.map((element) => {
    let dimCharge = 0;
    if (currentShipment?.service === 'DAU') {
      dimCharge = (element.packageWidth * element.packageHeight * element.packageLength) / 6000;
    } else if (currentShipment?.service === 'DUS') {
      dimCharge = (element.packageWidth * element.packageHeight * element.packageLength) / 6000;
    } else {
      dimCharge = (element.packageWidth * element.packageHeight * element.packageLength) / 5000;
    }

    if (dimCharge < element.packageWeight) {
      element.packageCharge = element.packageWeight;
    }
    if (element.packageWeight < dimCharge) {
      element.packageCharge = dimCharge;
    }
    if (element.packageCharge >= 30) {
      element.packageCharge = Math.ceil(element.packageCharge);
    }
    if (element.packageCharge < 30) {
      element.packageCharge = Math.ceil(element.packageCharge / 0.5) * 0.5;
    }
    if (element.packageWeight === '' || 0) {
      element.packageCharge = 0;
    }
    return {
      packageLength: element.packageLength,
      packageWidth: element.packageWidth,
      packageHeight: element.packageHeight,
      packageWeight: element.packageWeight,
      packageCharge: element.packageCharge,
      packNo: element.packNo,
    };
  });
  const finalCharge = packaging.reduce((a, v) => (a = +a + +v.packageCharge), 0);
  const finalGross = packaging.reduce((a, v) => (a = +a + +v.packageWeight), 0);

  const finalAttachInvoiceValue = invoiceValue.map((s) => {
    return {
      itemValue: s.itemQuantity * s.itemUnitPrice,
    };
  });
  const finalInvoiceValue = finalAttachInvoiceValue.reduce((a, v) => (a = +a.toFixed(2) + +v.itemValue?.toFixed(2)), 0);
  const totalInvoiceValue = +finalInvoiceValue + +currentShipment.transportation;
  let shipmentRef = useRef(null);
  let invoiceRef = useRef(null);
  const generateRandomPositions = () => {
    return [...Array(6)].map(() => ({
      top: `${Math.random() * 100}%`,
      left: `${Math.random() * 100}%`,
      right: Math.random(),
      bottom: Math.random(),
    }));
  };
  useEffect(() => {
    setPackageValue((prev) =>
      prev.map((pack, index) => ({
        ...pack,
        floatingPositions: generateRandomPositions(),
      }))
    );
    shipmentRef?.current?.scrollIntoView({
      behavior: 'smooth',
    });
  }, [packageValue.length]);
  useEffect(() => {
    invoiceRef?.current?.scrollIntoView({
      behavior: 'smooth',
    });
  }, [invoiceValue.length]);
  const handleSubmitPackaging = (e) => {
    e.preventDefault();
    if (currentStatus === '') {
      setCurrentStatus('invoice');
    } else {
      onFinishPackaging();
    }
  };

  const handleRefreshMulti = () => {
    setMultiShipments([]);
  };
  useEffect(() => {
    if (multiShipments.length < 1) {
      handleAddMulti();
    }
  }, [multiShipments.length]);
  return (
    <div>
      {displayPackaging && (
        <div className="fixed top-0 bottom-0 right-0 left-0 bg-[rgba(255,255,255,0.75)] flex justify-center z-[10000] items-center">
          <form
            className="w-[900px] h-[500px] overflow-auto bg-white rounded-[12px] shadow-[rgba(0,0,0,0.15)_0px_2px_8px] flex flex-col"
            onSubmit={(e) => handleSubmitPackaging(e)}
          >
            <div className="p-3.5 flex items-center sticky top-0 z-10 bg-white shadow-[rgba(0,0,0,0.1)_0px_1px_3px_0px,rgba(0,0,0,0.04)_0px_1px_2px_0px]">
              <p className="text-2xl font-bold text-slate-600">Shipment's packaging and invoice</p>
              <div
                className="flex items-center w-[32px] h-[32px] justify-center rounded-[10px] hover:bg-slate-200 active:bg-slate-300 cursor-pointer text-slate-500 ml-auto"
                onClick={handleAdd}
              >
                <IoAdd size={25} />
              </div>
              <div
                className="flex items-center w-[32px] h-[32px] justify-center rounded-[10px] hover:bg-slate-200 active:bg-slate-300 cursor-pointer text-slate-500"
                onClick={() => {
                  if (currentStatus === '') {
                    setPackageValue([]);
                  } else {
                    setInvoiceValue([]);
                  }
                }}
              >
                <BiRefresh size={25} />
              </div>
              <div
                className="flex items-center w-[32px] h-[32px] justify-center rounded-[10px] hover:bg-slate-200 active:bg-slate-300 cursor-pointer text-slate-500"
                onClick={() => {
                  setDisplayPackaging(false);
                }}
              >
                <MdOutlineClear size={25} />
              </div>
            </div>
            {currentStatus !== '' && (
              <div>
                <div className="p-4 flex items-center justify-between">
                  <p className="font-bold ">Invoice value/{currentShipment?.service}</p>
                  <p>
                    <span className="text-green-600 font-semibold">{invoiceValue?.length}</span> items - Total item
                    value:{' '}
                    <span className="text-[darkblue] bg-sky-50">
                      {(Math.round(finalInvoiceValue * 100) / 100).toFixed(2)} USD
                    </span>{' '}
                    - Total invoice value:{' '}
                    <span className="text-[purple]">{(Math.round(totalInvoiceValue * 100) / 100).toFixed(2)} USD</span>
                  </p>
                </div>
                <div>
                  <div className="m-2.5">
                    <p className="text-[14px] text-[darkblue] m-1">- Use ( ) to include your ingredients</p>
                    <p className="text-[14px] text-[darkblue] m-1">- Use '-' to declare detail</p>
                    {invoiceValue.map((iv, index) => {
                      let donGia1 = iv.itemUnitPrice;
                      let soLuong1 = iv.itemQuantity;
                      let tong1 = donGia1 * soLuong1;
                      iv.itemValue = tong1;
                      return (
                        <InvoiceItem
                          key={index}
                          iv={iv}
                          index={index}
                          setInvoiceValue={setInvoiceValue}
                          invoiceValue={invoiceValue}
                        />
                      );
                    })}
                  </div>
                  <div
                    className="p-2 flex items-center justify-center font-bold text-[18px]  select-none cursor-pointer hover:opacity-70 gap-1 active:opacity-50 "
                    onClick={handleAddInvoice}
                  >
                    <IoMdAdd size={25} />
                    <p>Add invoice</p>
                  </div>
                  {displayPackaging && <div ref={currentStatus !== '' ? invoiceRef : {}} />}
                </div>
              </div>
            )}
            {currentStatus === '' && (
              <div>
                <div className="p-4 flex items-center justify-between">
                  <div className="flex items-center">
                    <p className="font-bold ">Packaging value/{currentShipment?.service}</p>
                    <div className="flex items-center">
                      <Switch
                        checked={isAnimated}
                        onChange={(e) => {
                          setIsAnimated(e.target.checked);
                        }}
                      />
                      <p className="text-[14px] text-slate-500">Animated</p>
                    </div>
                  </div>
                  <p>
                    <span className="text-green-600 font-semibold">{packageValue?.length}</span> pices - Total
                    chargeable: <span className="text-[darkblue] bg-sky-50">{finalCharge.toFixed(2)}kg</span> - Total
                    gross: <span className="text-[purple]">{finalGross.toFixed(2)}kg</span>
                  </p>
                </div>
                <div className="flex relative items-start">
                  <div className="w-full  m-2.5 rounded-[8px] ">
                    {packageValue.map((pack, index) => (
                      <PackageItem
                        key={index}
                        pack={pack}
                        logo={skydartLogo}
                        index={index}
                        setPackageValue={setPackageValue}
                        packageValue={packageValue}
                        isAnimated={isAnimated}
                      />
                    ))}
                    <div
                      className="p-2 flex items-center justify-center font-bold text-[18px]  select-none cursor-pointer hover:opacity-70 gap-1 active:opacity-50 "
                      onClick={handleAddPackage}
                    >
                      <IoMdAdd size={25} />
                      <p>Add package</p>
                    </div>
                    <div ref={displayPackaging ? shipmentRef : {}} />
                  </div>
                </div>
              </div>
            )}
            <div className="sticky bottom-0 z-10 bg-white p-3 shadow-[rgba(0,0,0,0.15)_0px_2px_8px]  mt-auto">
              <div className="flex items-center">
                <p className="text-slate-600">Information that is close to reality will help make the process faster</p>
                <div className="ml-auto flex items-center gap-1.5">
                  <div
                    className="p-1.5 bg-slate-100 px-3.5 font-semibold cursor-pointer hover:bg-slate-200 active:bg-slate-300 rounded-[8px] select-none transition-[98s]"
                    onClick={handleClosePackaging}
                  >
                    <p>{currentStatus === '' ? 'Cancel' : 'Go back'}</p>
                  </div>
                  {packageValue.length > 0 && currentStatus === '' && (
                    <button
                      className="p-1.5 bg-[darkblue] text-white px-3.5 font-bold cursor-pointer hover:opacity-70  rounded-[8px] select-none transition-[98s] active:opacity-50"
                      type="submit"
                    >
                      <p>Next, go to invoice</p>
                    </button>
                  )}
                  {invoiceValue.length > 0 && currentStatus !== '' && (
                    <button
                      className="p-1.5 bg-[darkblue] text-white px-3.5 font-bold cursor-pointer hover:opacity-70  rounded-[8px] select-none transition-[98s] active:opacity-50"
                      type="submit"
                    >
                      <p>Create invoice</p>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
      <div className="m-5">
        <div className="relative">
          <img src={multipacking} alt="" loading="lazy" className="w-full rounded-[12px] h-[350px] object-cover" />
          <div className="absolute top-0 right-0 bottom-0 left-0 bg-[rgba(0,0,0,0.6)] rounded-[12px] p-4 text-white">
            <p className="text-[14px]">Multiple shipments created</p>
            <p className="text-2xl mt-1 font-extrabold tracking-widest text-emerald-500 bg-clip-text bg-gradient-to-r  drop-shadow-lg">
              We help you optimize your booking time and process your shipment faster and more convenience.
            </p>

            <div className="flex items-center gap-2 mt-2">
              <div className="bg-gradient-to-tr from-green-600 to-[darkblue] w-fit rounded-full font-semibold  cursor-pointer select-none flex items-center transition-[98s] p-1">
                <div className="bg-white text-slate-600 rounded-full px-4 p-1.5 tracking-wide hover:bg-slate-100 active:bg-slate-200">
                  <p className="font-extrabold tracking-wider text-transparent bg-clip-text bg-gradient-to-r from-emerald-500 to-blue-600 ">
                    Upgrade my account
                  </p>
                </div>
              </div>
              <p className="font-extrabold tracking-widest text-blue-500 bg-clip-text  drop-shadow-lg cursor-pointer select-none  active:opacity-50 hover:underline">
                Learn more
              </p>
            </div>
          </div>
        </div>
      </div>
      {multiShipments?.length !== 0 && !displayPackaging && (
        <MultiShipment
          multiShipments={multiShipments}
          handleChange={handleChange}
          handleChangeShipmentFilterCountryType={handleChangeShipmentFilterCountryType}
          handleShowShipmentCountry={handleShowShipmentCountry}
          handleCloseShipmentCountry={handleCloseShipmentCountry}
          handleChangeShipmentCountry={handleChangeShipmentCountry}
          handleAddMulti={handleAddMulti}
          handleShowShipmentType={handleShowShipmentType}
          handleCloseShipmentType={handleCloseShipmentType}
          handleChangeShipmentType={handleChangeShipmentType}
          handleShowPackaging={handleShowPackaging}
          getFormattedDate={getFormattedDate}
          handleRemoveShipment={handleRemoveShipment}
          handleBookSingleShipment={handleBookSingleShipment}
          handleRefreshMulti={handleRefreshMulti}
          handleBookMultipleShipment={handleBookMultipleShipment}
        />
      )}
    </div>
  );
}
