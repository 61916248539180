import React, { useState, useEffect, useRef } from 'react';

export default function WordGeneratorEffect({
  text,
  delay = 50,
  isColor,
  isMultiColor,
  isFontStyle,
  onComplete,
  isSeen,
}) {
  const [displayedCharacters, setDisplayedCharacters] = useState([]);
  const hasRunOnce = useRef(false);
  const getDynamicBoxShadow = () => {
    // Generate a smooth, colored shadow with bluish/purplish vibes
    const shadowColors = [
      'rgba(50, 100, 200, 0.4)', // Cool Blue
      'rgba(80, 60, 180, 0.4)', // Deep Purple
      'rgba(120, 50, 160, 0.4)', // Magenta Tint
      'rgba(60, 130, 200, 0.3)', // Cyan Glow
    ];

    // Randomly pick a shadow color
    const mainShadow = shadowColors[Math.floor(Math.random() * shadowColors.length)];

    return `${mainShadow} 0px 4px 15px, 
              rgba(0, 0, 0, 0.1) 0px 6px 20px`;
  };

  useEffect(() => {
    // Only run the word effect if isSeen is false and the effect hasn't run
    if (!isSeen && !hasRunOnce.current) {
      let currentIndex = 0;

      const getBalancedLightColor = () => {
        // Chọn ngẫu nhiên giữa các tông màu lạnh (blue, cyan, purple, pink)
        const colorOptions = [
          [30, 100, 180], // Xanh dương trầm
          [80, 50, 180], // Tím ánh xanh
          [20, 120, 150], // Xanh ngọc
          [160, 40, 90], // Hồng đậm
          [100, 30, 160], // Tím huyền bí
        ];

        // Chọn ngẫu nhiên một tông màu trong danh sách
        const [r, g, b] = colorOptions[Math.floor(Math.random() * colorOptions.length)];

        // Điều chỉnh một chút độ sáng để tránh bị quá tối hoặc quá sáng
        const rAdj = Math.min(255, r + Math.random() * 30);
        const gAdj = Math.min(255, g + Math.random() * 30);
        const bAdj = Math.min(255, b + Math.random() * 30);

        return `rgb(${Math.floor(rAdj)}, ${Math.floor(gAdj)}, ${Math.floor(bAdj)})`;
      };

      const interval = setInterval(() => {
        if (currentIndex < text.length) {
          const nextChar = text[currentIndex];
          setDisplayedCharacters((prev) => [...prev, { char: nextChar, color: getBalancedLightColor() }]);
          currentIndex++;
        } else {
          clearInterval(interval);
          hasRunOnce.current = true; // Mark as completed
          if (onComplete) {
            onComplete();
          }
        }
      }, 15);

      return () => clearInterval(interval);
    } else {
      setDisplayedCharacters(
        text.split('').map((t) => {
          const getBalancedLightColor = () => {
            // Chọn ngẫu nhiên giữa các tông màu lạnh (blue, cyan, purple, pink)
            const colorOptions = [
              [30, 100, 180], // Xanh dương trầm
              [80, 50, 180], // Tím ánh xanh
              [20, 120, 150], // Xanh ngọc
              [160, 40, 90], // Hồng đậm
              [100, 30, 160], // Tím huyền bí
            ];

            // Chọn ngẫu nhiên một tông màu trong danh sách
            const [r, g, b] = colorOptions[Math.floor(Math.random() * colorOptions.length)];

            // Điều chỉnh một chút độ sáng để tránh bị quá tối hoặc quá sáng
            const rAdj = Math.min(255, r + Math.random() * 30);
            const gAdj = Math.min(255, g + Math.random() * 30);
            const bAdj = Math.min(255, b + Math.random() * 30);

            return `rgb(${Math.floor(rAdj)}, ${Math.floor(gAdj)}, ${Math.floor(bAdj)})`;
          };
          return { char: t, color: getBalancedLightColor() };
        })
      );
    }
  }, [isSeen, text, isColor]);
  return (
    <div className="tracking-wide ">
      {displayedCharacters.map((item, index) => (
        <span
          key={index}
          style={{
            color: isMultiColor ? item.color : 'rgb(39,39,42)',
            fontWeight: item.color === 'black' ? '' : isFontStyle ? 510 : '450',
            fontFamily: isFontStyle ? 'fantasy' : '',
          }}
        >
          {item.char}
        </span>
      ))}
    </div>
  );
}
