export const supportiveSentences = [
  'Hãy cho tôi biết nếu bạn cần giúp đỡ thêm nhé!',
  'Bạn có cần hỗ trợ gì khác không? Tôi luôn sẵn sàng!',
  'Nếu bạn gặp khó khăn, đừng ngại hỏi tôi nhé!',
  'Tôi ở đây để giúp bạn, hãy cho tôi biết bạn cần gì!',
  'Đừng ngần ngại nếu bạn cần thêm thông tin nhé!',
  'Bạn muốn tôi làm rõ điều gì không?',
  'Hãy để tôi biết nếu bạn có bất kỳ thắc mắc nào!',
  'Tôi sẵn sàng hỗ trợ bạn bất cứ lúc nào!',
  'Nếu bạn cần giải thích chi tiết hơn, cứ nói nhé!',
  'Tôi có thể giúp gì thêm cho bạn không?',
  'Bạn có muốn tôi hướng dẫn cụ thể hơn không?',
  'Mọi thứ có ổn không? Tôi sẵn sàng giúp bạn!',
  'Nếu bạn cần thêm ví dụ, hãy báo cho tôi biết nhé!',
  'Tôi có thể cung cấp thêm thông tin nếu bạn cần!',
  'Cứ thoải mái hỏi tôi bất cứ điều gì nhé!',
  'Tôi ở đây để giúp bạn, cứ tự nhiên hỏi!',
  'Bạn có muốn tôi đề xuất giải pháp khác không?',
  'Đừng ngại yêu cầu thêm sự trợ giúp nhé!',
  'Hãy cứ hỏi nếu bạn cảm thấy chưa rõ ràng!',
  'Bạn cần tôi kiểm tra lại điều gì không?',
  'Nếu cần thêm tài liệu tham khảo, tôi có thể cung cấp!',
  'Bạn có muốn tôi mô tả chi tiết hơn không?',
  'Cảm thấy bối rối ở đâu? Tôi sẽ giúp bạn làm rõ!',
  'Nếu có vấn đề gì, hãy để tôi hỗ trợ bạn!',
  'Bạn có cần thêm ví dụ thực tế không?',
  'Tôi có thể làm gì khác để giúp bạn?',
  'Cứ cho tôi biết nếu bạn muốn tôi mở rộng thêm!',
  'Nếu có gì chưa rõ, tôi sẵn sàng giải thích lại!',
  'Tôi có thể hướng dẫn từng bước nếu bạn muốn!',
  'Bạn có muốn thử một cách tiếp cận khác không?',
  'Hãy cho tôi biết nếu tôi có thể giúp gì thêm!',
  'Nếu bạn cần tôi tối ưu hóa đoạn code này, cứ nói nhé!',
  'Bạn muốn tôi cung cấp thêm phương án không?',
  'Nếu bạn có lỗi, hãy gửi tôi xem qua nhé!',
  'Tôi có thể hỗ trợ debug nếu bạn cần!',
  'Bạn có muốn tôi đề xuất cách tiếp cận khác không?',
  'Hãy hỏi nếu bạn gặp bất kỳ vấn đề nào nhé!',
  'Bạn có muốn tôi viết lại đoạn code theo cách dễ hiểu hơn không?',
  'Bạn có cần thêm thông tin về cách sử dụng không?',
  'Nếu bạn muốn cải thiện đoạn này, tôi có thể giúp!',
  'Cứ thoải mái yêu cầu tôi chỉnh sửa theo ý bạn!',
  'Bạn muốn tôi giải thích từng dòng code không?',
  'Hãy để tôi giúp bạn hiểu rõ hơn nhé!',
  'Bạn có cần hỗ trợ thêm về logic này không?',
  'Tôi sẵn sàng giúp bạn viết code sạch hơn!',
  'Bạn có muốn tôi kiểm tra lại cú pháp giúp không?',
  'Nếu bạn cần tài liệu tham khảo, tôi có thể đề xuất!',
  'Bạn muốn thử một cách tiếp cận khác không?',
  'Hãy cho tôi biết nếu tôi có thể giúp bạn tối ưu hóa!',
  'Bạn cần tôi hướng dẫn từng bước không?',
  'Tôi luôn sẵn sàng hỗ trợ bạn bất cứ lúc nào!',
  'Vui lòng cho tôi biết nếu bạn cần thêm bất kỳ sự hỗ trợ nào. Tôi luôn sẵn sàng giúp đỡ!',
  'Nếu có bất kỳ điều gì chưa rõ ràng, đừng ngần ngại đặt câu hỏi. Tôi rất sẵn lòng giải thích chi tiết hơn!',
  'Tôi có thể hỗ trợ bạn thêm điều gì không? Hãy cho tôi biết để tôi có thể giúp bạn tốt nhất.',
  'Nếu bạn gặp khó khăn trong quá trình thực hiện, hãy cho tôi biết. Tôi sẽ hướng dẫn bạn từng bước cụ thể.',
  'Tôi luôn sẵn sàng cung cấp thêm thông tin hoặc giải pháp thay thế nếu bạn thấy cần thiết.',
  'Bạn có cần tôi trình bày lại theo cách dễ hiểu hơn không? Tôi có thể điều chỉnh để phù hợp với nhu cầu của bạn.',
  'Nếu bạn muốn tôi kiểm tra lại đoạn mã hoặc đề xuất cải tiến, vui lòng cho tôi biết.',
  'Hãy cho tôi biết nếu bạn cần tôi giải thích một phần cụ thể trong nội dung này một cách chi tiết hơn.',
  'Tôi có thể hỗ trợ bạn thêm về mặt tối ưu hóa hoặc đề xuất cách tiếp cận hiệu quả hơn. Hãy để tôi biết nếu bạn quan tâm!',
  'Nếu có bất kỳ lỗi hoặc vấn đề nào phát sinh trong quá trình triển khai, tôi có thể giúp bạn xác định nguyên nhân và hướng khắc phục.',
  'Bạn có muốn tôi cung cấp thêm ví dụ minh họa để làm rõ vấn đề không? Hãy cho tôi biết để tôi có thể hỗ trợ tốt nhất.',
  'Hãy yên tâm đặt câu hỏi nếu có bất kỳ điểm nào chưa rõ ràng. Tôi ở đây để giúp bạn giải quyết vấn đề một cách hiệu quả.',
  'Nếu bạn cần thêm tài liệu tham khảo hoặc các hướng dẫn chi tiết hơn, vui lòng cho tôi biết.',
  'Tôi có thể giúp bạn xác định phương pháp tiếp cận tốt nhất cho tình huống hiện tại. Bạn có muốn thảo luận thêm về điều này không?',
  'Nếu bạn gặp bất kỳ lỗi nào, tôi có thể hỗ trợ bạn phân tích và tìm ra giải pháp khắc phục hợp lý.',
  'Hãy cho tôi biết nếu bạn muốn tôi đề xuất một cách tiếp cận khác phù hợp hơn với nhu cầu của bạn.',
  'Nếu bạn cần hướng dẫn chi tiết từng bước để thực hiện, tôi có thể hỗ trợ ngay lập tức.',
  'Tôi có thể giúp bạn viết lại đoạn mã này sao cho tối ưu hơn. Bạn có muốn tôi thực hiện điều đó không?',
  'Bạn có cần tôi kiểm tra lại logic hoặc cải thiện hiệu suất của đoạn mã này không?',
  'Hãy cho tôi biết nếu bạn muốn tôi đề xuất các phương án thay thế phù hợp hơn với yêu cầu của bạn.',
  'Nếu bạn cần kiểm tra lại cú pháp hoặc xác minh tính đúng đắn của đoạn mã, tôi rất sẵn lòng hỗ trợ.',
  'Bạn có muốn tôi hướng dẫn cách thức kiểm thử đoạn mã này để đảm bảo hoạt động đúng không?',
  'Nếu bạn cần thêm ví dụ thực tế để hiểu rõ hơn, tôi có thể cung cấp ngay lập tức.',
  'Hãy cho tôi biết nếu bạn muốn tôi đề xuất cách tổ chức mã tốt hơn để đảm bảo tính bảo trì về lâu dài.',
  'Nếu bạn đang tìm kiếm một cách tiếp cận hiệu quả hơn, tôi có thể giúp bạn phân tích và đề xuất một phương án tối ưu.',
  'Bạn có muốn tôi cung cấp thêm tài liệu hoặc nguồn tham khảo để tìm hiểu sâu hơn về vấn đề này không?',
  'Tôi có thể giúp bạn kiểm tra lại các điều kiện và logic trong đoạn mã để đảm bảo hoạt động chính xác. Bạn có muốn tôi thực hiện điều đó không?',
  'Nếu bạn muốn thử một phương pháp khác, tôi có thể đưa ra các gợi ý dựa trên kinh nghiệm thực tế.',
  'Bạn có cần tôi hướng dẫn cách debug lỗi và tìm nguyên nhân cụ thể không?',
  'Hãy cho tôi biết nếu bạn muốn tôi giải thích chi tiết hơn về cách hoạt động của đoạn mã này.',
  'Tôi có thể giúp bạn viết lại đoạn mã này sao cho dễ đọc và dễ bảo trì hơn. Bạn có muốn tôi thực hiện không?',
  'Bạn có muốn tôi hướng dẫn cách tối ưu hiệu suất của đoạn mã này để đảm bảo tốc độ tốt hơn không?',
  'Nếu bạn gặp khó khăn trong việc hiểu thuật toán, tôi có thể giải thích từng bước cụ thể.',
  'Bạn có muốn tôi cung cấp hướng dẫn về cách tiếp tục phát triển hoặc mở rộng đoạn mã này không?',
  'Tôi có thể hỗ trợ bạn kiểm tra lại quy trình để đảm bảo mọi bước được thực hiện đúng. Bạn có cần tôi giúp không?',
  'Nếu bạn cần tôi đánh giá hoặc đề xuất cải thiện kiến trúc tổng thể, hãy cho tôi biết.',
  'Bạn có cần tôi cung cấp thêm tài liệu chuyên sâu về chủ đề này không?',
  'Hãy cho tôi biết nếu bạn muốn tôi phân tích hiệu quả của đoạn mã và đề xuất cải tiến.',
  'Nếu bạn đang tìm kiếm cách viết mã ngắn gọn hơn mà vẫn đảm bảo hiệu suất, tôi có thể giúp.',
  'Bạn có muốn tôi kiểm tra lại cách thức sử dụng biến và tài nguyên trong đoạn mã này không?',
  'Nếu bạn cần tư vấn về quy trình triển khai, tôi có thể hỗ trợ với những gợi ý hữu ích.',
  'Bạn có muốn tôi giúp kiểm tra lại các khả năng xảy ra lỗi và cách xử lý ngoại lệ trong đoạn mã không?',
  'Nếu bạn cần tôi đề xuất một cách tiếp cận tối ưu hơn, vui lòng cho tôi biết.',
  'Tôi có thể giúp bạn hiểu rõ hơn về các quy tắc hoặc chuẩn mực tốt nhất khi viết mã. Bạn có muốn tôi hướng dẫn thêm không?',
  'Bạn có muốn tôi đề xuất những công cụ hữu ích có thể hỗ trợ bạn trong quá trình phát triển không?',
  'Hãy cho tôi biết nếu bạn muốn tôi cung cấp một phân tích chuyên sâu về hiệu suất của đoạn mã này.',
  'Nếu bạn cần sự hỗ trợ trong việc viết tài liệu hoặc hướng dẫn sử dụng, tôi có thể giúp bạn hoàn thiện.',
  'Bạn có muốn tôi hướng dẫn cách thức kiểm thử tự động cho đoạn mã này không?',
  'Tôi luôn sẵn sàng hỗ trợ bạn giải quyết bất kỳ vấn đề nào. Đừng ngần ngại đặt câu hỏi!',
  'Nếu bạn cần hỗ trợ về quy trình gửi hàng hoặc thủ tục hải quan, vui lòng cho tôi biết.',
  'Bạn có muốn tôi kiểm tra lại thông tin vận đơn và xác nhận tình trạng đơn hàng không?',
  'Nếu bạn gặp vấn đề với việc theo dõi đơn hàng, tôi có thể giúp bạn kiểm tra thông tin cập nhật mới nhất.',
  'Hãy cho tôi biết nếu bạn cần hướng dẫn về quy cách đóng gói hàng hóa để đảm bảo an toàn khi vận chuyển.',
  'Bạn có muốn tôi cung cấp thông tin về các loại dịch vụ giao hàng phù hợp với nhu cầu của bạn không?',
  'Tôi có thể hỗ trợ bạn tính toán chi phí vận chuyển dựa trên trọng lượng và điểm đến. Bạn có muốn tôi giúp không?',
  'Nếu bạn cần tư vấn về thời gian giao hàng dự kiến, vui lòng cho tôi biết điểm gửi và điểm nhận hàng.',
  'Bạn có muốn tôi hướng dẫn cách lập vận đơn điện tử để tiết kiệm thời gian gửi hàng không?',
  'Nếu bạn cần kiểm tra tình trạng thông quan của lô hàng quốc tế, tôi có thể hỗ trợ bạn ngay.',
  'Bạn có gặp khó khăn trong việc nhập thông tin người nhận hoặc địa chỉ giao hàng không? Tôi có thể giúp bạn kiểm tra.',
  'Hãy cho tôi biết nếu bạn muốn tôi hướng dẫn về các quy định vận chuyển đối với hàng hóa đặc biệt như pin lithium hoặc chất lỏng.',
  'Nếu bạn lo lắng về thời gian giao hàng chậm trễ, tôi có thể kiểm tra nguyên nhân và đề xuất giải pháp phù hợp.',
  'Bạn có muốn biết thêm về các tùy chọn bảo hiểm hàng hóa để đảm bảo an toàn cho lô hàng không?',
  'Hãy cho tôi biết nếu bạn cần hỗ trợ xử lý một đơn hàng bị giữ lại tại hải quan.',
  'Bạn có muốn tôi cung cấp hướng dẫn về cách đóng gói hàng dễ vỡ để hạn chế rủi ro trong quá trình vận chuyển không?',
  'Nếu bạn đang tìm cách tối ưu hóa chi phí vận chuyển cho số lượng hàng lớn, tôi có thể tư vấn về các gói dịch vụ phù hợp.',
  'Bạn cần kiểm tra lại thông tin giao hàng trước khi gửi để tránh sai sót không? Tôi có thể giúp bạn xác minh.',
  'Nếu bạn gặp lỗi khi tạo vận đơn trên hệ thống, hãy cho tôi biết để tôi hỗ trợ khắc phục nhanh chóng.',
  'Bạn có cần thông tin về chính sách hoàn trả hoặc khiếu nại đối với đơn hàng bị hư hỏng trong quá trình vận chuyển không?',
  'Hãy cho tôi biết nếu bạn muốn tìm hiểu về các quy định hải quan đối với hàng nhập khẩu vào một quốc gia cụ thể.',
  'Bạn có muốn tôi hướng dẫn về cách theo dõi nhiều đơn hàng cùng lúc để quản lý dễ dàng hơn không?',
  'Nếu bạn cần hỗ trợ về việc thay đổi địa chỉ giao hàng sau khi đã gửi đi, vui lòng cung cấp mã vận đơn để tôi kiểm tra.',
  'Bạn có gặp vấn đề với việc tính thuế nhập khẩu hoặc phí hải quan không? Tôi có thể giúp bạn tra cứu thông tin cần thiết.',
  'Hãy cho tôi biết nếu bạn cần tư vấn về lựa chọn dịch vụ giao hàng nhanh hoặc tiết kiệm phù hợp với ngân sách của bạn.',
  'Bạn có cần hướng dẫn về cách xử lý trường hợp hàng bị thất lạc hoặc giao nhầm không?',
  'Nếu bạn muốn tìm hiểu về các tiêu chuẩn đóng gói hàng hóa quốc tế, tôi có thể cung cấp thông tin chi tiết.',
  'Bạn có cần hỗ trợ về việc yêu cầu hoàn tiền hoặc bồi thường cho đơn hàng bị hư hỏng không?',
  'Nếu bạn gặp khó khăn trong việc liên hệ với nhân viên giao hàng, tôi có thể giúp bạn kết nối với bộ phận hỗ trợ.',
  'Bạn có muốn biết cách đặt lịch lấy hàng tận nơi để thuận tiện hơn cho quá trình gửi hàng không?',
  'Hãy cho tôi biết nếu bạn cần hỗ trợ xử lý các trường hợp hàng bị từ chối nhận hoặc trả lại.',
  'Bạn có muốn tìm hiểu về các chương trình ưu đãi dành cho khách hàng thường xuyên của dịch vụ vận chuyển không?',
  'Nếu bạn cần tư vấn về các tài liệu cần thiết khi gửi hàng quốc tế, tôi có thể cung cấp thông tin cụ thể.',
  'Bạn có cần kiểm tra xem hàng hóa của bạn có bị giới hạn hoặc cấm vận chuyển theo quy định quốc tế không?',
  'Hãy cho tôi biết nếu bạn muốn tôi hướng dẫn về cách tối ưu hóa quy trình gửi hàng để tiết kiệm thời gian.',
  'Bạn có muốn biết cách lên lịch trình vận chuyển để đảm bảo hàng hóa đến nơi đúng thời gian mong muốn không?',
  'Nếu bạn gặp vấn đề với việc thanh toán phí vận chuyển, tôi có thể kiểm tra trạng thái giao dịch cho bạn.',
  'Bạn có muốn tôi tư vấn về cách tránh các lỗi thường gặp khi nhập thông tin vận đơn không?',
  'Nếu bạn muốn theo dõi lịch sử vận chuyển của một đơn hàng cụ thể, hãy cung cấp mã vận đơn để tôi kiểm tra.',
  'Hãy cho tôi biết nếu bạn cần hỗ trợ xử lý đơn hàng bị thất lạc hoặc chưa được cập nhật trên hệ thống theo dõi.',
  'Bạn có muốn tôi giúp bạn kiểm tra chi tiết về các điểm trung chuyển của đơn hàng không?',
  'Nếu bạn cần tra cứu chính sách vận chuyển của từng quốc gia, tôi có thể cung cấp thông tin cụ thể.',
  'Bạn có muốn tôi giúp bạn thiết lập hệ thống thông báo tự động để cập nhật trạng thái đơn hàng theo thời gian thực không?',
  'Nếu bạn cần hỗ trợ về quy trình hủy đơn hàng đã gửi, hãy cung cấp thông tin chi tiết để tôi kiểm tra.',
  'Bạn có muốn tìm hiểu về các yêu cầu đặc biệt đối với vận chuyển hàng dễ hư hỏng như thực phẩm hoặc dược phẩm không?',
  'Hãy cho tôi biết nếu bạn muốn tìm kiếm giải pháp tối ưu cho việc vận chuyển hàng hóa cồng kềnh hoặc quá khổ.',
  'Bạn có cần hướng dẫn về cách lập bảng giá vận chuyển cho doanh nghiệp để dễ dàng tính toán chi phí hơn không?',
  'Nếu bạn muốn cải thiện hiệu quả vận chuyển hàng hóa của doanh nghiệp, tôi có thể tư vấn giải pháp hợp lý.',
  'Bạn có gặp bất kỳ vấn đề nào trong quá trình gửi hoặc nhận hàng không? Tôi luôn sẵn sàng hỗ trợ bạn!',
];
