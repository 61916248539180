import { React, useCallback, useEffect, useRef } from 'react';
import { TextField } from '@mui/material';

const PackageItem = ({ pack, index, setPackageValue, packageValue, logo, isAnimated }) => {
  const handleChangePackage = (i, e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setPackageValue((prev) => prev.map((pack, index) => (index === i ? { ...pack, [e.target.name]: value } : pack)));
    }
  };

  return (
    <div
      className={`p-4 border-solid border-[1px] rounded-[16px] mb-2.5 shadow-lg ${
        index % 2 === 0
          ? 'border-gradient-to-r from-indigo-700 to-teal-700'
          : 'border-gradient-to-r from-blue-800 to-green-800'
      }`}
      style={{
        background: 'rgba(10, 15, 30, 0.95)',
        backdropFilter: 'blur(14px)',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.4)',
        animation: 'fadeIn 0.5s ease-in-out',
        position: 'relative',
        overflow: 'hidden', // Ensures particles don't go outside the box
      }}
    >
      <style>
        {`
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    @keyframes float {
      0% {
        transform: translate(0, 0);
      }
      50% {
        transform: translate(10px, -10px);
      }
      100% {
        transform: translate(0, 0);
      }
    }

    .floating-material {
      animation: float 3s ease-in-out infinite;
      opacity: 0.6;
    }
    `}
      </style>
      {isAnimated &&
        pack.floatingPositions.map((pos, i) => (
          <div
            key={i}
            className="floating-material"
            style={{
              position: 'absolute',
              top: pos.top,
              left: pos.left,
              width: i === 5 ? '120px' : i % 2 === 0 ? '20px' : '40px', // Truck width (adjusted smaller size)
              height: i === 5 ? '60px' : i % 2 === 0 ? '20px' : '40px', // Truck height (adjusted smaller size)
              backgroundColor:
                i === 5
                  ? '#4f6d7a'
                  : i % 2 === 0
                    ? `rgba(${pos.right * 255}, ${pos.bottom * 255}, ${pos.right * 255}, 0.8)` // Random color for stars
                    : '#d4a61b', // Yellow-brown for the boxes
              borderRadius: i === 5 ? '10px' : i % 2 === 0 ? '50%' : '5px', // Rounded edges for the truck, circle for stars, square for boxes
              boxShadow: i === 5 ? '5px 5px 15px rgba(0, 0, 0, 0.5)' : '3px 3px 10px rgba(0, 0, 0, 0.3)', // Soft shadow for truck
              animationDelay: `${i * 0.5}s`,
              backgroundImage:
                i === 5
                  ? 'none' // No gradient for truck
                  : i % 2 === 0
                    ? 'linear-gradient(145deg, rgba(255, 255, 255, 0.5) 25%, rgba(0, 0, 0, 0.1) 75%)' // Gradient for stars
                    : 'none', // No gradient for boxes
            }}
          >
            {i === 5 && (
              <>
                {/* 3D Truck Body (Compact, Realistic) */}
                <div
                  style={{
                    position: 'absolute',
                    top: '5px',
                    left: '5px',
                    width: '110px',
                    height: '45px',
                    backgroundColor: '#3d4c58', // Darker body for realism
                    borderRadius: '12px 12px 5px 5px', // Curved edges for the body
                    boxShadow: '5px 5px 20px rgba(0, 0, 0, 0.5)',
                    transform: 'perspective(200px) rotateX(5deg) rotateY(3deg)',
                    zIndex: 1,
                  }}
                />

                {/* 3D Truck Grill */}
                <div
                  style={{
                    position: 'absolute',
                    top: '10px',
                    left: '30px',
                    width: '50px',
                    height: '10px',
                    backgroundColor: '#2b3b45', // Darker grill
                    borderRadius: '2px',
                    boxShadow: 'inset 0px 1px 5px rgba(0, 0, 0, 0.3)',
                    zIndex: 2,
                  }}
                />

                {/* 3D Truck Cabin */}
                <div
                  style={{
                    position: 'absolute',
                    top: '15px',
                    left: '5px',
                    width: '30px',
                    height: '20px',
                    backgroundColor: '#e2e7eb', // Light gray for the cabin
                    borderRadius: '5px 5px 0 0',
                    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
                    zIndex: 3,
                  }}
                />

                {/* 3D Truck Windows */}
                <div
                  style={{
                    position: 'absolute',
                    top: '18px',
                    left: '7px',
                    width: '16px',
                    height: '8px',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)', // Transparent windows
                    borderRadius: '3px',
                    zIndex: 4,
                  }}
                />

                {/* 3D Back Cargo Box */}
                <div
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '65px',
                    width: '50px',
                    height: '35px',
                    backgroundColor: 'white', // Lighter truck bed color
                    borderRadius: '5px',
                    boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.5)',
                    transform: 'perspective(200px) rotateX(3deg) rotateY(-3deg)',
                    zIndex: 1,
                  }}
                />

                {/* 3D Truck Bed Area for Logo */}
                <div
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '85px',
                    width: '40px',
                    height: '6px',
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    textAlign: 'center',
                    lineHeight: '6px',
                    color: '#333',
                    fontSize: '8px',
                    fontWeight: 'bold',
                    zIndex: 5,
                  }}
                >
                  {/* Embed your logo here */}
                </div>

                {/* 3D Realistic Wheels */}
                <div
                  style={{
                    position: 'absolute',
                    bottom: '-3px',
                    left: '12px',
                    width: '18px',
                    height: '18px',
                    backgroundColor: '#1c1c1c', // Dark wheel color
                    borderRadius: '50%',
                    border: '2px solid #333',
                    transform: 'rotate(0deg)', // Properly aligned for a truck wheel
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.5)',
                    zIndex: 6,
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    bottom: '-3px',
                    left: '90px',
                    width: '18px',
                    height: '18px',
                    backgroundColor: '#1c1c1c', // Dark wheel color
                    borderRadius: '50%',
                    border: '2px solid #333',
                    transform: 'rotate(0deg)', // Properly aligned for a truck wheel
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.5)',
                    zIndex: 6,
                  }}
                />

                {/* 3D Side Mirror */}
                <div
                  style={{
                    position: 'absolute',
                    top: '10px',
                    left: '37px',
                    width: '5px',
                    height: '10px',
                    backgroundColor: '#343b45',
                    borderRadius: '50%',
                    boxShadow: 'inset 0px 1px 5px rgba(0, 0, 0, 0.3)',
                    zIndex: 5,
                  }}
                />
              </>
            )}
            {i % 2 !== 0 && (
              <>
                {/* Optional package label details for boxes */}
                <div
                  style={{
                    position: 'absolute',
                    bottom: '5px',
                    left: '5px',
                    width: '30px',
                    height: '2px',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)', // Label line for the package box
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    width: '5px',
                    height: '5px',
                    backgroundColor: 'rgba(0, 0, 0, 0.4)', // Corner dot for the box
                    borderRadius: '50%',
                  }}
                />
              </>
            )}
          </div>
        ))}

      {/* Top Flex Box */}
      <div className="flex items-center text-teal-500 relative z-10">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-[32px] h-[32px]">
          <path d="M12.378 1.602a.75.75 0 0 0-.756 0L3 6.632l9 5.25 9-5.25-8.622-5.03ZM21.75 7.93l-9 5.25v9l8.628-5.032a.75.75 0 0 0 .372-.648V7.93ZM11.25 22.18v-9l-9-5.25v8.57a.75.75 0 0 0 .372.648l8.628 5.033Z" />
        </svg>
        <p className="font-semibold ml-3 text-[25px]">Pack {index + 1}</p>
        <div className="ml-auto flex gap-1.5">
          <button
            type="button"
            className="p-2 px-4 bg-teal-700 hover:bg-teal-800 text-white rounded-md transition-transform transform hover:scale-105 active:scale-95 shadow-md relative z-10 font-medium"
            onClick={() => {
              setPackageValue([...packageValue, pack]);
            }}
          >
            Copy
          </button>
          <button
            type="button"
            className="p-2 bg-rose-800 hover:bg-rose-900 text-white rounded-md transition-transform transform hover:scale-105 active:scale-95 shadow-sm relative z-10 px-3.5 font-medium"
            onClick={() => {
              setPackageValue(packageValue.filter((_, i) => i !== index));
            }}
          >
            Remove package
          </button>
        </div>
      </div>

      {/* Other Content */}
      <div className="flex mt-4 gap-1 relative z-10">
        {['packageLength', 'packageWidth', 'packageHeight', 'packageWeight'].map((field, idx) => (
          <TextField
            key={idx}
            label={field.replace('package', '') + ' (cm)'}
            variant="filled"
            autoFocus={field === 'packageLength' ? true : false}
            size="small"
            fullWidth
            required
            name={field}
            value={pack[field] || ''}
            onChange={(e) => handleChangePackage(index, e)}
            sx={{
              '& .MuiFilledInput-root': {
                backgroundColor: 'rgba(20, 30, 50, 0.6)',
                borderRadius: '9px',
                boxShadow: 'inset 0px 2px 8px rgba(0, 0, 0, 0.3)',
                color: '#d1e9ff',
                border: '1px solid rgba(255, 255, 255, 0.1)',
              },
              '& .MuiInputLabel-root': {
                color: '#a6c9ff',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#d1e9ff',
              },
              '& .MuiFilledInput-root:hover': {
                backgroundColor: 'rgba(20, 30, 50, 0.8)',
              },
            }}
          />
        ))}
        <TextField
          label="Chargeable (kg)"
          variant="filled"
          type="number"
          size="small"
          fullWidth
          value={pack.packageCharge.toFixed(2)}
          InputProps={{
            readOnly: true,
          }}
          sx={{
            '& .MuiFilledInput-root': {
              backgroundColor: 'rgba(15, 20, 50, 0.7)', // Deep space background
              borderRadius: '8px',
              boxShadow: `
              inset 0px 2px 8px rgba(0, 0, 0, 0.4), 
              0 0 15px rgba(60, 90, 200, 0.6)`, // Glow effect resembling starlight
              color: '#e0e9ff', // Soft, glowing text color
              border: '1px solid rgba(255, 255, 255, 0.15)', // Subtle galaxy-inspired border
              backdropFilter: 'blur(10px)', // Frosted glass effect
            },
            '& .MuiInputLabel-root': {
              color: '#a0b9ff', // Dim star-like label color
              // Adds a cosmic touch
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#ffffff', // Bright white for focused state
              textShadow: '0 0 6px rgba(255, 255, 255, 0.8)', // Glowing effect
            },
            '& .MuiFilledInput-root:hover': {
              backgroundColor: 'rgba(15, 20, 50, 0.9)', // Slightly darker on hover
              boxShadow: `
              inset 0px 2px 8px rgba(0, 0, 0, 0.5), 
              0 0 20px rgba(100, 150, 255, 0.8)`, // Intensified glow on hover
            },
            '& .MuiFilledInput-root:before': {
              content: '""',
              position: 'absolute',
              top: '-15%',
              left: '-15%',
              width: '150%',
              height: '150%',
              background: `radial-gradient(circle, rgba(255, 255, 255, 0.1), transparent 60%), 
                        radial-gradient(circle at top right, rgba(255, 255, 255, 0.05), transparent 70%)`,
              zIndex: -1,
              animation: 'galaxy-movement 20s infinite linear',
            },
            '@keyframes galaxy-movement': {
              '0%': { transform: 'translate(0, 0)' },
              '50%': { transform: 'translate(-10px, 10px)' },
              '100%': { transform: 'translate(0, 0)' },
            },
          }}
        />
      </div>
    </div>
  );
};
export default PackageItem;
