export const addressArray = [
  '123 Main St., Los Angeles, CA 90001, USA',
  '45 Elm Street, New York, NY 10001, USA',
  '6789 Oak Ave, Chicago, IL 60611, USA',
  "5 King's Road, London, UK W1A1AA, England",
  '222 Baker St., London, UK 10001, England',
  '789 Boulevard St-Michel, Paris, FR 75005, France',
  '1000 Rue de Rivoli, Paris, FR 75001, France',
  '12 Avenida Paulista, São Paulo, BR 01310-100, Brazil',
  '321 George St., Sydney, NSW 2000, Australia',
  '88 Nathan Road, Hong Kong, HK 999077, China',
];
