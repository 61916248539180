import { TextField, Select, MenuItem, FormControl, InputLabel, InputAdornment } from '@mui/material';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import skydartdog from '../skydartdog.jpeg';
import skydartdog1 from '../skydartdog1.jpeg';
import skydartdog2 from '../skydartdog2.jpeg';

import { useNavigate } from 'react-router-dom';
import skydartLogo from '../../../skydartLogo.png';
import Masonry from '@mui/lab/Masonry';
import { HiArrowsExpand, HiOutlineSaveAs } from 'react-icons/hi';
import { v1 as uuid } from 'uuid';
import {
  MdOutlineCalculate,
  MdOutlineCancel,
  MdOutlineClear,
  MdOutlineModeEdit,
  MdOutlineModeEditOutline,
  MdQuestionMark,
} from 'react-icons/md';
import StaffBill2 from '../../../components/StaffBill2';
import { PiMagicWandLight } from 'react-icons/pi';
import { IoAdd, IoCalendarOutline, IoCopyOutline, IoCreateOutline } from 'react-icons/io5';
import Inv2 from '../../../components/Inv2';
import { BsQrCode } from 'react-icons/bs';
import { FaPrint, FaRegTrashCan } from 'react-icons/fa6';
import { FiRefreshCcw } from 'react-icons/fi';
import { GoTrash } from 'react-icons/go';
import { VscEmptyWindow } from 'react-icons/vsc';
import { TbThumbDown, TbThumbUp } from 'react-icons/tb';
import OutsideClickHandler from 'react-outside-click-handler';
import { FaRegEdit } from 'react-icons/fa';
import { CiCircleList } from 'react-icons/ci';
import DoxScript from './DoxScript';
import CopyToClipboard from 'react-copy-to-clipboard';

const DimensionGeneratorEffect = ({ text, delay, isColor, onComplete, highlightRules, gross, charged, isSeen }) => {
  const [displayedCharacters, setDisplayedCharacters] = useState([]);
  const hasRunOnce = useRef(false);

  useEffect(() => {
    if (!hasRunOnce.current) {
      let currentIndex = 0;

      const interval = setInterval(() => {
        if (currentIndex < text.length) {
          const nextChar = text[currentIndex];
          setDisplayedCharacters((prev) => [...prev, { char: nextChar, color: 'black' }]);
          currentIndex++;
        } else {
          clearInterval(interval);
          hasRunOnce.current = true;
        }
      }, 0);

      return () => clearInterval(interval);
    }
  }, [text, isColor]);

  // Helper function to determine styles for specific dynamic rules
  const getBackgroundStyle = (index) => {
    const currentText = displayedCharacters.map((item) => item.char).join('');

    // Highlight rules for "Chargeable: (dynamic number of weight)"
    const chargeablePattern = /Chargeable: \d+(\.\d+)?kg/; // Matches "Chargeable: {number}" with optional decimals
    const grossPattern = /Gross: \d+(\.\d+)?kg/;

    if (grossPattern.test(currentText)) {
      const match = currentText.match(grossPattern);
      if (match) {
        const startIndex = currentText.indexOf(match[0]);
        const endIndex = startIndex + match[0].length;

        if (index >= startIndex && index < endIndex) {
          return { backgroundColor: 'yellow', fontWeight: 'bold' };
        }
      }
    }

    if (chargeablePattern.test(currentText)) {
      const match = currentText.match(chargeablePattern);
      if (match) {
        const startIndex = currentText.indexOf(match[0]);
        const endIndex = startIndex + match[0].length;

        if (index >= startIndex && index < endIndex) {
          return {
            backgroundColor: 'darkblue',
            fontWeight: 'bold',
            color: 'lime',
            borderTop: '1px solid yellow',
            borderBottom: '1px solid yellow',
          };
        }
      }
    }

    return {};
  };

  return (
    <div style={{ fontSize: '24px' }}>
      {displayedCharacters.map((item, index) => (
        <span
          key={index}
          style={{
            color: item.color,
            ...getBackgroundStyle(index),
          }}
        >
          {item.char}
        </span>
      ))}
    </div>
  );
};

const WordGeneratorEffect = ({ text, delay = 50, isColor, isMultiColor, isFontStyle, onComplete, isSeen }) => {
  const [displayedCharacters, setDisplayedCharacters] = useState([]);
  const hasRunOnce = useRef(false);
  const getDynamicBoxShadow = () => {
    // Generate a smooth, colored shadow with bluish/purplish vibes
    const shadowColors = [
      'rgba(50, 100, 200, 0.4)', // Cool Blue
      'rgba(80, 60, 180, 0.4)', // Deep Purple
      'rgba(120, 50, 160, 0.4)', // Magenta Tint
      'rgba(60, 130, 200, 0.3)', // Cyan Glow
    ];

    // Randomly pick a shadow color
    const mainShadow = shadowColors[Math.floor(Math.random() * shadowColors.length)];

    return `${mainShadow} 0px 4px 15px, 
            rgba(0, 0, 0, 0.1) 0px 6px 20px`;
  };

  useEffect(() => {
    // Only run the word effect if isSeen is false and the effect hasn't run
    if (!isSeen && !hasRunOnce.current) {
      let currentIndex = 0;

      const getBalancedLightColor = () => {
        // Chọn ngẫu nhiên giữa các tông màu lạnh (blue, cyan, purple, pink)
        const colorOptions = [
          [30, 100, 180], // Xanh dương trầm
          [80, 50, 180], // Tím ánh xanh
          [20, 120, 150], // Xanh ngọc
          [160, 40, 90], // Hồng đậm
          [100, 30, 160], // Tím huyền bí
        ];

        // Chọn ngẫu nhiên một tông màu trong danh sách
        const [r, g, b] = colorOptions[Math.floor(Math.random() * colorOptions.length)];

        // Điều chỉnh một chút độ sáng để tránh bị quá tối hoặc quá sáng
        const rAdj = Math.min(255, r + Math.random() * 30);
        const gAdj = Math.min(255, g + Math.random() * 30);
        const bAdj = Math.min(255, b + Math.random() * 30);

        return `rgb(${Math.floor(rAdj)}, ${Math.floor(gAdj)}, ${Math.floor(bAdj)})`;
      };

      const interval = setInterval(() => {
        if (currentIndex < text.length) {
          const nextChar = text[currentIndex];
          setDisplayedCharacters((prev) => [...prev, { char: nextChar, color: getBalancedLightColor() }]);
          currentIndex++;
        } else {
          clearInterval(interval);
          hasRunOnce.current = true; // Mark as completed
          if (onComplete) {
            onComplete();
          }
        }
      }, 15);

      return () => clearInterval(interval);
    } else {
      setDisplayedCharacters(
        text?.split('').map((t) => {
          const getBalancedLightColor = () => {
            // Chọn ngẫu nhiên giữa các tông màu lạnh (blue, cyan, purple, pink)
            const colorOptions = [
              [30, 100, 180], // Xanh dương trầm
              [80, 50, 180], // Tím ánh xanh
              [20, 120, 150], // Xanh ngọc
              [160, 40, 90], // Hồng đậm
              [100, 30, 160], // Tím huyền bí
            ];

            // Chọn ngẫu nhiên một tông màu trong danh sách
            const [r, g, b] = colorOptions[Math.floor(Math.random() * colorOptions.length)];

            // Điều chỉnh một chút độ sáng để tránh bị quá tối hoặc quá sáng
            const rAdj = Math.min(255, r + Math.random() * 30);
            const gAdj = Math.min(255, g + Math.random() * 30);
            const bAdj = Math.min(255, b + Math.random() * 30);

            return `rgb(${Math.floor(rAdj)}, ${Math.floor(gAdj)}, ${Math.floor(bAdj)})`;
          };
          return { char: t, color: getBalancedLightColor() };
        })
      );
    }
  }, [isSeen, text, isColor]);
  return (
    <div className="tracking-wide ">
      {displayedCharacters.map((item, index) => (
        <span
          key={index}
          style={{
            color: isMultiColor ? item.color : 'rgb(39,39,42)',
            fontWeight: item.color === 'black' ? '' : isFontStyle ? 510 : '450',
            fontFamily: isFontStyle ? 'fantasy' : '',
          }}
        >
          {item.char}
        </span>
      ))}
    </div>
  );
};

const TitleWordEffect = ({ text, onComplete, isSeen }) => {
  const [displayedCharacters, setDisplayedCharacters] = useState([]);
  const hasRunOnce = useRef(false);
  useEffect(() => {
    if (!isSeen && !hasRunOnce.current) {
      let currentIndex = 0;
      const interval = setInterval(() => {
        if (currentIndex < text.length) {
          const nextChar = text[currentIndex];
          setDisplayedCharacters((prev) => [...prev, { char: nextChar, color: 'black' }]);
          currentIndex++;
        } else {
          clearInterval(interval);
          if (onComplete) {
            onComplete();
          }
        }
      }, 15);

      return () => clearInterval(interval);
    } else {
      setDisplayedCharacters(
        text?.split('').map((t) => {
          return {
            char: t,
            color: 'black',
          };
        })
      );
    }
  }, [text, isSeen]);
  return (
    <div className="tracking-wide text-xl font-semibold">
      {displayedCharacters.map((item, index) => (
        <span
          key={index}
          style={{
            color: 'rgb(39,39,42)',
          }}
        >
          {item.char}
        </span>
      ))}
    </div>
  );
};

const WordGenerating = ({
  isMultiColor,
  isFontStyle,
  messages,
  user,
  handleChildInputChange,
  loading,
  handleSelectInvoiceItem,
  formattedDate,
  handleBookSingleShipment,
  handleDeleteShipment,
  handleAddSingleShipment,
  handleDeleteSingleShipment,
  handleEffectComplete,
  handleEffectCompleteLast,
  currentChatId,
  serviceArray,
  exportReasonArray,
  termArray,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentDim, setCurrentDim] = useState(1);
  const [isShow, setIsShow] = useState(false);
  const [displayPackaging, setDisplayPackaging] = useState(false);
  const [displayDimGuide, setDisplayDimGuide] = useState(false);
  const [isHelpful, setIsHelpful] = useState('');
  const [displayInvoice, setDisplayInvoice] = useState(false);
  const [packageValue, setPackageValue] = useState([
    {
      packageLength: 0,
      packageWidth: 0,
      packageHeight: 0,
      packageWeight: 0,
      packageCharge: 0,
      packNo: 1,
      pack: '',
      confirm: false,
    },
  ]);

  const [invoiceValue, setInvoiceValue] = useState([
    {
      id: uuid(),
      isSelectItem: false,
      itemCommonity: '',
      itemVietnamCommonity: '',
      itemCountryOrigin: 'Vietnam',
      itemManufactAddress: '',
      itemPackType: '',
      itemPackType2: '',
      itemHsCode: '',
      itemPnc: '',
      itemUnit: '',
      itemOtherUnit: '',
      itemPurpose: '',
      itemManufact: '',
      itemUnitPrice: 0,
      itemQuantity: 0,
      itemValue: 0,
      itemBrand: '',
      itemIngredients: '',
      itemNo: 1,
      itemFda: '',
      itemUnit2: '',
    },
  ]);
  const [currentMessageId, setCurrentMessageId] = useState('');
  const [finalAttachInvoiceValue1, setFinalAttachInvoiceValue1] = useState(0);
  const [finalInvoiceValue1, setFinalInvoiceValue1] = useState(0);
  const [totalInvoiceValue1, setTotalInvoiceValue1] = useState(0);
  const [finalCharge1, setFinalCharge1] = useState(0);
  const [finalGross1, setFinalGross1] = useState(0);

  const navigate = useNavigate();
  const handleShowInfo = () => {
    setIsShow(!isShow);
  };
  useEffect(() => {
    if (packageValue) {
      setFinalCharge1(packageValue?.reduce((a, v) => (a = +a + +v?.packageCharge), 0));
      setFinalGross1(packageValue?.reduce((a, v) => (a = +a + +v?.packageWeight), 0));
    }
  }, [packageValue]);
  useEffect(() => {
    if (invoiceValue) {
      setTotalInvoiceValue1(invoiceValue?.reduce((a, v) => (a = +a + +v.itemValue), 0));
    }
  }, [invoiceValue]);
  const shipmentNameRef = useRef(null);
  const companyRef = useRef(null);
  const shipmentInfoRef = useRef(null);
  const handleScrollToShipment = () => {
    shipmentInfoRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    if (currentIndex < 3) {
      const timer = setTimeout(() => {
        setCurrentIndex(currentIndex + 1); // Increase the index to trigger the next render
      }, 1000); // 1-second delay between renders (can be adjusted)
      return () => clearTimeout(timer); // Cleanup on unmount or re-render
    }
  }, [currentIndex]);

  const handleHighlightRules = (chargedWeight, bookedWeight) => {
    const highlightRules = [
      {
        text: `gross: ${bookedWeight}`,
        style: { backgroundColor: 'lightgreen' },
      },
      {
        text: `charged: ${chargedWeight} `,
        style: { backgroundColor: 'lightblue' },
      },
    ];
    return highlightRules;
  };
  const handleShowPackaging = (newPackageValue, messageId) => {
    setPackageValue(newPackageValue);
    setDisplayPackaging(!displayPackaging);
    setCurrentMessageId(messageId);
  };
  const handleShowInvoice = (newInvoiceValue, messageId) => {
    setInvoiceValue(newInvoiceValue);
    setDisplayInvoice(!displayInvoice);
    setCurrentMessageId(messageId);
  };

  const handleAddPackage = () => {
    const packageData = {
      packageLength: 0,
      packageWidth: 0,
      packageHeight: 0,
      packageWeight: 0,
      packageCharge: 0,
      packNo: 1,
      pack: '',
      confirm: false,
    };
    setPackageValue([...packageValue, packageData]);
  };
  const handleAddInvoice = () => {
    const invoiceData = {
      id: uuid(),
      isSelectItem: false,
      itemCommonity: '',
      itemVietnamCommonity: '',
      itemCountryOrigin: 'Vietnam',
      itemManufactAddress: '',
      itemPackType: '',
      itemPackType2: '',
      itemHsCode: '',
      itemPnc: '',
      itemUnit: '',
      itemOtherUnit: '',
      itemPurpose: '',
      itemManufact: '',
      itemUnitPrice: 0,
      itemQuantity: 0,
      itemValue: 0,
      itemBrand: '',
      itemIngredients: '',
      itemNo: 1,
      itemFda: '',
      itemUnit2: '',
    };
    setInvoiceValue([...invoiceValue, invoiceData]);
  };
  let removeFormField = (index, e) => {
    if (packageValue.length === 1) {
      return;
    } else {
      setPackageValue(packageValue.filter((v, i) => i !== index));
    }
  };
  let handleChange = (i, e) => {
    let newFormValues = [...packageValue];
    newFormValues[i][e.target.name] = e.target.value;
    setPackageValue(newFormValues);
  };
  let invoiceChange = (i, e) => {
    const { name, value } = e.target;
    if (name === 'itemUnitPrice') {
      const newValue = value.replace(',', '.'); // Replace comma with dot for decimal input

      const decimalParts = newValue?.split('.'); // Split the input into parts separated by the decimal point

      if (decimalParts.length > 1 && decimalParts[1].length > 2) {
        // Check if the input has more than two decimal places
        return;
      }

      // Update the invoiceValue state with the modified value
      setInvoiceValue((prevState) => {
        const updatedInvoiceValue = prevState.map((item, index) => {
          if (index === i) {
            return { ...item, [name]: newValue };
          }
          return item;
        });
        return updatedInvoiceValue;
      });
    } else if (name === 'itemUnit') {
      setInvoiceValue((prevState) => {
        const updatedInvoiceValue = prevState.map((item, index) => {
          if (index === i) {
            return {
              ...item,
              [name]: value,
              itemOtherUnit:
                value === 'PCS'
                  ? 'Chiếc'
                  : value === 'BOX'
                    ? 'Hộp'
                    : value === 'KG'
                      ? 'KG'
                      : value === 'PRS'
                        ? 'Đôi'
                        : value === 'SET'
                          ? 'Bộ'
                          : '',
            };
          }
          return item;
        });
        return updatedInvoiceValue;
      });
    } else {
      let newInvoiceValues = [...invoiceValue];
      newInvoiceValues[i][e.target.name] = e.target.value;
      setInvoiceValue(newInvoiceValues);
    }
  };

  const handleUpdatePackage = (e) => {
    e.preventDefault();
    handleChildInputChange(currentMessageId, 'packageValue', packageValue);
    setDisplayPackaging(false);
    setPackageValue([]);
  };
  const handleUpdateInvoice = (e) => {
    e.preventDefault();
    handleChildInputChange(currentMessageId, 'invoiceValue', invoiceValue);
    setDisplayInvoice(false);
    setInvoiceValue([]);
  };

  return (
    <div
      style={{
        padding: '20px',
        width: '100%',
        color: '#333', // Text color for contrast
      }}
    >
      {displayInvoice && (
        <div className="fixed top-0 right-0 bottom-0 left-0 bg-[rgba(0,0,0,0.35)] flex items-center justify-center z-[10000] retriveShipment">
          <OutsideClickHandler
            onOutsideClick={() => {
              setDisplayInvoice(false);
            }}
          >
            <form
              className="rounded-[20px] bg-white w-[550px] max-h-[calc(100vh_-_12rem)] overflow-auto"
              onSubmit={handleUpdateInvoice}
            >
              <div className="p-5 flex items-start sticky top-0 bg-white z-10">
                <div>
                  <p className="text-lg font-semibold">Cập nhật hoá đơn & chứng từ của kiện hàng</p>
                  <p className="text-[13px]">
                    Khai báo đầy đủ và kỹ càng sẽ giúp ích cho bạn rất nhiều nếu kiện hàng không may xảy ra bất trắc!
                  </p>
                </div>
                <div
                  className="w-[40px] h-[40px] rounded-full hover:bg-zinc-100 active:bg-zinc-200 cursor-pointer flex items-center justify-center transition-[98s] ml-auto flex-shrink-0"
                  onClick={() => {
                    setInvoiceValue([]);
                  }}
                >
                  <FiRefreshCcw size={20} />
                </div>
                <div
                  className="w-[40px] h-[40px] rounded-full hover:bg-zinc-100 active:bg-zinc-200 cursor-pointer flex items-center justify-center transition-[98s] ml-1 flex-shrink-0"
                  onClick={() => {
                    setDisplayInvoice(false);
                  }}
                >
                  <MdOutlineClear size={20} />
                </div>
              </div>
              <div className="p-5 pt-0">
                <div className="flex flex-col gap-5">
                  {invoiceValue?.map((item, index) => {
                    let donGia1 = item.itemUnitPrice;
                    let soLuong1 = item.itemQuantity;
                    let tong1 = donGia1 * soLuong1;
                    item.itemValue = tong1;
                    return (
                      <div className="border-b-[1px] border-zinc-500 border-dashed pb-5" key={item.id}>
                        <div className="flex items-center mb-1">
                          <p className="font-bold tracking-wide text-xl text-zinc-600">
                            Mặt hàng <span className="underline">{index + 1}</span>
                          </p>
                          <div
                            className="ml-auto flex items-center w-[35px] h-[35px] rounded-full justify-center text-black cursor-pointer hover:bg-zinc-100 active:bg-zinc-200"
                            onClick={() => {
                              if (invoiceValue.length === 1) return;
                              setInvoiceValue(
                                invoiceValue.filter((s, i) => {
                                  return s.id !== item.id;
                                })
                              );
                            }}
                          >
                            <FaRegTrashCan size={20} />
                          </div>
                        </div>
                        <TextField
                          variant="filled"
                          label="Item commonity"
                          fullWidth
                          required
                          size="small"
                          name="itemCommonity"
                          defaultValue={item.itemCommonity}
                          onBlur={(e) => invoiceChange(index, e)}
                        />
                        <TextField
                          variant="filled"
                          label="Tên mặt hàng (Tiếng Việt)"
                          fullWidth
                          required
                          size="small"
                          name="itemVietnamCommonity"
                          defaultValue={item.itemVietnamCommonity}
                          onBlur={(e) => invoiceChange(index, e)}
                        />
                        <TextField
                          variant="filled"
                          label="Brand name"
                          fullWidth
                          size="small"
                          required
                          name="itemBrand"
                          defaultValue={item.itemBrand}
                          onBlur={(e) => invoiceChange(index, e)}
                        />
                        <select
                          value={item.itemPackType}
                          name="itemPackType"
                          required
                          onChange={(e) => invoiceChange(index, e)}
                          className="p-2 word-generating-select-box"
                          style={{
                            width: '100%',
                          }}
                        >
                          <option value="">Select a packing type</option>
                          <option value="Bag">Bag</option>
                          <option value="Box">Box</option>
                          <option value="Carton">Carton</option>
                          <option value="Wooden Pack">Wooden Pack</option>
                        </select>
                        <TextField
                          variant="filled"
                          label="Ingredients"
                          required
                          fullWidth
                          size="small"
                          name="itemIngredients"
                          defaultValue={item.itemIngredients}
                          onBlur={(e) => invoiceChange(index, e)}
                        />
                        <TextField
                          variant="filled"
                          label="Usage"
                          fullWidth
                          size="small"
                          name="itemPurpose"
                          defaultValue={item.itemPurpose}
                          onBlur={(e) => invoiceChange(index, e)}
                        />
                        <TextField
                          variant="filled"
                          label="Origin country"
                          fullWidth
                          size="small"
                          name="itemCountryOrigin"
                          defaultValue={item.itemCountryOrigin}
                          onBlur={(e) => invoiceChange(index, e)}
                        />
                        <TextField
                          variant="filled"
                          label="Manufacturer Name"
                          fullWidth
                          required
                          size="small"
                          name="itemManufact"
                          defaultValue={item.itemManufact}
                          onBlur={(e) => invoiceChange(index, e)}
                        />
                        <TextField
                          variant="filled"
                          label="Manufacturer Address"
                          fullWidth
                          required
                          size="small"
                          name="itemManufactAddress"
                          defaultValue={item.itemManufactAddress}
                          onBlur={(e) => invoiceChange(index, e)}
                        />
                        <TextField
                          variant="filled"
                          label="HS Code"
                          fullWidth
                          size="small"
                          name="itemHsCode"
                          defaultValue={item.itemHsCode}
                          onBlur={(e) => invoiceChange(index, e)}
                        />
                        <TextField
                          variant="filled"
                          label="FDA"
                          fullWidth
                          size="small"
                          name="itemFda"
                          defaultValue={item.itemFda}
                          onBlur={(e) => invoiceChange(index, e)}
                        />
                        <TextField
                          variant="filled"
                          label="Quantity"
                          type="number"
                          fullWidth
                          required
                          size="small"
                          name="itemQuantity"
                          defaultValue={item.itemQuantity}
                          onBlur={(e) => invoiceChange(index, e)}
                        />
                        <select
                          value={item.itemUnit}
                          name="itemUnit"
                          required
                          onChange={(e) => invoiceChange(index, e)}
                          className="p-2 word-generating-select-box"
                          style={{
                            width: '100%',
                          }}
                        >
                          <option value="">Select a packing type</option>
                          <option value="PCS">PCS</option>
                          <option value="PAIR">PAIR</option>
                          <option value="KG">KG</option>
                          <option value="SET">SET</option>
                          <option value="BAG">BAG</option>
                        </select>
                        <TextField
                          variant="filled"
                          label="Unit price (USD)"
                          type="number"
                          required
                          fullWidth
                          size="small"
                          name="itemUnitPrice"
                          defaultValue={item.itemUnitPrice}
                          onBlur={(e) => invoiceChange(index, e)}
                        />
                        <TextField
                          variant="outlined"
                          label="Total value (USD)"
                          type="number"
                          aria-readonly
                          fullWidth
                          size="small"
                          name="itemValue"
                          value={item.itemValue.toFixed(2)}
                          style={{ marginTop: 15 }}
                          InputProps={{
                            style: {
                              fontSize: '23px',
                              color: 'darkblue',
                            },
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="p-5 sticky bottom-0 bg-white z-10 flex items-center">
                <p className="text-zinc-500">
                  Tổng giá trị: <span className="text-black font-semibold">{totalInvoiceValue1.toFixed(2)} USD</span>
                </p>
                <div className="flex items-center ml-auto">
                  <div
                    className="p-2 px-3.5 flex items-center gap-1.5 rounded-[10px] hover:bg-zinc-100 active:bg-zinc-200 select-none cursor-pointer transition-[98s]"
                    onClick={handleAddInvoice}
                  >
                    <IoAdd size={23} />
                    <p>Thêm mặt hàng</p>
                  </div>
                  <button
                    type="submit"
                    className="p-2 px-3.5 flex items-center gap-1.5 rounded-[10px] hover:bg-zinc-100 active:bg-zinc-200 select-none cursor-pointer transition-[98s]"
                  >
                    <FaRegEdit size={23} />
                    <p>Cập nhật</p>
                  </button>
                </div>
              </div>
            </form>
          </OutsideClickHandler>
        </div>
      )}
      {displayDimGuide && (
        <div className="fixed top-0 right-0 bottom-0 left-0 bg-[rgba(0,0,0,0.35)] flex items-center justify-center z-[10000] retriveShipment">
          <div className="rounded-[20px] bg-white w-[65%] max-h-[80%] overflow-auto">
            <div className="m-7 border-b-[1px] border-zinc-300 border-solid mb-0 flex items-start">
              <div>
                <p className="text-2xl font-semibold text-[darkblue]  break-words tracking-wide">
                  Phương thức tính trọng lượng có thể tính phí cho lô hàng vận chuyển bằng đường hàng không.
                </p>
                <p className="text-lg mt-1">
                  Cách phân biệt trọng lượng tính bằng KG và trọng lượng thể tích mét khối (m<sup>3</sup>)
                </p>
              </div>
              <div
                className="ml-2.5 w-[40px] h-[40px] flex items-center rounded-full justify-center cursor-pointer hover:bg-zinc-100 active:bg-zinc-200 flex-shrink-0"
                onClick={() => {
                  setDisplayDimGuide(false);
                }}
              >
                <MdOutlineClear size={20} />
              </div>
            </div>
            <div className="p-7 pt-3">
              <p>
                Khi vận chuyển hàng hóa bằng đường hàng không, đường biển, đường bộ hay đường sắt, chi phí sẽ được tính
                dựa trên nguyên tắc công bằng: “Trọng lượng nào lớn hơn thì áp dụng.” Hãng chuyên chở sẽ so sánh giữa
                trọng lượng thực tế của lô hàng và trọng lượng thể tích (tính theo không gian hàng chiếm dụng), sau đó
                chọn giá trị cao hơn để đảm bảo mức phí phản ánh đúng tác động của lô hàng lên khả năng vận chuyển. Vì
                vậy, dù hàng nhẹ nhưng cồng kềnh, chi phí vẫn có thể cao hơn do chiếm nhiều không gian.
              </p>
            </div>
            <div className="px-7 pb-7">
              <p className="font-bold text-green-600 text-xl">
                Tránh Ước Tính Thấp – Đừng Để Hóa Đơn Khiến Bạn Bất Ngờ
              </p>
              <p className="text-black">
                Khi cước phí vận chuyển cao, việc hiểu rõ trọng lượng có thể tính phí của lô hàng là điều cực kỳ quan
                trọng, dù bạn gửi hàng bằng đường hàng không, đường biển, đường bộ hay đường sắt. Hãng vận chuyển sẽ
                tính phí dựa trên trọng lượng lớn hơn giữa trọng lượng thực tế và trọng lượng thể tích, vì vậy nếu bạn
                ước tính thấp, hóa đơn có thể mang đến một "cú sốc nhẹ nhàng nhưng sâu sắc." Để tránh tình huống này,
                hãy chắc chắn rằng bạn đã xác định chính xác trọng lượng có thể tính phí trước khi gửi hàng. Không chỉ
                giúp bạn có kế hoạch tài chính tốt hơn, điều này còn hỗ trợ bạn lựa chọn phương thức vận chuyển phù hợp
                nhất với thời gian giao hàng mong muốn và ngân sách của mình. Một chút chuẩn bị trước có thể giúp bạn
                tránh được những bất ngờ không đáng có! 🚛📦💡
              </p>
            </div>
            <div className="px-7 pb-7">
              <p className="font-bold text-green-600 text-xl">Có Hai Loại Trọng lượng</p>
              <p className="font-semibold text-black mt-1">
                Khi tính toán chi phí vận chuyển, hãng chuyên chở sẽ xem xét hai loại trọng lượng khác nhau để đảm bảo
                mức phí phản ánh đúng ảnh hưởng của lô hàng đến không gian và tải trọng.
              </p>
              <p className="mt-5 text-black">
                🔹 Trọng lượng đầu tiên là trọng lượng thực tế, hay còn gọi là trọng lượng bì hoặc tổng trọng lượng vật
                lý của lô hàng. Đây chính là con số thuần túy trên cân, bao gồm cả hàng hóa, bao bì, thùng carton, và
                thậm chí cả pallet nếu có. Đơn vị tính thường là kilôgam (kg) – dễ hiểu, đơn giản, không có gì phải bàn
                cãi.
              </p>
              <p className="text-black mt-2">
                🔹 Trọng lượng thứ hai là trọng lượng thể tích, hay còn gọi là trọng lượng kích thước. Nghe có vẻ phức
                tạp hơn một chút, nhưng thực chất đây là cách tính toán dựa trên không gian mà lô hàng của bạn chiếm
                dụng. Hãng vận chuyển sẽ đo chiều dài, chiều rộng và chiều cao (tính bằng xentimét), sau đó áp dụng tỷ
                lệ mật độ để quy đổi ra kilôgam. Điều này giúp xác định xem hàng hóa của bạn có chiếm quá nhiều chỗ so
                với trọng lượng thực tế hay không. Vậy nên, dù bạn gửi một kiện hàng siêu nhẹ nhưng lại có kích thước
                “to bự”, đừng ngạc nhiên nếu chi phí vận chuyển phản ánh chính xác điều đó! Và nhớ nhé, bao bì cũng là
                một phần của phép toán này, đừng quên tính luôn vào khi đo lường – kẻo lúc nhận hóa đơn lại có một bất
                ngờ nho nhỏ! 📦✈️🚛
              </p>
            </div>
            <div className="px-7 pb-7">
              <div className="max-w-4xl mx-auto p-8 bg-gradient-to-br from-blue-800 to-green-600 rounded-3xl shadow-3xl text-white relative overflow-hidden">
                <h2 className="text-4xl font-extrabold text-center mb-8 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-500 drop-shadow-xl">
                  Ví dụ về Cách Tính Trọng Lượng Thể Tích
                </h2>
                <p className="text-lg text-gray-300 mb-6 leading-relaxed">
                  Giả sử bạn cần vận chuyển một lô hàng gồm 2 kiện hàng, mỗi kiện có kích thước:
                </p>
                <ul className="list-disc list-inside text-lg text-gray-300 mb-8">
                  <li>Dài: 120 cm</li>
                  <li>Rộng: 80 cm</li>
                  <li>Cao: 50 cm</li>
                </ul>
                <p className="text-lg text-gray-300 mb-8 leading-relaxed">
                  Tùy theo phương thức vận chuyển, hãng chuyên chở sẽ áp dụng tỷ lệ mật độ khác nhau để tính trọng lượng
                  thể tích của lô hàng.
                </p>
                <div className="grid gap-4">
                  {[
                    {
                      method: 'Hàng không',
                      densityRate: 5000,
                      volumeWeight: 96,
                      formula: '( ( 120 × 80 × 50 ) / 5.000 ) × 2 = 96kg',
                    },
                    {
                      method: 'Đường biển',
                      densityRate: 1000,
                      volumeWeight: 960,
                      formula: '( ( 120 × 80 × 50 ) / 1.000 ) × 2 = 960kg',
                    },
                    {
                      method: 'Đường sắt',
                      densityRate: 3000,
                      volumeWeight: 320,
                      formula: '( ( 120 × 80 × 50 ) / 3.000 ) × 2 = 320kg',
                    },
                  ].map((shipment, index) => (
                    <div
                      key={index}
                      className="bg-gradient-to-r from-gray-900 via-gray-800 to-gray-700 p-8 rounded-2xl shadow-xl backdrop-blur-lg border border-gray-400 transition-transform transform hover:scale-105 hover:rotate-3d hover:shadow-2xl"
                      style={{
                        animation: `floating 6s ease-in-out infinite ${index % 2 === 0 ? 'alternate' : 'normal'}`,
                      }}
                    >
                      <h3 className="text-2xl font-semibold mb-4 text-green-300 drop-shadow-md">{shipment.method}</h3>
                      <p className="text-gray-200 text-lg">Tỷ lệ mật độ: 1:{shipment.densityRate / 1000}</p>
                      <p className="text-gray-200 text-lg">Công thức: {shipment.formula}</p>
                      <p className="font-bold text-green-400 text-xl">
                        Trọng lượng thể tích: {shipment.volumeWeight} kg
                      </p>
                    </div>
                  ))}
                </div>
                <div className="mt-10 p-8 bg-gradient-to-r from-yellow-600 via-yellow-500 to-yellow-400 rounded-2xl shadow-xl border border-yellow-400 backdrop-blur-lg">
                  <h3 className="text-xl font-bold text-yellow-200 drop-shadow-md">📌 Lưu ý quan trọng:</h3>
                  <p className="text-gray-100 text-lg">
                    Trọng lượng thể tích có thể thay đổi nếu tỷ lệ mật độ bị điều chỉnh. Ví dụ, nếu tỷ lệ thay đổi từ
                    1:3 thành 1:2, trọng lượng thể tích sẽ tăng từ 333,33 kg lên 500 kg. Luôn xác nhận với hãng chuyên
                    chở để tránh sai sót!
                  </p>
                </div>
                {/* 3D Floating Elements */}
                <div className=" top-0 left-0 w-full h-full z-[-1] animate-float-slow">
                  <div className="absolute bg-purple-300 w-20 h-20 rounded-full opacity-30 animate-float-circle"></div>
                  <div className="absolute bg-yellow-300 w-24 h-24 rounded-full opacity-20 animate-float-circle-delay"></div>
                  <div className="absolute bg-pink-500 w-16 h-16 rounded-full opacity-25 animate-float-circle-delay2"></div>
                </div>
              </div>
            </div>
            <div className="px-7 pb-7">
              <p className="font-bold text-green-600 text-xl">Vậy lô hàng sẽ được tính cước như thế nào ?</p>
              <p className="text-black mt-0.5">
                "Trọng lượng tính phí cho lô hàng sẽ luôn được xác định dựa trên giá trị nào cao hơn: trọng lượng thực
                tế hay trọng lượng thể tích, tùy theo lựa chọn của hãng chuyên chở. Cụ thể, nếu trọng lượng thực tế lớn
                hơn trọng lượng thể tích, hãng sẽ tính theo trọng lượng thực tế, còn nếu trọng lượng thể tích cao hơn,
                hãng sẽ tính theo trọng lượng thể tích. Điều này có lý do rõ ràng: ví dụ như một chiếc máy bay vừa có
                hạn chế về trọng lượng, vừa có không gian hầm chứa hàng hạn chế, do đó hãng chuyên chở phải tính toán
                trọng lượng và thể tích của hàng hóa một cách cẩn thận. Nếu hàng hóa của bạn chứa phụ tùng máy móc lớn,
                với trọng lượng thực tế có thể lớn hơn trọng lượng thể tích do mật độ dày đặc, thì chắc chắn cước phí sẽ
                tính theo trọng lượng thực tế. Ngược lại, nếu hàng hóa của bạn chứa nhiều linh kiện nhựa nhẹ bên trong
                bao bì bảo vệ, mặc dù có trọng lượng thực tế nhẹ nhưng chiếm nhiều không gian, thì trọng lượng thể tích
                sẽ cao hơn và cước phí sẽ được tính theo trọng lượng thể tích."
              </p>
            </div>
            <div className="flex items-center p-7">
              <div className="flex items-center w-full">
                <p>Thông tin này có hữu ích với bạn không ?</p>
                <div className="flex items-center gap-2 ml-2">
                  <div
                    className={`flex items-center gap-1.5 p-2 px-3.5 cursor-pointer  transition-[95s] rounded-full  select-none ${
                      isHelpful === 'yes'
                        ? 'bg-[darkblue] text-white'
                        : 'bg-zinc-100 hover:bg-zinc-200 active:bg-zinc-300'
                    }`}
                    onClick={() => {
                      setIsHelpful('yes');
                    }}
                  >
                    <TbThumbUp size={23} />
                    <p>Có</p>
                  </div>
                  <div
                    className={`flex items-center gap-1.5 p-2 px-3.5 cursor-pointer  transition-[95s] rounded-full  select-none ${
                      isHelpful === 'no'
                        ? 'bg-[darkblue] text-white'
                        : 'bg-zinc-100 hover:bg-zinc-200 active:bg-zinc-300'
                    }`}
                    onClick={() => {
                      setIsHelpful('no');
                    }}
                  >
                    <TbThumbDown size={23} />
                    <p>Không</p>
                  </div>
                </div>
                <p
                  className="font-semibold text-zinc-600 text-xl cursor-pointer hover:opacity-70 active:opacity-50 select-none ml-auto tracking-wide mr-2"
                  onClick={() => {
                    setDisplayDimGuide(false);
                  }}
                >
                  Đã hiểu
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {displayPackaging && (
        <div className="fixed top-0 right-0 bottom-0 left-0 bg-[rgba(0,0,0,0.35)] flex items-center justify-center z-[10000] retriveShipment">
          <OutsideClickHandler
            onOutsideClick={() => {
              setDisplayPackaging(false);
            }}
          >
            <form
              className="rounded-[20px] bg-white w-[700px] max-h-[450px] overflow-auto"
              onSubmit={handleUpdatePackage}
            >
              <div
                className="flex items-center justify-between p-4 border-b-[1px] border-solid border-zinc-200 bg-white sticky top-0 z-10"
                style={{ borderTopLeftRadius: 20, borderTopRightRadius: 20 }}
              >
                <p className="text-lg font-semibold">
                  Điều chỉnh thông tin kiện hàng {packageValue.length > 0 ? `(${packageValue?.length})` : ''}
                </p>
                <div className="flex items-center gap-1">
                  <div
                    className="w-[40px] h-[40px] rounded-[10px]  cursor-pointer flex items-center justify-center text-zinc-500 hover:opacity-70 active:opacity-50"
                    onClick={() => {}}
                  >
                    <MdQuestionMark size={20} />
                  </div>
                  <div
                    className="w-[40px] h-[40px] rounded-[10px] bg-zinc-100 hover:bg-zinc-200 active:bg-zinc-300 cursor-pointer flex items-center justify-center"
                    onClick={() => {
                      setPackageValue([]);
                    }}
                  >
                    <FiRefreshCcw size={20} />
                  </div>
                  <div
                    className="w-[40px] h-[40px] rounded-[10px] bg-zinc-100 hover:bg-zinc-200 active:bg-zinc-300 cursor-pointer flex items-center justify-center"
                    onClick={() => {
                      setDisplayPackaging(false);
                    }}
                  >
                    <MdOutlineClear size={20} />
                  </div>
                </div>
              </div>
              <div className="dimensions-container px-10 pb-10">
                {packageValue?.length > 0 && (
                  <div className="scene flex flex-col gap-5">
                    {packageValue?.map((p, index) => {
                      let dimCharge = 0;
                      dimCharge = (p.packageWidth * p.packageHeight * p.packageLength) / 5000;
                      p.packageCharge = Math.max(dimCharge, p.packageWeight || 0);
                      if (p.packageCharge >= 30) {
                        p.packageCharge = Math.ceil(p.packageCharge); // Round up to the nearest whole number
                      } else if (p.packageCharge > 0) {
                        p.packageCharge = Math.ceil(p.packageCharge * 2) / 2; // Round up to the nearest 0.5
                      } else {
                        p.packageCharge = 0; // Handle invalid or zero weights
                      }
                      // Return the updated package details
                      return (
                        <div className="mt-5 flex items-center">
                          {+p.packageWeight === +p.packageCharge && (
                            <div className="box-hawb">
                              <div className="face front"></div>
                              <div className="face back"></div>
                              <div className="face left"></div>
                              <div className="face right"></div>
                              <div className="face top"></div>
                              <div className="face bottom"></div>
                            </div>
                          )}
                          {+p.packageWeight < +p.packageCharge && (
                            <div className="rectangle-box">
                              <div className="rect-face front"></div>
                              <div className="rect-face back"></div>
                              <div className="rect-face left"></div>
                              <div className="rect-face right"></div>
                              <div className="rect-face top"></div>
                              <div className="rect-face bottom"></div>
                            </div>
                          )}
                          <div
                            className={`${
                              +p.packageWeight < +p.packageCharge ? 'ml-[68px]' : 'ml-20'
                            } flex items-center gap-2`}
                          >
                            <TextField
                              label="Length"
                              fullWidth
                              required
                              name="packageLength"
                              defaultValue={p.packageLength || ''}
                              onBlur={(e) => handleChange(index, e)}
                              autoComplete="off"
                              type="number"
                              variant="standard"
                              style={{ background: 'rgba(255,255,255,0.45)' }}
                              InputProps={{
                                style: {
                                  fontSize: '23px',
                                },
                              }}
                            />
                            <TextField
                              label="Width"
                              required
                              fullWidth
                              type="number"
                              name="packageWidth"
                              defaultValue={p.packageWidth || ''}
                              onBlur={(e) => handleChange(index, e)}
                              autoComplete="off"
                              variant="standard"
                              style={{ background: 'rgba(255,255,255,0.45)' }}
                              InputProps={{
                                style: {
                                  fontSize: '23px',
                                },
                              }}
                            />
                            <TextField
                              label="Height"
                              fullWidth
                              required
                              type="number"
                              autoComplete="off"
                              name="packageHeight"
                              defaultValue={p.packageHeight || ''}
                              onBlur={(e) => handleChange(index, e)}
                              variant="standard"
                              style={{ background: 'rgba(255,255,255,0.45)' }}
                              InputProps={{
                                style: {
                                  fontSize: '23px',
                                },
                              }}
                            />
                            <TextField
                              label="Weight"
                              fullWidth
                              required
                              autoComplete="off"
                              type="number"
                              name="packageWeight"
                              defaultValue={p.packageWeight || ''}
                              onBlur={(e) => handleChange(index, e)}
                              variant="standard"
                              style={{ background: 'rgba(255,255,255,0.45)' }}
                              InputProps={{
                                style: {
                                  fontSize: '23px',
                                },
                              }}
                            />
                            <TextField
                              label="Chargeable"
                              fullWidth
                              disabled
                              value={+p.packageCharge.toFixed(2)}
                              variant="standard"
                              style={{ background: 'rgba(255,255,255,0.45)' }}
                              InputProps={{
                                style: {
                                  fontSize: '23px',
                                },
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <GoTrash
                                      size={33}
                                      className="p-1.5 bg-zinc-100 text-rose-500 cursor-pointer transition-[98s] hover:bg-zinc-200 active:scale-90 rounded-full"
                                      onClick={(e) => {
                                        removeFormField(index, e);
                                      }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {packageValue?.length === 0 && (
                  <div className="flex flex-col items-center justify-center my-2">
                    <VscEmptyWindow
                      size={40}
                      className="p-1.5 bg-gradient-to-tr from-green-600 to-[darkblue] text-white rounded-full"
                    />
                    <p className="my-2">Chưa có kiện hàng nào</p>
                    <p className="text-zinc-500 text-[12px]">Công thức tính thể tích: Dài x Rộng x Cao / 5000</p>
                    <div
                      className="p-1.5 mt-2 px-4 bg-zinc-100 rounded-full cursor-pointer select-none transition-[98s] hover:bg-zinc-200 active:bg-zinc-300"
                      onClick={handleAddPackage}
                    >
                      <p>Thêm kiện hàng</p>
                    </div>
                  </div>
                )}
              </div>
              {packageValue?.length > 0 && (
                <div className="flex sticky bottom-0 bg-white z-10 p-4 border-t-[1px] border-solid border-zinc-200 items-center">
                  <div>
                    <p className="text-slate-600">Công thức tính thể tích: Dài x Rộng x Cao / 5000</p>
                    <div className="flex items-center ">
                      <p className="text-[12px] bg-emerald-50 text-emerald-600 px-1">
                        Tổng trọng lượng: {finalGross1?.toFixed(2)}kg
                      </p>
                      <p className="text-[12px] bg-purple-50 text-purple-500 px-1">
                        Trọng lượng tính cước: {finalCharge1?.toFixed(2)}kg
                      </p>
                    </div>
                  </div>
                  <div className="ml-auto flex items-center gap-1">
                    <button
                      type="submit"
                      className="p-2 px-4 bg-[darkblue] select-none cursor-pointer hover:opacity-70 active:opacity-50 transition-[95s] rounded-full font-semibold text-center text-white"
                    >
                      <p>Cập nhật</p>
                    </button>
                    <div
                      className="p-2 px-4 bg-zinc-200 select-none cursor-pointer hover:bg-zinc-300 active:bg-zinc-400 transition-[95s] rounded-full font-medium text-center"
                      onClick={handleAddPackage}
                    >
                      <p>Thêm kiện hàng</p>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </OutsideClickHandler>
        </div>
      )}
      {messages.map((chat) => {
        const type = chat.shipment?.shipmentType;
        let arr = [];
        let currentStep = chat.currentStep;
        const packaging = chat.shipment?.packageValue?.map((element) => {
          let dimCharge = 0;
          // Calculate dimensional charge
          dimCharge = (element.packageWidth * element.packageHeight * element.packageLength) / 5000;

          // Determine the package charge based on dimensional weight or actual weight
          element.packageCharge = Math.max(dimCharge, element.packageWeight || 0);

          // Rounding logic
          if (element.packageCharge >= 30) {
            element.packageCharge = Math.ceil(element.packageCharge); // Round up to the nearest whole number
          } else if (element.packageCharge > 0) {
            element.packageCharge = Math.ceil(element.packageCharge * 2) / 2; // Round up to the nearest 0.5
          } else {
            element.packageCharge = 0; // Handle invalid or zero weights
          }

          // Return the updated package details
          return {
            packageLength: element.packageLength,
            packageWidth: element.packageWidth,
            packageHeight: element.packageHeight,
            packageWeight: element.packageWeight,
            packageCharge: element.packageCharge,
            packNo: element.packNo,
          };
        });

        const finalAttachInvoiceValue = chat.shipment?.invoiceValue?.map((s) => {
          return {
            itemValue: +s.itemQuantity * +s.itemUnitPrice,
          };
        });
        const finalInvoiceValue = finalAttachInvoiceValue?.reduce((a, v) => (a = +a + +v.itemValue), 0);
        const totalInvoiceValue = +finalInvoiceValue + +chat?.shipment?.transportation;
        const finalCharge = packaging?.reduce((a, v) => (a = +a + +v?.packageCharge), 0);
        const finalGross = packaging?.reduce((a, v) => (a = +a + +v?.packageWeight), 0);
        const finalInvoiceValue1 =
          type === 'WPX'
            ? chat.shipment?.invoiceValue.map((s) => {
                const match = s.itemCommonity.match(/\(([^)]+)\)/);
                if (match) {
                  return {
                    ...s,
                    itemCommonity: s.itemCommonity,
                    itemIngredients: match[1],
                  };
                } else if (s.itemIngredients) {
                  return {
                    ...s,
                    itemCommonity: `${s.itemCommonity} (made of ${s.itemIngredients})`,
                  };
                }
                return s;
              })
            : [];
        finalInvoiceValue1.map((s) => {
          arr.push(s.itemCommonity);
          return s;
        });

        return (
          <div className="flex flex-col" key={chat.id}>
            {chat.sender !== user.uid && (
              <div className="flex my-5">
                <div className="flex flex-shrink-0 flex-col items-center  mr-5">
                  <div className="w-[25px] mt-[1px] h-[25px] bg-white border-zinc-300 flex items-center justify-center rounded-full border-solid border-[1px] flex-shrink-0">
                    <div className="w-[12px]  h-[12px] bg-[darkblue] rounded-full"></div>
                  </div>
                  <hr className="w-[1px] bg-zinc-300 h-full hr-line-down" />
                  <div className="w-[40px] h-[40px] rounded-full bg-white/50 flex items-center justify-center flex-shrink-0 border-zinc-300 border-solid border-[1px]">
                    <img
                      src={!chat.isSeen ? skydartdog2 : skydartdog}
                      alt=""
                      loading="lazy"
                      className="w-[28px] h-[28px] rounded-full object-contain"
                    />
                  </div>
                </div>
                {type === 'DOX' && (
                  <DoxScript
                    chat={chat}
                    handleChildInputChange={handleChildInputChange}
                    serviceArray={serviceArray}
                    currentStep={currentStep}
                    termArray={termArray}
                    exportReasonArray={exportReasonArray}
                    type={type}
                    finalGross={chat.shipment.chargeable}
                    finalCharge={chat.shipment.chargeable}
                    skydartLogo={skydartLogo}
                    handleDeleteShipment={handleDeleteShipment}
                    handleAddSingleShipment={handleAddSingleShipment}
                    handleBookSingleShipment={handleBookSingleShipment}
                    handleDeleteSingleShipment={handleDeleteSingleShipment}
                    isMultiColor={isMultiColor}
                    handleEffectCompleteLast={handleEffectCompleteLast}
                    handleEffectComplete={handleEffectComplete}
                    isFontStyle={isFontStyle}
                  />
                )}
                {type === 'WPX' && (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (chat.isBooking) return;
                      handleBookSingleShipment(
                        {
                          ...chat.shipment,
                          totalCharge: finalCharge?.toFixed(2),
                          reweight: finalCharge?.toFixed(2),
                          weight: finalGross?.toFixed(2),
                          totalPackage: chat.shipment.packageValue?.length,
                          totalItem: chat.shipment.invoiceValue?.length,
                          totalItemValue: type === 'WPX' ? finalInvoiceValue.toFixed(2).toString() : '0.00',
                          totalInvoiceValue: type === 'WPX' ? totalInvoiceValue.toFixed(2).toString() : '0.00',
                          description: type === 'DOX' ? 'Document' : arr.toString() + ',',
                        },
                        chat.id
                      );
                    }}
                  >
                    <div className="text-xl font-semibold mb-2 text-black">
                      <TitleWordEffect
                        isSeen={chat.isSeen}
                        text={chat.shipment.hawb}
                        onComplete={() => {
                          handleEffectComplete(chat.id);
                        }}
                      />
                    </div>
                    {currentStep > 0 && (
                      <WordGeneratorEffect
                        isSeen={chat.isSeen}
                        isFontStyle={isFontStyle}
                        text={chat.text}
                        delay={50}
                        isColor={true}
                        onComplete={() => {
                          handleEffectComplete(chat.id);
                        }}
                        isMultiColor={isMultiColor}
                      />
                    )}
                    {currentStep > 1 && (
                      <div className="mt-2.5">
                        <WordGeneratorEffect
                          isSeen={chat.isSeen}
                          isFontStyle={isFontStyle}
                          text={chat?.subText[0].value}
                          delay={50}
                          isColor={true}
                          onComplete={() => {
                            handleEffectComplete(chat.id);
                          }}
                          isMultiColor={isMultiColor}
                        />
                      </div>
                    )}
                    <div ref={shipmentInfoRef}></div>
                    {currentStep > 2 && (
                      <div className="my-7 bg-zinc-100 border-solid border-zinc-300 border-[1px] rounded-[7px] fadebox-stg">
                        <div className="p-1 px-4 flex items-center justify-between border-b-[1px] border-t-0 border-x-0 border-zinc-300">
                          <p className="text-[13px]">Thông tin đơn hàng:</p>
                          <div className="flex items-center">
                            <div className="p-1 flex items-center cursor-pointer hover:underline active:opacity-50 select-none transition-[98s]">
                              <HiOutlineSaveAs size={20} />
                              <p className="text-[15px] ml-1.5">Lưu đơn hàng</p>
                            </div>
                          </div>
                        </div>
                        <div className="m-1.5 mx-3  flex">
                          <Masonry columns={2} spacing={0} key={chat.id}>
                            <TextField
                              label="Shipment name"
                              placeholder="Enter a name for this shipment"
                              autoComplete="off"
                              defaultValue={chat.shipment.shipmentName}
                              onBlur={(e) => handleChildInputChange(chat.id, 'shipmentName', e.target.value)}
                              size="small"
                              fullWidth
                              variant="filled"
                            />
                            <TextField
                              label="Custom HAWB"
                              placeholder="Enter a customer bill number"
                              autoComplete="off"
                              defaultValue={chat.shipment.customHawb}
                              onBlur={(e) => handleChildInputChange(chat.id, 'customHawb', e.target.value)}
                              size="small"
                              fullWidth
                              variant="filled"
                            />
                            <select
                              required
                              value={chat.shipment.shipmentType}
                              onChange={(e) => handleChildInputChange(chat.id, 'shipmentType', e.target.value)}
                              className="p-2 word-generating-select-box"
                            >
                              <option value="">Select a service</option>
                              <option value="WPX">WPX</option>
                              <option value="DOX">DOX</option>
                            </select>
                            <select
                              required
                              value={chat.shipment.service}
                              onChange={(e) => handleChildInputChange(chat.id, 'service', e.target.value)}
                              className="p-2 word-generating-select-box"
                            >
                              <option value="">Select a service</option>
                              {serviceArray?.map((item) => (
                                <option value={item.label}>{item.proxy}</option>
                              ))}
                            </select>
                            <select
                              value={chat.shipment.usage}
                              onChange={(e) => handleChildInputChange(chat.id, 'usage', e.target.value)}
                              className="p-2 word-generating-select-box"
                            >
                              <option value="">Select an export reason</option>
                              {exportReasonArray?.map((item) => (
                                <option value={item.value}>{item.label}</option>
                              ))}
                            </select>
                            <TextField
                              required
                              label="Destination (Country)"
                              placeholder="Enter a country"
                              autoComplete="off"
                              size="small"
                              error={!chat.shipment.toCountry ? true : false}
                              defaultValue={chat.shipment.toCountry}
                              onBlur={(e) => handleChildInputChange(chat.id, 'toCountry', e.target.value)}
                              fullWidth
                              maxRows={3}
                              multiline
                              variant="filled"
                            />
                            <TextField
                              required
                              label="Company (importer)"
                              placeholder="Enter a company name"
                              autoComplete="off"
                              error={!chat.shipment.importer ? true : false}
                              size="small"
                              defaultValue={chat.shipment.importer}
                              onBlur={(e) => handleChildInputChange(chat.id, 'importer', e.target.value)}
                              fullWidth
                              maxRows={3}
                              multiline
                              variant="filled"
                            />
                            <TextField
                              label="Address 1"
                              placeholder="Enter primary address"
                              autoComplete="off"
                              size="small"
                              required
                              error={!chat.shipment.toAddress ? true : false}
                              fullWidth
                              defaultValue={chat.shipment.toAddress}
                              onBlur={(e) => handleChildInputChange(chat.id, 'toAddress', e.target.value)}
                              maxRows={5}
                              multiline
                              variant="filled"
                            />
                            <TextField
                              label="Address 2"
                              required
                              placeholder="Enter secondary address"
                              autoComplete="off"
                              size="small"
                              error={!chat.shipment.toAddress2 ? true : false}
                              fullWidth
                              maxRows={5}
                              multiline
                              defaultValue={chat.shipment.toAddress2}
                              onBlur={(e) => handleChildInputChange(chat.id, 'toAddress2', e.target.value)}
                              variant="filled"
                            />
                            <TextField
                              label="Address 3"
                              placeholder="Enter third address"
                              autoComplete="off"
                              size="small"
                              fullWidth
                              maxRows={5}
                              multiline
                              defaultValue={chat.shipment.toAddress3}
                              onBlur={(e) => handleChildInputChange(chat.id, 'toAddress3', e.target.value)}
                              variant="filled"
                            />

                            <TextField
                              label="Town"
                              placeholder="Enter a town name"
                              autoComplete="off"
                              size="small"
                              fullWidth
                              required
                              error={!chat.shipment.toTown ? true : false}
                              variant="filled"
                              defaultValue={chat.shipment.toTown}
                              onBlur={(e) => handleChildInputChange(chat.id, 'toTown', e.target.value)}
                            />
                            <TextField
                              label="City"
                              required
                              placeholder="Enter a city name"
                              autoComplete="off"
                              size="small"
                              error={!chat.shipment.toCity ? true : false}
                              defaultValue={chat.shipment.toCity}
                              onBlur={(e) => handleChildInputChange(chat.id, 'toCity', e.target.value)}
                              fullWidth
                              variant="filled"
                            />
                            <TextField
                              label="State"
                              required
                              error={!chat.shipment.toState ? true : false}
                              defaultValue={chat.shipment.toState}
                              onBlur={(e) => handleChildInputChange(chat.id, 'toState', e.target.value)}
                              placeholder="Enter a state name"
                              autoComplete="off"
                              size="small"
                              fullWidth
                              variant="filled"
                            />
                            <TextField
                              label="Postal code"
                              required
                              error={!chat.shipment.toPostal ? true : false}
                              defaultValue={chat.shipment.toPostal}
                              onBlur={(e) => handleChildInputChange(chat.id, 'toPostal', e.target.value)}
                              placeholder="Enter a postal code"
                              autoComplete="off"
                              size="small"
                              fullWidth
                              variant="filled"
                            />
                            <TextField
                              label="Telephone"
                              required
                              error={!chat.shipment.toPhone ? true : false}
                              defaultValue={chat.shipment.toPhone}
                              onBlur={(e) => handleChildInputChange(chat.id, 'toPhone', e.target.value)}
                              placeholder="Enter receiver phone number"
                              autoComplete="off"
                              size="small"
                              fullWidth
                              type="number"
                              variant="filled"
                            />
                            <TextField
                              label="Contact name"
                              required
                              error={!chat.shipment.toName ? true : false}
                              placeholder="Enter recipient name"
                              autoComplete="off"
                              defaultValue={chat.shipment.toName}
                              onBlur={(e) => handleChildInputChange(chat.id, 'toName', e.target.value)}
                              size="small"
                              fullWidth
                              variant="filled"
                            />
                            <TextField
                              label="Email address"
                              placeholder="Enter recipient email"
                              autoComplete="off"
                              defaultValue={chat.shipment.toEmail}
                              onBlur={(e) => handleChildInputChange(chat.id, 'toEmail', e.target.value)}
                              size="small"
                              fullWidth
                              variant="filled"
                            />
                            <TextField
                              label="Transport value"
                              placeholder="Enter a valuation for transporting"
                              autoComplete="off"
                              size="small"
                              defaultValue={chat.shipment.transportation}
                              onBlur={(e) => handleChildInputChange(chat.id, 'transportation', e.target.value)}
                              fullWidth
                              type="number"
                              variant="filled"
                            />
                            <select
                              value={chat.shipment.term}
                              onChange={(e) => handleChildInputChange(chat.id, 'term', e.target.value)}
                              className="p-2 word-generating-select-box"
                            >
                              <option value="">Select a transport term</option>
                              {termArray.map((t) => (
                                <option value={t}>{t}</option>
                              ))}
                            </select>
                            <TextField
                              label="IOSS / TIN / EORI Number / ID Tax Number"
                              placeholder="Enter a valuation for transporting"
                              autoComplete="off"
                              size="small"
                              defaultValue={chat.shipment.eori}
                              onBlur={(e) => handleChildInputChange(chat.id, 'eori', e.target.value)}
                              fullWidth
                              variant="filled"
                            />
                          </Masonry>
                        </div>
                        <div className="p-3 pt-2">
                          <WordGeneratorEffect
                            isSeen={chat.isSeen}
                            isFontStyle={isFontStyle}
                            text={`Xin lưu ý: ${chat?.subText[1]?.value}`}
                            delay={50}
                            isColor={true}
                            onComplete={() => {
                              handleEffectComplete(chat.id);
                            }}
                            isMultiColor={false}
                          />
                        </div>
                      </div>
                    )}
                    {currentStep > 3 && (
                      <TitleWordEffect
                        isSeen={chat.isSeen}
                        text="Thông tin chi tiết về kiện hàng, đóng gói và tính cước"
                        onComplete={() => {
                          handleEffectComplete(chat.id);
                        }}
                      />
                    )}
                    <div className="my-2">
                      {currentStep > 4 && (
                        <div className="my-5">
                          <div className="bg-zinc-100 border-zinc-300 border-solid border-[1px] rounded-[7px]">
                            <div className="p-1 px-4 flex items-center justify-between border-b-[1px] border-t-0 border-x-0 border-zinc-300">
                              <p className="text-[13px]">Tóm tắt kiện hàng:</p>
                              <div className="flex items-center">
                                <div
                                  className="p-1 flex items-center cursor-pointer hover:underline active:opacity-50 select-none transition-[98s]"
                                  onClick={() => {
                                    setDisplayDimGuide(!displayDimGuide);
                                  }}
                                >
                                  <MdOutlineCalculate size={20} />
                                  <p className="text-[15px] ml-1.5">Công thức tính cước</p>
                                </div>
                                <div
                                  className="p-1 flex items-center cursor-pointer hover:underline active:opacity-50 select-none transition-[98s]"
                                  onClick={() => {
                                    handleShowPackaging(packaging, chat.id);
                                  }}
                                >
                                  <PiMagicWandLight size={20} />
                                  <p className="text-[15px] ml-1.5">Chỉnh sửa kiện hàng</p>
                                </div>
                              </div>
                            </div>
                            <div className="p-2 px-[15px]">
                              <div>
                                {packaging.map((item, i) => (
                                  <DimensionGeneratorEffect
                                    isColor={false}
                                    key={`${item.packageLength}-${item.packageWidth}-${item.packageHeight}-${item.packageWeight}-${item.packageCharge}-${i}`}
                                    text={`${item.packageLength} x ${item.packageWidth} x ${item.packageHeight} (cm) - Gross: ${item.packageWeight}kg, Chargeable: ${item.packageCharge}kg`}
                                    highlightRules={handleHighlightRules}
                                    gross={item.packageWeight}
                                    charged={item.packageCharge}
                                    isSeen={chat.isSeen}
                                  />
                                ))}

                                <div className="my-2">
                                  <p className="tracking-wide">
                                    Nếu anh chị thắc mắc về cách tính cước trọng lượng và tự hỏi vì sao trọng lượng tích
                                    cước lại chênh lệch so với trọng lượng của lô hàng, điều này có thể bị tác động bởi
                                    công thức tính trọng lượng thể tích.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {currentStep > 4 && (
                      <TitleWordEffect
                        isSeen={chat.isSeen}
                        text="Thông thường, 1 lô hàng sẽ có 2 loại trọng lượng:"
                        onComplete={() => {
                          handleEffectComplete(chat.id);
                        }}
                      />
                    )}
                    {currentStep > 5 && (
                      <div className="mt-2">
                        <WordGeneratorEffect
                          isSeen={chat.isSeen}
                          isFontStyle={isFontStyle}
                          text={chat.subText[2]?.value || 'pending'}
                          delay={50}
                          isColor={true}
                          onComplete={() => {
                            handleEffectComplete(chat.id);
                          }}
                          isMultiColor={isMultiColor}
                        />
                      </div>
                    )}
                    {currentStep > 6 && (
                      <div className="mt-2.5">
                        <WordGeneratorEffect
                          isSeen={chat.isSeen}
                          isFontStyle={isFontStyle}
                          text={chat.subText[3]?.value || 'pending'}
                          delay={50}
                          isColor={true}
                          onComplete={() => {
                            handleEffectComplete(chat.id);
                          }}
                          isMultiColor={isMultiColor}
                        />
                      </div>
                    )}
                    {currentStep > 7 && (
                      <div className="mt-5">
                        <TitleWordEffect
                          isSeen={chat.isSeen}
                          text="Hoá đơn, chứng từ của vận đơn"
                          onComplete={() => {
                            handleEffectComplete(chat.id);
                          }}
                        />
                      </div>
                    )}
                    {currentStep > 7 && (
                      <div className="my-4 bg-zinc-100 rounded-[7px] border-zinc-300 border-solid border-[1px] fadebox-stg">
                        <div className="p-1 px-4 flex items-center justify-between border-b-[1px] border-t-0 border-x-0 border-zinc-300">
                          <p className="text-[13px]">Giá trị hoá đơn:</p>
                          <div className="flex items-center">
                            <div
                              className="p-1 flex items-center cursor-pointer hover:underline active:opacity-50 select-none transition-[98s]"
                              onClick={() => {
                                handleShowInvoice(chat.shipment.invoiceValue, chat.id);
                              }}
                            >
                              <PiMagicWandLight size={20} />
                              <p className="text-[15px] ml-1.5">Chỉnh sửa hoá đơn</p>
                            </div>
                          </div>
                        </div>
                        <div className="mt-2.5 w-full px-4">
                          <div className="flex w-full gap-2">
                            <div className="w-full">
                              <p className="text-[14px] font-semibold text-slate-600">Mã vận đơn</p>
                              <div className="w-full">
                                <p className="p-1.5 px-3 mt-1.5 rounded-[10px] w-full border-zinc-300 border-solid border-[1px] bg-white">
                                  {chat.shipment.hawb}
                                </p>
                              </div>
                            </div>
                            <div className="w-full">
                              <p className="text-[15px] font-semibold text-slate-600">Ngày áp dụng</p>
                              <div className="w-full ">
                                <div className="p-1.5 px-3 mt-1.5 rounded-[10px] w-full border-zinc-300 border-solid border-[1px] bg-white flex items-center">
                                  <IoCalendarOutline size={22} />
                                  <p className="ml-1.5">{formattedDate}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-5 w-full px-4">
                          <div className="flex w-full gap-2">
                            <div className="w-full">
                              <p className="text-lg  text-slate-600">Gửi từ</p>
                              <div className="w-full">
                                <div className="p-3.5 mt-1.5 rounded-[10px] w-full bg-zinc-200">
                                  <div className="flex items-start">
                                    <p className="text-black font-medium  ">{chat.shipment.exporter}</p>
                                  </div>
                                  <div className="my-2">
                                    <p className="font-mono text-slate-800 break-words">
                                      {chat.shipment.fromAddress}, {chat.shipment.fromState} , {chat.shipment.fromTown}
                                    </p>
                                    <p className="font-mono text-slate-800 break-words">
                                      {chat.shipment.fromCity}, {chat.shipment.fromPostal}
                                    </p>
                                    <p className="font-mono text-slate-800 break-words">{chat.shipment.fromCountry}</p>
                                  </div>
                                  <div className="my-2">
                                    <p className="font-mono text-slate-800 break-words">{chat.shipment.fromPhone}</p>
                                  </div>
                                  <div className="my-2">
                                    <p className="font-mono text-slate-800 break-words w-[260px]">
                                      {chat.shipment.fromEmail}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-full">
                              <p className="text-lg  text-slate-600">Đến</p>
                              <div className="w-full">
                                <div className="p-3.5 mt-1.5 rounded-[10px] w-full bg-zinc-200">
                                  <div className="flex items-center">
                                    <p className="text-black font-medium">{chat.shipment.importer}</p>
                                    <p
                                      className="ml-auto select-none hover:underline active:opacity-50 cursor-pointer text-[darkblue]"
                                      onClick={handleScrollToShipment}
                                    >
                                      Chỉnh sửa
                                    </p>
                                  </div>
                                  <div className="my-2">
                                    <p className="font-mono text-slate-800">
                                      {chat.shipment.toAddress}, {chat.shipment.toState} , {chat.shipment.toTown}
                                    </p>
                                    <p className="font-mono text-slate-800">
                                      {chat.shipment.toCity}, {chat.shipment.toPostal}
                                    </p>
                                    <p className="font-mono text-slate-800">{chat.shipment.toCountry}</p>
                                  </div>
                                  <div className="my-2">
                                    <p className="font-mono text-slate-800">{chat.shipment.toPhone}</p>
                                  </div>
                                  <div className="my-2">
                                    <p className="font-mono text-slate-800">{chat.shipment.toEmail}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="my-5  px-4">
                          <div className="bg-white mt-2.5 rounded-[10px] border-zinc-300 border-solid border-[1px]">
                            <div className="flex items-center py-2 p-3 bg-zinc-200 rounded-tr-[10px] rounded-tl-[10px]">
                              <div className="w-full">
                                <p>Description of goods</p>
                              </div>
                              <div className="w-full flex items-center">
                                <p className="w-full px-1">Qty</p>
                                <p className="w-full px-1">Unit price</p>
                                <p className="w-full px-1">Total value</p>
                              </div>
                            </div>
                            {chat.shipment?.invoiceValue.map((item, i) => (
                              <div>
                                <div
                                  className={`flex items-center py-1.5 p-3  border-t-0 border-x-0 border-zinc-300 border-solid ${
                                    i === chat.shipment?.invoiceValue?.length - 1
                                      ? 'border-b-0 rounded-bl-[10px] rounded-br-[10px]'
                                      : 'border-b-[1px]'
                                  } hover:bg-zinc-100 transition-[98s] cursor-pointer active:opacity-50`}
                                  onClick={() => {
                                    handleSelectInvoiceItem(chat.id, item.id);
                                  }}
                                >
                                  <div className="w-full pr-0.5 ">
                                    <p>{item.itemCommonity}</p>
                                  </div>
                                  <div className="w-full flex items-center">
                                    <div className="w-full px-1">
                                      <p>
                                        {item.itemQuantity} / {item.itemUnit}
                                      </p>
                                    </div>
                                    <div className="w-full px-1">
                                      <p>${item.itemUnitPrice}</p>
                                    </div>
                                    <p className="w-full px-1">${item.itemValue}</p>
                                  </div>
                                </div>
                                {item.isSelectItem && (
                                  <div
                                    className={`p-1.5 px-3  ${
                                      i === chat.shipment?.invoiceValue?.length - 1 ? 'border-b-0' : 'border-b-[1px]'
                                    } }`}
                                  >
                                    <div>
                                      <p className="text-[20px] font-semibold">Chi tiết mặt hàng</p>
                                    </div>
                                    <div className="p-2.5 py-1">
                                      <div className="flex items-start">
                                        <div className="w-full flex items-start">
                                          <div className="w-[25%]">
                                            <p className="font-medium text-[14px] border-b-[1px] border-solid border-zinc-300 px-1">
                                              Tên tiếng việt
                                            </p>
                                            <p className="text-[14px] text-zinc-600 px-1">
                                              {item.itemVietnamCommonity || 'Chưa xác định'}
                                            </p>
                                          </div>
                                          <div className="w-[25%]">
                                            <p className="text-[14px] font-medium border-b-[1px] border-solid border-zinc-300 px-1">
                                              Nhãn hiệu
                                            </p>
                                            <p className="text-[14px] text-zinc-600 px-1">
                                              {item.itemBrand || 'Chưa xác định'}
                                            </p>
                                          </div>
                                          <div className="w-[25%]">
                                            <p className="text-[14px] font-medium border-b-[1px] border-solid border-zinc-300 px-1">
                                              Thành phần
                                            </p>
                                            <p className="text-[14px] text-zinc-600 px-1">
                                              {item.itemIngredients || 'Chưa xác định'}
                                            </p>
                                          </div>
                                          <div className="w-[25%]">
                                            <p className="text-[14px] font-medium border-b-[1px] border-solid border-zinc-300 px-1">
                                              Sử dụng
                                            </p>
                                            <p className="text-[14px] text-zinc-600 px-1">
                                              {item.itemPurpose || 'Chưa xác định'}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="p-2.5">
                                      <div className="flex items-start">
                                        <div className="w-full">
                                          <p className="text-[14px] font-medium border-b-[1px] border-solid border-zinc-300 px-1">
                                            Địa chỉ sản xuất
                                          </p>
                                          <p className="text-[14px] text-zinc-600 px-1">
                                            {item.itemManufactAddress || 'Chưa xác định'}
                                          </p>
                                        </div>
                                        <div className="w-full flex items-start">
                                          <div className="w-full">
                                            <p className="text-[14px] font-medium border-b-[1px] border-solid border-zinc-300 px-1">
                                              NSX
                                            </p>
                                            <p className="text-[14px] text-zinc-600 px-1">
                                              {item.itemManufact || 'Chưa xác định'}
                                            </p>
                                          </div>
                                          <div className="w-full">
                                            <p className="text-[14px] font-medium border-b-[1px] border-solid border-zinc-300 px-1">
                                              HS Code
                                            </p>
                                            <p className="text-[14px] text-zinc-600 px-1">
                                              {item.itemHsCode || 'Chưa xác định'}
                                            </p>
                                          </div>
                                          <div className="w-full">
                                            <p className="text-[14px] font-medium border-b-[1px] border-solid border-zinc-300 px-1">
                                              FDA
                                            </p>
                                            <p className="text-[14px] text-zinc-600 px-1">
                                              {item.itemFda || 'Chưa xác định'}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                    {currentStep > 8 && (
                      <WordGeneratorEffect
                        isSeen={chat.isSeen}
                        isFontStyle={isFontStyle}
                        text="Hãy nhấp chuột vào tên sản phẩm để thấy những chi tiết khác nhé!"
                        delay={50}
                        isColor={true}
                        onComplete={() => {
                          handleEffectComplete(chat.id);
                        }}
                        isMultiColor={isMultiColor}
                      />
                    )}
                    {currentStep > 9 && (
                      <div className="text-xl font-semibold mt-10 text-black pt-3" style={{ borderTop: '1px solid' }}>
                        <TitleWordEffect
                          isSeen={chat.isSeen}
                          text="Xem trước"
                          onComplete={() => {
                            handleEffectComplete(chat.id);
                          }}
                        />
                      </div>
                    )}
                    {currentStep > 10 && (
                      <div className="mt-3">
                        <div className="flex w-[370px] border-solid border-[1px] border-black bg-white fadebox-stg">
                          <div className="w-full border-r-[1px] border-solid border-black">
                            <div>
                              <div className="bg-blue-300 p-0.5 px-1.5 border-b-[1px] border-solid border-black mb-0.5">
                                <p className="text-[7px] font-semibold">From (Exporter)</p>
                              </div>
                              <div className="px-1.5 flex items-start text-[6px]">
                                <p>
                                  Name / Company: <span className="font-semibold">{chat.shipment.exporter}</span>
                                </p>
                              </div>
                              <div className="flex items-start text-[6px] px-1.5">
                                <p>
                                  Address:{' '}
                                  <span className="font-semibold">
                                    {chat.shipment.fromAddress}, {chat.shipment.fromAddress2},{' '}
                                    {chat.shipment.fromAddress3}
                                  </span>
                                </p>
                              </div>
                              <div className="px-1.5 text-[6px] flex items-start">
                                <p>
                                  City: <span className="font-semibold">{chat.shipment.fromCity}</span>
                                </p>
                              </div>
                              <div className="px-1.5 text-[6px] flex items-start">
                                <p>
                                  Town: <span className="font-semibold">{chat.shipment.fromTown}</span>
                                </p>
                              </div>
                              <div className="px-1.5 text-[6px] flex items-start">
                                <p>
                                  State: <span className="font-semibold">{chat.shipment.fromState}</span>
                                </p>
                              </div>
                              <div className="px-1.5 text-[6px] flex items-start">
                                <p>
                                  Postal code: <span className="font-semibold"></span>
                                  {chat.shipment.fromPostal}
                                </p>
                              </div>
                              <div className="px-1.5 text-[6px] flex items-start">
                                <p>
                                  Country: <span className="font-semibold">{chat.shipment.fromCountry}</span>
                                </p>
                              </div>
                              <div className="px-1.5 text-[6px] flex items-start">
                                <p>
                                  Telephone: <span className="font-semibold">{chat.shipment.fromPhone}</span>
                                </p>
                              </div>
                              <div className="px-1.5 text-[6px] flex items-start">
                                <p>
                                  Contact name: <span className="font-semibold">{chat.shipment.fromName}</span>
                                </p>
                              </div>
                              <div className="px-1.5 text-[6px] flex items-start">
                                <p>
                                  Email address: <span className="font-semibold">{chat.shipment.fromEmail}</span>
                                </p>
                              </div>
                            </div>
                            <div>
                              <div className="p-0.5 bg-blue-300 px-1.5 border-b-[1px] border-solid border-black border-t-[1px]">
                                <p className="text-[7px] font-semibold">To (Importer)</p>
                              </div>
                              <div className="px-1.5 flex items-start text-[6px]">
                                <p>
                                  Name / Company: <span className="font-semibold">{chat.shipment.importer}</span>
                                </p>
                              </div>
                              <div className="flex items-start text-[6px] px-1.5">
                                <p>
                                  Address:{' '}
                                  <span className="font-semibold">
                                    {chat.shipment.toAddress}, {chat.shipment.toAddress2}, {chat.shipment.toAddress3}
                                  </span>
                                </p>
                              </div>
                              <div className="px-1.5 text-[6px] flex items-start">
                                <p>
                                  City: <span className="font-semibold">{chat.shipment.toCity}</span>
                                </p>
                              </div>
                              <div className="px-1.5 text-[6px] flex items-start">
                                <p>
                                  Town: <span className="font-semibold">{chat.shipment.toTown}</span>
                                </p>
                              </div>
                              <div className="px-1.5 text-[6px] flex items-start">
                                <p>
                                  State: <span className="font-semibold">{chat.shipment.toState}</span>
                                </p>
                              </div>
                              <div className="px-1.5 text-[6px] flex items-start">
                                <p>
                                  Postal code: <span className="font-semibold">{chat.shipment.toPostal}</span>
                                </p>
                              </div>
                              <div className="px-1.5 text-[6px] flex items-start">
                                <p>
                                  Country: <span className="font-semibold">{chat.shipment.toCountry}</span>
                                </p>
                              </div>
                              <div className="px-1.5 text-[6px] flex items-start">
                                <p>
                                  Telephone: <span className="font-semibold">{chat.shipment.toPhone}</span>
                                </p>
                              </div>
                              <div className="px-1.5 text-[6px] flex items-start">
                                <p>
                                  Contact name: <span className="font-semibold">{chat.shipment.toName}</span>
                                </p>
                              </div>
                              <div className="px-1.5 text-[6px] flex items-start">
                                <p>
                                  Email address: <span className="font-semibold">{chat.shipment.toEmail}</span>
                                </p>
                              </div>
                            </div>
                            <div className="border-t-[1px] border-x-0 border-solid border-black">
                              <div className="flex">
                                <div className="w-[40%] border-r-[1px] border-solid border-black">
                                  <p className="font-semibold text-[7px] whitespace-nowrap px-1.5">Shipper's account</p>
                                  <p className="text-[5px] px-1.5">{chat.shipment.fromEmail}</p>
                                  <div className="bg-blue-300 flex items-center mt-0.5 border-t-[1px] border-solid border-black px-0.5">
                                    <p className="text-[7px]">Credit</p>
                                    <div className="w-[7px] h-[7px] rounded-[5px] bg-white border-zinc-300 border-solid border-[1px] ml-auto"></div>
                                  </div>
                                  <div className="bg-blue-300 flex items-center px-0.5 border-t-[1px] border-solid border-black">
                                    <p className="text-[7px]">Prepaid</p>
                                    <div className="w-[7px] h-[7px] rounded-[5px] bg-white border-zinc-300 border-solid border-[1px] ml-auto"></div>
                                  </div>
                                </div>
                                <div className="w-[60%]">
                                  <p className="p-0.5 text-[6px]">Shipper's Agreement And Signature</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-full">
                            <div className="flex item-start mt-1 w-full">
                              <div className="w-full">
                                <div className="flex items-center w-full">
                                  <img
                                    src={skydartLogo}
                                    alt=""
                                    loading="lazy"
                                    className="w-[30px] h-[30px] object-contain"
                                  />
                                  <div>
                                    <p className="text-[12px] font-semibold leading-3 text-[darkblue]">SKYDART</p>
                                    <p className="text-[12px] font-semibold leading-3 text-[darkblue]">EXPRESS</p>
                                  </div>
                                  <BsQrCode size={30} className="ml-auto mr-2" />
                                </div>
                                <p className="text-black text-[6px] font-bold ml-1">
                                  Document and parcel worldwide express
                                </p>
                              </div>
                            </div>
                            <div className="w-full flex items-center justify-center mt-2.5">
                              <p className="text-[darkblue] text-[6px]">https://skydart.com.vn</p>
                            </div>
                            <div className="border-y-[1px] border-x-0 border-solid border-black flex items-center text-[9px] mt-1">
                              <div className="w-[50px] bg-blue-300 text-center p-1 flex items-center justify-center border-r-[1px] border-solid border-black">
                                <p>Waybill</p>
                              </div>
                              <div className="w-full p-1 flex items-center justify-center">
                                <p>{chat.shipment.hawb}</p>
                              </div>
                            </div>
                            <div className="bg-blue-300 flex items-center p-0.5 border-b-[1px] border-solid border-black">
                              <p className="text-center text-[5px] w-[33%]">Packages amount</p>
                              <p className="text-center text-[5px] w-[33%]">Gross weight</p>
                              <p className="text-center text-[5px] w-[33%]">Chargeable weight</p>
                            </div>
                            <div className="bg-white flex items-center border-b-[1px] border-solid border-black">
                              <p className="text-center text-[8px] w-[33%] border-r-[1px] border-solid border-black p-0.5">
                                {chat.shipment?.packageValue?.length}
                              </p>
                              <p className="text-center text-[8px] w-[33%] p-0.5 border-r-[1px] border-solid border-black">
                                {finalGross?.toFixed(2)} KG
                              </p>
                              <p className="text-center text-[8px] w-[33%] p-0.5 ">{finalCharge?.toFixed(2)} KG</p>
                            </div>
                            <div className="bg-blue-300 flex items-center p-0.5 border-b-[1px] border-solid border-black">
                              <p className=" text-[5px] ml-0.5 ">Full description of goods</p>
                            </div>
                            <div className="p-1 border-solid border-black border-b-[1px] flex flex-wrap overflow-y-auto font-medium gap-1 h-[83.5px]">
                              {chat.shipment?.invoiceValue?.map((s, index) => (
                                <div key={index}>
                                  <h5 className="text-[8px]">{s.itemCommonity + ',' || ''}</h5>
                                </div>
                              ))}
                            </div>
                            <div className="flex">
                              <div className="text-[7px] p-0.5">
                                <p>Pick up by:</p>
                                <p className="mt-2">Shipment date:</p>
                              </div>
                              <div className="ml-auto w-[50px] bg-blue-300 border-l-[1px] border-solid border-black flex items-center justify-center font-semibold h-[43px]">
                                <p>{chat.shipment.shipmentType}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="w-[370px] border-black border-solid border-[1px]  bg-white mt-5 overflow-auto">
                          <div className="p-3">
                            {currentStep > 10 && (
                              <div className="text-lg font-bold">
                                <TitleWordEffect
                                  isSeen={chat.isSeen}
                                  text="INVOICE"
                                  onComplete={() => {
                                    handleEffectComplete(chat.id);
                                  }}
                                />
                              </div>
                            )}
                            {currentStep > 11 && (
                              <div className="mt-2 border-solid border-[1px] border-black">
                                <div className="flex items-center">
                                  <div className="text-[9px] p-0.5 px-1 border-solid border-r-[1px] border-b-[1px] border-black  flex-shrink-0 w-[40%]">
                                    <p>INVOICE NUMBER</p>
                                  </div>
                                  <div className="text-[9px] px-1 p-0.5 border-solid  border-b-[1px] border-black w-[60%]">
                                    <p>{chat.shipment.hawb}</p>
                                  </div>
                                </div>
                                <div className="flex items-center">
                                  <div className="text-[9px] p-0.5 px-1 border-solid border-r-[1px] border-b-[1px] border-black  flex-shrink-0 w-[40%]">
                                    <p>Date of invoice</p>
                                  </div>
                                  <div className="text-[9px] px-1 p-0.5 border-solid  border-b-[1px] border-black w-[60%]">
                                    <p>{chat.shipment.createAt}</p>
                                  </div>
                                </div>
                                <div className="flex items-center">
                                  <div className="text-[9px] p-0.5 px-1 border-solid border-r-[1px] border-b-[1px] border-black  flex-shrink-0 w-[40%]">
                                    <p>INTL Airway bill</p>
                                  </div>
                                  <div className="text-[9px] px-1 p-0.5 border-solid  border-b-[1px] border-black w-[60%]">
                                    <p>{chat.shipment.sin || 'Pending'}</p>
                                  </div>
                                </div>
                                <div className="flex items-center">
                                  <div className="text-[9px] p-0.5 px-1 border-solid border-r-[1px] border-b-[1px] border-black  flex-shrink-0 w-[40%]">
                                    <p>Country of origin</p>
                                  </div>
                                  <div className="text-[9px] px-1 p-0.5 border-solid  border-b-[1px] border-black w-[60%]">
                                    <p>{chat.shipment.fromCountry}</p>
                                  </div>
                                </div>
                                <div className="flex items-center">
                                  <div className="text-[9px] p-0.5 px-1 border-solid border-r-[1px] border-b-[1px] border-black  flex-shrink-0 w-[40%]">
                                    <p>Destination</p>
                                  </div>
                                  <div className="text-[9px] px-1 p-0.5 border-solid  border-b-[1px] border-black w-[60%]">
                                    <p>{chat.shipment.toCountry}</p>
                                  </div>
                                </div>
                                <div className="flex items-center">
                                  <div className="text-[9px] p-0.5 px-1 border-solid border-r-[1px] border-b-[1px] border-black  flex-shrink-0 w-[40%]">
                                    <p>REASON FOR EXPORT</p>
                                  </div>
                                  <div className="text-[9px] px-1 p-0.5 border-solid  border-b-[1px] border-black w-[60%]">
                                    <p>{chat.shipment.usage || 'PENDING'}</p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {currentStep > 11 && (
                              <div className="mt-2.5 flex items-start">
                                <div className="flex items-center justify-center flex-col w-[50%] flex-shrink-0">
                                  <div
                                    style={{
                                      fontWeight: 600,
                                      fontSize: 9,
                                    }}
                                  >
                                    <WordGeneratorEffect
                                      isSeen={chat.isSeen}
                                      isFontStyle={false}
                                      text="SHIPPER / EXPORTER"
                                      delay={50}
                                      isColor={false}
                                      onComplete={() => {
                                        handleEffectComplete(chat.id);
                                      }}
                                      isMultiColor={false}
                                    />
                                  </div>
                                  {currentStep > 12 && (
                                    <div className="w-full fadebox-stg">
                                      <div className="w-full border-solid border-b-0 border-[1px] border-black">
                                        <div className="flex  text-[7px]">
                                          <div className="w-full border-r-[1px] border-solid border-black">
                                            <p>Company / Sender</p>
                                          </div>
                                          <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                            <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              {chat.shipment.exporter}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-full border-solid border-b-0 border-[1px] border-black">
                                        <div className="flex text-[7px]">
                                          <div className="w-full border-r-[1px] border-solid border-black">
                                            <p>Address</p>
                                          </div>
                                          <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                            <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              {chat.shipment.fromAddress}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-full border-solid border-b-0 border-[1px] border-black h-[12px]">
                                        <div className="flex text-[7px]">
                                          <div className="w-full border-r-[1px] border-solid border-black"></div>
                                          <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                            <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              {chat.shipment.fromAddress2 || '.'}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-full border-solid border-b-0 border-[1px] border-black h-[12px]">
                                        <div className="flex text-[7px]">
                                          <div className="w-full border-r-[1px] border-solid border-black"></div>
                                          <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                            <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              {chat.shipment.fromAddress3 || '.'}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-full border-solid border-b-0 border-[1px] border-black">
                                        <div className="flex text-[7px]">
                                          <div className="w-full border-r-[1px] border-solid border-black">
                                            <p>Ward</p>
                                          </div>
                                          <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                            <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              {chat.shipment.fromState}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-full border-solid border-b-0 border-[1px] border-black">
                                        <div className="flex text-[7px]">
                                          <div className="w-full border-r-[1px] border-solid border-black">
                                            <p>District</p>
                                          </div>
                                          <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                            <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              {chat.shipment.fromTown}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-full border-solid border-b-0 border-[1px] border-black">
                                        <div className="flex text-[7px]">
                                          <div className="w-full border-r-[1px] border-solid border-black">
                                            <p>City / province</p>
                                          </div>
                                          <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                            <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              {chat.shipment.fromCity}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-full border-solid border-b-0 border-[1px] border-black">
                                        <div className="flex text-[7px]">
                                          <div className="w-full border-r-[1px] border-solid border-black">
                                            <p>Postal code</p>
                                          </div>
                                          <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                            <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              {chat.shipment.fromPostal}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-full border-solid border-b-0 border-[1px] border-black">
                                        <div className="flex text-[7px]">
                                          <div className="w-full border-r-[1px] border-solid border-black">
                                            <p>Country</p>
                                          </div>
                                          <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                            <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              {chat.shipment.fromCountry}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-full border-solid border-b-0 border-[1px] border-black">
                                        <div className="flex text-[7px]">
                                          <div className="w-full border-r-[1px] border-solid border-black">
                                            <p>Contact name</p>
                                          </div>
                                          <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                            <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              {chat.shipment.fromName}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-full border-solid border-b-0 border-[1px] border-black">
                                        <div className="flex text-[7px]">
                                          <div className="w-full border-r-[1px] border-solid border-black">
                                            <p>Contact number</p>
                                          </div>
                                          <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                            <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              {chat.shipment.fromPhone}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-full border-solid border-b-0 border-[1px] border-black">
                                        <div className="flex text-[7px]">
                                          <div className="w-full border-r-[1px] border-solid border-black">
                                            <p>Email Address</p>
                                          </div>
                                          <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                            <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              {chat.shipment.fromEmail}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-full border-solid border-b-[1px] border-[1px] border-black">
                                        <div className="flex text-[7px]">
                                          <div className="w-full border-r-[1px] border-solid border-black">
                                            <p>TAX ID</p>
                                          </div>
                                          <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                            <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              {user.taxId}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                {currentStep > 12 && (
                                  <div className="flex items-center justify-center flex-col w-[50%]">
                                    <div
                                      style={{
                                        fontWeight: 600,
                                        fontSize: 9,
                                      }}
                                    >
                                      <WordGeneratorEffect
                                        isSeen={chat.isSeen}
                                        isFontStyle={false}
                                        text="CONSIGNEE / IMPORTER"
                                        delay={50}
                                        isColor={false}
                                        onComplete={() => {
                                          handleEffectComplete(chat.id);
                                        }}
                                        isMultiColor={false}
                                      />
                                    </div>
                                    {currentStep > 13 && (
                                      <div className="w-full">
                                        <div className="w-full border-solid border-b-0 border-[1px] border-black">
                                          <div className="flex  text-[7px]">
                                            <div className="w-full border-r-[1px] border-solid border-black">
                                              <p>Company / Receiver</p>
                                            </div>
                                            <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                                {chat.shipment.importer}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-full border-solid border-b-0 border-[1px] border-black">
                                          <div className="flex  text-[7px]">
                                            <div className="w-full border-r-[1px] border-solid border-black">
                                              <p>Address</p>
                                            </div>
                                            <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                                {chat.shipment.toAddress}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-full border-solid border-b-0 border-[1px] border-black">
                                          <div className="flex  text-[7px]">
                                            <div className="w-full border-r-[1px] border-solid border-black"></div>
                                            <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                                {chat.shipment.toAddress2}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-full border-solid border-b-0 border-[1px] border-black h-[12px]">
                                          <div className="flex  text-[7px]">
                                            <div className="w-full border-r-[1px] border-solid border-black"></div>
                                            <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                                {chat.shipment.toAddress3 || '.'}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-full border-solid border-b-0 border-[1px] border-black">
                                          <div className="flex  text-[7px]">
                                            <div className="w-full border-r-[1px] border-solid border-black">
                                              <p>City / province</p>
                                            </div>
                                            <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                                {chat.shipment.toCity}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-full border-solid border-b-0 border-[1px] border-black">
                                          <div className="flex  text-[7px]">
                                            <div className="w-full border-r-[1px] border-solid border-black">
                                              <p>Town</p>
                                            </div>
                                            <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                                {chat.shipment.toTown}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-full border-solid border-b-0 border-[1px] border-black">
                                          <div className="flex  text-[7px]">
                                            <div className="w-full border-r-[1px] border-solid border-black">
                                              <p>State</p>
                                            </div>
                                            <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                                {chat.shipment.toState}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-full border-solid border-b-0 border-[1px] border-black">
                                          <div className="flex  text-[7px]">
                                            <div className="w-full border-r-[1px] border-solid border-black">
                                              <p>Postal code</p>
                                            </div>
                                            <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                                {chat.shipment.toPostal}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-full border-solid border-b-0 border-[1px] border-black">
                                          <div className="flex  text-[7px]">
                                            <div className="w-full border-r-[1px] border-solid border-black">
                                              <p>Country</p>
                                            </div>
                                            <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                                {chat.shipment.toCountry}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-full border-solid border-b-0 border-[1px] border-black">
                                          <div className="flex  text-[7px]">
                                            <div className="w-full border-r-[1px] border-solid border-black">
                                              <p>Contct name</p>
                                            </div>
                                            <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                                {chat.shipment.toName}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-full border-solid border-b-0 border-[1px] border-black">
                                          <div className="flex  text-[7px]">
                                            <div className="w-full border-r-[1px] border-solid border-black">
                                              <p>Contact number</p>
                                            </div>
                                            <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                                {chat.shipment.toPhone}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-full border-solid border-b-0 border-[1px] border-black">
                                          <div className="flex  text-[7px]">
                                            <div className="w-full border-r-[1px] border-solid border-black">
                                              <p>Email address</p>
                                            </div>
                                            <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                                {chat.shipment.toEmail}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-full border-solid border-b-[1px] border-[1px] border-black">
                                          <div className="flex  text-[7px]">
                                            <div className="w-full border-r-[1px] border-solid border-black">
                                              <p>IOSS / TIN / EORI</p>
                                            </div>
                                            <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                              <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                                {chat.shipment.eori || '.'}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                            <div className="mt-2.5">
                              {currentStep > 13 && (
                                <div className="font-semibold text-[9px] mb-0.5 text-center">
                                  <WordGeneratorEffect
                                    isSeen={chat.isSeen}
                                    isFontStyle={false}
                                    text="FULL DESCRIPTION OF GOODS"
                                    delay={50}
                                    isColor={false}
                                    onComplete={() => {
                                      handleEffectComplete(chat.id);
                                    }}
                                    isMultiColor={false}
                                  />
                                </div>
                              )}
                              {currentStep > 14 && (
                                <div className="w-full border-solid border-[1px] border-black fadebox-stg">
                                  {chat.shipment.invoiceValue?.map((el, i) => (
                                    <div className="" style={{ borderBottom: '1px solid' }}>
                                      <div className="flex" style={{ borderBottom: '1px solid black' }}>
                                        <div className="p-0.5 border-solid border-black text-[8px] w-[40%] font-semibold border-r-[1px]">
                                          <p>Item {i + 1}</p>
                                        </div>
                                        <div className=" text-[8px] w-full">
                                          <div className="flex">
                                            <div className="p-0.5 border-solid border-black text-[8px]  text-center w-[20%] border-r-[1px]">
                                              <p>PACKING</p>
                                            </div>
                                            <div className="p-0.5 border-solid border-black text-[8px] border-r-[1px] text-center w-[20%]">
                                              <p>Q.TY</p>
                                            </div>
                                            <div className="p-0.5 border-solid border-black text-[8px] border-r-[1px] text-center w-[20%]">
                                              <p>UNIT</p>
                                            </div>
                                            <div className="p-0.5 border-solid border-black text-[8px] border-r-[1px] text-center w-[20%]">
                                              <p>UNIT PRICE</p>
                                            </div>
                                            <div className="p-0.5 border-solid border-black text-[8px]  text-center w-[20%]">
                                              <p>S.TOTAL (USD)</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex" style={{ borderBottom: '1px solid black' }}>
                                        <div className="p-0.5 border-solid border-black text-[8px] border-r-[1px] w-[40%] font-semibold">
                                          <p>{el.itemCommonity}</p>
                                        </div>
                                        <div className="border-solid border-black text-[8px] w-full">
                                          <div className="flex">
                                            <div className="p-0.5 border-solid border-black text-[8px] border-r-[1px] text-center w-[20%]">
                                              <p>{el.itemPackType}</p>
                                            </div>
                                            <div className="p-0.5 border-solid border-black text-[8px] border-r-[1px] text-center w-[20%]">
                                              <p>{el.itemQuantity}</p>
                                            </div>
                                            <div className="p-0.5 border-solid border-black text-[8px] border-r-[1px] text-center w-[20%]">
                                              <p>{el.itemUnit}</p>
                                            </div>
                                            <div className="p-0.5 border-solid border-black text-[8px] border-r-[1px] text-center w-[20%]">
                                              <p>{el.itemUnitPrice}</p>
                                            </div>
                                            <div className="p-0.5 border-solid border-black text-[8px]  text-center w-[20%]">
                                              <p>{el.itemValue}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex">
                                        <div className="p-0.5 border-solid border-black text-[8px] border-r-[1px] w-[40%]">
                                          <p>Origin</p>
                                        </div>
                                        <div className="border-solid border-black text-[8px] w-full">
                                          <div className="p-0.5 border-solid border-black text-[8px] px-1">
                                            <p>{el.itemCountryOrigin}</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex mt-[-4px]">
                                        <div className="p-0.5 border-solid border-black text-[8px] border-r-[1px] w-[40%]">
                                          <p>HS Code</p>
                                        </div>
                                        <div className="border-solid border-black text-[8px] w-full">
                                          <div className="p-0.5 border-solid border-black text-[8px] px-1">
                                            <p>{el.itemHsCode}</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex mt-[-4px]">
                                        <div className="p-0.5 border-solid border-black text-[8px] border-r-[1px] w-[40%]">
                                          <p>Brand name</p>
                                        </div>
                                        <div className="border-solid border-black text-[8px] w-full">
                                          <div className="p-0.5 border-solid border-black text-[8px] px-1">
                                            <p>{el.itemBrand}</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex mt-[-4px]">
                                        <div className="p-0.5 border-solid border-black text-[8px] border-r-[1px] w-[40%]">
                                          <p>Ingredients</p>
                                        </div>
                                        <div className="border-solid border-black text-[8px] w-full">
                                          <div className="p-0.5 border-solid border-black text-[8px] px-1">
                                            <p>{el.itemIngredients}</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex mt-[-4px]">
                                        <div className="p-0.5 border-solid border-black text-[8px] border-r-[1px] w-[40%]">
                                          <p>Purpose</p>
                                        </div>
                                        <div className="border-solid border-black text-[8px] w-full">
                                          <div className="p-0.5 border-solid border-black text-[8px] px-1">
                                            <p>{el.itemPurpose}</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex mt-[-4px]">
                                        <div className="p-0.5 border-solid border-black text-[8px] border-r-[1px] w-[40%]">
                                          <p>Manufacture</p>
                                        </div>
                                        <div className="border-solid border-black text-[8px] w-full">
                                          <div className="p-0.5 border-solid border-black text-[8px] px-1">
                                            <p>{el.itemManufact}</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex mt-[-4px]">
                                        <div className="p-0.5 border-solid border-black text-[8px] border-r-[1px] w-[40%]">
                                          <p>Manufacture address</p>
                                        </div>
                                        <div className="border-solid border-black text-[8px] w-full">
                                          <div className="p-0.5 border-solid border-black text-[8px] px-1">
                                            <p>{el.itemManufactAddress}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                              {currentStep > 14 && (
                                <div className="text-[7px] font-semibold mt-1">
                                  <WordGeneratorEffect
                                    isSeen={chat.isSeen}
                                    isFontStyle={false}
                                    text={`I ${chat.shipment.fromName} .Declare that the articles here in specified are to the best of
                                  my knowledgeand and belief Shipment was exported from VietNam under term with the true
                                  quantity, prices, value and manufacturer ${chat.shipment.usage}.`}
                                    delay={50}
                                    isColor={false}
                                    onComplete={() => {
                                      handleEffectComplete(chat.id);
                                    }}
                                    isMultiColor={false}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {currentStep > 15 && (
                      <div className="text-xl font-semibold mt-2.5 text-black pt-3">
                        <TitleWordEffect
                          isSeen={chat.isSeen}
                          text="Shipping marks"
                          onComplete={() => {
                            handleEffectComplete(chat.id);
                          }}
                        />
                      </div>
                    )}
                    {currentStep > 16 && (
                      <div className="mt-2 w-full  gap-2.5 flex flex-wrap">
                        {packaging.map((_, i) => (
                          <div
                            className="bg-white border-solid border-[1px] border-black cursor-pointer fadebox-stg"
                            key={`${_.packageLength}-${_.packageWidth}-${_.packageHeight}-${_.packageWeight}-${_.packageCharge}-${i}`}
                          >
                            <div className="flex border-b-[1px] border-solid border-black">
                              <div className="w-[50px] flex-shrink-0 border-r-[1px] border-solid p-2 border-black">
                                <p>{chat.shipment.shipmentType}</p>
                              </div>
                              <div className="w-full border-r-[1px] border-solid border-black p-2 font-semibold">
                                <p>{chat.shipment.hawb}</p>
                              </div>
                              <div className="p-2 flex-shrink-0">
                                <BsQrCode size={30} />
                              </div>
                            </div>
                            <div className="flex border-b-[1px] border-solid border-black">
                              <div className="w-full  flex items-center justify-center flex-col">
                                <p className="border-b-[1px] border-solid text-center w-full border-black text-[5px]">
                                  Shipment information
                                </p>
                                <div className="flex items-center text-[6px] w-full">
                                  <div className="w-full flex flex-col items-center justify-center">
                                    <p>Packages</p>
                                    <p>
                                      {i + 1}/{chat.shipment.packageValue?.length}
                                    </p>
                                  </div>
                                  <div className="w-full flex flex-col items-center justify-center">
                                    <p>Gross</p>
                                    <p>{_.packageWeight}</p>
                                  </div>
                                  <div className="w-full flex flex-col items-center justify-center">
                                    <p>Chargeable</p>
                                    <p>{_.packageCharge}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="w-full border-l-[1px] border-solid border-black flex items-center justify-center flex-col">
                                <p className="border-b-[1px] border-solid text-center w-full border-black text-[5px]">
                                  Dimensions
                                </p>
                                <div className="flex items-center text-[6px] w-full">
                                  <div className="w-full flex flex-col items-center justify-center">
                                    <p>Length</p>
                                    <p>{_.packageLength}</p>
                                  </div>
                                  <div className="w-full flex flex-col items-center justify-center">
                                    <p>Width</p>
                                    <p>{_.packageWidth}</p>
                                  </div>
                                  <div className="w-full flex flex-col items-center justify-center">
                                    <p>Height</p>
                                    <p>{_.packageHeight}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex mt-0.5">
                              <div className="w-full text-[7px]">
                                <p>Importer / Company</p>
                                <p>Address</p>
                                <p>Address 2</p>
                                <p>Town</p>
                                <p>City</p>
                                <p>State</p>
                                <p>Postal code</p>
                                <p>Country</p>
                                <p>Telephone</p>
                                <p>Contact name</p>
                              </div>
                              <div className="w-full text-black text-[7px] font-semibold">
                                <p>{chat.shipment.importer}</p>
                                <p>{chat.shipment.toAddress}</p>
                                <p>{chat.shipment.toAddress2}</p>
                                <p>{chat.shipment.toTown}</p>
                                <p>{chat.shipment.toCity}</p>
                                <p>{chat.shipment.toState}</p>
                                <p>{chat.shipment.toPostal}</p>
                                <p>{chat.shipment.toCountry}</p>
                                <p>{chat.shipment.toPhone}</p>
                                <p>{chat.shipment.toName}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    {currentStep > 16 && (
                      <div className="mt-5 flex items-center flex-wrap gap-1.5">
                        <button
                          type="submit"
                          className={`retriveShipment1 p-[1px] rounded-full active:scale-95 ${
                            chat.isBooking
                              ? 'cursor-not-allowed  bg-zinc-200'
                              : 'cursor-pointer bg-gradient-to-tr from-green-600 to-[darkblue]'
                          }`}
                        >
                          <div
                            className={`p-2  px-4 rounded-full hover:bg-zinc-100 active:bg-zinc-200 select-none  transition-[98s] ${
                              chat.isBooking ? 'bg-zinc-200' : 'bg-white'
                            }`}
                          >
                            <p
                              className={` ${
                                chat.isBooking
                                  ? 'text-zinc-500'
                                  : 'font-bold text-transparent bg-clip-text bg-gradient-to-tr from-emerald-500 to-blue-600'
                              }`}
                            >
                              {chat.isBooking ? 'Vận đơn đã được tạo thành công' : 'Tạo vận đơn'}
                            </p>
                          </div>
                        </button>
                        {!chat.isBooking && (
                          <div
                            className="bg-gradient-to-tr from-lime-500 to-teal-500 p-[1px] rounded-full retriveShipment1 active:scale-95 cursor-pointer"
                            onClick={() => {
                              if (chat.isPending) {
                                handleDeleteSingleShipment(chat.shipment.hawb, chat.id, chat.shipment.belongTo);
                              } else {
                                handleAddSingleShipment(chat.shipment, chat.id);
                              }
                            }}
                          >
                            <div className="p-2 bg-white px-4 rounded-full hover:bg-zinc-100 active:bg-zinc-200 select-none transition-[98s]">
                              <p className="font-bold text-transparent bg-clip-text bg-gradient-to-tr from-lime-500 to-teal-500">
                                {chat.isPending ? 'Huỷ khỏi hàng chờ' : 'Thêm đơn vào hàng chờ'}
                              </p>
                            </div>
                          </div>
                        )}
                        {chat.isBooking && (
                          <div
                            className="retriveShipment1 bg-gradient-to-tr from-lime-500 to-teal-500 p-[1px] rounded-full active:scale-95"
                            onClick={() => {
                              navigate(`/shipment/detail/${chat.shipment.hawb}`);
                            }}
                          >
                            <div className="p-2 bg-white px-4 rounded-full hover:bg-zinc-100 active:bg-zinc-200 select-none cursor-pointer transition-[98s]">
                              <p className="font-bold text-transparent bg-clip-text bg-gradient-to-tr from-lime-500 to-teal-500">
                                Quản lý vận đơn
                              </p>
                            </div>
                          </div>
                        )}
                        <div
                          className="retriveShipment1 bg-gradient-to-tr from-rose-500 to-pink-500 p-[1px] rounded-full active:scale-95 cursor-pointer select-none"
                          onClick={() => {
                            handleDeleteShipment(chat.id);
                          }}
                        >
                          <div className="p-2 bg-white px-4 rounded-full hover:bg-zinc-100 active:bg-zinc-200 select-none cursor-pointer transition-[98s]">
                            <p className="font-bold text-transparent bg-clip-text bg-gradient-to-tr from-pink-500 to-rose-500">
                              Xoá đơn
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    {currentStep > 16 && (
                      <div className="mt-5">
                        <WordGeneratorEffect
                          isSeen={chat.isSeen}
                          isFontStyle={isFontStyle}
                          text='Khi nhấn vào "Tạo vận đơn", anh chị đã đồng ý với các chính sách và công thức tính cước vận chuyển
                    quốc tế của chúng tôi.'
                          delay={50}
                          isColor={true}
                          onComplete={() => {
                            handleEffectComplete(chat.id);
                          }}
                          isMultiColor={isMultiColor}
                        />
                      </div>
                    )}
                    {currentStep > 17 && (
                      <div className="mt-5">
                        <WordGeneratorEffect
                          isSeen={chat.isSeen}
                          isFontStyle={isFontStyle}
                          text={chat?.subText[4].value || 'Nếu bạn cần hỗ trợ thêm điều gì, hãy cho tôi biết nhé!'}
                          delay={50}
                          isColor={true}
                          onComplete={() => {
                            handleEffectCompleteLast(chat.id);
                          }}
                          isMultiColor={isMultiColor}
                        />
                      </div>
                    )}
                  </form>
                )}
                <div className="w-[40px] h-[40px] ml-5 flex-shrink-0"></div>
              </div>
            )}
            {chat.sender === user.uid && (
              <div className="ml-auto group mr-[60px] my-3 relative flex items-start">
                <div className=" p-2  group-hover:flex hidden rounded-tl-[20px] rounded-tr-[20px]">
                  <div className="ml-auto flex items-center gap-1 mr-2 select-none">
                    <CopyToClipboard text={chat.text}>
                      <div className="cursor-pointer hover:opacity-70 active:opacity-50 w-[30px] h-[30px] rounded-full flex items-center justify-center hover:bg-zinc-200 bg-white">
                        <IoCopyOutline size={23} className="p-0.5" />
                      </div>
                    </CopyToClipboard>
                    <div
                      className="cursor-pointer hover:opacity-70 active:opacity-50 w-[30px] h-[30px] rounded-full flex items-center justify-center hover:bg-zinc-200 bg-white"
                      onClick={() => {
                        handleDeleteShipment(chat.id);
                      }}
                    >
                      <MdOutlineModeEdit size={23} className="p-0.5" />
                    </div>
                    <div
                      className="cursor-pointer hover:opacity-70 active:opacity-50 w-[30px] h-[30px] rounded-full flex items-center justify-center hover:bg-zinc-200 bg-white"
                      onClick={() => {
                        handleDeleteShipment(chat.id);
                      }}
                    >
                      <MdOutlineClear size={23} className="p-0.5" />
                    </div>
                  </div>
                </div>
                <div
                  className={`p-2 px-[20px] max-w-[550px] rounded-[20px] flex flex-col bg-zinc-100  rounded-br-none`}
                >
                  <p style={{ color: 'black' }} className="break-words whitespace-pre-wrap">
                    {chat.text}
                  </p>
                </div>
              </div>
            )}
          </div>
        );
      })}
      {loading && (
        <div className="flex flex-col">
          <div className="flex items-start my-5">
            <div className="w-[40px] h-[40px] rounded-full bg-white/50 flex items-center justify-center flex-shrink-0  mr-5 border-zinc-300 border-solid border-[1px]">
              <img src={skydartdog1} alt="" loading="lazy" className="w-[28px] h-[28px] rounded-full object-contain" />
            </div>
            <div className="p-1 mt-0.5">
              <p>Đang suy nghĩ...</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WordGenerating;
