import { TextField } from '@mui/material';
import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function InvoiceItem({ iv, index, setInvoiceValue, invoiceValue }) {
  let handleChangeInvoice = (i, e) => {
    const { name, value } = e.target;
    if (name === 'itemUnitPrice') {
      const newValue = value.replace(',', '.'); // Replace comma with dot for decimal input

      const decimalParts = newValue.split('.'); // Split the input into parts separated by the decimal point

      if (decimalParts.length > 1 && decimalParts[1].length > 2) {
        // Check if the input has more than two decimal places
        return;
      }

      // Update the invoiceValue state with the modified value
      setInvoiceValue((prevState) => {
        const updatedInvoiceValue = prevState.map((item, index) => {
          if (index === i) {
            return { ...item, [name]: newValue };
          }
          return item;
        });
        return updatedInvoiceValue;
      });
    } else if (name === 'itemUnit') {
      setInvoiceValue((prevState) => {
        const updatedInvoiceValue = prevState.map((item, index) => {
          if (index === i) {
            return {
              ...item,
              [name]: value,
              itemOtherUnit:
                value === 'PCS'
                  ? 'Chiếc'
                  : value === 'BOX'
                    ? 'Hộp'
                    : value === 'KG'
                      ? 'KG'
                      : value === 'PRS'
                        ? 'Đôi'
                        : value === 'SET'
                          ? 'Bộ'
                          : '',
            };
          }
          return item;
        });
        return updatedInvoiceValue;
      });
    } else {
      setInvoiceValue((prev) =>
        prev.map((iv, index) => (index === i ? { ...iv, [e.target.name]: e.target.value } : iv))
      );
    }
  };
  return (
    <div className="m-2.5 p-4 bg-slate-100 rounded-[9px]">
      <div className="flex items-center">
        <p className="text-lg">Item {index + 1}</p>
        <div className="ml-auto flex items-center gap-1.5">
          <div
            className="p-1.5 bg-slate-200 px-3.5 rounded-[5px] select-none cursor-pointer transition-[95s] hover:bg-slate-300 active:bg-slate-400 font-medium"
            onClick={() => {
              setInvoiceValue([...invoiceValue, iv]);
            }}
          >
            <p>Copy</p>
          </div>
          <div
            className="p-1.5 bg-rose-50 px-3.5 rounded-[5px] select-none cursor-pointer transition-[95s] hover:bg-rose-100 active:bg-rose-200 text-rose-500 font-medium"
            onClick={() => {
              setInvoiceValue(invoiceValue.filter((_, i) => i !== index));
            }}
          >
            <p>Remove Item</p>
          </div>
        </div>
      </div>
      <div className="w-full flex  gap-2.5 mt-1.5">
        <TextField
          label="Item commonity"
          multiline
          placeholder="Type in item name"
          required
          name="itemCommonity"
          value={iv.itemCommonity}
          onChange={(e) => handleChangeInvoice(index, e)}
          size="small"
          style={{ background: 'white', width: '32.5%' }}
        />
        <TextField
          label="Commonity in Vietnamese"
          required
          placeholder="Type in item name in vietnamese"
          name="itemVietnamCommonity"
          value={iv.itemVietnamCommonity}
          onChange={(e) => handleChangeInvoice(index, e)}
          size="small"
          multiline
          style={{ background: 'white', width: '32.5%' }}
        />
        <TextField
          label="Brand name"
          name="itemBrand"
          required
          value={iv.itemBrand}
          onChange={(e) => handleChangeInvoice(index, e)}
          placeholder="Type in brand name"
          size="small"
          style={{ background: 'white', width: '32.5%' }}
        />
      </div>
      <div className="w-full flex gap-2.5 mt-1.5">
        <select
          required
          className="w-[32.5%] px-2.5 rounded-[4px] border-gray-400 border-solid border-[1px] outline-none cursor-pointer transition-[95s] hover:bg-slate-200 active:bg-slate-300"
          value={iv.itemPackType}
          name="itemPackType"
          onChange={(e) => handleChangeInvoice(index, e)}
        >
          <option value="">Select packing type</option>
          <option value="Bag">Bag</option>
          <option value="Box">Box</option>
          <option value="Carton">Carton</option>
          <option value="Wooden Pack">Wooden Pack</option>
          <option value="Other">Other</option>
        </select>
        <TextField
          label="Ingredient"
          placeholder="Type in item ingredient"
          size="small"
          required
          name="itemIngredients"
          value={iv.itemIngredients}
          onChange={(e) => handleChangeInvoice(index, e)}
          style={{ background: 'white', width: '32.5%' }}
        />
        <TextField
          label="Usage"
          placeholder="Type in reason of usage"
          size="small"
          name="itemPurpose"
          value={iv.itemPurple}
          onChange={(e) => handleChangeInvoice(index, e)}
          style={{ background: 'white', width: '32.5%' }}
        />
      </div>
      <div className="w-full flex gap-2.5 mt-1.5">
        <TextField
          label="Country of origin"
          size="small"
          style={{ background: 'white', width: '32.5%' }}
          value={iv.itemCountryOrigin}
        />
        <TextField
          label="Manufacturer name"
          multiline
          required
          placeholder="Type in manufacturer name"
          name="itemManufact"
          value={iv.itemManufact}
          onChange={(e) => handleChangeInvoice(index, e)}
          size="small"
          style={{ background: 'white', width: '32.5%' }}
        />
        <TextField
          label="Manufacturer address"
          placeholder="Type in manufacturer address"
          size="small"
          name="itemManufactAddress"
          value={iv.itemManufactAddress}
          onChange={(e) => handleChangeInvoice(index, e)}
          multiline
          style={{ background: 'white', width: '32.5%' }}
        />
      </div>
      <div className="w-full flex gap-2.5 mt-1.5">
        <TextField
          label="HS Code"
          placeholder="Type in HS CODE"
          name="itemHsCode"
          value={iv.itemHsCode}
          onChange={(e) => handleChangeInvoice(index, e)}
          size="small"
          style={{ background: 'white', width: '32.5%' }}
        />
        <TextField
          label="FDA"
          value={iv.itemFda}
          name="itemFda"
          onChange={(e) => handleChangeInvoice(index, e)}
          placeholder="FDA content..."
          size="small"
          style={{ background: 'white', width: '32.5%' }}
        />
        <TextField
          label="Quantity"
          required
          type="number"
          placeholder="Amount of item"
          size="small"
          name="itemQuantity"
          value={iv.itemQuantity || ''}
          onChange={(e) => handleChangeInvoice(index, e)}
          style={{ background: 'white', width: '32.5%' }}
        />
      </div>
      <div className="w-full flex gap-2.5 mt-1.5">
        <select
          required
          className="w-[32.5%] px-2.5 rounded-[4px] border-gray-400 border-solid border-[1px] outline-none cursor-pointer transition-[95s] hover:bg-slate-200 active:bg-slate-300"
          value={iv.itemUnit}
          name="itemUnit"
          onChange={(e) => handleChangeInvoice(index, e)}
        >
          <option value="">Select item unit</option>
          <option value="PCS">PCS</option>
          <option value="BOX">BOX</option>
          <option value="KG">KG</option>
          <option value="PRS">PRS</option>
          <option value="SET">SET</option>
        </select>
        <TextField
          label="Unit price"
          required
          name="itemUnitPrice"
          type="number"
          value={iv.itemUnitPrice || ''}
          onChange={(e) => handleChangeInvoice(index, e)}
          placeholder="Type in item name"
          size="small"
          style={{ background: 'white', width: '32.5%' }}
        />
        <TextField
          label="Item total value"
          size="small"
          style={{ background: 'white', width: '32.5%' }}
          value={iv.itemValue.toFixed(2) + ' ' + 'USD' || '0.00' + 'USD'}
        />
      </div>
    </div>
  );
}
