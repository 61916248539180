export const countries = [
  { code: 'AD', label: 'Andorra', phone: '376', skydartZone: 'middleRange', label_vn: 'Andorra' },
  {
    code: 'AE',
    label: 'United Arab Emirates',
    phone: '971',
    skydartZone: 'middleRange',
    label_vn: 'Các Tiểu vương quốc Ả Rập Thống nhất',
  },
  { code: 'AF', label: 'Afghanistan', phone: '93', label_vn: 'Afghanistan' },
  { code: 'AG', label: 'Antigua and Barbuda', phone: '1-268', label_vn: 'Antigua và Barbuda' },
  { code: 'AI', label: 'Anguilla', phone: '1-264', label_vn: 'Anguilla' },
  { code: 'AL', label: 'Albania', phone: '355', skydartZone: 'middleRange', label_vn: 'Albania' },
  { code: 'AM', label: 'Armenia', phone: '374', skydartZone: 'middleRange', label_vn: 'Armenia' },
  { code: 'AO', label: 'Angola', phone: '244', skydartZone: 'middleRange', label_vn: 'Angola' },
  { code: 'AQ', label: 'Antarctica', phone: '672', label_vn: 'Nam Cực' },
  { code: 'AR', label: 'Argentina', phone: '54', skydartZone: 'highRange', label_vn: 'Argentina' },
  { code: 'AS', label: 'American Samoa', phone: '1-684', label_vn: 'Samoa thuộc Mỹ' },
  { code: 'AT', label: 'Austria', phone: '43', skydartZone: 'middleRange', label_vn: 'Áo' },
  { code: 'AU', label: 'Australia', phone: '61', suggested: true, label_vn: 'Úc' },
  { code: 'AW', label: 'Aruba', phone: '297', label_vn: 'Aruba' },
  { code: 'AX', label: 'Alland Islands', phone: '358', label_vn: 'Quần đảo Aland' },
  { code: 'AZ', label: 'Azerbaijan', phone: '994', skydartZone: 'middleRange', label_vn: 'Azerbaijan' },
  {
    code: 'BA',
    label: 'Bosnia and Herzegovina',
    phone: '387',
    skydartZone: 'middleRange',
    label_vn: 'Bosna và Herzegovina',
  },
  { code: 'BB', label: 'Barbados', phone: '1-246', label_vn: 'Barbados' },
  { code: 'BD', label: 'Bangladesh', phone: '880', skydartZone: 'middleRange', label_vn: 'Bangladesh' },
  { code: 'BE', label: 'Belgium', phone: '32', skydartZone: 'middleRange', label_vn: 'Bỉ' },
  { code: 'BF', label: 'Burkina Faso', phone: '226', skydartZone: 'middleRange', label_vn: 'Burkina Faso' },
  { code: 'BG', label: 'Bulgaria', phone: '359', skydartZone: 'middleRange', label_vn: 'Bulgaria' },
  { code: 'BH', label: 'Bahrain', phone: '973', skydartZone: 'middleRange', label_vn: 'Bahrain' },
  { code: 'BI', label: 'Burundi', phone: '257', skydartZone: 'middleRange', label_vn: 'Burundi' },
  { code: 'BJ', label: 'Benin', phone: '229', skydartZone: 'middleRange', label_vn: 'Benin' },
  { code: 'BL', label: 'Saint Barthelemy', phone: '590', label_vn: 'Saint Barthelemy' },
  { code: 'BM', label: 'Bermuda', phone: '1-441', label_vn: 'Bermuda' },
  { code: 'BN', label: 'Brunei Darussalam', phone: '673', label_vn: 'Brunei' },
  { code: 'BO', label: 'Bolivia', phone: '591', skydartZone: 'highRange', label_vn: 'Bolivia' },
  { code: 'BR', label: 'Brazil', phone: '55', skydartZone: 'highRange', label_vn: 'Brazil' },
  { code: 'BS', label: 'Bahamas', phone: '1-242', label_vn: 'Bahamas' },
  { code: 'BT', label: 'Bhutan', phone: '975', skydartZone: 'middleRange', label_vn: 'Bhutan' },
  { code: 'BV', label: 'Bouvet Island', phone: '47', label_vn: 'Đảo Bouvet' },
  { code: 'BW', label: 'Botswana', phone: '267', skydartZone: 'middleRange', label_vn: 'Botswana' },
  { code: 'BY', label: 'Belarus', phone: '375', skydartZone: 'middleRange', label_vn: 'Belarus' },
  { code: 'BZ', label: 'Belize', phone: '501', label_vn: 'Belize' },
  { code: 'CA', label: 'Canada', phone: '1', skydartZone: 'highRange', label_vn: 'Canada' },
  { code: 'CC', label: 'Cocos (Keeling) Islands', phone: '61', label_vn: 'Quần đảo Cocos (Keeling)' },
  {
    code: 'CD',
    label: 'Democratic Republic of the Congo',
    phone: '243',
    skydartZone: 'highRange',
    label_vn: 'Cộng hòa Dân chủ Congo',
  },
  {
    code: 'CF',
    label: 'Central African Republic',
    phone: '236',
    skydartZone: 'middleRange',
    label_vn: 'Cộng hòa Trung Phi',
  },
  { code: 'CG', label: 'Congo', phone: '242', skydartZone: 'middleRange', label_vn: 'Congo' },
  { code: 'CH', label: 'Switzerland', phone: '41', skydartZone: 'highRange', label_vn: 'Thụy Sĩ' },
  { code: 'CI', label: 'Ivory Coast', phone: '225', skydartZone: 'middleRange', label_vn: 'Bờ Biển Ngà' },
  { code: 'CK', label: 'Cook Islands', phone: '682', label_vn: 'Quần đảo Cook' },
  { code: 'CL', label: 'Chile', phone: '56', skydartZone: 'highRange', label_vn: 'Chile' },
  { code: 'CM', label: 'Cameroon', phone: '237', skydartZone: 'middleRange', label_vn: 'Cameroon' },
  { code: 'CN', label: 'China', phone: '86', skydartZone: 'highRange', label_vn: 'Trung Quốc' },
  { code: 'CO', label: 'Colombia', phone: '57', skydartZone: 'highRange', label_vn: 'Colombia' },
  { code: 'CR', label: 'Costa Rica', phone: '506', skydartZone: 'middleRange', label_vn: 'Costa Rica' },
  { code: 'CU', label: 'Cuba', phone: '53', label_vn: 'Cuba' },
  { code: 'CV', label: 'Cape Verde', phone: '238', label_vn: 'Cape Verde' },
  { code: 'CW', label: 'Curacao', phone: '599', label_vn: 'Curaçao' },
  { code: 'CX', label: 'Christmas Island', phone: '61', label_vn: 'Đảo Giáng Sinh' },
  { code: 'CY', label: 'Cyprus', phone: '357', skydartZone: 'middleRange', label_vn: 'Síp' },
  { code: 'CZ', label: 'Czech Republic', phone: '420', skydartZone: 'middleRange', label_vn: 'Cộng hòa Séc' },
  { code: 'DE', label: 'Germany', phone: '49', skydartZone: 'highRange', label_vn: 'Đức' },
  { code: 'DJ', label: 'Djibouti', phone: '253', skydartZone: 'middleRange', label_vn: 'Djibouti' },
  { code: 'DK', label: 'Denmark', phone: '45', skydartZone: 'highRange', label_vn: 'Đan Mạch' },
  { code: 'DM', label: 'Dominica', phone: '1-767', label_vn: 'Dominica' },
  {
    code: 'DO',
    label: 'Dominican Republic',
    phone: '1-809',
    skydartZone: 'middleRange',
    label_vn: 'Cộng hòa Dominican',
  },
  { code: 'DZ', label: 'Algeria', phone: '213', skydartZone: 'highRange', label_vn: 'Algeria' },
  { code: 'EC', label: 'Ecuador', phone: '593', skydartZone: 'highRange', label_vn: 'Ecuador' },
  { code: 'EE', label: 'Estonia', phone: '372', skydartZone: 'middleRange', label_vn: 'Estonia' },
  { code: 'EG', label: 'Egypt', phone: '20', skydartZone: 'highRange', label_vn: 'Ai Cập' },
  { code: 'EH', label: 'Western Sahara', phone: '212', label_vn: 'Sahara Tây' },
  { code: 'ER', label: 'Eritrea', phone: '291', skydartZone: 'middleRange', label_vn: 'Eritrea' },
  { code: 'ES', label: 'Spain', phone: '34', skydartZone: 'highRange', label_vn: 'Tây Ban Nha' },
  { code: 'ET', label: 'Ethiopia', phone: '251', skydartZone: 'middleRange', label_vn: 'Ethiopia' },
  { code: 'FI', label: 'Finland', phone: '358', skydartZone: 'highRange', label_vn: 'Phần Lan' },
  { code: 'FJ', label: 'Fiji', phone: '679', label_vn: 'Fiji' },
  { code: 'FM', label: 'Micronesia', phone: '691', label_vn: 'Micronesia' },
  { code: 'FO', label: 'Faroe Islands', phone: '298', label_vn: 'Quần đảo Faroe' },
  { code: 'FR', label: 'France', phone: '33', skydartZone: 'highRange', label_vn: 'Pháp' },
  { code: 'GA', label: 'Gabon', phone: '241', skydartZone: 'middleRange', label_vn: 'Gabon' },
  { code: 'GB', label: 'United Kingdom', phone: '44', skydartZone: 'highRange', label_vn: 'Vương quốc Anh' },
  { code: 'GD', label: 'Grenada', phone: '1-473', label_vn: 'Grenada' },
  { code: 'GE', label: 'Georgia', phone: '995', skydartZone: 'middleRange', label_vn: 'Georgia' },
  { code: 'GF', label: 'French Guiana', phone: '594', label_vn: 'Guyane thuộc Pháp' },
  { code: 'GG', label: 'Guernsey', phone: '44', label_vn: 'Guernsey' },
  { code: 'GH', label: 'Ghana', phone: '233', skydartZone: 'middleRange', label_vn: 'Ghana' },
  { code: 'GI', label: 'Gibraltar', phone: '350', label_vn: 'Gibraltar' },
  { code: 'GL', label: 'Greenland', phone: '299', label_vn: 'Greenland' },
  { code: 'GM', label: 'Gambia', phone: '220', skydartZone: 'middleRange', label_vn: 'Gambia' },
  { code: 'GN', label: 'Guinea', phone: '224', skydartZone: 'middleRange', label_vn: 'Guinea' },
  { code: 'GP', label: 'Guadeloupe', phone: '590', label_vn: 'Guadeloupe' },
  { code: 'GQ', label: 'Equatorial Guinea', phone: '240', skydartZone: 'middleRange', label_vn: 'Guinea Xích đạo' },
  { code: 'GR', label: 'Greece', phone: '30', skydartZone: 'highRange', label_vn: 'Hy Lạp' },
  { code: 'GT', label: 'Guatemala', phone: '502', skydartZone: 'middleRange', label_vn: 'Guatemala' },
  { code: 'GU', label: 'Guam', phone: '1-671', label_vn: 'Guam' },
  { code: 'GW', label: 'Guinea-Bissau', phone: '245', skydartZone: 'middleRange', label_vn: 'Guinea-Bissau' },
  { code: 'GY', label: 'Guyana', phone: '592', label_vn: 'Guyana' },
  { code: 'HK', label: 'Hong Kong', phone: '852', label_vn: 'Hồng Kông' },
  { code: 'HM', label: 'Heard Island and McDonald Islands', phone: '61', label_vn: 'Đảo Heard và Quần đảo McDonald' },
  { code: 'HN', label: 'Honduras', phone: '504', skydartZone: 'middleRange', label_vn: 'Honduras' },
  { code: 'HR', label: 'Croatia', phone: '385', skydartZone: 'middleRange', label_vn: 'Croatia' },
  { code: 'HT', label: 'Haiti', phone: '509', skydartZone: 'middleRange', label_vn: 'Haiti' },
  { code: 'HU', label: 'Hungary', phone: '36', skydartZone: 'highRange', label_vn: 'Hungary' },
  { code: 'ID', label: 'Indonesia', phone: '62', skydartZone: 'highRange', label_vn: 'Indonesia' },
  { code: 'IE', label: 'Ireland', phone: '353', skydartZone: 'highRange', label_vn: 'Ireland' },
  { code: 'IL', label: 'Israel', phone: '972', skydartZone: 'middleRange', label_vn: 'Israel' },
  { code: 'IM', label: 'Isle of Man', phone: '44', label_vn: 'Đảo Man' },
  { code: 'IN', label: 'India', phone: '91', skydartZone: 'highRange', label_vn: 'Ấn Độ' },
  { code: 'IO', label: 'British Indian Ocean Territory', phone: '246', label_vn: 'Lãnh thổ Ấn Độ Dương thuộc Anh' },
  { code: 'IQ', label: 'Iraq', phone: '964', skydartZone: 'middleRange', label_vn: 'Iraq' },
  { code: 'IR', label: 'Iran', phone: '98', skydartZone: 'highRange', label_vn: 'Iran' },
  { code: 'IS', label: 'Iceland', phone: '354', skydartZone: 'highRange', label_vn: 'Iceland' },
  { code: 'IT', label: 'Italy', phone: '39', skydartZone: 'highRange', label_vn: 'Ý' },
  { code: 'JE', label: 'Jersey', phone: '44', label_vn: 'Jersey' },
  { code: 'JM', label: 'Jamaica', phone: '1-876', label_vn: 'Jamaica' },
  { code: 'JO', label: 'Jordan', phone: '962', skydartZone: 'middleRange', label_vn: 'Jordan' },
  { code: 'JP', label: 'Japan', phone: '81', skydartZone: 'highRange', label_vn: 'Nhật Bản' },
  { code: 'KE', label: 'Kenya', phone: '254', skydartZone: 'middleRange', label_vn: 'Kenya' },
  { code: 'KG', label: 'Kyrgyzstan', phone: '996', skydartZone: 'middleRange', label_vn: 'Kyrgyzstan' },
  { code: 'KH', label: 'Cambodia', phone: '855', skydartZone: 'middleRange', label_vn: 'Campuchia' },
  { code: 'KI', label: 'Kiribati', phone: '686', label_vn: 'Kiribati' },
  { code: 'KM', label: 'Comoros', phone: '269', skydartZone: 'middleRange', label_vn: 'Comoros' },
  { code: 'KN', label: 'Saint Kitts and Nevis', phone: '1-869', label_vn: 'Saint Kitts và Nevis' },
  { code: 'KP', label: 'North Korea', phone: '850', skydartZone: 'middleRange', label_vn: 'Triều Tiên' },
  { code: 'KR', label: 'Korea', phone: '82', skydartZone: 'highRange', label_vn: 'Hàn Quốc' },
  { code: 'KW', label: 'Kuwait', phone: '965', skydartZone: 'highRange', label_vn: 'Kuwait' },
  { code: 'KY', label: 'Cayman Islands', phone: '1-345', label_vn: 'Quần đảo Cayman' },
  { code: 'KZ', label: 'Kazakhstan', phone: '7', skydartZone: 'highRange', label_vn: 'Kazakhstan' },
  { code: 'LA', label: 'Laos', phone: '856', skydartZone: 'middleRange', label_vn: 'Lào' },
  { code: 'LB', label: 'Lebanon', phone: '961', skydartZone: 'middleRange', label_vn: 'Liban' },
  { code: 'LC', label: 'Saint Lucia', phone: '1-758', label_vn: 'Saint Lucia' },
  { code: 'LI', label: 'Liechtenstein', phone: '423', label_vn: 'Liechtenstein' },
  { code: 'LK', label: 'Sri Lanka', phone: '94', skydartZone: 'middleRange', label_vn: 'Sri Lanka' },
  { code: 'LR', label: 'Liberia', phone: '231', skydartZone: 'middleRange', label_vn: 'Liberia' },
  { code: 'LS', label: 'Lesotho', phone: '266', skydartZone: 'middleRange', label_vn: 'Lesotho' },
  { code: 'LT', label: 'Lithuania', phone: '370', skydartZone: 'highRange', label_vn: 'Lithuania' },
  { code: 'LU', label: 'Luxembourg', phone: '352', skydartZone: 'highRange', label_vn: 'Luxembourg' },
  { code: 'LV', label: 'Latvia', phone: '371', skydartZone: 'highRange', label_vn: 'Latvia' },
  { code: 'LY', label: 'Libya', phone: '218', skydartZone: 'middleRange', label_vn: 'Lybia' },
  { code: 'MA', label: 'Morocco', phone: '212', skydartZone: 'highRange', label_vn: 'Ma-rốc' },
  { code: 'MC', label: 'Monaco', phone: '377', label_vn: 'Monaco' },
  { code: 'MD', label: 'Moldova', phone: '373', skydartZone: 'middleRange', label_vn: 'Moldova' },
  { code: 'ME', label: 'Montenegro', phone: '382', label_vn: 'Montenegro' },
  { code: 'MF', label: 'Saint Martin', phone: '590', label_vn: 'Saint Martin' },
  { code: 'MG', label: 'Madagascar', phone: '261', skydartZone: 'middleRange', label_vn: 'Madagascar' },
  { code: 'MH', label: 'Marshall Islands', phone: '692', label_vn: 'Quần đảo Marshall' },
  { code: 'MK', label: 'North Macedonia', phone: '389', skydartZone: 'middleRange', label_vn: 'Macedonia Bắc' },
  { code: 'ML', label: 'Mali', phone: '223', skydartZone: 'middleRange', label_vn: 'Mali' },
  { code: 'MM', label: 'Myanmar', phone: '95', skydartZone: 'middleRange', label_vn: 'Myanmar' },
  { code: 'MN', label: 'Mongolia', phone: '976', skydartZone: 'middleRange', label_vn: 'Mông Cổ' },
  { code: 'MO', label: 'Macau', phone: '853', label_vn: 'Ma Cao' },
  { code: 'MP', label: 'Northern Mariana Islands', phone: '1-670', label_vn: 'Quần đảo Bắc Mariana' },
  { code: 'MQ', label: 'Martinique', phone: '596', label_vn: 'Martinique' },
  { code: 'MR', label: 'Mauritania', phone: '222', skydartZone: 'middleRange', label_vn: 'Mauritania' },
  { code: 'MS', label: 'Montserrat', phone: '1-664', label_vn: 'Montserrat' },
  { code: 'MT', label: 'Malta', phone: '356', skydartZone: 'highRange', label_vn: 'Malta' },
  { code: 'MU', label: 'Mauritius', phone: '230', skydartZone: 'middleRange', label_vn: 'Mauritius' },

  { code: 'MV', label: 'Maldives', phone: '960', skydartZone: 'middleRange', label_vn: 'Maldives' },
  { code: 'MW', label: 'Malawi', phone: '265', skydartZone: 'middleRange', label_vn: 'Malawi' },
  { code: 'MX', label: 'Mexico', phone: '52', skydartZone: 'highRange', label_vn: 'Mexico' },
  { code: 'MY', label: 'Malaysia', phone: '60', skydartZone: 'highRange', label_vn: 'Malaysia' },
  { code: 'MZ', label: 'Mozambique', phone: '258', skydartZone: 'middleRange', label_vn: 'Mozambique' },
  { code: 'NA', label: 'Namibia', phone: '264', skydartZone: 'middleRange', label_vn: 'Namibia' },
  { code: 'NC', label: 'New Caledonia', phone: '687', label_vn: 'New Caledonia' },
  { code: 'NE', label: 'Niger', phone: '227', skydartZone: 'middleRange', label_vn: 'Niger' },
  { code: 'NF', label: 'Norfolk Island', phone: '672', label_vn: 'Norfolk Island' },
  { code: 'NG', label: 'Nigeria', phone: '234', skydartZone: 'highRange', label_vn: 'Nigeria' },
  { code: 'NI', label: 'Nicaragua', phone: '505', skydartZone: 'middleRange', label_vn: 'Nicaragua' },
  { code: 'NL', label: 'Netherlands', phone: '31', skydartZone: 'highRange', label_vn: 'Hà Lan' },
  { code: 'NO', label: 'Norway', phone: '47', skydartZone: 'highRange', label_vn: 'Na Uy' },
  { code: 'NP', label: 'Nepal', phone: '977', skydartZone: 'middleRange', label_vn: 'Nepal' },
  { code: 'NR', label: 'Nauru', phone: '674', label_vn: 'Nauru' },
  { code: 'NU', label: 'Niue', phone: '683', label_vn: 'Niue' },
  { code: 'NZ', label: 'New Zealand', phone: '64', skydartZone: 'highRange', label_vn: 'New Zealand' },
  { code: 'OM', label: 'Oman', phone: '968', skydartZone: 'middleRange', label_vn: 'Oman' },
  { code: 'PA', label: 'Panama', phone: '507', skydartZone: 'middleRange', label_vn: 'Panama' },
  { code: 'PE', label: 'Peru', phone: '51', skydartZone: 'middleRange', label_vn: 'Peru' },
  { code: 'PF', label: 'French Polynesia', phone: '689', label_vn: 'Polynesia thuộc Pháp' },
  { code: 'PG', label: 'Papua New Guinea', phone: '675', skydartZone: 'middleRange', label_vn: 'Papua New Guinea' },
  { code: 'PH', label: 'Philippines', phone: '63', skydartZone: 'highRange', label_vn: 'Philippines' },
  { code: 'PK', label: 'Pakistan', phone: '92', skydartZone: 'highRange', label_vn: 'Pakistan' },
  { code: 'PL', label: 'Poland', phone: '48', skydartZone: 'highRange', label_vn: 'Ba Lan' },
  { code: 'PM', label: 'Saint Pierre and Miquelon', phone: '508', label_vn: 'Saint Pierre và Miquelon' },
  { code: 'PN', label: 'Pitcairn Islands', phone: '64', label_vn: 'Quần đảo Pitcairn' },
  { code: 'PR', label: 'Puerto Rico', phone: '1-787', label_vn: 'Puerto Rico' },
  { code: 'PT', label: 'Portugal', phone: '351', skydartZone: 'highRange', label_vn: 'Bồ Đào Nha' },
  { code: 'PW', label: 'Palau', phone: '680', label_vn: 'Palau' },
  { code: 'PY', label: 'Paraguay', phone: '595', skydartZone: 'middleRange', label_vn: 'Paraguay' },
  { code: 'QA', label: 'Qatar', phone: '974', skydartZone: 'highRange', label_vn: 'Qatar' },
  { code: 'RE', label: 'Réunion', phone: '262', label_vn: 'Réunion' },
  { code: 'RO', label: 'Romania', phone: '40', skydartZone: 'highRange', label_vn: 'Romania' },
  { code: 'RS', label: 'Serbia', phone: '381', skydartZone: 'highRange', label_vn: 'Serbia' },
  { code: 'RU', label: 'Russia', phone: '7', skydartZone: 'highRange', label_vn: 'Nga' },
  { code: 'RW', label: 'Rwanda', phone: '250', skydartZone: 'middleRange', label_vn: 'Rwanda' },
  { code: 'SA', label: 'Saudi Arabia', phone: '966', skydartZone: 'highRange', label_vn: 'Ả Rập Saudi' },
  { code: 'SB', label: 'Solomon Islands', phone: '677', label_vn: 'Quần đảo Solomon' },
  { code: 'SC', label: 'Seychelles', phone: '248', label_vn: 'Seychelles' },
  { code: 'SD', label: 'Sudan', phone: '249', skydartZone: 'middleRange', label_vn: 'Sudan' },
  { code: 'SE', label: 'Sweden', phone: '46', skydartZone: 'highRange', label_vn: 'Thụy Điển' },
  { code: 'SG', label: 'Singapore', phone: '65', skydartZone: 'highRange', label_vn: 'Singapore' },
  { code: 'SH', label: 'Saint Helena', phone: '290', label_vn: 'Saint Helena' },
  { code: 'SI', label: 'Slovenia', phone: '386', skydartZone: 'highRange', label_vn: 'Slovenia' },
  { code: 'SJ', label: 'Svalbard and Jan Mayen', phone: '47', label_vn: 'Svalbard và Jan Mayen' },
  { code: 'SK', label: 'Slovakia', phone: '421', skydartZone: 'highRange', label_vn: 'Slovakia' },
  { code: 'SL', label: 'Sierra Leone', phone: '232', skydartZone: 'middleRange', label_vn: 'Sierra Leone' },
  { code: 'SM', label: 'San Marino', phone: '378', label_vn: 'San Marino' },
  { code: 'SN', label: 'Senegal', phone: '221', skydartZone: 'middleRange', label_vn: 'Senegal' },
  { code: 'SO', label: 'Somalia', phone: '252', skydartZone: 'middleRange', label_vn: 'Somalia' },
  { code: 'SR', label: 'Suriname', phone: '597', skydartZone: 'middleRange', label_vn: 'Suriname' },
  { code: 'SS', label: 'South Sudan', phone: '211', skydartZone: 'middleRange', label_vn: 'Nam Sudan' },
  { code: 'ST', label: 'São Tomé and Príncipe', phone: '239', label_vn: 'São Tomé và Príncipe' },
  { code: 'SV', label: 'El Salvador', phone: '503', skydartZone: 'middleRange', label_vn: 'El Salvador' },
  { code: 'SX', label: 'Sint Maarten', phone: '1-721', label_vn: 'Sint Maarten' },
  { code: 'SY', label: 'Syria', phone: '963', skydartZone: 'highRange', label_vn: 'Syria' },
  { code: 'SZ', label: 'Eswatini', phone: '268', label_vn: 'Eswatini' },
  { code: 'TC', label: 'Turks and Caicos Islands', phone: '1-649', label_vn: 'Quần đảo Turks và Caicos' },
  { code: 'TD', label: 'Chad', phone: '235', skydartZone: 'middleRange', label_vn: 'Chad' },
  { code: 'TW', label: 'Taiwan', phone: '886', skydartZone: 'middleRange', label_vn: 'Taiwan' },
  {
    code: 'TF',
    label: 'French Southern and Antarctic Lands',
    phone: '262',
    label_vn: 'Lãnh thổ Nam và Antarctic của Pháp',
  },
  { code: 'TG', label: 'Togo', phone: '228', skydartZone: 'middleRange', label_vn: 'Togo' },
  { code: 'TH', label: 'Thailand', phone: '66', skydartZone: 'highRange', label_vn: 'Thái Lan' },
  { code: 'TJ', label: 'Tajikistan', phone: '992', skydartZone: 'middleRange', label_vn: 'Tajikistan' },
  { code: 'TK', label: 'Tokelau', phone: '690', label_vn: 'Tokelau' },
  { code: 'TL', label: 'Timor-Leste', phone: '670', skydartZone: 'middleRange', label_vn: 'Timor-Leste' },
  { code: 'TM', label: 'Turkmenistan', phone: '993', skydartZone: 'middleRange', label_vn: 'Turkmenistan' },
  { code: 'TN', label: 'Tunisia', phone: '216', skydartZone: 'highRange', label_vn: 'Tunisia' },
  { code: 'TO', label: 'Tonga', phone: '676', label_vn: 'Tonga' },
  { code: 'TR', label: 'Turkey', phone: '90', skydartZone: 'highRange', label_vn: 'Thổ Nhĩ Kỳ' },
  { code: 'TT', label: 'Trinidad and Tobago', phone: '1-868', label_vn: 'Trinidad và Tobago' },
  { code: 'TV', label: 'Tuvalu', phone: '688', label_vn: 'Tuvalu' },
  { code: 'TZ', label: 'Tanzania', phone: '255', skydartZone: 'middleRange', label_vn: 'Tanzania' },
  { code: 'UA', label: 'Ukraine', phone: '380', skydartZone: 'highRange', label_vn: 'Ukraine' },
  { code: 'UG', label: 'Uganda', phone: '256', skydartZone: 'middleRange', label_vn: 'Uganda' },
  { code: 'UM', label: 'U.S. Minor Outlying Islands', phone: '1', label_vn: 'Quần đảo phụ thuộc của Mỹ' },
  { code: 'US', label: 'United States', phone: '1', skydartZone: 'highRange', label_vn: 'USA' },
  { code: 'UY', label: 'Uruguay', phone: '598', skydartZone: 'middleRange', label_vn: 'Uruguay' },
  { code: 'UZ', label: 'Uzbekistan', phone: '998', skydartZone: 'middleRange', label_vn: 'Uzbekistan' },
  { code: 'VA', label: 'Vatican City', phone: '379', label_vn: 'Vatican' },
  { code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1-784', label_vn: 'Saint Vincent và Grenadines' },
  { code: 'VE', label: 'Venezuela', phone: '58', skydartZone: 'highRange', label_vn: 'Venezuela' },
  { code: 'VG', label: 'British Virgin Islands', phone: '1-284', label_vn: 'Quần đảo Virgin thuộc Anh' },
  { code: 'VI', label: 'U.S. Virgin Islands', phone: '1-340', label_vn: 'Quần đảo Virgin thuộc Mỹ' },
  { code: 'VN', label: 'Vietnam', phone: '84', skydartZone: 'highRange', label_vn: 'Việt Nam' },
  { code: 'VU', label: 'Vanuatu', phone: '678', label_vn: 'Vanuatu' },
  { code: 'WF', label: 'Wallis and Futuna', phone: '681', label_vn: 'Wallis và Futuna' },
  { code: 'WS', label: 'Samoa', phone: '685', label_vn: 'Samoa' },
  { code: 'YE', label: 'Yemen', phone: '967', skydartZone: 'middleRange', label_vn: 'Yemen' },
  { code: 'YT', label: 'Mayotte', phone: '262', label_vn: 'Mayotte' },
  { code: 'ZA', label: 'South Africa', phone: '27', skydartZone: 'highRange', label_vn: 'Nam Phi' },
  { code: 'ZM', label: 'Zambia', phone: '260', skydartZone: 'middleRange', label_vn: 'Zambia' },
  { code: 'ZW', label: 'Zimbabwe', phone: '263', skydartZone: 'middleRange', label_vn: 'Zimbabwe' },
];
