import React from 'react';
import { GoPackage } from 'react-icons/go';
import { MdOutlineClear } from 'react-icons/md';
import onelinebooking from './onelinebooking.jpeg';
import { useNavigate } from 'react-router-dom';
import basicbooking from './basicbooking.jpeg';
import speakerbooking from './speakerbooking.jpeg';
import { FaNoteSticky } from 'react-icons/fa6';
export default function BookingMenu({ user }) {
  const navigate = useNavigate();
  return (
    <div className="h-screen p-2.5 px-5 bg-gradient-to-br from-teal-50 via-purple-50 to-blue-50">
      <p className="text-xl font-extrabold tracking-wider text-transparent bg-clip-text bg-gradient-to-tr from-emerald-500 to-blue-600      drop-shadow-lg">
        FSC charge value will depend on which couriers that has been selected
      </p>
      <div className="bg-white h-[92.5%] overflow-auto mt-3  rounded-[20px]">
        <div className="p-6 pb-4 flex items-center gap-2.5 sticky top-0 bg-white z-10">
          <p className="text-2xl">Create shipment tools</p>
          <div
            className="flex items-center p-1.5 bg-slate-100 px-5 rounded-full cursor-pointer select-none text-slate-600 font-semibold hover:bg-slate-200 active:bg-slate-300 transition-[95s] ml-auto mr-1"
            onClick={() => {
              navigate('/drafts');
            }}
          >
            <p>Drafted shipment</p>
          </div>
        </div>
        <div className="p-6 pt-0 pb-2.5">
          <div className="p-4 bg-gradient-to-r from-teal-200 to-emerald-100 flex items-center rounded-[18px]">
            <div className="package-icon relative">
              <div className="package-box">
                <div className="box-face front"></div>
                <div className="box-face top"></div>
                <div className="box-face side"></div>
              </div>
              {/* <div className="paper-stick"></div> */}
            </div>
            <div className="ml-7 mr-2">
              <p className="font-bold text-[18px] ">Start shipping smarter today with us.</p>
              <p className="text-[15px] leading-5">
                Experience the future of automated order processing with ShipScript.
              </p>
            </div>
            <div className="ml-auto flex items-center">
              <div className="p-2 px-5 bg-emerald-500 text-white whitespace-nowrap font-semibold rounded-full text-[15px]">
                <p>Try now</p>
              </div>
              <div className="p-2.5 rounded-full hover:bg-emerald-200 cursor-pointer transition-[98s]">
                <MdOutlineClear size={25} />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center flex-col mt-2">
          <p className="text-[40px] font-medium ">Welcome, {user.exporter}</p>
          <p className="text-[18px] text-gray-700">Ready? Set. Ship! Let's jump right into things.</p>
        </div>
        <div className="flex items-center justify-center gap-1 my-5 ">
          <div className="flex flex-col items-center">
            <img
              src={onelinebooking}
              alt=""
              loading="lazy"
              className="w-[270px] h-[200px] object-contain select-none"
              draggable={false}
            />
            <p className="text-center text-[18px] w-[270px] break-words leading-6">
              Create shipments with just a single line of text
            </p>
            <div
              className="p-2.5 px-7 rounded-full bg-teal-100  w-fit text-[15px] font-semibold text-center mt-2.5 hover:bg-teal-200 active:bg-teal-300 transition-[98s] cursor-pointer select-none"
              onClick={() => {
                navigate('/create/text-generating');
              }}
            >
              <p>Paste a shipment</p>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <img
              src={basicbooking}
              alt=""
              loading="lazy"
              className="w-[270px] h-[200px] object-contain select-none"
              draggable={false}
            />
            <p className="text-center text-[18px] w-[270px] break-words leading-6">
              Create shipments with normal booking template
            </p>
            <div
              className="p-2.5 px-7 rounded-full bg-teal-100  w-fit text-[15px] font-semibold text-center mt-2.5 hover:bg-teal-200 active:bg-teal-300 transition-[98s] cursor-pointer select-none"
              onClick={() => {
                navigate('/create/basic');
              }}
            >
              <p>Create a shipment</p>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <img
              src={speakerbooking}
              alt=""
              loading="lazy"
              className="w-[270px] h-[200px] object-contain select-none"
              draggable={false}
            />
            <p className="text-center text-[18px] w-[270px] break-words leading-6">
              Tell us your destination and we will do the rest for you
            </p>
            <div
              className="p-2.5 px-7 rounded-full bg-teal-100  w-fit text-[15px] font-semibold text-center mt-2.5 hover:bg-teal-200 active:bg-teal-300 transition-[98s] cursor-pointer select-none"
              onClick={() => {
                navigate('/multi');
              }}
            >
              <p>Multiple shipping</p>
            </div>
          </div>
        </div>
        <div className="mt-[70px] p-2 flex items-center justify-center">
          <div className="flex items-center justify-center flex-col">
            <p className="text-[18px] font-medium mb-1">Other things that you may need</p>
            <div className="flex items-center">
              {/* <div className="p-2 flex items-center border-r-2 border-l-0 border-y-0 boder-slate-300 border-solid">
                <FaNoteSticky size={20} className="text-slate-300" />
                <p className="text-[14px] text-[darkblue] ml-3 hover:underline active:opacity-50 cursor-pointer select-none">
                  Nội quy gửi hàng
                </p>
              </div> */}
              {/* <div className="p-2 flex items-center border-r-2 border-l-0 border-y-0 boder-slate-300 border-solid">
                <FaNoteSticky size={20} className="text-slate-300" />
                <p className="text-[14px] text-[darkblue] ml-3 hover:underline active:opacity-50 cursor-pointer select-none">
                  Hướng dẫn gửi hàng
                </p>
              </div> */}
              {/* <div className="p-2 flex items-center border-r-2 border-l-0 border-y-0 boder-slate-300 border-solid">
                <FaNoteSticky size={20} className="text-slate-300" />
                <p className="text-[14px] text-[darkblue] ml-3 hover:underline active:opacity-50 cursor-pointer select-none">
                  Cước vận chuyển
                </p>
              </div> */}
              <div
                className="p-2 flex items-center border-r-2 border-l-0 border-y-0 boder-slate-300 border-solid"
                onClick={() => {
                  navigate('/multi');
                }}
              >
                <FaNoteSticky size={20} className="text-slate-300" />
                <p className="text-[14px] text-[darkblue] ml-3 hover:underline active:opacity-50 cursor-pointer select-none">
                  Multiship
                </p>
              </div>
              <div
                className="p-2 flex items-center border-r-2 border-l-0 border-y-0 boder-slate-300 border-solid"
                onClick={() => {
                  navigate('/tracking');
                }}
              >
                <FaNoteSticky size={20} className="text-slate-300" />
                <p className="text-[14px] text-[darkblue] ml-3 hover:underline active:opacity-50 cursor-pointer select-none">
                  Tra cứu vận đơn
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
