import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { IoCopyOutline } from 'react-icons/io5';
import { MdOutlineClear, MdOutlineMenuBook } from 'react-icons/md';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { Alert, Snackbar } from '@mui/material';
import { BiFoodMenu } from 'react-icons/bi';
export default function ShipScriptGuide({ handleShowGuide, user, handleShowKeyboard }) {
  const [displayCopy, setDisplayCopy] = useState(false);
  const [copyText, setCopyText] = useState('');
  const navigate = useNavigate();
  const formatArray = [
    {
      title: `👤 Người nhận: [Tên người nhận], ✉️ [Email người nhận], 📞 [Số điện thoại];  
🏠 [Địa chỉ giao hàng đầy đủ bao gồm quốc gia,thành phố, quận, huyện, tiểu bang, mã bưu chính], 🏬 gửi đến: [Đơn vị, tổ chức, công ty nhận hàng],
    
📏 [Dài] x [Rộng] x [Cao] | 📦 [Cân nặng] Kg
// quý khách vui lòng xuống dòng để thêm kiện hàng mới & xem thêm định dạng ở mục lục.\n

🧾 Chứng từ: [Tên mặt hàng] - [Tên mặt hàng bằng Tiếng Việt] ([Số lượng]) [Giá tiền trên đơn vị/đơn vị/NSX: ???,Địa chỉ nơi sản xuất/Hs code/Thành phần: ???/ Nhãn hiệu: ???/ FDA: ???/ BAG/ mục đích sử dụng: ???],\n
// sau dấu phẩy tiếp tục giữ nguyên định dạng để thêm mặt hàng mới.
    
📌 [dịch vụ, (WPX,DOX), mã phụ phí], eori: [Mã eori nếu có], 🚛 transport:[Phụ phí vận chuyển], 🎁 (GIFT,E-COMMERCE,RETURNED FOR MAINTENANCE,SAMPLE,COMMERCIAL),`,
      content: `📏 15 x 25 x 35 | 📦 10 Kg  
    📏 D: 25 x R: 14 x C: 22 cm, 📦 Nặng: 35 Kg  
    📏 L: 25 x W: 14 x H: 22 cm, 📦 Weight: 15 Kg  
    
    👤 Người nhận: Nguyen Van A, ✉️ n.a@gmail.com, 📞 0123456789;  
    🏠 123456 THE W3E ST, ELMONTE, CA, 72038,  
    USA, 🏬 gửi đến: ${user.exporter},  
    
    🧾 chứng từ: Belt - Thắt lưng (5) [2/pcs/NSX:Deedword,201 Ba Dinh/230922/Thành phần: 100% Cotton/ Nhãn hiệu: Gucci/ FDA: ???/ BAG/ use for: lam dep anh oi ], Shoes - Giày (14) [10/pcs/Manufacture:${user.exporter},${user.address}/502303/Ingredients: 100% thep/ Brand: Hong & Mai/ BOX/ mục đích: đi bộ cho có sức khoẻ],  
    
    📌 SDH WPX EXW eori: ???, 🚛 transport:20, 🎁 GIFT,`,
    },
    {
      title:
        '[Khối lượng] Kg / Dim [Dài]x[Rộng]x[Cao]cm\nD: [Dài] x R: [Rộng] x C: [Cao] cm, Nặng: [Cân nặng] Kg\nL: [Dài] x W: [Rộng] x H: [Cao] cm, Weight: [Cân nặng] Kg\nNgười nhận: [Tên người nhận], [Email], [Số điện thoại];\n[Địa chỉ đầy đủ]\n[Quốc gia điểm đến],\ninvoice: [Tên hàng hóa] - [Tên hàng hoá bằng tiếng việt] ([Số lượng]) [Số kiện/đơn vị tính/NSX: [Nhà sản xuất], [Địa chỉ sản xuất]/[Mã sản xuất]/\nThành phần: [Thành phần]/Nhãn hiệu: [Thương hiệu]/[Mã chứng nhận]/[Loại đóng gói]/use for: [Mục đích sử dụng] ],\n[Tên hàng hóa] ([Số lượng]) [Số kiện/đơn vị tính/Manufacture: [Nhà sản xuất], [Địa chỉ sản xuất]/[Mã sản xuất]/\nIngredients: [Thành phần]/Brand: [Thương hiệu]/[Loại đóng gói]/[Mục đích sử dụng] ],\nSDH [Mã vận đơn] eori: [Mã EORI nếu có],\ntransport: [Phí vận chuyển], [Loại hàng], To: [Tên công ty/đơn vị nhận hàng]',
      content: `23 Kg / Dim 25x14x22cm
        D: 25 x R: 14 x C: 22 cm, Nặng: 35 Kg 
        L: 25 x W: 14 x H: 22 cm, Weight: 15 Kg 
        Người nhận: Nguyen Van A,n.a@gmail.com, 0123456789;
        123456 THE W3E ST, ELMONTE, CA, 72038
        USA, 
        invoice: Belt - Thắt lưng (5) [2/pcs/NSX:Deedword,5b Pho Quang/230922/Thành phần: 100% Cotton/ Nhãn hiệu: Gucci/ FDA: ???/ BAG/ use for: lam dep anh oi ], Shoes - Giày (14) [10/pcs/Manufacture:Skydart Express,32 Lam Son/502303/Ingredients: 100% thep/ Brand: LV/ BOX/ mục đích: dưỡng da cho đẹp mắt],
        SDH WPX EXW eori: ???, transport:20, GIFT, gửi đến: Skydart Express`,
    },
    {
      title:
        'Gửi hàng đi [Quốc gia điểm đến], [Địa chỉ điểm đến], [Thành phố], [Tiểu bang,quận, huyện], [Mã bưu điện],liên hệ: [Tên người nhận], [Email người nhận], [Số điện thoại người nhận], [Dài]x[Rộng]x[Cao] - [Trọng lượng]kg, chứng từ: [Tên hàng hóa] - [Tên hàng hoá bằng Tiếng Việt] ([Số lượng]) [Giá tiền/đơn vị tính/NSX: [Nhà sản xuất], [Địa chỉ sản xuất]/[Mã sản xuất]/Thành phần: [Thành phần]/Nhãn hiệu: [Thương hiệu]/[Mã chứng nhận]/[Loại đóng gói]/use for: [Mục đích sử dụng] ], transport: [Chi phí vận chuyển], [Mục đích gửi], eori: [Mã EORI nếu có], Gửi đến: [Tên công ty/đơn vị nhận hàng]',
      content: `Tôi muốn gửi hàng đi Canada, địa chỉ điểm đến là 123456 THE W3E ST, ELMONTE, CA, 91732, USA, liên hệ: Nguyen Van Canh, nguyenvan.c@gmail.com, 2000123456, bao gồm 3 kiện hàng mỗi kiện như sau: 30x20x20 - 20kg, 40x40x10 - 2kg, 34x20x43 - 10kg, SDH WPX, chứng từ: Belt - Thắt lưng (5) [2/pcs/NSX:Deedword,5b Pho Quang/230922/Thành phần: 100% Cotton/ Nhãn hiệu: Gucci/ FDA: iowejfiqwfoiw iwoej/ BAG/ dùng cho: lam dep anh oi ], Shoes - Giày (14) [10/pcs/Manufacture:Skydart Express,32 Lam Son/502303/Thành phần: 100% thep/ Brand: LV/ BOX/ mục đích: dưỡng da cho đẹp mắt], transport:20, GIFT, eori:eoriValue???, gửi đến: ???`,
    },
    {
      title:
        'Receiver: [Full Name],[Email], [Phone Number], [Street Address], [City], [Postal Code] [Country], [Reason for export],\ninvoice: [Item Name] - [Item Name in Vietnamese] ([Quantity]) [[Unit Price/Currency]/Manufacturer: [Manufacturer],[Address]/[Date]/\nIngredients: [Ingredients]/ Brand: [Brand]/ FDA: [FDA Code]/ [Packaging]/ Use for: [Usage] ],\n[Item Name] ([Quantity]) [[Unit Quantity]/Manufacturer: [Manufacturer],[Address]/[Date]/\nIngredients: [Ingredients]/ Brand: [Brand]/ [Packaging]/ Use for: [Usage] ],\nL: [Length] x W: [Width] x H: [Height] cm, Weight: [Weight] Kg,\nL: [Length] x W: [Width] x H: [Height] cm, Weight: [Weight] Kg,\n[Service] [Shipment type] [Term], to: [Importer name/company name]',
      content: `Receiver: Ngo Thi B,ngo.b@gmail.com, 0239-209-318, 123456 THE W3E ST, ELMONTE, CA, 72038 Australia, GIFT, invoice: Belt - Thắt lưng (5) [2/pcs/NSX:Deedword,5b Ba Dinh/230922/Ingredients: 100% Cotton/ Brand: H&M/ FDA: ???/ BAG/ use for: beauty beauty ], Shoes - Giày (14) [10/pcs/Manufacture:Skydart Express,32 Lam Son/502303/Ingredients: 100% thep/ Brand: LV/ BOX/ use for: dưỡng da cho đẹp mắt], L: 25 x W: 14 x H: 22 cm, Weight: 15 Kg, L: 25 x W: 14 x H: 22 cm, Weight: 50 Kg, SDH WPX EXW, to: Skydart Express`,
    },
  ];
  function resetCopy(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setDisplayCopy(false);
  }
  const formatText = (text) => {
    return text.split('\n').map((line, index) => {
      if (line.trim().startsWith('//')) {
        return (
          <p key={index} className="text-gray-500 italic">
            {line}
          </p>
        );
      }
      return (
        <p key={index} className="whitespace-pre-wrap">
          {line}
        </p>
      );
    });
  };
  return (
    <div className="fixed inset-0 bg-black/80 flex items-center justify-center z-[10000] p-4">
      {displayCopy && (
        <Snackbar
          open={displayCopy}
          autoHideDuration={5000}
          onClose={resetCopy}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert elevation={5} variant="filled" onClose={resetCopy} severity="success">
            Sao chép {copyText}
          </Alert>
        </Snackbar>
      )}
      <OutsideClickHandler
        onOutsideClick={() => {
          handleShowGuide();
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          className="bg-gradient-to-b from-gray-900 to-black p-10 rounded-2xl shadow-2xl w-full max-w-3xl relative border border-gray-700 max-h-[calc(100vh-5rem)] overflow-auto"
        >
          <div
            className=" w-[45px] h-[45px] rounded-full flex items-center justify-center cursor-pointer text-white hover:bg-slate-700 active:bg-slate-600 ml-auto"
            onClick={() => {
              handleShowGuide();
            }}
          >
            <MdOutlineClear />
          </div>
          <div className="text-center">
            <h2 className="text-4xl font-extrabold text-white mb-6 uppercase tracking-widest">
              Chào mừng đến với ShipScript
            </h2>
            <p className="text-gray-300 text-lg leading-relaxed max-w-2xl mx-auto">
              ShipScript là công cụ hỗ trợ tạo vận đơn tự động chỉ với 1 dòng lệnh duy nhất. Được phát triển bởi đội ngũ
              của <span className="font-bold text-white">Skydart Express</span>. Chúng tôi hi vọng rằng sẽ mang lại cho
              bạn những hành trình vận chuyển nhanh chóng và hiệu quả hơn. Anh chị chỉ cần sao chép & dán (Copy & Paste)
              thông tin chi tiết của kiện hàng và hệ thống sẽ phân tích, xử lý và tạo đơn hàng ngay lập tức, giúp anh
              chị tiết kiệm thời gian và tối ưu năng suất khai thác hàng hoá.
            </p>
          </div>

          <div className="mt-8 p-6 bg-gray-800 rounded-lg shadow-md border border-gray-700">
            <h3 className="text-gray-100 text-xl font-semibold mb-3">Hướng dẫn sử dụng ShipScript</h3>
            <p className="text-gray-400 text-base leading-relaxed">
              Để hệ thống xử lý đơn hàng được trở nên chính xác, anh chị có thể cân nhắc nhập thông tin theo những định
              dạng sau:
            </p>
            {formatArray.map((item, index) => (
              <div className="mb-4">
                <div className="flex items-center mt-3">
                  {index !== 0 && <p className="text-white font-medium">Định dạng (mẫu) {index + 1}:</p>}
                  {index === 0 && <p className="text-white font-medium">Định dạng được đề xuất:</p>}
                  <CopyToClipboard
                    onCopy={() => {
                      setDisplayCopy(true);
                      setCopyText(`Mẫu ${index + 1}`);
                    }}
                    text={item.title}
                  >
                    <div className="p-1 flex items-center gap-1.5 text-purple-500 cursor-pointer select-none hover:opacity-70 active:opacity-50 ml-auto">
                      <IoCopyOutline size={20} />
                      <p className="text-[15px]">Sao chép mẫu</p>
                    </div>
                  </CopyToClipboard>
                  {index === 0 && (
                    <div
                      className="p-1 flex items-center gap-1.5 text-teal-500 cursor-pointer select-none hover:opacity-70 active:opacity-50 ml-1.5"
                      onClick={() => {
                        handleShowGuide();
                        handleShowKeyboard();
                      }}
                    >
                      <BiFoodMenu size={20} />
                      <p className="text-[15px]">Mục lục & từ khoá</p>
                    </div>
                  )}
                </div>
                <p className="bg-gray-900 text-gray-200 p-4 rounded-lg  font-mono text-sm border border-gray-700 break-words mt-1 whitespace-pre-line">
                  {formatText(item.title)}
                </p>
                <p className="text-gray-400 text-sm my-3">
                  <strong>Ví dụ:</strong>
                  <br />
                  <span className="text-gray-300 whitespace-pre-line font-mono">{item.content}</span>
                </p>
              </div>
            ))}
            <p className="text-gray-400 text-base leading-relaxed mt-4">
              Những định dạng phía trên là những mẫu mà ShipScript đã có thể tối ưu và cho ra được 1 vận đơn hoàn chỉnh
              với tỷ lệ chính xác 100%. Chúng tôi đang nỗ lực từng ngày để có thể xử lý đa dạng khuôn mẫu hơn và chúng
              tôi trân trọng mọi sự ủng hộ của quý khách hàng cũng như các nhà vận chuyển.
            </p>
            <p
              className="text-white hover:underline select-none hover:opacity-70 active:opacity-50 w-fit my-2 cursor-pointer"
              onClick={() => {
                navigate('/create/text-generating/booking-template');
              }}
            >
              Xem thêm định dạng
            </p>
            <p className="text-gray-400 text-base leading-relaxed mt-2">
              Ngoài ra, anh chị cũng có thể tự sáng tạo ra định dạng vận đơn của mình theo các mã mà chúng tôi hướng
              dẫn.
            </p>
          </div>

          <div className="mt-8 p-6 bg-gray-800 rounded-lg shadow-md border border-gray-700">
            <h3 className="text-gray-100 text-xl font-semibold mb-3">Lưu ý khi gửi hàng</h3>
            <ul className="text-gray-400 list-disc list-inside mt-3 space-y-2 text-sm">
              <li>Đảm bảo nhập đầy đủ và chính xác thông tin theo định dạng hướng dẫn.</li>
              <li>Giữ nguyên cấu trúc dòng, tránh thay đổi dấu ngăn cách hoặc xuống dòng không cần thiết.</li>
              <li>Thông tin người nhận cần chính xác tuyệt đối để tránh thất lạc đơn hàng.</li>
              <li>Địa chỉ giao hàng phải đầy đủ, bao gồm số nhà, đường, phường/xã, thành phố/quốc gia.</li>
              <li>Hóa đơn cần chi tiết, bao gồm số lượng, nhãn hiệu, thành phần và mục đích sử dụng.</li>
              <li>Nếu có yêu cầu đặc biệt (ví dụ: giao hàng ngoài giờ, không gọi trước), hãy ghi vào ghi chú.</li>
              <li>
                Vui lòng kiểm tra kỹ thông tin trước khi xác nhận gửi hàng để đảm bảo quy trình vận chuyển diễn ra thuận
                lợi.
              </li>
            </ul>
          </div>

          <div className="mt-8 flex justify-center gap-6">
            <button
              onClick={() => handleShowGuide()}
              className="px-8 py-3 bg-blue-600 text-white rounded-xl font-bold shadow-md hover:bg-blue-500 transition-all border border-blue-700"
            >
              Bắt đầu ngay
            </button>
            {/* <button
              onClick={() => navigate(-1)}
              className="px-8 py-3 bg-gray-700 text-gray-300 rounded-xl font-bold shadow-md hover:bg-gray-600 transition-all border border-gray-600"
            >
              Để sau
            </button> */}
          </div>
        </motion.div>
      </OutsideClickHandler>
    </div>
  );
}
