import React, { useState, useEffect, useMemo } from 'react';
import NewBookingHeader from './components/NewBookingHeader';
import NewBookingFilterBar from './components/NewBookingFilterBar';
import NewBookingGrid from './components/NewBookingGrid';
import ShippingMark from '../../../components/ShippingMark';
import StaffBill2 from '../../../components/StaffBill2';
import { db } from '../../../server/firebase';
import { useTable, useResizeColumns, useFlexLayout, useColumnOrder } from 'react-table';
import { useBlockLayout } from 'react-table/dist/react-table.development';
import { START_DATE, useDatepicker } from '@datepicker-react/hooks';

import { LinearProgress } from '@mui/material';
import { FcCheckmark } from 'react-icons/fc';
import Month1 from './components/Month1';
import DatepickerContext from './components/datepickerContext';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { AiOutlineMinus } from 'react-icons/ai';
import EditShipment from '../../components/EditShipment';
import DashboardEdit from '../../../customer/components/DashboardEdit';
export default function NewBooking({ user }) {
  // Date declared
  let newDate = new Date();
  const firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
  let date = (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();

  const month = (newDate.getMonth() + 1 < 10 ? '0' : '') + (newDate.getMonth() + 1);
  const year = newDate.getFullYear();
  let firstDayOfCurrentWeek = date - (newDate.getDay() < 10 ? '0' : '');
  let last = firstDayOfCurrentWeek + 6;
  let firstday = firstDayOfCurrentWeek - 6;

  let lastDayOfCurrentWeek = last < 10 ? `0${last}` : last;
  let firstDayOfLastWeek = firstday < 10 ? `0${firstday}` : firstday;
  const first = (firstDay.getDate() < 10 ? '0' : '') + firstDay.getDate();
  let yesterday = date - 1;
  let months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  let lastMonth = month - 1;
  let lastDay = new Date(year, lastMonth, 0);
  let firstDayOfYear = new Date(year, 0, 1);
  let lastPrevMonthDay = newDate.setMonth(newDate.getMonth(), 0);
  let prevMonth = lastMonth < 10 ? `0${lastMonth}` : lastMonth;

  // Variable declared
  const [loading, setLoading] = useState(false);
  const [dateStatus, setDateStatus] = useState('Period');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState(new Date().toISOString().substr(0, 10));
  const [searchInput, setSearchInput] = useState('');
  const [searchCus, setSearchCus] = useState('');
  const [searchHawb, setSearchHawb] = useState('');
  const [searchCountry, setSearchCountry] = useState('');
  const [searchType, setSearchType] = useState('');
  const [searchClass, setSearchClass] = useState('');
  const [searchCode, setSearchCode] = useState('');
  const [searchWeight, setSearchWeight] = useState('');
  const [searchPcs, setSearchPcs] = useState('');
  const [searchService, setSearchService] = useState('');
  const [searchSale, setSearchSale] = useState('');
  const [searchSin, setSearchSin] = useState('');
  const [searchDescription, setSearchDescription] = useState('');
  const [searchOps, setSearchOps] = useState('');
  const [searchArray, setSearchArray] = useState('');
  const [bookingType, setBookingType] = useState('');
  const [bookingTypeList, setBookingTypeList] = useState([]);
  const [displaySearchOption, setDisplaySearchOption] = useState(false);
  const [shipments, setShipments] = useState([]);
  const [selectedShipment, setSelectedShipment] = useState([]);
  const [selectedHeader, setSelectedHeader] = useState([]);
  const [displayBookingStatus, setDisplayBookingStatus] = useState(false);
  const [displayDate, setDisplayDate] = useState(false);
  const [bookingStatus, setBookingStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(20);
  const [pageNumberLimit, setPageNumberLimit] = useState(10);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const [maxPageLimit, setMaxPageLimit] = useState(10);
  const [displayEditScreen, setDisplayEditScreen] = useState(false);
  const [displayShipmentLabel, setDisplayShipmentLabel] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState({});
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    focusedInput: START_DATE,
  });

  const [sortClass, setSortClass] = useState(false);
  const [sortCustomer, setSortCustomer] = useState(false);
  const [sortHawb, setSortHawb] = useState(false);
  const [sortDate, setSortDate] = useState(false);
  const [sortCountry, setSortCountry] = useState(false);
  const [sortService, setSortService] = useState(false);
  const [sortType, setSortType] = useState(false);
  const [sortWeight, setSortWeight] = useState(false);
  const [sortPcs, setSortPcs] = useState(false);
  const [sortDescription, setSortDescription] = useState(false);
  const [sortValue, setSortValue] = useState(false);
  const [sortSin, setSortSin] = useState(false);
  const [sortSale, setSortSale] = useState(false);
  const [sortOps, setSortOps] = useState(false);
  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    focusedInput: dateRange.focusedInput,
    onDatesChange: handleDateChange,
  });
  function handleDateChange(data) {
    if (!data.focusedInput) {
      setDateRange({ ...data, focusedInput: START_DATE });
    } else {
      setDateRange(data);
    }
    // console.log(data);
  }
  // Get date
  useEffect(() => {
    let newDate = new Date();
    const firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
    const first = (firstDay.getDate() < 10 ? '0' : '') + firstDay.getDate();
    const date = (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();
    const month = (newDate.getMonth() + 1 < 10 ? '0' : '') + (newDate.getMonth() + 1);
    const year = newDate.getFullYear();
    setStartDate(`${year}-${month}-${first}`);
  }, []);
  const d = new Date(dateRange.startDate);
  const selectMonth = (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1);
  const selectDate = (d.getDate() < 10 ? '0' : '') + d.getDate();
  const selectYear = d.getFullYear();

  const e = new Date(dateRange.endDate);
  const selectEndMonth = (e.getMonth() + 1 < 10 ? '0' : '') + (e.getMonth() + 1);
  const selectEndDate = (e.getDate() < 10 ? '0' : '') + e.getDate();
  const selectEndYear = e.getFullYear();
  const currentYear = new Date().getFullYear();
  const handleApplyDate = () => {
    if (!dateRange.endDate) {
      setStartDate(`${selectYear}-${selectMonth}-${selectDate}`);
      setEndDate(`${selectYear}-${selectMonth}-${selectDate}`);
      setDisplayDate(false);
      setDateStatus('Period');
    } else {
      setStartDate(`${selectYear}-${selectMonth}-${selectDate}`);
      setEndDate(`${selectEndYear}-${selectEndMonth}-${selectEndDate}`);
      setDisplayDate(false);
      setDateStatus('Period');
    }
  };
  const handleShowDate = () => {
    setDisplayDate(!displayDate);
  };
  const handleCloseDate = () => {
    setDisplayDate(false);
  };
  const handleCloseEdit = () => {
    setDisplayEditScreen(false);
  };

  // Get booking type
  useEffect(() => {
    const bookingTypeArray = [
      { label: 'All booking', value: '', id: 0, lengthItem: 12 },
      { label: 'Labeled booking', value: 'Labeled booking', id: 1, lengthItem: 20 },
      { label: 'Un labeled booking', value: 'Un labeled booking', id: 2, lengthItem: 300 },
    ];
    setBookingTypeList(bookingTypeArray);
  }, []);
  const handleBookingStatus = () => {
    setDisplayBookingStatus(!displayBookingStatus);
  };
  const handleCloseBookingStatus = () => {
    setDisplayBookingStatus(false);
  };

  const startDay = new Date(startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  const endDay = new Date(endDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });

  // Handle search
  const handleSearchShipment = (e) => {
    setSearchInput(e.target.value);
  };

  // Handle booking type
  const handleBookingType = (value) => {
    setBookingType(value);
    setDisplayBookingStatus(false);
  };
  // Handle search shipment option
  const onSearchFocus = () => {
    setDisplaySearchOption(true);
  };

  const onCloseSearch = () => {
    setDisplaySearchOption(false);
  };

  const handleToday = () => {
    setStartDate(`${year}-${month}-${date}`);
    setEndDate(`${year}-${month}-${date}`);
    setDisplayDate(false);
    setDateStatus('Today');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleChangeCode = (e) => {
    setSearchCode(e.target.value);
  };

  const handleChangeInputColumn = (index, e) => {
    if (index === 2) {
      setSearchCus(e.target.value);
    } else if (index === 3) {
      setSearchHawb(e.target.value);
    } else if (index === 5) {
      setSearchCountry(e.target.value);
    } else if (index === 6) {
      setSearchService(e.target.value);
    } else if (index === 7) {
      setSearchType(e.target.value);
    } else if (index === 8) {
      setSearchWeight(e.target.value);
    } else if (index === 9) {
      setSearchPcs(e.target.value);
    } else if (index === 10) {
      setSearchDescription(e.target.value);
    } else if (index === 13) {
      setSearchSin(e.target.value);
    } else if (index === 14) {
      setSearchSale(e.target.value);
    } else if (index === 15) {
      setSearchOps(e.target.value);
    } else if (index === 1) {
      setSearchClass(e.target.value);
    }
  };

  const handleResetSearchColumn = (index) => {
    if (index === 1) {
      setSearchClass('');
    } else if (index === 2) {
      setSearchCus('');
    } else if (index === 3) {
      setSearchHawb('');
    } else if (index === 5) {
      setSearchCountry('');
    } else if (index === 6) {
      setSearchService('');
    } else if (index === 7) {
      setSearchType('');
    } else if (index === 8) {
      setSearchWeight('');
    } else if (index === 9) {
      setSearchPcs('');
    } else if (index === 10) {
      setSearchDescription('');
    } else if (index === 13) {
      setSearchSin('');
    } else if (index === 14) {
      setSearchSale('');
    } else if (index === 15) {
      setSearchOps('');
    }
  };

  const fetchMultipleSearched = (trackingArray) => {
    if (trackingArray[0] === '') {
      fetchShipment();
    } else {
      setLoading(true);
      db.collection('shipments')
        .where('hawb', 'in', trackingArray)
        .get()
        .then((snapshot) => {
          let data = snapshot.docs.map((doc) => doc.data());
          setShipments(
            data.map((s, i) => {
              let dhlAccount = '';
              let cleName = '';
              let clePhone = '';
              let cleEmail = '';
              let cleState = '';
              let pcsWeight =
                s.shipmentType === 'WPX' ? s.packageValue?.reduce((a, v) => (a = +a + +v.packageCharge), 0) : 0;
              let pcsLength =
                s.shipmentType === 'WPX' ? s.packageValue?.reduce((a, v) => (a = +a + +v.packageLength), 0) : 0;
              let pcsWidth =
                s.shipmentType === 'WPX' ? s.packageValue?.reduce((a, v) => (a = +a + +v.packageWidth), 0) : 0;
              let pcsHeight =
                s.shipmentType === 'WPX' ? s.packageValue?.reduce((a, v) => (a = +a + +v.packageHeight), 0) : 0;
              if (s.toCountry === 'United States') {
                dhlAccount = '611051446';
                cleName = 'C/O NVH TRADING SERVICES PTE LTD';
                clePhone = '65459990';
                cleEmail = 'cs@courierlink.com.sg';
              } else if (s.toCountry !== 'United States' && s.shipmentType === 'WPX') {
                dhlAccount = '610799051';
                cleName = 'C/O ANTRON SERVICES';
                clePhone = '65423395';
                cleState = s.toState;
                cleEmail = 'angie.antron@gmail.com';
              } else if (s.toCountry !== 'United States' && s.shipmentType === 'DOX') {
                dhlAccount = '610654958';
                cleName = 'C/O ANTRON SERVICES';
                clePhone = '65423395';
                cleState = s.toState;
                cleEmail = 'angie.antron@gmail.com';
              }

              let value = s.totalInvoiceValue;
              let updated = s.updated || 'false';
              let select = s.select || false;
              let multi = s.multi || false;
              let loading = false;
              let eamFormat = s.shipmentType === 'DOX' ? 'DOX' : 'NDX';
              let removeSkd = s.hawb.substring(3);
              let itemValue = s.invoiceValue[0]?.itemValue;
              let itemValue2 = s.invoiceValue[1]?.itemValue;
              let itemValue3 = s.invoiceValue[2]?.itemValue;
              let itemValue4 = s.invoiceValue[3]?.itemValue;
              let itemValue5 = s.invoiceValue[4]?.itemValue;
              let itemValue6 = s.invoiceValue[5]?.itemValue;
              let itemValue7 = s.invoiceValue[6]?.itemValue;
              let itemValue8 = s.invoiceValue[7]?.itemValue;
              let itemValue9 = s.invoiceValue[8]?.itemValue;
              let itemValue10 = s.invoiceValue[9]?.itemValue;
              let itemValue11 = s.invoiceValue[10]?.itemValue;
              let itemValue12 = s.invoiceValue[11]?.itemValue;
              let itemValue13 = s.invoiceValue[12]?.itemValue;
              let itemValue14 = s.invoiceValue[13]?.itemValue;
              let itemValue15 = s.invoiceValue[14]?.itemValue;
              let itemValue16 = s.invoiceValue[15]?.itemValue;
              let itemValue17 = s.invoiceValue[16]?.itemValue;
              let itemValue18 = s.invoiceValue[17]?.itemValue;
              let itemValue19 = s.invoiceValue[18]?.itemValue;
              let itemValue20 = s.invoiceValue[19]?.itemValue;
              let itemNo = s.invoiceValue[0]?.itemNo;
              let itemNo2 = s.invoiceValue[1]?.itemNo || '';
              let itemNo3 = s.invoiceValue[2]?.itemNo || '';
              let itemNo4 = s.invoiceValue[3]?.itemNo || '';
              let itemNo5 = s.invoiceValue[4]?.itemNo || '';
              let itemNo6 = s.invoiceValue[5]?.itemNo || '';
              let itemNo7 = s.invoiceValue[6]?.itemNo || '';
              let itemNo8 = s.invoiceValue[7]?.itemNo || '';
              let itemNo9 = s.invoiceValue[8]?.itemNo || '';
              let itemNo10 = s.invoiceValue[9]?.itemNo || '';
              let itemNo11 = s.invoiceValue[10]?.itemNo || '';
              let itemNo12 = s.invoiceValue[11]?.itemNo || '';
              let itemNo13 = s.invoiceValue[12]?.itemNo || '';
              let itemNo14 = s.invoiceValue[13]?.itemNo || '';
              let itemNo15 = s.invoiceValue[14]?.itemNo || '';
              let itemNo16 = s.invoiceValue[15]?.itemNo || '';
              let itemNo17 = s.invoiceValue[16]?.itemNo || '';
              let itemNo18 = s.invoiceValue[17]?.itemNo || '';
              let itemNo19 = s.invoiceValue[18]?.itemNo || '';
              let itemNo20 = s.invoiceValue[19]?.itemNo || '';
              let itemNo21 = s.invoiceValue[20]?.itemNo || '';
              let itemNo22 = s.invoiceValue[21]?.itemNo || '';
              let itemNo23 = s.invoiceValue[22]?.itemNo || '';
              let itemNo24 = s.invoiceValue[23]?.itemNo || '';
              let itemNo25 = s.invoiceValue[24]?.itemNo || '';
              let itemNo26 = s.invoiceValue[25]?.itemNo || '';
              let itemNo27 = s.invoiceValue[26]?.itemNo || '';
              let itemNo28 = s.invoiceValue[27]?.itemNo || '';
              let itemNo29 = s.invoiceValue[28]?.itemNo || '';
              let itemNo30 = s.invoiceValue[29]?.itemNo || '';
              let itemNo31 = s.invoiceValue[30]?.itemNo || '';
              let itemNo32 = s.invoiceValue[31]?.itemNo || '';
              let itemNo33 = s.invoiceValue[32]?.itemNo || '';
              let itemNo34 = s.invoiceValue[33]?.itemNo || '';
              let itemNo35 = s.invoiceValue[34]?.itemNo || '';
              let itemNo36 = s.invoiceValue[35]?.itemNo || '';
              let itemNo37 = s.invoiceValue[36]?.itemNo || '';
              let itemNo38 = s.invoiceValue[37]?.itemNo || '';
              let itemNo39 = s.invoiceValue[38]?.itemNo || '';
              let itemNo40 = s.invoiceValue[39]?.itemNo || '';
              let itemNo41 = s.invoiceValue[40]?.itemNo || '';
              let itemNo42 = s.invoiceValue[41]?.itemNo || '';
              let itemNo43 = s.invoiceValue[42]?.itemNo || '';
              let itemNo44 = s.invoiceValue[43]?.itemNo || '';
              let itemNo45 = s.invoiceValue[44]?.itemNo || '';
              let itemNo46 = s.invoiceValue[45]?.itemNo || '';
              let itemNo47 = s.invoiceValue[46]?.itemNo || '';
              let itemNo48 = s.invoiceValue[47]?.itemNo || '';
              let itemNo49 = s.invoiceValue[48]?.itemNo || '';
              let itemNo50 = s.invoiceValue[49]?.itemNo || '';
              let itemNo51 = s.invoiceValue[50]?.itemNo || '';
              let itemNo52 = s.invoiceValue[51]?.itemNo || '';
              let itemNo53 = s.invoiceValue[52]?.itemNo || '';
              let itemNo54 = s.invoiceValue[53]?.itemNo || '';
              let itemNo55 = s.invoiceValue[54]?.itemNo || '';
              let itemNo56 = s.invoiceValue[55]?.itemNo || '';
              let itemNo57 = s.invoiceValue[56]?.itemNo || '';
              let itemNo58 = s.invoiceValue[57]?.itemNo || '';
              let itemNo59 = s.invoiceValue[58]?.itemNo || '';
              let itemNo60 = s.invoiceValue[59]?.itemNo || '';
              let itemNo61 = s.invoiceValue[60]?.itemNo || '';
              let itemNo62 = s.invoiceValue[61]?.itemNo || '';
              let itemNo63 = s.invoiceValue[62]?.itemNo || '';
              let itemNo64 = s.invoiceValue[63]?.itemNo || '';
              let itemNo65 = s.invoiceValue[64]?.itemNo || '';
              let itemNo66 = s.invoiceValue[65]?.itemNo || '';
              let itemNo67 = s.invoiceValue[66]?.itemNo || '';
              let itemNo68 = s.invoiceValue[67]?.itemNo || '';
              let itemNo69 = s.invoiceValue[68]?.itemNo || '';
              let itemNo70 = s.invoiceValue[69]?.itemNo || '';
              let itemNo71 = s.invoiceValue[70]?.itemNo || '';
              let itemNo72 = s.invoiceValue[71]?.itemNo || '';
              let itemNo73 = s.invoiceValue[72]?.itemNo || '';
              let itemNo74 = s.invoiceValue[73]?.itemNo || '';
              let itemNo75 = s.invoiceValue[74]?.itemNo || '';
              let itemNo76 = s.invoiceValue[75]?.itemNo || '';
              let itemNo77 = s.invoiceValue[76]?.itemNo || '';
              let itemNo78 = s.invoiceValue[77]?.itemNo || '';
              let itemNo79 = s.invoiceValue[78]?.itemNo || '';
              let itemNo80 = s.invoiceValue[79]?.itemNo || '';
              let itemNo81 = s.invoiceValue[80]?.itemNo || '';
              let itemNo82 = s.invoiceValue[81]?.itemNo || '';
              let itemNo83 = s.invoiceValue[82]?.itemNo || '';
              let itemNo84 = s.invoiceValue[83]?.itemNo || '';
              let itemNo85 = s.invoiceValue[84]?.itemNo || '';
              let itemNo86 = s.invoiceValue[85]?.itemNo || '';
              let itemNo87 = s.invoiceValue[86]?.itemNo || '';
              let itemNo88 = s.invoiceValue[87]?.itemNo || '';
              let itemNo89 = s.invoiceValue[88]?.itemNo || '';
              let itemNo90 = s.invoiceValue[89]?.itemNo || '';
              let itemNo91 = s.invoiceValue[90]?.itemNo || '';
              let itemNo92 = s.invoiceValue[91]?.itemNo || '';
              let itemNo93 = s.invoiceValue[92]?.itemNo || '';
              let itemNo94 = s.invoiceValue[93]?.itemNo || '';
              let itemNo95 = s.invoiceValue[94]?.itemNo || '';
              let itemNo96 = s.invoiceValue[95]?.itemNo || '';
              let itemNo97 = s.invoiceValue[96]?.itemNo || '';
              let itemNo98 = s.invoiceValue[97]?.itemNo || '';
              let itemNo99 = s.invoiceValue[98]?.itemNo || '';
              let itemNo100 = s.invoiceValue[99]?.itemNo || '';
              let quantity = s.invoiceValue[0]?.itemQuantity;
              let quantity2 = s.invoiceValue[1]?.itemQuantity || '';
              let quantity3 = s.invoiceValue[2]?.itemQuantity || '';
              let quantity4 = s.invoiceValue[3]?.itemQuantity || '';
              let quantity5 = s.invoiceValue[4]?.itemQuantity || '';
              let quantity6 = s.invoiceValue[5]?.itemQuantity || '';
              let quantity7 = s.invoiceValue[6]?.itemQuantity || '';
              let quantity8 = s.invoiceValue[7]?.itemQuantity || '';
              let quantity9 = s.invoiceValue[8]?.itemQuantity || '';
              let quantity10 = s.invoiceValue[9]?.itemQuantity || '';
              let quantity11 = s.invoiceValue[10]?.itemQuantity || '';
              let quantity12 = s.invoiceValue[11]?.itemQuantity || '';
              let quantity13 = s.invoiceValue[12]?.itemQuantity || '';
              let quantity14 = s.invoiceValue[13]?.itemQuantity || '';
              let quantity15 = s.invoiceValue[14]?.itemQuantity || '';
              let quantity16 = s.invoiceValue[15]?.itemQuantity || '';
              let quantity17 = s.invoiceValue[16]?.itemQuantity || '';
              let quantity18 = s.invoiceValue[17]?.itemQuantity || '';
              let quantity19 = s.invoiceValue[18]?.itemQuantity || '';
              let quantity20 = s.invoiceValue[19]?.itemQuantity || '';
              let quantity21 = s.invoiceValue[20]?.itemQuantity || '';
              let quantity22 = s.invoiceValue[21]?.itemQuantity || '';
              let quantity23 = s.invoiceValue[22]?.itemQuantity || '';
              let quantity24 = s.invoiceValue[23]?.itemQuantity || '';
              let quantity25 = s.invoiceValue[24]?.itemQuantity || '';
              let quantity26 = s.invoiceValue[25]?.itemQuantity || '';
              let quantity27 = s.invoiceValue[26]?.itemQuantity || '';
              let quantity28 = s.invoiceValue[27]?.itemQuantity || '';
              let quantity29 = s.invoiceValue[28]?.itemQuantity || '';
              let quantity30 = s.invoiceValue[29]?.itemQuantity || '';
              let quantity31 = s.invoiceValue[30]?.itemQuantity || '';
              let quantity32 = s.invoiceValue[31]?.itemQuantity || '';
              let quantity33 = s.invoiceValue[32]?.itemQuantity || '';
              let quantity34 = s.invoiceValue[33]?.itemQuantity || '';
              let quantity35 = s.invoiceValue[34]?.itemQuantity || '';
              let quantity36 = s.invoiceValue[35]?.itemQuantity || '';
              let quantity37 = s.invoiceValue[36]?.itemQuantity || '';
              let quantity38 = s.invoiceValue[37]?.itemQuantity || '';
              let quantity39 = s.invoiceValue[38]?.itemQuantity || '';
              let quantity40 = s.invoiceValue[39]?.itemQuantity || '';
              let quantity41 = s.invoiceValue[40]?.itemQuantity || '';
              let quantity42 = s.invoiceValue[41]?.itemQuantity || '';
              let quantity43 = s.invoiceValue[42]?.itemQuantity || '';
              let quantity44 = s.invoiceValue[43]?.itemQuantity || '';
              let quantity45 = s.invoiceValue[44]?.itemQuantity || '';
              let quantity46 = s.invoiceValue[45]?.itemQuantity || '';
              let quantity47 = s.invoiceValue[46]?.itemQuantity || '';
              let quantity48 = s.invoiceValue[47]?.itemQuantity || '';
              let quantity49 = s.invoiceValue[48]?.itemQuantity || '';
              let quantity50 = s.invoiceValue[49]?.itemQuantity || '';
              let quantity51 = s.invoiceValue[50]?.itemQuantity || '';
              let quantity52 = s.invoiceValue[51]?.itemQuantity || '';
              let quantity53 = s.invoiceValue[52]?.itemQuantity || '';
              let quantity54 = s.invoiceValue[53]?.itemQuantity || '';
              let quantity55 = s.invoiceValue[54]?.itemQuantity || '';
              let quantity56 = s.invoiceValue[55]?.itemQuantity || '';
              let quantity57 = s.invoiceValue[56]?.itemQuantity || '';
              let quantity58 = s.invoiceValue[57]?.itemQuantity || '';
              let quantity59 = s.invoiceValue[58]?.itemQuantity || '';
              let quantity60 = s.invoiceValue[59]?.itemQuantity || '';
              let quantity61 = s.invoiceValue[60]?.itemQuantity || '';
              let quantity62 = s.invoiceValue[61]?.itemQuantity || '';
              let quantity63 = s.invoiceValue[62]?.itemQuantity || '';
              let quantity64 = s.invoiceValue[63]?.itemQuantity || '';
              let quantity65 = s.invoiceValue[64]?.itemQuantity || '';
              let quantity66 = s.invoiceValue[65]?.itemQuantity || '';
              let quantity67 = s.invoiceValue[66]?.itemQuantity || '';
              let quantity68 = s.invoiceValue[67]?.itemQuantity || '';
              let quantity69 = s.invoiceValue[68]?.itemQuantity || '';
              let quantity70 = s.invoiceValue[69]?.itemQuantity || '';
              let quantity71 = s.invoiceValue[70]?.itemQuantity || '';
              let quantity72 = s.invoiceValue[71]?.itemQuantity || '';
              let quantity73 = s.invoiceValue[72]?.itemQuantity || '';
              let quantity74 = s.invoiceValue[73]?.itemQuantity || '';
              let quantity75 = s.invoiceValue[74]?.itemQuantity || '';
              let quantity76 = s.invoiceValue[75]?.itemQuantity || '';
              let quantity77 = s.invoiceValue[76]?.itemQuantity || '';
              let quantity78 = s.invoiceValue[77]?.itemQuantity || '';
              let quantity79 = s.invoiceValue[78]?.itemQuantity || '';
              let quantity80 = s.invoiceValue[79]?.itemQuantity || '';
              let quantity81 = s.invoiceValue[80]?.itemQuantity || '';
              let quantity82 = s.invoiceValue[81]?.itemQuantity || '';
              let quantity83 = s.invoiceValue[82]?.itemQuantity || '';
              let quantity84 = s.invoiceValue[83]?.itemQuantity || '';
              let quantity85 = s.invoiceValue[84]?.itemQuantity || '';
              let quantity86 = s.invoiceValue[85]?.itemQuantity || '';
              let quantity87 = s.invoiceValue[86]?.itemQuantity || '';
              let quantity88 = s.invoiceValue[87]?.itemQuantity || '';
              let quantity89 = s.invoiceValue[88]?.itemQuantity || '';
              let quantity90 = s.invoiceValue[89]?.itemQuantity || '';
              let quantity91 = s.invoiceValue[90]?.itemQuantity || '';
              let quantity92 = s.invoiceValue[91]?.itemQuantity || '';
              let quantity93 = s.invoiceValue[92]?.itemQuantity || '';
              let quantity94 = s.invoiceValue[93]?.itemQuantity || '';
              let quantity95 = s.invoiceValue[94]?.itemQuantity || '';
              let quantity96 = s.invoiceValue[95]?.itemQuantity || '';
              let quantity97 = s.invoiceValue[96]?.itemQuantity || '';
              let quantity98 = s.invoiceValue[97]?.itemQuantity || '';
              let quantity99 = s.invoiceValue[98]?.itemQuantity || '';
              let quantity100 = s.invoiceValue[99]?.itemQuantity || '';
              let unit = s.invoiceValue[0]?.itemUnit;
              let unit2 = s.invoiceValue[1]?.itemUnit || '';
              let unit3 = s.invoiceValue[2]?.itemUnit || '';
              let unit4 = s.invoiceValue[3]?.itemUnit || '';
              let unit5 = s.invoiceValue[4]?.itemUnit || '';
              let unit6 = s.invoiceValue[5]?.itemUnit || '';
              let unit7 = s.invoiceValue[6]?.itemUnit || '';
              let unit8 = s.invoiceValue[7]?.itemUnit || '';
              let unit9 = s.invoiceValue[8]?.itemUnit || '';
              let unit10 = s.invoiceValue[9]?.itemUnit || '';
              let unit11 = s.invoiceValue[10]?.itemUnit || '';
              let unit12 = s.invoiceValue[11]?.itemUnit || '';
              let unit13 = s.invoiceValue[12]?.itemUnit || '';
              let unit14 = s.invoiceValue[13]?.itemUnit || '';
              let unit15 = s.invoiceValue[14]?.itemUnit || '';
              let unit16 = s.invoiceValue[15]?.itemUnit || '';
              let unit17 = s.invoiceValue[16]?.itemUnit || '';
              let unit18 = s.invoiceValue[17]?.itemUnit || '';
              let unit19 = s.invoiceValue[18]?.itemUnit || '';
              let unit20 = s.invoiceValue[19]?.itemUnit || '';
              let unit21 = s.invoiceValue[20]?.itemUnit || '';
              let unit22 = s.invoiceValue[21]?.itemUnit || '';
              let unit23 = s.invoiceValue[22]?.itemUnit || '';
              let unit24 = s.invoiceValue[23]?.itemUnit || '';
              let unit25 = s.invoiceValue[24]?.itemUnit || '';
              let unit26 = s.invoiceValue[25]?.itemUnit || '';
              let unit27 = s.invoiceValue[26]?.itemUnit || '';
              let unit28 = s.invoiceValue[27]?.itemUnit || '';
              let unit29 = s.invoiceValue[28]?.itemUnit || '';
              let unit30 = s.invoiceValue[29]?.itemUnit || '';
              let unit31 = s.invoiceValue[30]?.itemUnit || '';
              let unit32 = s.invoiceValue[31]?.itemUnit || '';
              let unit33 = s.invoiceValue[32]?.itemUnit || '';
              let unit34 = s.invoiceValue[33]?.itemUnit || '';
              let unit35 = s.invoiceValue[34]?.itemUnit || '';
              let unit36 = s.invoiceValue[35]?.itemUnit || '';
              let unit37 = s.invoiceValue[36]?.itemUnit || '';
              let unit38 = s.invoiceValue[37]?.itemUnit || '';
              let unit39 = s.invoiceValue[38]?.itemUnit || '';
              let unit40 = s.invoiceValue[39]?.itemUnit || '';
              let unit41 = s.invoiceValue[40]?.itemUnit || '';
              let unit42 = s.invoiceValue[41]?.itemUnit || '';
              let unit43 = s.invoiceValue[42]?.itemUnit || '';
              let unit44 = s.invoiceValue[43]?.itemUnit || '';
              let unit45 = s.invoiceValue[44]?.itemUnit || '';
              let unit46 = s.invoiceValue[45]?.itemUnit || '';
              let unit47 = s.invoiceValue[46]?.itemUnit || '';
              let unit48 = s.invoiceValue[47]?.itemUnit || '';
              let unit49 = s.invoiceValue[48]?.itemUnit || '';
              let unit50 = s.invoiceValue[49]?.itemUnit || '';
              let unit51 = s.invoiceValue[50]?.itemUnit || '';
              let unit52 = s.invoiceValue[51]?.itemUnit || '';
              let unit53 = s.invoiceValue[52]?.itemUnit || '';
              let unit54 = s.invoiceValue[53]?.itemUnit || '';
              let unit55 = s.invoiceValue[54]?.itemUnit || '';
              let unit56 = s.invoiceValue[55]?.itemUnit || '';
              let unit57 = s.invoiceValue[56]?.itemUnit || '';
              let unit58 = s.invoiceValue[57]?.itemUnit || '';
              let unit59 = s.invoiceValue[58]?.itemUnit || '';
              let unit60 = s.invoiceValue[59]?.itemUnit || '';
              let unit61 = s.invoiceValue[60]?.itemUnit || '';
              let unit62 = s.invoiceValue[61]?.itemUnit || '';
              let unit63 = s.invoiceValue[62]?.itemUnit || '';
              let unit64 = s.invoiceValue[63]?.itemUnit || '';
              let unit65 = s.invoiceValue[64]?.itemUnit || '';
              let unit66 = s.invoiceValue[65]?.itemUnit || '';
              let unit67 = s.invoiceValue[66]?.itemUnit || '';
              let unit68 = s.invoiceValue[67]?.itemUnit || '';
              let unit69 = s.invoiceValue[68]?.itemUnit || '';
              let unit70 = s.invoiceValue[69]?.itemUnit || '';
              let unit71 = s.invoiceValue[70]?.itemUnit || '';
              let unit72 = s.invoiceValue[71]?.itemUnit || '';
              let unit73 = s.invoiceValue[72]?.itemUnit || '';
              let unit74 = s.invoiceValue[73]?.itemUnit || '';
              let unit75 = s.invoiceValue[74]?.itemUnit || '';
              let unit76 = s.invoiceValue[75]?.itemUnit || '';
              let unit77 = s.invoiceValue[76]?.itemUnit || '';
              let unit78 = s.invoiceValue[77]?.itemUnit || '';
              let unit79 = s.invoiceValue[78]?.itemUnit || '';
              let unit80 = s.invoiceValue[79]?.itemUnit || '';
              let unit81 = s.invoiceValue[80]?.itemUnit || '';
              let unit82 = s.invoiceValue[81]?.itemUnit || '';
              let unit83 = s.invoiceValue[82]?.itemUnit || '';
              let unit84 = s.invoiceValue[83]?.itemUnit || '';
              let unit85 = s.invoiceValue[84]?.itemUnit || '';
              let unit86 = s.invoiceValue[85]?.itemUnit || '';
              let unit87 = s.invoiceValue[86]?.itemUnit || '';
              let unit88 = s.invoiceValue[87]?.itemUnit || '';
              let unit89 = s.invoiceValue[88]?.itemUnit || '';
              let unit90 = s.invoiceValue[89]?.itemUnit || '';
              let unit91 = s.invoiceValue[90]?.itemUnit || '';
              let unit92 = s.invoiceValue[91]?.itemUnit || '';
              let unit93 = s.invoiceValue[92]?.itemUnit || '';
              let unit94 = s.invoiceValue[93]?.itemUnit || '';
              let unit95 = s.invoiceValue[94]?.itemUnit || '';
              let unit96 = s.invoiceValue[95]?.itemUnit || '';
              let unit97 = s.invoiceValue[96]?.itemUnit || '';
              let unit98 = s.invoiceValue[97]?.itemUnit || '';
              let unit99 = s.invoiceValue[98]?.itemUnit || '';
              let unit100 = s.invoiceValue[99]?.itemUnit || '';
              let itemName = s.invoiceValue[0]?.itemCommonity;
              let itemName2 = s.invoiceValue[1]?.itemCommonity || '';
              let itemName3 = s.invoiceValue[2]?.itemCommonity || '';
              let itemName4 = s.invoiceValue[3]?.itemCommonity || '';
              let itemName5 = s.invoiceValue[4]?.itemCommonity || '';
              let itemName6 = s.invoiceValue[5]?.itemCommonity || '';
              let itemName7 = s.invoiceValue[6]?.itemCommonity || '';
              let itemName8 = s.invoiceValue[7]?.itemCommonity || '';
              let itemName9 = s.invoiceValue[8]?.itemCommonity || '';
              let itemName10 = s.invoiceValue[9]?.itemCommonity || '';
              let itemName11 = s.invoiceValue[10]?.itemCommonity || '';
              let itemName12 = s.invoiceValue[11]?.itemCommonity || '';
              let itemName13 = s.invoiceValue[12]?.itemCommonity || '';
              let itemName14 = s.invoiceValue[13]?.itemCommonity || '';
              let itemName15 = s.invoiceValue[14]?.itemCommonity || '';
              let itemName16 = s.invoiceValue[15]?.itemCommonity || '';
              let itemName17 = s.invoiceValue[16]?.itemCommonity || '';
              let itemName18 = s.invoiceValue[17]?.itemCommonity || '';
              let itemName19 = s.invoiceValue[18]?.itemCommonity || '';
              let itemName20 = s.invoiceValue[19]?.itemCommonity || '';
              let itemName21 = s.invoiceValue[20]?.itemCommonity || '';
              let itemName22 = s.invoiceValue[21]?.itemCommonity || '';
              let itemName23 = s.invoiceValue[22]?.itemCommonity || '';
              let itemName24 = s.invoiceValue[23]?.itemCommonity || '';
              let itemName25 = s.invoiceValue[24]?.itemCommonity || '';
              let itemName26 = s.invoiceValue[25]?.itemCommonity || '';
              let itemName27 = s.invoiceValue[26]?.itemCommonity || '';
              let itemName28 = s.invoiceValue[27]?.itemCommonity || '';
              let itemName29 = s.invoiceValue[28]?.itemCommonity || '';
              let itemName30 = s.invoiceValue[29]?.itemCommonity || '';
              let itemName31 = s.invoiceValue[30]?.itemCommonity || '';
              let itemName32 = s.invoiceValue[31]?.itemCommonity || '';
              let itemName33 = s.invoiceValue[32]?.itemCommonity || '';
              let itemName34 = s.invoiceValue[33]?.itemCommonity || '';
              let itemName35 = s.invoiceValue[34]?.itemCommonity || '';
              let itemName36 = s.invoiceValue[35]?.itemCommonity || '';
              let itemName37 = s.invoiceValue[36]?.itemCommonity || '';
              let itemName38 = s.invoiceValue[37]?.itemCommonity || '';
              let itemName39 = s.invoiceValue[38]?.itemCommonity || '';
              let itemName40 = s.invoiceValue[39]?.itemCommonity || '';
              let itemName41 = s.invoiceValue[40]?.itemCommonity || '';
              let itemName42 = s.invoiceValue[41]?.itemCommonity || '';
              let itemName43 = s.invoiceValue[42]?.itemCommonity || '';
              let itemName44 = s.invoiceValue[43]?.itemCommonity || '';
              let itemName45 = s.invoiceValue[44]?.itemCommonity || '';
              let itemName46 = s.invoiceValue[45]?.itemCommonity || '';
              let itemName47 = s.invoiceValue[46]?.itemCommonity || '';
              let itemName48 = s.invoiceValue[47]?.itemCommonity || '';
              let itemName49 = s.invoiceValue[48]?.itemCommonity || '';
              let itemName50 = s.invoiceValue[49]?.itemCommonity || '';
              let itemName51 = s.invoiceValue[50]?.itemCommonity || '';
              let itemName52 = s.invoiceValue[51]?.itemCommonity || '';
              let itemName53 = s.invoiceValue[52]?.itemCommonity || '';
              let itemName54 = s.invoiceValue[53]?.itemCommonity || '';
              let itemName55 = s.invoiceValue[54]?.itemCommonity || '';
              let itemName56 = s.invoiceValue[55]?.itemCommonity || '';
              let itemName57 = s.invoiceValue[56]?.itemCommonity || '';
              let itemName58 = s.invoiceValue[57]?.itemCommonity || '';
              let itemName59 = s.invoiceValue[58]?.itemCommonity || '';
              let itemName60 = s.invoiceValue[59]?.itemCommonity || '';
              let itemName61 = s.invoiceValue[60]?.itemCommonity || '';
              let itemName62 = s.invoiceValue[61]?.itemCommonity || '';
              let itemName63 = s.invoiceValue[62]?.itemCommonity || '';
              let itemName64 = s.invoiceValue[63]?.itemCommonity || '';
              let itemName65 = s.invoiceValue[64]?.itemCommonity || '';
              let itemName66 = s.invoiceValue[65]?.itemCommonity || '';
              let itemName67 = s.invoiceValue[66]?.itemCommonity || '';
              let itemName68 = s.invoiceValue[67]?.itemCommonity || '';
              let itemName69 = s.invoiceValue[68]?.itemCommonity || '';
              let itemName70 = s.invoiceValue[69]?.itemCommonity || '';
              let itemName71 = s.invoiceValue[70]?.itemCommonity || '';
              let itemName72 = s.invoiceValue[71]?.itemCommonity || '';
              let itemName73 = s.invoiceValue[72]?.itemCommonity || '';
              let itemName74 = s.invoiceValue[73]?.itemCommonity || '';
              let itemName75 = s.invoiceValue[74]?.itemCommonity || '';
              let itemName76 = s.invoiceValue[75]?.itemCommonity || '';
              let itemName77 = s.invoiceValue[76]?.itemCommonity || '';
              let itemName78 = s.invoiceValue[77]?.itemCommonity || '';
              let itemName79 = s.invoiceValue[78]?.itemCommonity || '';
              let itemName80 = s.invoiceValue[79]?.itemCommonity || '';
              let itemName81 = s.invoiceValue[80]?.itemCommonity || '';
              let itemName82 = s.invoiceValue[81]?.itemCommonity || '';
              let itemName83 = s.invoiceValue[82]?.itemCommonity || '';
              let itemName84 = s.invoiceValue[83]?.itemCommonity || '';
              let itemName85 = s.invoiceValue[84]?.itemCommonity || '';
              let itemName86 = s.invoiceValue[85]?.itemCommonity || '';
              let itemName87 = s.invoiceValue[86]?.itemCommonity || '';
              let itemName88 = s.invoiceValue[87]?.itemCommonity || '';
              let itemName89 = s.invoiceValue[88]?.itemCommonity || '';
              let itemName90 = s.invoiceValue[89]?.itemCommonity || '';
              let itemName91 = s.invoiceValue[90]?.itemCommonity;
              let itemName92 = s.invoiceValue[91]?.itemCommonity || '';
              let itemName93 = s.invoiceValue[92]?.itemCommonity || '';
              let itemName94 = s.invoiceValue[93]?.itemCommonity || '';
              let itemName95 = s.invoiceValue[94]?.itemCommonity || '';
              let itemName96 = s.invoiceValue[95]?.itemCommonity || '';
              let itemName97 = s.invoiceValue[96]?.itemCommonity || '';
              let itemName98 = s.invoiceValue[97]?.itemCommonity || '';
              let itemName99 = s.invoiceValue[98]?.itemCommonity || '';
              let itemName100 = s.invoiceValue[99]?.itemCommonity || '';
              let unitPrice = s.invoiceValue[0]?.itemUnitPrice;
              let unitPrice2 = s.invoiceValue[1]?.itemUnitPrice || '';
              let unitPrice3 = s.invoiceValue[2]?.itemUnitPrice || '';
              let unitPrice4 = s.invoiceValue[3]?.itemUnitPrice || '';
              let unitPrice5 = s.invoiceValue[4]?.itemUnitPrice || '';
              let unitPrice6 = s.invoiceValue[5]?.itemUnitPrice || '';
              let unitPrice7 = s.invoiceValue[6]?.itemUnitPrice || '';
              let unitPrice8 = s.invoiceValue[7]?.itemUnitPrice || '';
              let unitPrice9 = s.invoiceValue[8]?.itemUnitPrice || '';
              let unitPrice10 = s.invoiceValue[9]?.itemUnitPrice || '';
              let unitPrice11 = s.invoiceValue[10]?.itemUnitPrice || '';
              let unitPrice12 = s.invoiceValue[11]?.itemUnitPrice || '';
              let unitPrice13 = s.invoiceValue[12]?.itemUnitPrice || '';
              let unitPrice14 = s.invoiceValue[13]?.itemUnitPrice || '';
              let unitPrice15 = s.invoiceValue[14]?.itemUnitPrice || '';
              let unitPrice16 = s.invoiceValue[15]?.itemUnitPrice || '';
              let unitPrice17 = s.invoiceValue[16]?.itemUnitPrice || '';
              let unitPrice18 = s.invoiceValue[17]?.itemUnitPrice || '';
              let unitPrice19 = s.invoiceValue[18]?.itemUnitPrice || '';
              let unitPrice20 = s.invoiceValue[19]?.itemUnitPrice || '';
              let unitPrice21 = s.invoiceValue[20]?.itemUnitPrice || '';
              let unitPrice22 = s.invoiceValue[21]?.itemUnitPrice || '';
              let unitPrice23 = s.invoiceValue[22]?.itemUnitPrice || '';
              let unitPrice24 = s.invoiceValue[23]?.itemUnitPrice || '';
              let unitPrice25 = s.invoiceValue[24]?.itemUnitPrice || '';
              let unitPrice26 = s.invoiceValue[25]?.itemUnitPrice || '';
              let unitPrice27 = s.invoiceValue[26]?.itemUnitPrice || '';
              let unitPrice28 = s.invoiceValue[27]?.itemUnitPrice || '';
              let unitPrice29 = s.invoiceValue[28]?.itemUnitPrice || '';
              let unitPrice30 = s.invoiceValue[29]?.itemUnitPrice || '';
              let unitPrice31 = s.invoiceValue[30]?.itemUnitPrice || '';
              let unitPrice32 = s.invoiceValue[31]?.itemUnitPrice || '';
              let unitPrice33 = s.invoiceValue[32]?.itemUnitPrice || '';
              let unitPrice34 = s.invoiceValue[33]?.itemUnitPrice || '';
              let unitPrice35 = s.invoiceValue[34]?.itemUnitPrice || '';
              let unitPrice36 = s.invoiceValue[35]?.itemUnitPrice || '';
              let unitPrice37 = s.invoiceValue[36]?.itemUnitPrice || '';
              let unitPrice38 = s.invoiceValue[37]?.itemUnitPrice || '';
              let unitPrice39 = s.invoiceValue[38]?.itemUnitPrice || '';
              let unitPrice40 = s.invoiceValue[39]?.itemUnitPrice || '';
              let unitPrice41 = s.invoiceValue[40]?.itemUnitPrice || '';
              let unitPrice42 = s.invoiceValue[41]?.itemUnitPrice || '';
              let unitPrice43 = s.invoiceValue[42]?.itemUnitPrice || '';
              let unitPrice44 = s.invoiceValue[43]?.itemUnitPrice || '';
              let unitPrice45 = s.invoiceValue[44]?.itemUnitPrice || '';
              let unitPrice46 = s.invoiceValue[45]?.itemUnitPrice || '';
              let unitPrice47 = s.invoiceValue[46]?.itemUnitPrice || '';
              let unitPrice48 = s.invoiceValue[47]?.itemUnitPrice || '';
              let unitPrice49 = s.invoiceValue[48]?.itemUnitPrice || '';
              let unitPrice50 = s.invoiceValue[49]?.itemUnitPrice || '';
              let unitPrice51 = s.invoiceValue[50]?.itemUnitPrice || '';
              let unitPrice52 = s.invoiceValue[51]?.itemUnitPrice || '';
              let unitPrice53 = s.invoiceValue[52]?.itemUnitPrice || '';
              let unitPrice54 = s.invoiceValue[53]?.itemUnitPrice || '';
              let unitPrice55 = s.invoiceValue[54]?.itemUnitPrice || '';
              let unitPrice56 = s.invoiceValue[55]?.itemUnitPrice || '';
              let unitPrice57 = s.invoiceValue[56]?.itemUnitPrice || '';
              let unitPrice58 = s.invoiceValue[57]?.itemUnitPrice || '';
              let unitPrice59 = s.invoiceValue[58]?.itemUnitPrice || '';
              let unitPrice60 = s.invoiceValue[59]?.itemUnitPrice || '';
              let unitPrice61 = s.invoiceValue[60]?.itemUnitPrice || '';
              let unitPrice62 = s.invoiceValue[61]?.itemUnitPrice || '';
              let unitPrice63 = s.invoiceValue[62]?.itemUnitPrice || '';
              let unitPrice64 = s.invoiceValue[63]?.itemUnitPrice || '';
              let unitPrice65 = s.invoiceValue[64]?.itemUnitPrice || '';
              let unitPrice66 = s.invoiceValue[65]?.itemUnitPrice || '';
              let unitPrice67 = s.invoiceValue[66]?.itemUnitPrice || '';
              let unitPrice68 = s.invoiceValue[67]?.itemUnitPrice || '';
              let unitPrice69 = s.invoiceValue[68]?.itemUnitPrice || '';
              let unitPrice70 = s.invoiceValue[69]?.itemUnitPrice || '';
              let unitPrice71 = s.invoiceValue[70]?.itemUnitPrice || '';
              let unitPrice72 = s.invoiceValue[71]?.itemUnitPrice || '';
              let unitPrice73 = s.invoiceValue[72]?.itemUnitPrice || '';
              let unitPrice74 = s.invoiceValue[73]?.itemUnitPrice || '';
              let unitPrice75 = s.invoiceValue[74]?.itemUnitPrice || '';
              let unitPrice76 = s.invoiceValue[75]?.itemUnitPrice || '';
              let unitPrice77 = s.invoiceValue[76]?.itemUnitPrice || '';
              let unitPrice78 = s.invoiceValue[77]?.itemUnitPrice || '';
              let unitPrice79 = s.invoiceValue[78]?.itemUnitPrice || '';
              let unitPrice80 = s.invoiceValue[79]?.itemUnitPrice || '';
              let unitPrice81 = s.invoiceValue[80]?.itemUnitPrice || '';
              let unitPrice82 = s.invoiceValue[81]?.itemUnitPrice || '';
              let unitPrice83 = s.invoiceValue[82]?.itemUnitPrice || '';
              let unitPrice84 = s.invoiceValue[83]?.itemUnitPrice || '';
              let unitPrice85 = s.invoiceValue[84]?.itemUnitPrice || '';
              let unitPrice86 = s.invoiceValue[85]?.itemUnitPrice || '';
              let unitPrice87 = s.invoiceValue[86]?.itemUnitPrice || '';
              let unitPrice88 = s.invoiceValue[87]?.itemUnitPrice || '';
              let unitPrice89 = s.invoiceValue[88]?.itemUnitPrice || '';
              let unitPrice90 = s.invoiceValue[89]?.itemUnitPrice || '';
              let unitPrice91 = s.invoiceValue[90]?.itemUnitPrice || '';
              let unitPrice92 = s.invoiceValue[91]?.itemUnitPrice || '';
              let unitPrice93 = s.invoiceValue[92]?.itemUnitPrice || '';
              let unitPrice94 = s.invoiceValue[93]?.itemUnitPrice || '';
              let unitPrice95 = s.invoiceValue[94]?.itemUnitPrice || '';
              let unitPrice96 = s.invoiceValue[95]?.itemUnitPrice || '';
              let unitPrice97 = s.invoiceValue[96]?.itemUnitPrice || '';
              let unitPrice98 = s.invoiceValue[97]?.itemUnitPrice || '';
              let unitPrice99 = s.invoiceValue[98]?.itemUnitPrice || '';
              let unitPrice100 = s.invoiceValue[99]?.itemUnitPrice || '';
              let itemGrossWeight = +s.totalCharge / s.invoiceValue.length;
              let itemGrossWeight2 = s.invoiceValue?.length >= 2 ? +s.totalCharge / s.invoiceValue.length : '';
              let itemGrossWeight3 = s.invoiceValue?.length >= 3 ? +s.totalCharge / s.invoiceValue.length : '';
              let itemGrossWeight4 = s.invoiceValue?.length >= 4 ? +s.totalCharge / s.invoiceValue.length : '';
              let itemGrossWeight5 = s.invoiceValue?.length >= 5 ? +s.totalCharge / s.invoiceValue.length : '';
              let itemGrossWeight6 = s.invoiceValue?.length >= 6 ? +s.totalCharge / s.invoiceValue.length : '';
              let itemGrossWeight7 = s.invoiceValue?.length >= 7 ? +s.totalCharge / s.invoiceValue.length : '';
              let itemGrossWeight8 = s.invoiceValue?.length >= 8 ? +s.totalCharge / s.invoiceValue.length : '';
              let itemGrossWeight9 = s.invoiceValue?.length >= 9 ? +s.totalCharge / s.invoiceValue.length : '';
              let itemGrossWeight10 = s.invoiceValue?.length >= 10 ? +s.totalCharge / s.invoiceValue.length : '';
              let itemGrossWeight11 = s.invoiceValue?.lnegth >= 11 ? +s.totalCharge / s.invoiceValue.length : '';
              let itemGrossWeight12 = s.invoiceValue?.length >= 12 ? +s.totalCharge / s.invoiceValue.length : '';
              let itemGrossWeight13 = s.invoiceValue?.length >= 13 ? +s.totalCharge / s.invoiceValue.length : '';
              let itemGrossWeight14 = s.invoiceValue?.length >= 14 ? +s.totalCharge / s.invoiceValue.length : '';
              let itemGrossWeight15 = s.invoiceValue?.length >= 15 ? +s.totalCharge / s.invoiceValue.length : '';
              let itemGrossWeight16 = s.invoiceValue?.length >= 16 ? +s.totalCharge / s.invoiceValue.length : '';
              let itemGrossWeight17 = s.invoiceValue?.length >= 17 ? +s.totalCharge / s.invoiceValue.length : '';
              let itemGrossWeight18 = s.invoiceValue?.length >= 18 ? +s.totalCharge / s.invoiceValue.length : '';
              let itemGrossWeight19 = s.invoiceValue?.length >= 19 ? +s.totalCharge / s.invoiceValue.length : '';
              let itemGrossWeight20 = s.invoiceValue?.length === 20 ? +s.totalCharge / s.invoiceValue.length : '';
              let itemNetWeight = itemGrossWeight - 0.5;
              let itemNetWeight2 = s.invoiceValue?.length >= 2 ? itemGrossWeight - 0.5 : '';
              let itemNetWeight3 = s.invoiceValue?.length >= 3 ? itemGrossWeight - 0.5 : '';
              let itemNetWeight4 = s.invoiceValue?.length >= 4 ? itemGrossWeight - 0.5 : '';
              let itemNetWeight5 = s.invoiceValue?.length >= 5 ? itemGrossWeight - 0.5 : '';
              let itemNetWeight6 = s.invoiceValue?.length >= 6 ? itemGrossWeight - 0.5 : '';
              let itemNetWeight7 = s.invoiceValue?.length >= 7 ? itemGrossWeight - 0.5 : '';
              let itemNetWeight8 = s.invoiceValue?.length >= 8 ? itemGrossWeight - 0.5 : '';
              let itemNetWeight9 = s.invoiceValue?.length >= 9 ? itemGrossWeight - 0.5 : '';
              let itemNetWeight10 = s.invoiceValue?.length >= 10 ? itemGrossWeight - 0.5 : '';
              let itemNetWeight11 = s.invoiceValue?.length >= 11 ? itemGrossWeight - 0.5 : '';
              let itemNetWeight12 = s.invoiceValue?.length >= 12 ? itemGrossWeight - 0.5 : '';
              let itemNetWeight13 = s.invoiceValue?.length >= 13 ? itemGrossWeight - 0.5 : '';
              let itemNetWeight14 = s.invoiceValue?.length >= 14 ? itemGrossWeight - 0.5 : '';
              let itemNetWeight15 = s.invoiceValue?.length >= 15 ? itemGrossWeight - 0.5 : '';
              let itemNetWeight16 = s.invoiceValue?.length >= 16 ? itemGrossWeight - 0.5 : '';
              let itemNetWeight17 = s.invoiceValue?.length >= 17 ? itemGrossWeight - 0.5 : '';
              let itemNetWeight18 = s.invoiceValue?.length >= 18 ? itemGrossWeight - 0.5 : '';
              let itemNetWeight19 = s.invoiceValue?.length >= 19 ? itemGrossWeight - 0.5 : '';
              let itemNetWeight20 = s.invoiceValue?.length === 20 ? itemGrossWeight - 0.5 : '';
              let hsCode = s.invoiceValue[0]?.itemHsCode;
              let hsCode2 = s.invoiceValue[1]?.itemHsCode || '';
              let hsCode3 = s.invoiceValue[2]?.itemHsCode || '';
              let hsCode4 = s.invoiceValue[3]?.itemHsCode || '';
              let hsCode5 = s.invoiceValue[4]?.itemHsCode || '';
              let hsCode6 = s.invoiceValue[5]?.itemHsCode || '';
              let hsCode7 = s.invoiceValue[6]?.itemHsCode || '';
              let hsCode8 = s.invoiceValue[7]?.itemHsCode || '';
              let hsCode9 = s.invoiceValue[8]?.itemHsCode || '';
              let hsCode10 = s.invoiceValue[9]?.itemHsCode || '';
              let hsCode11 = s.invoiceValue[10]?.itemHsCode || '';
              let hsCode12 = s.invoiceValue[11]?.itemHsCode || '';
              let hsCode13 = s.invoiceValue[12]?.itemHsCode || '';
              let hsCode14 = s.invoiceValue[13]?.itemHsCode || '';
              let hsCode15 = s.invoiceValue[14]?.itemHsCode || '';
              let hsCode16 = s.invoiceValue[15]?.itemHsCode || '';
              let hsCode17 = s.invoiceValue[16]?.itemHsCode || '';
              let hsCode18 = s.invoiceValue[17]?.itemHsCode || '';
              let hsCode19 = s.invoiceValue[18]?.itemHsCode || '';
              let hsCode20 = s.invoiceValue[19]?.itemHsCode || '';
              let hsCode21 = s.invoiceValue[20]?.itemHsCode || '';
              let hsCode22 = s.invoiceValue[21]?.itemHsCode || '';
              let hsCode23 = s.invoiceValue[22]?.itemHsCode || '';
              let hsCode24 = s.invoiceValue[23]?.itemHsCode || '';
              let hsCode25 = s.invoiceValue[24]?.itemHsCode || '';
              let hsCode26 = s.invoiceValue[25]?.itemHsCode || '';
              let hsCode27 = s.invoiceValue[26]?.itemHsCode || '';
              let hsCode28 = s.invoiceValue[27]?.itemHsCode || '';
              let hsCode29 = s.invoiceValue[28]?.itemHsCode || '';
              let hsCode30 = s.invoiceValue[29]?.itemHsCode || '';
              let hsCode31 = s.invoiceValue[30]?.itemHsCode || '';
              let hsCode32 = s.invoiceValue[31]?.itemHsCode || '';
              let hsCode33 = s.invoiceValue[32]?.itemHsCode || '';
              let hsCode34 = s.invoiceValue[33]?.itemHsCode || '';
              let hsCode35 = s.invoiceValue[34]?.itemHsCode || '';
              let hsCode36 = s.invoiceValue[35]?.itemHsCode || '';
              let hsCode37 = s.invoiceValue[36]?.itemHsCode || '';
              let hsCode38 = s.invoiceValue[37]?.itemHsCode || '';
              let hsCode39 = s.invoiceValue[38]?.itemHsCode || '';
              let hsCode40 = s.invoiceValue[39]?.itemHsCode || '';
              let hsCode41 = s.invoiceValue[40]?.itemHsCode || '';
              let hsCode42 = s.invoiceValue[41]?.itemHsCode || '';
              let hsCode43 = s.invoiceValue[42]?.itemHsCode || '';
              let hsCode44 = s.invoiceValue[43]?.itemHsCode || '';
              let hsCode45 = s.invoiceValue[44]?.itemHsCode || '';
              let hsCode46 = s.invoiceValue[45]?.itemHsCode || '';
              let hsCode47 = s.invoiceValue[46]?.itemHsCode || '';
              let hsCode48 = s.invoiceValue[47]?.itemHsCode || '';
              let hsCode49 = s.invoiceValue[48]?.itemHsCode || '';
              let hsCode50 = s.invoiceValue[49]?.itemHsCode || '';
              let hsCode51 = s.invoiceValue[50]?.itemHsCode || '';
              let hsCode52 = s.invoiceValue[51]?.itemHsCode || '';
              let hsCode53 = s.invoiceValue[52]?.itemHsCode || '';
              let hsCode54 = s.invoiceValue[53]?.itemHsCode || '';
              let hsCode55 = s.invoiceValue[54]?.itemHsCode || '';
              let hsCode56 = s.invoiceValue[55]?.itemHsCode || '';
              let hsCode57 = s.invoiceValue[56]?.itemHsCode || '';
              let hsCode58 = s.invoiceValue[57]?.itemHsCode || '';
              let hsCode59 = s.invoiceValue[58]?.itemHsCode || '';
              let hsCode60 = s.invoiceValue[59]?.itemHsCode || '';
              let hsCode61 = s.invoiceValue[60]?.itemHsCode || '' || '';
              let hsCode62 = s.invoiceValue[61]?.itemHsCode || '';
              let hsCode63 = s.invoiceValue[62]?.itemHsCode || '';
              let hsCode64 = s.invoiceValue[63]?.itemHsCode || '';
              let hsCode65 = s.invoiceValue[64]?.itemHsCode || '';
              let hsCode66 = s.invoiceValue[65]?.itemHsCode || '';
              let hsCode67 = s.invoiceValue[66]?.itemHsCode || '';
              let hsCode68 = s.invoiceValue[67]?.itemHsCode || '';
              let hsCode69 = s.invoiceValue[68]?.itemHsCode || '';
              let hsCode70 = s.invoiceValue[69]?.itemHsCode || '';
              let hsCode71 = s.invoiceValue[70]?.itemHsCode || '';
              let hsCode72 = s.invoiceValue[71]?.itemHsCode || '';
              let hsCode73 = s.invoiceValue[72]?.itemHsCode || '';
              let hsCode74 = s.invoiceValue[73]?.itemHsCode || '';
              let hsCode75 = s.invoiceValue[74]?.itemHsCode || '';
              let hsCode76 = s.invoiceValue[75]?.itemHsCode || '';
              let hsCode77 = s.invoiceValue[76]?.itemHsCode || '';
              let hsCode78 = s.invoiceValue[77]?.itemHsCode || '';
              let hsCode79 = s.invoiceValue[78]?.itemHsCode || '';
              let hsCode80 = s.invoiceValue[79]?.itemHsCode || '';
              let hsCode81 = s.invoiceValue[80]?.itemHsCode || '';
              let hsCode82 = s.invoiceValue[81]?.itemHsCode || '';
              let hsCode83 = s.invoiceValue[82]?.itemHsCode || '';
              let hsCode84 = s.invoiceValue[83]?.itemHsCode || '';
              let hsCode85 = s.invoiceValue[84]?.itemHsCode || '';
              let hsCode86 = s.invoiceValue[85]?.itemHsCode || '';
              let hsCode87 = s.invoiceValue[86]?.itemHsCode || '';
              let hsCode88 = s.invoiceValue[87]?.itemHsCode || '';
              let hsCode89 = s.invoiceValue[88]?.itemHsCode || '';
              let hsCode90 = s.invoiceValue[89]?.itemHsCode || '';
              let hsCode91 = s.invoiceValue[90]?.itemHsCode || '';
              let hsCode92 = s.invoiceValue[91]?.itemHsCode || '';
              let hsCode93 = s.invoiceValue[92]?.itemHsCode || '';
              let hsCode94 = s.invoiceValue[93]?.itemHsCode || '';
              let hsCode95 = s.invoiceValue[94]?.itemHsCode || '';
              let hsCode96 = s.invoiceValue[95]?.itemHsCode || '';
              let hsCode97 = s.invoiceValue[96]?.itemHsCode || '';
              let hsCode98 = s.invoiceValue[97]?.itemHsCode || '';
              let hsCode99 = s.invoiceValue[98]?.itemHsCode || '';
              let hsCode100 = s.invoiceValue[99]?.itemHsCode || '';
              let desc = s.description.split(/[,]+/);
              let lengthValue = s.packageValue[0]?.packageHeight || '';
              let widthValue = s.packageValue[0]?.packageWidth || '';
              let heightValue = s.packageValue[0]?.packageLength || '';

              let lengthValue2 = s.packageValue[1]?.packageHeight || '';
              let widthValue2 = s.packageValue[1]?.packageWidth || '';
              let heightValue2 = s.packageValue[1]?.packageLength || '';

              let lengthValue3 = s.packageValue[2]?.packageHeight || '';
              let widthValue3 = s.packageValue[2]?.packageWidth || '';
              let heightValue3 = s.packageValue[2]?.packageLength || '';

              let lengthValue4 = s.packageValue[3]?.packageHeight || '';
              let widthValue4 = s.packageValue[3]?.packageWidth || '';
              let heightValue4 = s.packageValue[3]?.packageLength || '';

              let lengthValue5 = s.packageValue[4]?.packageHeight || '';
              let widthValue5 = s.packageValue[4]?.packageWidth || '';
              let heightValue5 = s.packageValue[4]?.packageLength || '';

              let lengthValue6 = s.packageValue[5]?.packageHeight || '';
              let widthValue6 = s.packageValue[5]?.packageWidth || '';
              let heightValue6 = s.packageValue[5]?.packageLength || '';

              let lengthValue7 = s.packageValue[6]?.packageHeight || '';
              let widthValue7 = s.packageValue[6]?.packageWidth || '';
              let heightValue7 = s.packageValue[6]?.packageLength || '';

              let lengthValue8 = s.packageValue[7]?.packageHeight || '';
              let widthValue8 = s.packageValue[7]?.packageWidth || '';
              let heightValue8 = s.packageValue[7]?.packageLength || '';

              let lengthValue9 = s.packageValue[8]?.packageHeight || '';
              let widthValue9 = s.packageValue[8]?.packageWidth || '';
              let heightValue9 = s.packageValue[8]?.packageLength || '';

              let lengthValue10 = s.packageValue[9]?.packageHeight || '';
              let widthValue10 = s.packageValue[9]?.packageWidth || '';
              let heightValue10 = s.packageValue[9]?.packageLength || '';

              let lengthValue11 = s.packageValue[10]?.packageHeight || '';
              let widthValue11 = s.packageValue[10]?.packageWidth || '';
              let heightValue11 = s.packageValue[10]?.packageLength || '';

              let lengthValue12 = s.packageValue[11]?.packageHeight || '';
              let widthValue12 = s.packageValue[11]?.packageWidth || '';
              let heightValue12 = s.packageValue[11]?.packageLength || '';

              let lengthValue13 = s.packageValue[12]?.packageHeight || '';
              let widthValue13 = s.packageValue[12]?.packageWidth || '';
              let heightValue13 = s.packageValue[12]?.packageLength || '';

              let lengthValue14 = s.packageValue[13]?.packageHeight || '';
              let widthValue14 = s.packageValue[13]?.packageWidth || '';
              let heightValue14 = s.packageValue[13]?.packageLength || '';

              let lengthValue15 = s.packageValue[14]?.packageHeight || '';
              let widthValue15 = s.packageValue[14]?.packageWidth || '';
              let heightValue15 = s.packageValue[14]?.packageLength || '';

              let lengthValue16 = s.packageValue[15]?.packageHeight || '';
              let widthValue16 = s.packageValue[15]?.packageWidth || '';
              let heightValue16 = s.packageValue[15]?.packageLength || '';

              let lengthValue17 = s.packageValue[16]?.packageHeight || '';
              let widthValue17 = s.packageValue[16]?.packageWidth || '';
              let heightValue17 = s.packageValue[16]?.packageLength || '';

              let lengthValue18 = s.packageValue[17]?.packageHeight || '';
              let widthValue18 = s.packageValue[17]?.packageWidth || '';
              let heightValue18 = s.packageValue[17]?.packageLength || '';

              let lengthValue19 = s.packageValue[18]?.packageHeight || '';
              let widthValue19 = s.packageValue[18]?.packageWidth || '';
              let heightValue19 = s.packageValue[18]?.packageLength || '';

              let lengthValue20 = s.packageValue[19]?.packageHeight || '';
              let widthValue20 = s.packageValue[19]?.packageWidth || '';
              let heightValue20 = s.packageValue[19]?.packageLength || '';
              const originalDateTime = new Date(s.pickupScheduled);
              const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
              const formattedDateTime = originalDateTime.toLocaleDateString(undefined, options);

              const date = s.timestamp.toDate();

              // Months array for mapping month numbers to month names
              const months = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
              ];

              // Get the month, day, year, hours, and minutes
              const month = months[date.getMonth()];
              const day = date.getDate();
              const year = date.getFullYear();
              let hours = date.getHours();
              let minutes = date.getMinutes();

              // Format hours to have leading zero if needed
              hours = hours < 10 ? `0${hours}` : hours;

              // Format minutes to have leading zero if needed
              minutes = minutes < 10 ? `0${minutes}` : minutes;
              const mergeDate = `${month} ${day}, ${year} - ${hours}:${minutes}`;
              const mergeAddress = `${s.toAddress3}, ${s.toCity}`;
              return {
                updated: updated,
                select: select,
                descArr: desc,
                no: i + 1,
                cancel: s.cancel || 'booking',
                booked: s.booked,
                account: s.account || '',
                currency: s.currency || '',
                service: s.service || 'SKD',
                date: s.createAt || '',
                dateTime: mergeDate,
                deliverySign: s.deliverySign,
                pickupScheduled: s.pickupScheduled === 'T:00' ? 'Pending' : formattedDateTime || 'Pending',
                cleDate: s.createAt.replaceAll(/-/g, ''),
                hawb: s.hawb || '',
                mawb: s.mawb || '',
                makh: s.makh || '',
                incoterm: 'DAP',
                satDev: 'N',
                numberOfLine: s.shipmentType === 'WPX' ? s.invoiceValue?.length : 1,
                removeSkd: removeSkd,
                negative: 1,
                company: s.importer || '',
                contact: s.toName || '',
                type: s.shipmentType || '',
                status: s.status || '',
                term: s.term || '',
                time: s.time || '',
                fromAddress: s.fromAddress || '',
                fromAddress2: s.fromAddress2 || '.',
                fromAddress3: s.fromAddress3 || '.',
                address: s.toAddress || '',
                address2: s.toAddress2 || '.',
                address3: s.toAddress3 || '.',
                city: s.toCity || '',
                country: s.toCountry || '',
                bookedBy: s.bookedBy || 'Label not created',
                fromCountry: s.fromCountry || '',
                phone: s.toPhone.replace(/[&\/\\#^+()$~%.'":*?<>{}!@]/g, '') || '',
                postal: s.toPostal || '',
                toState: s.toState || '',
                cleState: s.stateLabel,
                term: s.term,
                fromPhoneType: 'O',
                town: s.toTown || '',
                chargeable: (Math.round(s.totalCharge * 100) / 100).toFixed(2).toString() || '',
                amount: s.totalPackage || '',
                amountPcs: `${s.totalPackage} pcs`,
                totalItem: s.totalItem || '',
                transport: s.transportation || '',
                description: s.description || '',
                descriptionCut: s.description.slice(0, 60).replaceAll(',', ''),
                cleDesc: s.shipmentType === 'WPX' ? s.description.slice(0, 168) : 'Documents - general business',
                controller: s.controller || '',
                eamFormat: eamFormat,
                toPhoneCode: s.phoneCode || '',
                toPhoneType: 'M',
                value: s.shipmentType === 'DOX' ? '0.00' : value,
                airline: s.airline || '',
                reweight: s.reweight || '',
                cleShipmentType: s.shipmentType === 'WPX' ? 'P' : 'D',
                agent: s.agent || '',
                etd: s.etd || '',
                subStatus: s.subStatus || '',
                sin: s.sin === 'Null' ? 'Pending' : s.sin,
                sender: s.exporter || '',
                note: s.note || '',
                perform: s.perform || '',
                rate: s.rate || '',
                fsc: s.fsc || '',
                vat: s.vat || '',
                remote: s.remote || '',
                comestic: s.comestic || '',
                isMerged: s.isMerged,
                addressCorrection: s.addressCorrection || '',
                totalValue: s.totalValue || '',
                surcharge: s.surcharge || '',
                surcharge2: s.surcharge2 || '',
                surcharge3: s.surcharge3 || '',
                vatValue: s.vatValue || '',
                sellNote: s.sellNote || '',
                paymentPickup: s.paymentPickup || '',
                paymentAcc: s.paymentAcc || '',
                paymentCompany: s.paymentCompany || '',
                fromEmail: s.fromEmail || '',
                fromCity: s.fromCity || '',
                com: s.com || '',
                rateUpdate: s.rateUpdate || '',
                paymentStatus: s.paymentStatus || '',
                paymentSubStatus: s.paymentSubStatus || '',
                docs: s.shipmentType === 'WPX' ? `${s.invoiceValue.length} items` : 'DOX',
                code: s.code || '',
                eamService: s.service === 'DHL' ? 'INT' : s.service,
                fromPhoneCode: '84',
                dutyPaid: 0,
                pack: s.pack || '',
                serviceType: 'EPAQSCT-CUP-PMDA_SG',
                d3pServiceType: 'EPAQ-DHL-P',
                d3pSenderAddress: '71 Alps Ave #04-01, Singapore, 498745',

                plan: s.plan || 'Pending',
                invoiceValue: s.shipmentType === 'WPX' ? s.invoiceValue : [],
                packageValue: s.shipmentType === 'WPX' ? s.packageValue : [],
                term: s.term || '.',
                reason: s.usage?.charAt(0).toUpperCase() + s.usage?.slice(1).toLowerCase() || '.',
                fromName: s.fromName || '',
                fromPostal: s.fromPostal || '',
                fromCode: 'VN',
                fromPhone: s.fromPhone,
                pickupBy: s.pickupBy,
                receiveBy: s.receiveBy,
                processBy: s.processBy,
                clearanceBy: s.clearanceBy,
                clearedBy: s.clearedBy,
                holdBy: s.holdBy,
                toEmail: s.toEmail,
                transportation: s.transportation,
                fromAddress2: s.fromAddress2 || '.',
                fromTown: s.fromTown,
                fromState: s.fromState,
                stateCode: s.stateCode,
                loading: false,
                eori: s.eori,
                ref: 'Pending',
                auCode: 'AU',
                firstPcs: s.shipmentType === 'WPX' ? pcsWeight / s.packageValue.length : s.weight,
                firstLength: s.shipmentType === 'WPX' ? pcsLength / s.packageValue.length : 1,
                firstWidth: s.shipmentType === 'WPX' ? pcsWidth / s.packageValue.length : 1,
                firstHeight: s.shipmentType === 'WPX' ? pcsHeight / s.packageValue.length : 1,
                weightCode: 'KG',
                dimCode: 'CM',
                dus: s.dus || [],
                labelUrl: s.labelUrl,
                dhlAccount: dhlAccount,
                cleNumberOfLine: s.invoiceValue.length,
                cleName: cleName,
                cleAddress: '#02-18',
                cleAddress2: 'CARGO AGENT BUILDING C',
                cleAddress3: 'CHANGI AIRFREIGHT CENTRE',
                clePostal: '819466',
                cleCode: 'SG',
                lengthValue: lengthValue,
                widthValue: widthValue,
                heightValue: heightValue,

                lengthValue2: lengthValue2,
                widthValue2: widthValue2,
                heightValue2: heightValue2,

                lengthValue3: lengthValue3,
                widthValue3: widthValue3,
                heightValue3: heightValue3,

                lengthValue4: lengthValue4,
                widthValue4: widthValue4,
                heightValue4: heightValue4,

                lengthValue5: lengthValue5,
                widthValue5: widthValue5,
                heightValue5: heightValue5,

                lengthValue6: lengthValue6,
                widthValue6: widthValue6,
                heightValue6: heightValue6,

                lengthValue7: lengthValue7,
                widthValue7: widthValue7,
                heightValue7: heightValue7,

                lengthValue8: lengthValue8,
                widthValue8: widthValue8,
                heightValue8: heightValue8,

                lengthValue9: lengthValue9,
                widthValue9: widthValue9,
                heightValue9: heightValue9,

                lengthValue10: lengthValue10,
                widthValue10: widthValue10,
                heightValue10: heightValue10,

                lengthValue11: lengthValue11,
                widthValue11: widthValue11,
                heightValue11: heightValue11,

                lengthValue12: lengthValue12,
                widthValue12: widthValue12,
                heightValue12: heightValue12,

                lengthValue13: lengthValue13,
                widthValue13: widthValue13,
                heightValue13: heightValue13,

                lengthValue14: lengthValue14,
                widthValue14: widthValue14,
                heightValue14: heightValue14,

                lengthValue15: lengthValue15,
                widthValue15: widthValue15,
                heightValue15: heightValue15,

                lengthValue16: lengthValue16,
                widthValue16: widthValue16,
                heightValue16: heightValue16,

                lengthValue17: lengthValue17,
                widthValue17: widthValue17,
                heightValue17: heightValue17,

                lengthValue18: lengthValue18,
                widthValue18: widthValue18,
                heightValue18: heightValue18,

                lengthValue19: lengthValue19,
                widthValue19: widthValue19,
                heightValue19: heightValue19,

                lengthValue20: lengthValue20,
                widthValue20: widthValue20,
                heightValue20: heightValue20,
                uom: 'PCS',
                uom2: s.invoiceValue.length >= 2 ? 'PCS' : '',
                uom3: s.invoiceValue.length >= 3 ? 'PCS' : '',
                uom4: s.invoiceValue.length >= 4 ? 'PCS' : '',
                uom5: s.invoiceValue.length >= 5 ? 'PCS' : '',
                uom6: s.invoiceValue.length >= 6 ? 'PCS' : '',
                uom7: s.invoiceValue.length >= 7 ? 'PCS' : '',
                uom8: s.invoiceValue.length >= 8 ? 'PCS' : '',
                uom9: s.invoiceValue.length >= 9 ? 'PCS' : '',
                uom10: s.invoiceValue.length >= 10 ? 'PCS' : '',
                uom11: s.invoiceValue.length >= 11 ? 'PCS' : '',
                uom12: s.invoiceValue.length >= 12 ? 'PCS' : '',
                uom13: s.invoiceValue.length >= 13 ? 'PCS' : '',
                uom14: s.invoiceValue.length >= 14 ? 'PCS' : '',
                uom15: s.invoiceValue.length >= 15 ? 'PCS' : '',
                uom16: s.invoiceValue.length >= 16 ? 'PCS' : '',
                uom17: s.invoiceValue.length >= 17 ? 'PCS' : '',
                uom18: s.invoiceValue.length >= 18 ? 'PCS' : '',
                uom19: s.invoiceValue.length >= 19 ? 'PCS' : '',
                uom20: s.invoiceValue.length >= 20 ? 'PCS' : '',
                cleCity: 'SINGAPORE',
                cleEmail: cleEmail,
                clePhoneType: 'O',
                clePhoneCountryCode: '65',
                clePhone: clePhone,
                item_value: itemValue / quantity,
                item_value2: itemValue2 / quantity2,
                item_value3: itemValue3 / quantity3,
                item_value4: itemValue4 / quantity4,
                item_value5: itemValue5 / quantity5,
                item_value6: itemValue6 / quantity6,
                item_value7: itemValue7 / quantity7,
                item_value8: itemValue8 / quantity8,
                item_value9: itemValue9 / quantity9,
                item_value10: itemValue10 / quantity10,
                item_value11: itemValue11 / quantity11,
                item_value12: itemValue12 / quantity12,
                item_value13: itemValue13 / quantity13,
                item_value14: itemValue14 / quantity14,
                item_value15: itemValue15 / quantity15,
                item_value16: itemValue16 / quantity16,
                item_value17: itemValue17 / quantity17,
                item_value18: itemValue18 / quantity18,
                item_value19: itemValue19 / quantity19,
                item_value20: itemValue20 / quantity20,
                item_number: itemNo,
                item_number2: itemNo2,
                item_number3: itemNo3,
                item_number4: itemNo4,
                item_number5: itemNo5,
                item_number6: itemNo6,
                item_number7: itemNo7,
                item_number8: itemNo8,
                item_number9: itemNo9,
                item_number10: itemNo10,
                item_number11: itemNo11,
                item_number12: itemNo12,
                item_number13: itemNo13,
                item_number14: itemNo14,
                item_number15: itemNo15,
                item_number16: itemNo16,
                item_number17: itemNo17,
                item_number18: itemNo18,
                item_number19: itemNo19,
                item_number20: itemNo20,
                item_number21: itemNo21,
                item_number22: itemNo22,
                item_number23: itemNo23,
                item_number24: itemNo24,
                item_number25: itemNo25,
                item_number26: itemNo26,
                item_number27: itemNo27,
                item_number28: itemNo28,
                item_number29: itemNo29,
                item_number30: itemNo30,
                item_number31: itemNo31,
                item_number32: itemNo32,
                item_number33: itemNo33,
                item_number34: itemNo34,
                item_number35: itemNo35,
                item_number36: itemNo36,
                item_number37: itemNo37,
                item_number38: itemNo38,
                item_number39: itemNo39,
                item_number40: itemNo40,
                item_number41: itemNo41,
                item_number42: itemNo42,
                item_number43: itemNo43,
                item_number44: itemNo44,
                item_number45: itemNo45,
                item_number46: itemNo46,
                item_number47: itemNo47,
                item_number48: itemNo48,
                item_number49: itemNo49,
                item_number50: itemNo50,
                item_number51: itemNo51,
                item_number52: itemNo52,
                item_number53: itemNo53,
                item_number54: itemNo54,
                item_number55: itemNo55,
                item_number56: itemNo56,
                item_number57: itemNo57,
                item_number58: itemNo58,
                item_number59: itemNo59,
                item_number60: itemNo60,
                item_number61: itemNo61,
                item_number62: itemNo62,
                item_number63: itemNo63,
                item_number64: itemNo64,
                item_number65: itemNo65,
                item_number66: itemNo66,
                item_number67: itemNo67,
                item_number68: itemNo68,
                item_number69: itemNo69,
                item_number70: itemNo70,
                item_number71: itemNo71,
                item_number72: itemNo72,
                item_number73: itemNo73,
                item_number74: itemNo74,
                item_number75: itemNo75,
                item_number76: itemNo76,
                item_number77: itemNo77,
                item_number78: itemNo78,
                item_number79: itemNo79,
                item_number80: itemNo80,
                item_number81: itemNo81,
                item_number82: itemNo82,
                item_number83: itemNo83,
                item_number84: itemNo84,
                item_number85: itemNo85,
                item_number86: itemNo86,
                item_number87: itemNo87,
                item_number88: itemNo88,
                item_number89: itemNo89,
                item_number90: itemNo90,
                item_number91: itemNo91,
                item_number92: itemNo92,
                item_number93: itemNo93,
                item_number94: itemNo94,
                item_number95: itemNo95,
                item_number96: itemNo96,
                item_number97: itemNo97,
                item_number98: itemNo98,
                item_number99: itemNo99,
                item_number100: itemNo100,
                quantity: quantity,
                quantity2: quantity2,
                quantity3: quantity3,
                quantity4: quantity4,
                quantity5: quantity5,
                quantity6: quantity6,
                quantity7: quantity7,
                quantity8: quantity8,
                quantity9: quantity9,
                quantity10: quantity10,
                quantity11: quantity11,
                quantity12: quantity12,
                quantity13: quantity13,
                quantity14: quantity14,
                quantity15: quantity15,
                quantity16: quantity16,
                quantity17: quantity17,
                quantity18: quantity18,
                quantity19: quantity19,
                quantity20: quantity20,
                quantity21: quantity21,
                quantity22: quantity22,
                quantity23: quantity23,
                quantity24: quantity24,
                quantity25: quantity25,
                quantity26: quantity26,
                quantity27: quantity27,
                quantity28: quantity28,
                quantity29: quantity29,
                quantity30: quantity30,
                quantity31: quantity31,
                quantity32: quantity32,
                quantity33: quantity33,
                quantity34: quantity34,
                quantity35: quantity35,
                quantity36: quantity36,
                quantity37: quantity37,
                quantity38: quantity38,
                quantity39: quantity39,
                quantity40: quantity40,
                quantity41: quantity41,
                quantity42: quantity42,
                quantity43: quantity43,
                quantity44: quantity44,
                quantity45: quantity45,
                quantity46: quantity46,
                quantity47: quantity47,
                quantity48: quantity48,
                quantity49: quantity49,
                quantity50: quantity50,
                quantity51: quantity51,
                quantity52: quantity52,
                quantity53: quantity53,
                quantity54: quantity54,
                quantity55: quantity55,
                quantity56: quantity56,
                quantity57: quantity57,
                quantity58: quantity58,
                quantity59: quantity59,
                quantity60: quantity60,
                quantity61: quantity61,
                quantity62: quantity62,
                quantity63: quantity63,
                quantity64: quantity64,
                quantity65: quantity65,
                quantity66: quantity66,
                quantity67: quantity67,
                quantity68: quantity68,
                quantity69: quantity69,
                quantity70: quantity70,
                quantity71: quantity71,
                quantity72: quantity72,
                quantity73: quantity73,
                quantity74: quantity74,
                quantity75: quantity75,
                quantity76: quantity76,
                quantity77: quantity77,
                quantity78: quantity78,
                quantity79: quantity79,
                quantity80: quantity80,
                quantity81: quantity81,
                quantity82: quantity82,
                quantity83: quantity83,
                quantity84: quantity84,
                quantity85: quantity85,
                quantity86: quantity86,
                quantity87: quantity87,
                quantity88: quantity88,
                quantity89: quantity89,
                quantity90: quantity90,
                quantity91: quantity91,
                quantity92: quantity92,
                quantity93: quantity93,
                quantity94: quantity94,
                quantity95: quantity95,
                quantity96: quantity96,
                quantity97: quantity97,
                quantity98: quantity98,
                quantity99: quantity99,
                quantity100: quantity100,
                unit: unit,
                unit2: unit2,
                unit3: unit3,
                unit4: unit4,
                unit5: unit5,
                unit6: unit6,
                unit7: unit7,
                unit8: unit8,
                unit9: unit9,
                unit10: unit10,
                unit11: unit11,
                unit12: unit12,
                unit13: unit13,
                unit14: unit14,
                unit15: unit15,
                unit16: unit16,
                unit17: unit17,
                unit18: unit18,
                unit19: unit19,
                unit20: unit20,
                unit21: unit21,
                unit22: unit22,
                unit23: unit23,
                unit24: unit24,
                unit25: unit25,
                unit26: unit26,
                unit27: unit27,
                unit28: unit28,
                unit29: unit29,
                unit30: unit30,
                unit31: unit31,
                unit32: unit32,
                unit33: unit33,
                unit34: unit34,
                unit35: unit35,
                unit36: unit36,
                unit37: unit37,
                unit38: unit38,
                unit39: unit39,
                unit40: unit40,
                unit41: unit41,
                unit42: unit42,
                unit43: unit43,
                unit44: unit44,
                unit45: unit45,
                unit46: unit46,
                unit47: unit47,
                unit48: unit48,
                unit49: unit49,
                unit50: unit50,
                unit51: unit51,
                unit52: unit52,
                unit53: unit53,
                unit54: unit54,
                unit55: unit55,
                unit56: unit56,
                unit57: unit57,
                unit58: unit58,
                unit59: unit59,
                unit60: unit60,
                unit61: unit61,
                unit62: unit62,
                unit63: unit63,
                unit64: unit64,
                unit65: unit65,
                unit66: unit66,
                unit67: unit67,
                unit68: unit68,
                unit69: unit69,
                unit70: unit70,
                unit71: unit71,
                unit72: unit72,
                unit73: unit73,
                unit74: unit74,
                unit75: unit75,
                unit76: unit76,
                unit77: unit77,
                unit78: unit78,
                unit79: unit79,
                unit80: unit80,
                unit81: unit81,
                unit82: unit82,
                unit83: unit83,
                unit84: unit84,
                unit85: unit85,
                unit86: unit86,
                unit87: unit87,
                unit88: unit88,
                unit89: unit89,
                unit90: unit90,
                unit91: unit91,
                unit92: unit92,
                unit93: unit93,
                unit94: unit94,
                unit95: unit95,
                unit96: unit96,
                unit97: unit97,
                unit98: unit98,
                unit99: unit99,
                unit100: unit100,
                itemName: itemName,
                itemName2: itemName2,
                itemName3: itemName3,
                itemName4: itemName4,
                itemName5: itemName5,
                itemName6: itemName6,
                itemName7: itemName7,
                itemName8: itemName8,
                itemName9: itemName9,
                itemName10: itemName10,
                itemName11: itemName11,
                itemName12: itemName12,
                itemName13: itemName13,
                itemName14: itemName14,
                itemName15: itemName15,
                itemName16: itemName16,
                itemName17: itemName17,
                itemName18: itemName18,
                itemName19: itemName19,
                itemName20: itemName20,
                itemName21: itemName21,
                itemName22: itemName22,
                itemName23: itemName23,
                itemName24: itemName24,
                itemName25: itemName25,
                itemName26: itemName26,
                itemName27: itemName27,
                itemName28: itemName28,
                itemName29: itemName29,
                itemName30: itemName30,
                itemName31: itemName31,
                itemName32: itemName32,
                itemName33: itemName33,
                itemName34: itemName34,
                itemName35: itemName35,
                itemName36: itemName36,
                itemName37: itemName37,
                itemName38: itemName38,
                itemName39: itemName39,
                itemName40: itemName40,
                itemName41: itemName41,
                itemName42: itemName42,
                itemName43: itemName43,
                itemName44: itemName44,
                itemName45: itemName45,
                itemName46: itemName46,
                itemName47: itemName47,
                itemName48: itemName48,
                itemName49: itemName49,
                itemName50: itemName50,
                itemName51: itemName51,
                itemName52: itemName52,
                itemName53: itemName53,
                itemName54: itemName54,
                itemName55: itemName55,
                itemName56: itemName56,
                itemName57: itemName57,
                itemName58: itemName58,
                itemName59: itemName59,
                itemName60: itemName60,
                itemName61: itemName61,
                itemName62: itemName62,
                itemName63: itemName63,
                itemName64: itemName64,
                itemName65: itemName65,
                itemName66: itemName66,
                itemName67: itemName67,
                itemName68: itemName68,
                itemName69: itemName69,
                itemName70: itemName70,
                itemName71: itemName71,
                itemName72: itemName72,
                itemName73: itemName73,
                itemName74: itemName74,
                itemName75: itemName75,
                itemName76: itemName76,
                itemName77: itemName77,
                itemName78: itemName78,
                itemName79: itemName79,
                itemName80: itemName80,
                itemName81: itemName81,
                itemName82: itemName82,
                itemName83: itemName83,
                itemName84: itemName84,
                itemName85: itemName85,
                itemName86: itemName86,
                itemName87: itemName87,
                itemName88: itemName88,
                itemName89: itemName89,
                itemName90: itemName90,
                itemName91: itemName91,
                itemName92: itemName92,
                itemName93: itemName93,
                itemName94: itemName94,
                itemName95: itemName95,
                itemName96: itemName96,
                itemName97: itemName97,
                itemName98: itemName98,
                itemName99: itemName99,
                itemName100: itemName100,
                unitPrice: unitPrice,
                unitPrice2: unitPrice2,
                unitPrice3: unitPrice3,
                unitPrice4: unitPrice4,
                unitPrice5: unitPrice5,
                unitPrice6: unitPrice6,
                unitPrice7: unitPrice7,
                unitPrice8: unitPrice8,
                unitPrice9: unitPrice9,
                unitPrice10: unitPrice10,
                unitPrice11: unitPrice11,
                unitPrice12: unitPrice12,
                unitPrice13: unitPrice13,
                unitPrice14: unitPrice14,
                unitPrice15: unitPrice15,
                unitPrice16: unitPrice16,
                unitPrice17: unitPrice17,
                unitPrice18: unitPrice18,
                unitPrice19: unitPrice19,
                unitPrice20: unitPrice20,
                unitPrice21: unitPrice21,
                unitPrice22: unitPrice22,
                unitPrice23: unitPrice23,
                unitPrice24: unitPrice24,
                unitPrice25: unitPrice25,
                unitPrice26: unitPrice26,
                unitPrice27: unitPrice27,
                unitPrice28: unitPrice28,
                unitPrice29: unitPrice29,
                unitPrice30: unitPrice30,
                unitPrice31: unitPrice31,
                unitPrice32: unitPrice32,
                unitPrice33: unitPrice33,
                unitPrice34: unitPrice34,
                unitPrice35: unitPrice35,
                unitPrice36: unitPrice36,
                unitPrice37: unitPrice37,
                unitPrice38: unitPrice38,
                unitPrice39: unitPrice39,
                unitPrice40: unitPrice40,
                unitPrice41: unitPrice41,
                unitPrice42: unitPrice42,
                unitPrice43: unitPrice43,
                unitPrice44: unitPrice44,
                unitPrice45: unitPrice45,
                unitPrice46: unitPrice46,
                unitPrice47: unitPrice47,
                unitPrice48: unitPrice48,
                unitPrice49: unitPrice49,
                unitPrice50: unitPrice50,
                unitPrice51: unitPrice51,
                unitPrice52: unitPrice52,
                unitPrice53: unitPrice53,
                unitPrice54: unitPrice54,
                unitPrice55: unitPrice55,
                unitPrice56: unitPrice56,
                unitPrice57: unitPrice57,
                unitPrice58: unitPrice58,
                unitPrice59: unitPrice59,
                unitPrice60: unitPrice60,
                unitPrice61: unitPrice61,
                unitPrice62: unitPrice62,
                unitPrice63: unitPrice63,
                unitPrice64: unitPrice64,
                unitPrice65: unitPrice65,
                unitPrice66: unitPrice66,
                unitPrice67: unitPrice67,
                unitPrice68: unitPrice68,
                unitPrice69: unitPrice69,
                unitPrice70: unitPrice70,
                unitPrice71: unitPrice71,
                unitPrice72: unitPrice72,
                unitPrice73: unitPrice73,
                unitPrice74: unitPrice74,
                unitPrice75: unitPrice75,
                unitPrice76: unitPrice76,
                unitPrice77: unitPrice77,
                unitPrice78: unitPrice78,
                unitPrice79: unitPrice79,
                unitPrice80: unitPrice80,
                unitPrice81: unitPrice81,
                unitPrice82: unitPrice82,
                unitPrice83: unitPrice83,
                unitPrice84: unitPrice84,
                unitPrice85: unitPrice85,
                unitPrice86: unitPrice86,
                unitPrice87: unitPrice87,
                unitPrice88: unitPrice88,
                unitPrice89: unitPrice89,
                unitPrice90: unitPrice90,
                unitPrice91: unitPrice91,
                unitPrice92: unitPrice92,
                unitPrice93: unitPrice93,
                unitPrice94: unitPrice94,
                unitPrice95: unitPrice95,
                unitPrice96: unitPrice96,
                unitPrice97: unitPrice97,
                unitPrice98: unitPrice98,
                unitPrice99: unitPrice99,
                unitPrice100: unitPrice100,
                itemGrossWeight: itemGrossWeight / quantity,
                itemGrossWeight2: itemGrossWeight2 / quantity2,
                itemGrossWeight3: itemGrossWeight3 / quantity3,
                itemGrossWeight4: itemGrossWeight4 / quantity4,
                itemGrossWeight5: itemGrossWeight5 / quantity5,
                itemGrossWeight6: itemGrossWeight6 / quantity6,
                itemGrossWeight7: itemGrossWeight7 / quantity7,
                itemGrossWeight8: itemGrossWeight8 / quantity8,
                itemGrossWeight9: itemGrossWeight9 / quantity9,
                itemGrossWeight10: itemGrossWeight10 / quantity10,
                itemGrossWeight11: itemGrossWeight11 / quantity11,
                itemGrossWeight12: itemGrossWeight12 / quantity12,
                itemGrossWeight13: itemGrossWeight13 / quantity13,
                itemGrossWeight14: itemGrossWeight14 / quantity14,
                itemGrossWeight15: itemGrossWeight15 / quantity15,
                itemGrossWeight16: itemGrossWeight16 / quantity16,
                itemGrossWeight17: itemGrossWeight17 / quantity17,
                itemGrossWeight18: itemGrossWeight18 / quantity18,
                itemGrossWeight19: itemGrossWeight19 / quantity19,
                itemGrossWeight20: itemGrossWeight20 / quantity20,
                itemNetWeight: itemNetWeight / quantity,
                itemNetWeight2: itemNetWeight2 / quantity2,
                itemNetWeight3: itemNetWeight3 / quantity3,
                itemNetWeight4: itemNetWeight4 / quantity4,
                itemNetWeight5: itemNetWeight5 / quantity5,
                itemNetWeight6: itemNetWeight6 / quantity6,
                itemNetWeight7: itemNetWeight7 / quantity7,
                itemNetWeight8: itemNetWeight8 / quantity8,
                itemNetWeight9: itemNetWeight9 / quantity9,
                itemNetWeight10: itemNetWeight10 / quantity10,
                itemNetWeight11: itemNetWeight11 / quantity11,
                itemNetWeight12: itemNetWeight12 / quantity12,
                itemNetWeight13: itemNetWeight13 / quantity13,
                itemNetWeight14: itemNetWeight14 / quantity14,
                itemNetWeight15: itemNetWeight15 / quantity15,
                itemNetWeight16: itemNetWeight16 / quantity16,
                itemNetWeight17: itemNetWeight17 / quantity17,
                itemNetWeight18: itemNetWeight18 / quantity18,
                itemNetWeight19: itemNetWeight19 / quantity19,
                itemNetWeight20: itemNetWeight20 / quantity20,
                itemCurrency: 'USD',
                itemCurrency2: s.invoiceValue.length >= 2 ? 'USD' : '',
                itemCurrency3: s.invoiceValue.length >= 3 ? 'USD' : '',
                itemCurrency4: s.invoiceValue.length >= 4 ? 'USD' : '',
                itemCurrency5: s.invoiceValue.length >= 5 ? 'USD' : '',
                itemCurrency6: s.invoiceValue.length >= 6 ? 'USD' : '',
                itemCurrency7: s.invoiceValue.length >= 7 ? 'USD' : '',
                itemCurrency8: s.invoiceValue.length >= 8 ? 'USD' : '',
                itemCurrency9: s.invoiceValue.length >= 9 ? 'USD' : '',
                itemCurrency10: s.invoiceValue.length >= 10 ? 'USD' : '',
                itemCurrency11: s.invoiceValue.length >= 11 ? 'USD' : '',
                itemCurrency12: s.invoiceValue.length >= 12 ? 'USD' : '',
                itemCurrency13: s.invoiceValue.length >= 13 ? 'USD' : '',
                itemCurrency14: s.invoiceValue.length >= 14 ? 'USD' : '',
                itemCurrency15: s.invoiceValue.length >= 15 ? 'USD' : '',
                itemCurrency16: s.invoiceValue.length >= 16 ? 'USD' : '',
                itemCurrency17: s.invoiceValue.length >= 17 ? 'USD' : '',
                itemCurrency18: s.invoiceValue.length >= 18 ? 'USD' : '',
                itemCurrency19: s.invoiceValue.length >= 19 ? 'USD' : '',
                itemCurrency20: s.invoiceValue.length >= 20 ? 'USD' : '',
                countryManufactured: 'VN',
                countryManufactured2: s.invoiceValue.length >= 2 ? 'VN' : '',
                countryManufactured3: s.invoiceValue.length >= 3 ? 'VN' : '',
                countryManufactured4: s.invoiceValue.length >= 4 ? 'VN' : '',
                countryManufactured5: s.invoiceValue.length >= 5 ? 'VN' : '',
                countryManufactured6: s.invoiceValue.length >= 6 ? 'VN' : '',
                countryManufactured7: s.invoiceValue.length >= 7 ? 'VN' : '',
                countryManufactured8: s.invoiceValue.length >= 8 ? 'VN' : '',
                countryManufactured9: s.invoiceValue.length >= 9 ? 'VN' : '',
                countryManufactured10: s.invoiceValue.length >= 10 ? 'VN' : '',
                countryManufactured11: s.invoiceValue.length >= 11 ? 'VN' : '',
                countryManufactured12: s.invoiceValue.length >= 12 ? 'VN' : '',
                countryManufactured13: s.invoiceValue.length >= 13 ? 'VN' : '',
                countryManufactured14: s.invoiceValue.length >= 14 ? 'VN' : '',
                countryManufactured15: s.invoiceValue.length >= 15 ? 'VN' : '',
                countryManufactured16: s.invoiceValue.length >= 16 ? 'VN' : '',
                countryManufactured17: s.invoiceValue.length >= 17 ? 'VN' : '',
                countryManufactured18: s.invoiceValue.length >= 18 ? 'VN' : '',
                countryManufactured19: s.invoiceValue.length >= 19 ? 'VN' : '',
                countryManufactured20: s.invoiceValue.length >= 20 ? 'VN' : '',
                hsCode: hsCode || 'acb',
                hsCode2: hsCode2 || 'acb',
                hsCode3: hsCode3 || 'acb',
                hsCode4: hsCode4 || 'acb',
                hsCode5: hsCode5 || 'acb',
                hsCode6: hsCode6 || 'acb',
                hsCode7: hsCode7 || 'acb',
                hsCode8: hsCode8 || 'acb',
                hsCode9: hsCode9 || 'acb',
                hsCode10: hsCode10 || 'acb',
                hsCode11: hsCode11 || 'acb',
                hsCode12: hsCode12 || 'acb',
                hsCode13: hsCode13 || 'acb',
                hsCode14: hsCode14 || 'acb',
                hsCode15: hsCode15 || 'acb',
                hsCode16: hsCode16 || 'acb',
                hsCode17: hsCode17 || 'acb',
                hsCode18: hsCode18 || 'acb',
                hsCode19: hsCode19 || 'acb',
                hsCode20: hsCode20 || 'acb',
                hsCode21: hsCode21 || 'acb',
                hsCode22: hsCode22 || 'acb',
                hsCode23: hsCode23 || 'acb',
                hsCode24: hsCode24 || 'acb',
                hsCode25: hsCode25 || 'acb',
                hsCode26: hsCode26 || 'acb',
                hsCode27: hsCode27 || 'acb',
                hsCode28: hsCode28 || 'acb',
                hsCode29: hsCode29 || 'acb',
                hsCode30: hsCode30 || 'acb',
                hsCode31: hsCode31 || 'acb',
                hsCode32: hsCode32 || 'acb',
                hsCode33: hsCode33 || 'acb',
                hsCode34: hsCode34 || 'acb',
                hsCode35: hsCode35 || 'acb',
                hsCode36: hsCode36 || 'acb',
                hsCode37: hsCode37 || 'acb',
                hsCode38: hsCode38 || 'acb',
                hsCode39: hsCode39 || 'acb',
                hsCode40: hsCode40 || 'acb',
                hsCode41: hsCode41 || 'acb',
                hsCode42: hsCode42 || 'acb',
                hsCode43: hsCode43 || 'acb',
                hsCode44: hsCode44 || 'acb',
                hsCode45: hsCode45 || 'acb',
                hsCode46: hsCode46 || 'acb',
                hsCode47: hsCode47 || 'acb',
                hsCode48: hsCode48 || 'acb',
                hsCode49: hsCode49 || 'acb',
                hsCode50: hsCode50 || 'acb',
                hsCode51: hsCode51 || 'acb',
                hsCode52: hsCode52 || 'acb',
                hsCode53: hsCode53 || 'acb',
                hsCode54: hsCode54 || 'acb',
                hsCode55: hsCode55 || 'acb',
                hsCode56: hsCode56 || 'acb',
                hsCode57: hsCode57 || 'acb',
                hsCode58: hsCode58 || 'acb',
                hsCode59: hsCode59 || 'acb',
                hsCode60: hsCode60 || 'acb',
                hsCode61: hsCode61 || 'acb',
                hsCode62: hsCode62 || 'acb',
                hsCode63: hsCode63 || 'acb',
                hsCode64: hsCode64 || 'acb',
                hsCode65: hsCode65 || 'acb',
                hsCode66: hsCode66 || 'acb',
                hsCode67: hsCode67 || 'acb',
                hsCode68: hsCode68 || 'acb',
                hsCode69: hsCode69 || 'acb',
                hsCode70: hsCode70 || 'acb',
                hsCode71: hsCode71 || 'acb',
                hsCode72: hsCode72 || 'acb',
                hsCode73: hsCode73 || 'acb',
                hsCode74: hsCode74 || 'acb',
                hsCode75: hsCode75 || 'acb',
                hsCode76: hsCode76 || 'acb',
                hsCode77: hsCode77 || 'acb',
                hsCode78: hsCode78 || 'acb',
                hsCode79: hsCode79 || 'acb',
                hsCode80: hsCode80 || 'acb',
                hsCode81: hsCode81 || 'acb',
                hsCode82: hsCode82 || 'acb',
                hsCode83: hsCode83 || 'acb',
                hsCode84: hsCode84 || 'acb',
                hsCode85: hsCode85 || 'acb',
                hsCode86: hsCode86 || 'acb',
                hsCode87: hsCode87 || 'acb',
                hsCode88: hsCode88 || 'acb',
                hsCode89: hsCode89 || 'acb',
                hsCode90: hsCode90 || 'acb',
                hsCode91: hsCode91 || 'acb',
                hsCode92: hsCode92 || 'acb',
                hsCode93: hsCode93 || 'acb',
                hsCode94: hsCode94 || 'acb',
                hsCode95: hsCode95 || 'acb',
                hsCode96: hsCode96 || 'acb',
                hsCode97: hsCode97 || 'acb',
                hsCode98: hsCode98 || 'acb',
                hsCode99: hsCode99 || 'acb',
                hsCode100: hsCode100 || 'acb',
                taxIT: '',
                slash: '#item',
                dimSlash: '#dim',
                dimSlash2: lengthValue2 === '' ? '' : '#dim',
                dimSlash3: lengthValue3 === '' ? '' : '#dim',
                dimSlash4: lengthValue4 === '' ? '' : '#dim',
                dimSlash5: lengthValue5 === '' ? '' : '#dim',
                dimSlash6: lengthValue6 === '' ? '' : '#dim',
                dimSlash7: lengthValue7 === '' ? '' : '#dim',
                dimSlash8: lengthValue8 === '' ? '' : '#dim',
                dimSlash9: lengthValue9 === '' ? '' : '#dim',
                dimSlash10: lengthValue10 === '' ? '' : '#dim',
                dimSlash11: lengthValue11 === '' ? '' : '#dim',
                dimSlash12: lengthValue12 === '' ? '' : '#dim',
                dimSlash13: lengthValue13 === '' ? '' : '#dim',
                dimSlash14: lengthValue14 === '' ? '' : '#dim',
                dimSlash15: lengthValue15 === '' ? '' : '#dim',
                dimSlash16: lengthValue16 === '' ? '' : '#dim',
                dimSlash17: lengthValue17 === '' ? '' : '#dim',
                dimSlash18: lengthValue18 === '' ? '' : '#dim',
                dimSlash19: lengthValue19 === '' ? '' : '#dim',
                dimSlash20: lengthValue20 === '' ? '' : '#dim',
                slash2: itemNo2 === '' ? '' : '#item',
                slash3: itemNo3 === '' ? '' : '#item',
                slash4: itemNo4 === '' ? '' : '#item',
                slash5: itemNo5 === '' ? '' : '#item',
                slash6: itemNo6 === '' ? '' : '#item',
                slash7: itemNo7 === '' ? '' : '#item',
                slash8: itemNo8 === '' ? '' : '#item',
                slash9: itemNo9 === '' ? '' : '#item',
                slash10: itemNo10 === '' ? '' : '#item',
                slash11: itemNo11 === '' ? '' : '#item',
                slash12: itemNo12 === '' ? '' : '#item',
                slash13: itemNo13 === '' ? '' : '#item',
                slash14: itemNo14 === '' ? '' : '#item',
                slash15: itemNo15 === '' ? '' : '#item',
                slash16: itemNo16 === '' ? '' : '#item',
                slash17: itemNo17 === '' ? '' : '#item',
                slash18: itemNo18 === '' ? '' : '#item',
                slash19: itemNo19 === '' ? '' : '#item',
                slash20: itemNo20 === '' ? '' : '#item',
                slash21: itemNo21 === '' ? '' : '#item',
                slash22: itemNo22 === '' ? '' : '#item',
                slash23: itemNo23 === '' ? '' : '#item',
                slash24: itemNo24 === '' ? '' : '#item',
                slash25: itemNo25 === '' ? '' : '#item',
                slash26: itemNo26 === '' ? '' : '#item',
                slash27: itemNo27 === '' ? '' : '#item',
                slash28: itemNo28 === '' ? '' : '#item',
                slash29: itemNo29 === '' ? '' : '#item',
                slash30: itemNo30 === '' ? '' : '#item',
                slash31: itemNo31 === '' ? '' : '#item',
                slash32: itemNo32 === '' ? '' : '#item',
                slash33: itemNo33 === '' ? '' : '#item',
                slash34: itemNo34 === '' ? '' : '#item',
                slash35: itemNo35 === '' ? '' : '#item',
                slash36: itemNo36 === '' ? '' : '#item',
                slash37: itemNo37 === '' ? '' : '#item',
                slash38: itemNo38 === '' ? '' : '#item',
                slash39: itemNo39 === '' ? '' : '#item',
                slash40: itemNo40 === '' ? '' : '#item',
                slash41: itemNo41 === '' ? '' : '#item',
                slash42: itemNo42 === '' ? '' : '#item',

                slash43: itemNo43 === '' ? '' : '#item',
                slash44: itemNo44 === '' ? '' : '#item',
                slash45: itemNo45 === '' ? '' : '#item',
                slash46: itemNo46 === '' ? '' : '#item',
                slash47: itemNo47 === '' ? '' : '#item',
                slash48: itemNo48 === '' ? '' : '#item',
                slash49: itemNo49 === '' ? '' : '#item',
                slash50: itemNo50 === '' ? '' : '#item',
                slash51: itemNo51 === '' ? '' : '#item',
                slash52: itemNo52 === '' ? '' : '#item',
                slash53: itemNo53 === '' ? '' : '#item',
                slash54: itemNo54 === '' ? '' : '#item',
                slash55: itemNo55 === '' ? '' : '#item',
                slash56: itemNo56 === '' ? '' : '#item',
                slash57: itemNo57 === '' ? '' : '#item',
                slash58: itemNo58 === '' ? '' : '#item',
                slash59: itemNo59 === '' ? '' : '#item',
                slash60: itemNo60 === '' ? '' : '#item',
                slash61: itemNo61 === '' ? '' : '#item',
                slash62: itemNo62 === '' ? '' : '#item',
                slash63: itemNo63 === '' ? '' : '#item',
                slash64: itemNo64 === '' ? '' : '#item',
                slash65: itemNo65 === '' ? '' : '#item',
                slash66: itemNo66 === '' ? '' : '#item',
                slash67: itemNo67 === '' ? '' : '#item',
                slash68: itemNo68 === '' ? '' : '#item',
                slash69: itemNo69 === '' ? '' : '#item',
                slash70: itemNo70 === '' ? '' : '#item',
                slash71: itemNo71 === '' ? '' : '#item',
                slash72: itemNo72 === '' ? '' : '#item',
                slash73: itemNo73 === '' ? '' : '#item',
                slash74: itemNo74 === '' ? '' : '#item',
                slash75: itemNo75 === '' ? '' : '#item',
                slash76: itemNo76 === '' ? '' : '#item',
                slash77: itemNo77 === '' ? '' : '#item',
                slash78: itemNo78 === '' ? '' : '#item',
                slash79: itemNo79 === '' ? '' : '#item',
                slash80: itemNo80 === '' ? '' : '#item',
                slash81: itemNo81 === '' ? '' : '#item',
                slash82: itemNo82 === '' ? '' : '#item',
                slash83: itemNo83 === '' ? '' : '#item',
                slash84: itemNo84 === '' ? '' : '#item',
                slash85: itemNo85 === '' ? '' : '#item',
                slash86: itemNo86 === '' ? '' : '#item',
                slash87: itemNo87 === '' ? '' : '#item',
                slash88: itemNo88 === '' ? '' : '#item',
                slash89: itemNo89 === '' ? '' : '#item',
                slash90: itemNo90 === '' ? '' : '#item',
                slash91: itemNo91 === '' ? '' : '#item',
                slash92: itemNo92 === '' ? '' : '#item',
                slash93: itemNo93 === '' ? '' : '#item',
                slash94: itemNo94 === '' ? '' : '#item',
                slash95: itemNo95 === '' ? '' : '#item',
                slash96: itemNo96 === '' ? '' : '#item',
                slash97: itemNo97 === '' ? '' : '#item',
                slash98: itemNo98 === '' ? '' : '#item',
                slash99: itemNo99 === '' ? '' : '#item',
                slash100: itemNo100 === '' ? '' : '#item',
                shortAddress: s.fromAddress.slice(0, 30),
                timestamp: s.timestamp,
              };
            })
          );

          setLoading(false);
        });
    }
  };

  function removeAccents(str) {
    var AccentsMap = [
      'aàảãáạăằẳẵắặâầẩẫấậ',
      'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
      'dđ',
      'DĐ',
      'eèẻẽéẹêềểễếệ',
      'EÈẺẼÉẸÊỀỂỄẾỆ',
      'iìỉĩíị',
      'IÌỈĨÍỊ',
      'oòỏõóọôồổỗốộơờởỡớợ',
      'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
      'uùủũúụưừửữứự',
      'UÙỦŨÚỤƯỪỬỮỨỰ',
      'yỳỷỹýỵ',
      'YỲỶỸÝỴ',
    ];
    for (var i = 0; i < AccentsMap.length; i++) {
      var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
      var char = AccentsMap[i][0];
      str = str.replace(re, char);
    }
    return str;
  }

  // Get shipment from new booking
  const fetchShipment = () => {
    setLoading(true);
    db.collection('shipments')
      .where('status', '==', 'Pending')
      .where('createAt', '>=', startDate)
      .where('createAt', '<=', endDate)
      .orderBy('createAt', 'desc')
      .get()
      .then((snapshot) => {
        let data = snapshot.docs.map((doc) => doc.data());
        setShipments(
          data.map((s, i) => {
            let dhlAccount = '';
            let cleName = '';
            let clePhone = '';
            let cleEmail = '';
            let cleState = '';
            let pcsWeight =
              s.shipmentType === 'WPX' ? s.packageValue?.reduce((a, v) => (a = +a + +v.packageCharge), 0) : 0;
            let pcsLength =
              s.shipmentType === 'WPX' ? s.packageValue?.reduce((a, v) => (a = +a + +v.packageLength), 0) : 0;
            let pcsWidth =
              s.shipmentType === 'WPX' ? s.packageValue?.reduce((a, v) => (a = +a + +v.packageWidth), 0) : 0;
            let pcsHeight =
              s.shipmentType === 'WPX' ? s.packageValue?.reduce((a, v) => (a = +a + +v.packageHeight), 0) : 0;
            if (s.toCountry === 'United States') {
              dhlAccount = '611051446';
              cleName = 'C/O NVH TRADING SERVICES PTE LTD';
              clePhone = '65459990';
              cleEmail = 'cs@courierlink.com.sg';
            } else if (s.toCountry !== 'United States' && s.shipmentType === 'WPX') {
              dhlAccount = '610799051';
              cleName = 'C/O ANTRON SERVICES';
              clePhone = '65423395';
              cleState = s.toState;
              cleEmail = 'angie.antron@gmail.com';
            } else if (s.toCountry !== 'United States' && s.shipmentType === 'DOX') {
              dhlAccount = '610654958';
              cleName = 'C/O ANTRON SERVICES';
              clePhone = '65423395';
              cleState = s.toState;
              cleEmail = 'angie.antron@gmail.com';
            }

            let value = s.totalInvoiceValue;
            let updated = s.updated || 'false';
            let select = s.select || false;
            let multi = s.multi || false;
            let loading = false;
            let eamFormat = s.shipmentType === 'DOX' ? 'DOX' : 'NDX';
            let removeSkd = s.hawb.substring(3);

            let itemValue = s.invoiceValue[0]?.itemValue;
            let itemValue2 = s.invoiceValue[1]?.itemValue;
            let itemValue3 = s.invoiceValue[2]?.itemValue;
            let itemValue4 = s.invoiceValue[3]?.itemValue;
            let itemValue5 = s.invoiceValue[4]?.itemValue;
            let itemValue6 = s.invoiceValue[5]?.itemValue;
            let itemValue7 = s.invoiceValue[6]?.itemValue;
            let itemValue8 = s.invoiceValue[7]?.itemValue;
            let itemValue9 = s.invoiceValue[8]?.itemValue;
            let itemValue10 = s.invoiceValue[9]?.itemValue;
            let itemValue11 = s.invoiceValue[10]?.itemValue;
            let itemValue12 = s.invoiceValue[11]?.itemValue;
            let itemValue13 = s.invoiceValue[12]?.itemValue;
            let itemValue14 = s.invoiceValue[13]?.itemValue;
            let itemValue15 = s.invoiceValue[14]?.itemValue;
            let itemValue16 = s.invoiceValue[15]?.itemValue;
            let itemValue17 = s.invoiceValue[16]?.itemValue;
            let itemValue18 = s.invoiceValue[17]?.itemValue;
            let itemValue19 = s.invoiceValue[18]?.itemValue;
            let itemValue20 = s.invoiceValue[19]?.itemValue;
            let itemNo = s.invoiceValue[0]?.itemNo;
            let itemNo2 = s.invoiceValue[1]?.itemNo || '';
            let itemNo3 = s.invoiceValue[2]?.itemNo || '';
            let itemNo4 = s.invoiceValue[3]?.itemNo || '';
            let itemNo5 = s.invoiceValue[4]?.itemNo || '';
            let itemNo6 = s.invoiceValue[5]?.itemNo || '';
            let itemNo7 = s.invoiceValue[6]?.itemNo || '';
            let itemNo8 = s.invoiceValue[7]?.itemNo || '';
            let itemNo9 = s.invoiceValue[8]?.itemNo || '';
            let itemNo10 = s.invoiceValue[9]?.itemNo || '';
            let itemNo11 = s.invoiceValue[10]?.itemNo || '';
            let itemNo12 = s.invoiceValue[11]?.itemNo || '';
            let itemNo13 = s.invoiceValue[12]?.itemNo || '';
            let itemNo14 = s.invoiceValue[13]?.itemNo || '';
            let itemNo15 = s.invoiceValue[14]?.itemNo || '';
            let itemNo16 = s.invoiceValue[15]?.itemNo || '';
            let itemNo17 = s.invoiceValue[16]?.itemNo || '';
            let itemNo18 = s.invoiceValue[17]?.itemNo || '';
            let itemNo19 = s.invoiceValue[18]?.itemNo || '';
            let itemNo20 = s.invoiceValue[19]?.itemNo || '';
            let itemNo21 = s.invoiceValue[20]?.itemNo || '';
            let itemNo22 = s.invoiceValue[21]?.itemNo || '';
            let itemNo23 = s.invoiceValue[22]?.itemNo || '';
            let itemNo24 = s.invoiceValue[23]?.itemNo || '';
            let itemNo25 = s.invoiceValue[24]?.itemNo || '';
            let itemNo26 = s.invoiceValue[25]?.itemNo || '';
            let itemNo27 = s.invoiceValue[26]?.itemNo || '';
            let itemNo28 = s.invoiceValue[27]?.itemNo || '';
            let itemNo29 = s.invoiceValue[28]?.itemNo || '';
            let itemNo30 = s.invoiceValue[29]?.itemNo || '';
            let itemNo31 = s.invoiceValue[30]?.itemNo || '';
            let itemNo32 = s.invoiceValue[31]?.itemNo || '';
            let itemNo33 = s.invoiceValue[32]?.itemNo || '';
            let itemNo34 = s.invoiceValue[33]?.itemNo || '';
            let itemNo35 = s.invoiceValue[34]?.itemNo || '';
            let itemNo36 = s.invoiceValue[35]?.itemNo || '';
            let itemNo37 = s.invoiceValue[36]?.itemNo || '';
            let itemNo38 = s.invoiceValue[37]?.itemNo || '';
            let itemNo39 = s.invoiceValue[38]?.itemNo || '';
            let itemNo40 = s.invoiceValue[39]?.itemNo || '';
            let itemNo41 = s.invoiceValue[40]?.itemNo || '';
            let itemNo42 = s.invoiceValue[41]?.itemNo || '';
            let itemNo43 = s.invoiceValue[42]?.itemNo || '';
            let itemNo44 = s.invoiceValue[43]?.itemNo || '';
            let itemNo45 = s.invoiceValue[44]?.itemNo || '';
            let itemNo46 = s.invoiceValue[45]?.itemNo || '';
            let itemNo47 = s.invoiceValue[46]?.itemNo || '';
            let itemNo48 = s.invoiceValue[47]?.itemNo || '';
            let itemNo49 = s.invoiceValue[48]?.itemNo || '';
            let itemNo50 = s.invoiceValue[49]?.itemNo || '';
            let itemNo51 = s.invoiceValue[50]?.itemNo || '';
            let itemNo52 = s.invoiceValue[51]?.itemNo || '';
            let itemNo53 = s.invoiceValue[52]?.itemNo || '';
            let itemNo54 = s.invoiceValue[53]?.itemNo || '';
            let itemNo55 = s.invoiceValue[54]?.itemNo || '';
            let itemNo56 = s.invoiceValue[55]?.itemNo || '';
            let itemNo57 = s.invoiceValue[56]?.itemNo || '';
            let itemNo58 = s.invoiceValue[57]?.itemNo || '';
            let itemNo59 = s.invoiceValue[58]?.itemNo || '';
            let itemNo60 = s.invoiceValue[59]?.itemNo || '';
            let itemNo61 = s.invoiceValue[60]?.itemNo || '';
            let itemNo62 = s.invoiceValue[61]?.itemNo || '';
            let itemNo63 = s.invoiceValue[62]?.itemNo || '';
            let itemNo64 = s.invoiceValue[63]?.itemNo || '';
            let itemNo65 = s.invoiceValue[64]?.itemNo || '';
            let itemNo66 = s.invoiceValue[65]?.itemNo || '';
            let itemNo67 = s.invoiceValue[66]?.itemNo || '';
            let itemNo68 = s.invoiceValue[67]?.itemNo || '';
            let itemNo69 = s.invoiceValue[68]?.itemNo || '';
            let itemNo70 = s.invoiceValue[69]?.itemNo || '';
            let itemNo71 = s.invoiceValue[70]?.itemNo || '';
            let itemNo72 = s.invoiceValue[71]?.itemNo || '';
            let itemNo73 = s.invoiceValue[72]?.itemNo || '';
            let itemNo74 = s.invoiceValue[73]?.itemNo || '';
            let itemNo75 = s.invoiceValue[74]?.itemNo || '';
            let itemNo76 = s.invoiceValue[75]?.itemNo || '';
            let itemNo77 = s.invoiceValue[76]?.itemNo || '';
            let itemNo78 = s.invoiceValue[77]?.itemNo || '';
            let itemNo79 = s.invoiceValue[78]?.itemNo || '';
            let itemNo80 = s.invoiceValue[79]?.itemNo || '';
            let itemNo81 = s.invoiceValue[80]?.itemNo || '';
            let itemNo82 = s.invoiceValue[81]?.itemNo || '';
            let itemNo83 = s.invoiceValue[82]?.itemNo || '';
            let itemNo84 = s.invoiceValue[83]?.itemNo || '';
            let itemNo85 = s.invoiceValue[84]?.itemNo || '';
            let itemNo86 = s.invoiceValue[85]?.itemNo || '';
            let itemNo87 = s.invoiceValue[86]?.itemNo || '';
            let itemNo88 = s.invoiceValue[87]?.itemNo || '';
            let itemNo89 = s.invoiceValue[88]?.itemNo || '';
            let itemNo90 = s.invoiceValue[89]?.itemNo || '';
            let itemNo91 = s.invoiceValue[90]?.itemNo || '';
            let itemNo92 = s.invoiceValue[91]?.itemNo || '';
            let itemNo93 = s.invoiceValue[92]?.itemNo || '';
            let itemNo94 = s.invoiceValue[93]?.itemNo || '';
            let itemNo95 = s.invoiceValue[94]?.itemNo || '';
            let itemNo96 = s.invoiceValue[95]?.itemNo || '';
            let itemNo97 = s.invoiceValue[96]?.itemNo || '';
            let itemNo98 = s.invoiceValue[97]?.itemNo || '';
            let itemNo99 = s.invoiceValue[98]?.itemNo || '';
            let itemNo100 = s.invoiceValue[99]?.itemNo || '';
            let quantity = s.invoiceValue[0]?.itemQuantity;
            let quantity2 = s.invoiceValue[1]?.itemQuantity || '';
            let quantity3 = s.invoiceValue[2]?.itemQuantity || '';
            let quantity4 = s.invoiceValue[3]?.itemQuantity || '';
            let quantity5 = s.invoiceValue[4]?.itemQuantity || '';
            let quantity6 = s.invoiceValue[5]?.itemQuantity || '';
            let quantity7 = s.invoiceValue[6]?.itemQuantity || '';
            let quantity8 = s.invoiceValue[7]?.itemQuantity || '';
            let quantity9 = s.invoiceValue[8]?.itemQuantity || '';
            let quantity10 = s.invoiceValue[9]?.itemQuantity || '';
            let quantity11 = s.invoiceValue[10]?.itemQuantity || '';
            let quantity12 = s.invoiceValue[11]?.itemQuantity || '';
            let quantity13 = s.invoiceValue[12]?.itemQuantity || '';
            let quantity14 = s.invoiceValue[13]?.itemQuantity || '';
            let quantity15 = s.invoiceValue[14]?.itemQuantity || '';
            let quantity16 = s.invoiceValue[15]?.itemQuantity || '';
            let quantity17 = s.invoiceValue[16]?.itemQuantity || '';
            let quantity18 = s.invoiceValue[17]?.itemQuantity || '';
            let quantity19 = s.invoiceValue[18]?.itemQuantity || '';
            let quantity20 = s.invoiceValue[19]?.itemQuantity || '';
            let quantity21 = s.invoiceValue[20]?.itemQuantity || '';
            let quantity22 = s.invoiceValue[21]?.itemQuantity || '';
            let quantity23 = s.invoiceValue[22]?.itemQuantity || '';
            let quantity24 = s.invoiceValue[23]?.itemQuantity || '';
            let quantity25 = s.invoiceValue[24]?.itemQuantity || '';
            let quantity26 = s.invoiceValue[25]?.itemQuantity || '';
            let quantity27 = s.invoiceValue[26]?.itemQuantity || '';
            let quantity28 = s.invoiceValue[27]?.itemQuantity || '';
            let quantity29 = s.invoiceValue[28]?.itemQuantity || '';
            let quantity30 = s.invoiceValue[29]?.itemQuantity || '';
            let quantity31 = s.invoiceValue[30]?.itemQuantity || '';
            let quantity32 = s.invoiceValue[31]?.itemQuantity || '';
            let quantity33 = s.invoiceValue[32]?.itemQuantity || '';
            let quantity34 = s.invoiceValue[33]?.itemQuantity || '';
            let quantity35 = s.invoiceValue[34]?.itemQuantity || '';
            let quantity36 = s.invoiceValue[35]?.itemQuantity || '';
            let quantity37 = s.invoiceValue[36]?.itemQuantity || '';
            let quantity38 = s.invoiceValue[37]?.itemQuantity || '';
            let quantity39 = s.invoiceValue[38]?.itemQuantity || '';
            let quantity40 = s.invoiceValue[39]?.itemQuantity || '';
            let quantity41 = s.invoiceValue[40]?.itemQuantity || '';
            let quantity42 = s.invoiceValue[41]?.itemQuantity || '';
            let quantity43 = s.invoiceValue[42]?.itemQuantity || '';
            let quantity44 = s.invoiceValue[43]?.itemQuantity || '';
            let quantity45 = s.invoiceValue[44]?.itemQuantity || '';
            let quantity46 = s.invoiceValue[45]?.itemQuantity || '';
            let quantity47 = s.invoiceValue[46]?.itemQuantity || '';
            let quantity48 = s.invoiceValue[47]?.itemQuantity || '';
            let quantity49 = s.invoiceValue[48]?.itemQuantity || '';
            let quantity50 = s.invoiceValue[49]?.itemQuantity || '';
            let quantity51 = s.invoiceValue[50]?.itemQuantity || '';
            let quantity52 = s.invoiceValue[51]?.itemQuantity || '';
            let quantity53 = s.invoiceValue[52]?.itemQuantity || '';
            let quantity54 = s.invoiceValue[53]?.itemQuantity || '';
            let quantity55 = s.invoiceValue[54]?.itemQuantity || '';
            let quantity56 = s.invoiceValue[55]?.itemQuantity || '';
            let quantity57 = s.invoiceValue[56]?.itemQuantity || '';
            let quantity58 = s.invoiceValue[57]?.itemQuantity || '';
            let quantity59 = s.invoiceValue[58]?.itemQuantity || '';
            let quantity60 = s.invoiceValue[59]?.itemQuantity || '';
            let quantity61 = s.invoiceValue[60]?.itemQuantity || '';
            let quantity62 = s.invoiceValue[61]?.itemQuantity || '';
            let quantity63 = s.invoiceValue[62]?.itemQuantity || '';
            let quantity64 = s.invoiceValue[63]?.itemQuantity || '';
            let quantity65 = s.invoiceValue[64]?.itemQuantity || '';
            let quantity66 = s.invoiceValue[65]?.itemQuantity || '';
            let quantity67 = s.invoiceValue[66]?.itemQuantity || '';
            let quantity68 = s.invoiceValue[67]?.itemQuantity || '';
            let quantity69 = s.invoiceValue[68]?.itemQuantity || '';
            let quantity70 = s.invoiceValue[69]?.itemQuantity || '';
            let quantity71 = s.invoiceValue[70]?.itemQuantity || '';
            let quantity72 = s.invoiceValue[71]?.itemQuantity || '';
            let quantity73 = s.invoiceValue[72]?.itemQuantity || '';
            let quantity74 = s.invoiceValue[73]?.itemQuantity || '';
            let quantity75 = s.invoiceValue[74]?.itemQuantity || '';
            let quantity76 = s.invoiceValue[75]?.itemQuantity || '';
            let quantity77 = s.invoiceValue[76]?.itemQuantity || '';
            let quantity78 = s.invoiceValue[77]?.itemQuantity || '';
            let quantity79 = s.invoiceValue[78]?.itemQuantity || '';
            let quantity80 = s.invoiceValue[79]?.itemQuantity || '';
            let quantity81 = s.invoiceValue[80]?.itemQuantity || '';
            let quantity82 = s.invoiceValue[81]?.itemQuantity || '';
            let quantity83 = s.invoiceValue[82]?.itemQuantity || '';
            let quantity84 = s.invoiceValue[83]?.itemQuantity || '';
            let quantity85 = s.invoiceValue[84]?.itemQuantity || '';
            let quantity86 = s.invoiceValue[85]?.itemQuantity || '';
            let quantity87 = s.invoiceValue[86]?.itemQuantity || '';
            let quantity88 = s.invoiceValue[87]?.itemQuantity || '';
            let quantity89 = s.invoiceValue[88]?.itemQuantity || '';
            let quantity90 = s.invoiceValue[89]?.itemQuantity || '';
            let quantity91 = s.invoiceValue[90]?.itemQuantity || '';
            let quantity92 = s.invoiceValue[91]?.itemQuantity || '';
            let quantity93 = s.invoiceValue[92]?.itemQuantity || '';
            let quantity94 = s.invoiceValue[93]?.itemQuantity || '';
            let quantity95 = s.invoiceValue[94]?.itemQuantity || '';
            let quantity96 = s.invoiceValue[95]?.itemQuantity || '';
            let quantity97 = s.invoiceValue[96]?.itemQuantity || '';
            let quantity98 = s.invoiceValue[97]?.itemQuantity || '';
            let quantity99 = s.invoiceValue[98]?.itemQuantity || '';
            let quantity100 = s.invoiceValue[99]?.itemQuantity || '';
            let unit = s.invoiceValue[0]?.itemUnit;
            let unit2 = s.invoiceValue[1]?.itemUnit || '';
            let unit3 = s.invoiceValue[2]?.itemUnit || '';
            let unit4 = s.invoiceValue[3]?.itemUnit || '';
            let unit5 = s.invoiceValue[4]?.itemUnit || '';
            let unit6 = s.invoiceValue[5]?.itemUnit || '';
            let unit7 = s.invoiceValue[6]?.itemUnit || '';
            let unit8 = s.invoiceValue[7]?.itemUnit || '';
            let unit9 = s.invoiceValue[8]?.itemUnit || '';
            let unit10 = s.invoiceValue[9]?.itemUnit || '';
            let unit11 = s.invoiceValue[10]?.itemUnit || '';
            let unit12 = s.invoiceValue[11]?.itemUnit || '';
            let unit13 = s.invoiceValue[12]?.itemUnit || '';
            let unit14 = s.invoiceValue[13]?.itemUnit || '';
            let unit15 = s.invoiceValue[14]?.itemUnit || '';
            let unit16 = s.invoiceValue[15]?.itemUnit || '';
            let unit17 = s.invoiceValue[16]?.itemUnit || '';
            let unit18 = s.invoiceValue[17]?.itemUnit || '';
            let unit19 = s.invoiceValue[18]?.itemUnit || '';
            let unit20 = s.invoiceValue[19]?.itemUnit || '';
            let unit21 = s.invoiceValue[20]?.itemUnit || '';
            let unit22 = s.invoiceValue[21]?.itemUnit || '';
            let unit23 = s.invoiceValue[22]?.itemUnit || '';
            let unit24 = s.invoiceValue[23]?.itemUnit || '';
            let unit25 = s.invoiceValue[24]?.itemUnit || '';
            let unit26 = s.invoiceValue[25]?.itemUnit || '';
            let unit27 = s.invoiceValue[26]?.itemUnit || '';
            let unit28 = s.invoiceValue[27]?.itemUnit || '';
            let unit29 = s.invoiceValue[28]?.itemUnit || '';
            let unit30 = s.invoiceValue[29]?.itemUnit || '';
            let unit31 = s.invoiceValue[30]?.itemUnit || '';
            let unit32 = s.invoiceValue[31]?.itemUnit || '';
            let unit33 = s.invoiceValue[32]?.itemUnit || '';
            let unit34 = s.invoiceValue[33]?.itemUnit || '';
            let unit35 = s.invoiceValue[34]?.itemUnit || '';
            let unit36 = s.invoiceValue[35]?.itemUnit || '';
            let unit37 = s.invoiceValue[36]?.itemUnit || '';
            let unit38 = s.invoiceValue[37]?.itemUnit || '';
            let unit39 = s.invoiceValue[38]?.itemUnit || '';
            let unit40 = s.invoiceValue[39]?.itemUnit || '';
            let unit41 = s.invoiceValue[40]?.itemUnit || '';
            let unit42 = s.invoiceValue[41]?.itemUnit || '';
            let unit43 = s.invoiceValue[42]?.itemUnit || '';
            let unit44 = s.invoiceValue[43]?.itemUnit || '';
            let unit45 = s.invoiceValue[44]?.itemUnit || '';
            let unit46 = s.invoiceValue[45]?.itemUnit || '';
            let unit47 = s.invoiceValue[46]?.itemUnit || '';
            let unit48 = s.invoiceValue[47]?.itemUnit || '';
            let unit49 = s.invoiceValue[48]?.itemUnit || '';
            let unit50 = s.invoiceValue[49]?.itemUnit || '';
            let unit51 = s.invoiceValue[50]?.itemUnit || '';
            let unit52 = s.invoiceValue[51]?.itemUnit || '';
            let unit53 = s.invoiceValue[52]?.itemUnit || '';
            let unit54 = s.invoiceValue[53]?.itemUnit || '';
            let unit55 = s.invoiceValue[54]?.itemUnit || '';
            let unit56 = s.invoiceValue[55]?.itemUnit || '';
            let unit57 = s.invoiceValue[56]?.itemUnit || '';
            let unit58 = s.invoiceValue[57]?.itemUnit || '';
            let unit59 = s.invoiceValue[58]?.itemUnit || '';
            let unit60 = s.invoiceValue[59]?.itemUnit || '';
            let unit61 = s.invoiceValue[60]?.itemUnit || '';
            let unit62 = s.invoiceValue[61]?.itemUnit || '';
            let unit63 = s.invoiceValue[62]?.itemUnit || '';
            let unit64 = s.invoiceValue[63]?.itemUnit || '';
            let unit65 = s.invoiceValue[64]?.itemUnit || '';
            let unit66 = s.invoiceValue[65]?.itemUnit || '';
            let unit67 = s.invoiceValue[66]?.itemUnit || '';
            let unit68 = s.invoiceValue[67]?.itemUnit || '';
            let unit69 = s.invoiceValue[68]?.itemUnit || '';
            let unit70 = s.invoiceValue[69]?.itemUnit || '';
            let unit71 = s.invoiceValue[70]?.itemUnit || '';
            let unit72 = s.invoiceValue[71]?.itemUnit || '';
            let unit73 = s.invoiceValue[72]?.itemUnit || '';
            let unit74 = s.invoiceValue[73]?.itemUnit || '';
            let unit75 = s.invoiceValue[74]?.itemUnit || '';
            let unit76 = s.invoiceValue[75]?.itemUnit || '';
            let unit77 = s.invoiceValue[76]?.itemUnit || '';
            let unit78 = s.invoiceValue[77]?.itemUnit || '';
            let unit79 = s.invoiceValue[78]?.itemUnit || '';
            let unit80 = s.invoiceValue[79]?.itemUnit || '';
            let unit81 = s.invoiceValue[80]?.itemUnit || '';
            let unit82 = s.invoiceValue[81]?.itemUnit || '';
            let unit83 = s.invoiceValue[82]?.itemUnit || '';
            let unit84 = s.invoiceValue[83]?.itemUnit || '';
            let unit85 = s.invoiceValue[84]?.itemUnit || '';
            let unit86 = s.invoiceValue[85]?.itemUnit || '';
            let unit87 = s.invoiceValue[86]?.itemUnit || '';
            let unit88 = s.invoiceValue[87]?.itemUnit || '';
            let unit89 = s.invoiceValue[88]?.itemUnit || '';
            let unit90 = s.invoiceValue[89]?.itemUnit || '';
            let unit91 = s.invoiceValue[90]?.itemUnit || '';
            let unit92 = s.invoiceValue[91]?.itemUnit || '';
            let unit93 = s.invoiceValue[92]?.itemUnit || '';
            let unit94 = s.invoiceValue[93]?.itemUnit || '';
            let unit95 = s.invoiceValue[94]?.itemUnit || '';
            let unit96 = s.invoiceValue[95]?.itemUnit || '';
            let unit97 = s.invoiceValue[96]?.itemUnit || '';
            let unit98 = s.invoiceValue[97]?.itemUnit || '';
            let unit99 = s.invoiceValue[98]?.itemUnit || '';
            let unit100 = s.invoiceValue[99]?.itemUnit || '';
            let itemName = s.invoiceValue[0]?.itemCommonity;
            let itemName2 = s.invoiceValue[1]?.itemCommonity || '';
            let itemName3 = s.invoiceValue[2]?.itemCommonity || '';
            let itemName4 = s.invoiceValue[3]?.itemCommonity || '';
            let itemName5 = s.invoiceValue[4]?.itemCommonity || '';
            let itemName6 = s.invoiceValue[5]?.itemCommonity || '';
            let itemName7 = s.invoiceValue[6]?.itemCommonity || '';
            let itemName8 = s.invoiceValue[7]?.itemCommonity || '';
            let itemName9 = s.invoiceValue[8]?.itemCommonity || '';
            let itemName10 = s.invoiceValue[9]?.itemCommonity || '';
            let itemName11 = s.invoiceValue[10]?.itemCommonity || '';
            let itemName12 = s.invoiceValue[11]?.itemCommonity || '';
            let itemName13 = s.invoiceValue[12]?.itemCommonity || '';
            let itemName14 = s.invoiceValue[13]?.itemCommonity || '';
            let itemName15 = s.invoiceValue[14]?.itemCommonity || '';
            let itemName16 = s.invoiceValue[15]?.itemCommonity || '';
            let itemName17 = s.invoiceValue[16]?.itemCommonity || '';
            let itemName18 = s.invoiceValue[17]?.itemCommonity || '';
            let itemName19 = s.invoiceValue[18]?.itemCommonity || '';
            let itemName20 = s.invoiceValue[19]?.itemCommonity || '';
            let itemName21 = s.invoiceValue[20]?.itemCommonity || '';
            let itemName22 = s.invoiceValue[21]?.itemCommonity || '';
            let itemName23 = s.invoiceValue[22]?.itemCommonity || '';
            let itemName24 = s.invoiceValue[23]?.itemCommonity || '';
            let itemName25 = s.invoiceValue[24]?.itemCommonity || '';
            let itemName26 = s.invoiceValue[25]?.itemCommonity || '';
            let itemName27 = s.invoiceValue[26]?.itemCommonity || '';
            let itemName28 = s.invoiceValue[27]?.itemCommonity || '';
            let itemName29 = s.invoiceValue[28]?.itemCommonity || '';
            let itemName30 = s.invoiceValue[29]?.itemCommonity || '';
            let itemName31 = s.invoiceValue[30]?.itemCommonity || '';
            let itemName32 = s.invoiceValue[31]?.itemCommonity || '';
            let itemName33 = s.invoiceValue[32]?.itemCommonity || '';
            let itemName34 = s.invoiceValue[33]?.itemCommonity || '';
            let itemName35 = s.invoiceValue[34]?.itemCommonity || '';
            let itemName36 = s.invoiceValue[35]?.itemCommonity || '';
            let itemName37 = s.invoiceValue[36]?.itemCommonity || '';
            let itemName38 = s.invoiceValue[37]?.itemCommonity || '';
            let itemName39 = s.invoiceValue[38]?.itemCommonity || '';
            let itemName40 = s.invoiceValue[39]?.itemCommonity || '';
            let itemName41 = s.invoiceValue[40]?.itemCommonity || '';
            let itemName42 = s.invoiceValue[41]?.itemCommonity || '';
            let itemName43 = s.invoiceValue[42]?.itemCommonity || '';
            let itemName44 = s.invoiceValue[43]?.itemCommonity || '';
            let itemName45 = s.invoiceValue[44]?.itemCommonity || '';
            let itemName46 = s.invoiceValue[45]?.itemCommonity || '';
            let itemName47 = s.invoiceValue[46]?.itemCommonity || '';
            let itemName48 = s.invoiceValue[47]?.itemCommonity || '';
            let itemName49 = s.invoiceValue[48]?.itemCommonity || '';
            let itemName50 = s.invoiceValue[49]?.itemCommonity || '';
            let itemName51 = s.invoiceValue[50]?.itemCommonity || '';
            let itemName52 = s.invoiceValue[51]?.itemCommonity || '';
            let itemName53 = s.invoiceValue[52]?.itemCommonity || '';
            let itemName54 = s.invoiceValue[53]?.itemCommonity || '';
            let itemName55 = s.invoiceValue[54]?.itemCommonity || '';
            let itemName56 = s.invoiceValue[55]?.itemCommonity || '';
            let itemName57 = s.invoiceValue[56]?.itemCommonity || '';
            let itemName58 = s.invoiceValue[57]?.itemCommonity || '';
            let itemName59 = s.invoiceValue[58]?.itemCommonity || '';
            let itemName60 = s.invoiceValue[59]?.itemCommonity || '';
            let itemName61 = s.invoiceValue[60]?.itemCommonity || '';
            let itemName62 = s.invoiceValue[61]?.itemCommonity || '';
            let itemName63 = s.invoiceValue[62]?.itemCommonity || '';
            let itemName64 = s.invoiceValue[63]?.itemCommonity || '';
            let itemName65 = s.invoiceValue[64]?.itemCommonity || '';
            let itemName66 = s.invoiceValue[65]?.itemCommonity || '';
            let itemName67 = s.invoiceValue[66]?.itemCommonity || '';
            let itemName68 = s.invoiceValue[67]?.itemCommonity || '';
            let itemName69 = s.invoiceValue[68]?.itemCommonity || '';
            let itemName70 = s.invoiceValue[69]?.itemCommonity || '';
            let itemName71 = s.invoiceValue[70]?.itemCommonity || '';
            let itemName72 = s.invoiceValue[71]?.itemCommonity || '';
            let itemName73 = s.invoiceValue[72]?.itemCommonity || '';
            let itemName74 = s.invoiceValue[73]?.itemCommonity || '';
            let itemName75 = s.invoiceValue[74]?.itemCommonity || '';
            let itemName76 = s.invoiceValue[75]?.itemCommonity || '';
            let itemName77 = s.invoiceValue[76]?.itemCommonity || '';
            let itemName78 = s.invoiceValue[77]?.itemCommonity || '';
            let itemName79 = s.invoiceValue[78]?.itemCommonity || '';
            let itemName80 = s.invoiceValue[79]?.itemCommonity || '';
            let itemName81 = s.invoiceValue[80]?.itemCommonity || '';
            let itemName82 = s.invoiceValue[81]?.itemCommonity || '';
            let itemName83 = s.invoiceValue[82]?.itemCommonity || '';
            let itemName84 = s.invoiceValue[83]?.itemCommonity || '';
            let itemName85 = s.invoiceValue[84]?.itemCommonity || '';
            let itemName86 = s.invoiceValue[85]?.itemCommonity || '';
            let itemName87 = s.invoiceValue[86]?.itemCommonity || '';
            let itemName88 = s.invoiceValue[87]?.itemCommonity || '';
            let itemName89 = s.invoiceValue[88]?.itemCommonity || '';
            let itemName90 = s.invoiceValue[89]?.itemCommonity || '';
            let itemName91 = s.invoiceValue[90]?.itemCommonity;
            let itemName92 = s.invoiceValue[91]?.itemCommonity || '';
            let itemName93 = s.invoiceValue[92]?.itemCommonity || '';
            let itemName94 = s.invoiceValue[93]?.itemCommonity || '';
            let itemName95 = s.invoiceValue[94]?.itemCommonity || '';
            let itemName96 = s.invoiceValue[95]?.itemCommonity || '';
            let itemName97 = s.invoiceValue[96]?.itemCommonity || '';
            let itemName98 = s.invoiceValue[97]?.itemCommonity || '';
            let itemName99 = s.invoiceValue[98]?.itemCommonity || '';
            let itemName100 = s.invoiceValue[99]?.itemCommonity || '';
            let unitPrice = s.invoiceValue[0]?.itemUnitPrice;
            let unitPrice2 = s.invoiceValue[1]?.itemUnitPrice || '';
            let unitPrice3 = s.invoiceValue[2]?.itemUnitPrice || '';
            let unitPrice4 = s.invoiceValue[3]?.itemUnitPrice || '';
            let unitPrice5 = s.invoiceValue[4]?.itemUnitPrice || '';
            let unitPrice6 = s.invoiceValue[5]?.itemUnitPrice || '';
            let unitPrice7 = s.invoiceValue[6]?.itemUnitPrice || '';
            let unitPrice8 = s.invoiceValue[7]?.itemUnitPrice || '';
            let unitPrice9 = s.invoiceValue[8]?.itemUnitPrice || '';
            let unitPrice10 = s.invoiceValue[9]?.itemUnitPrice || '';
            let unitPrice11 = s.invoiceValue[10]?.itemUnitPrice || '';
            let unitPrice12 = s.invoiceValue[11]?.itemUnitPrice || '';
            let unitPrice13 = s.invoiceValue[12]?.itemUnitPrice || '';
            let unitPrice14 = s.invoiceValue[13]?.itemUnitPrice || '';
            let unitPrice15 = s.invoiceValue[14]?.itemUnitPrice || '';
            let unitPrice16 = s.invoiceValue[15]?.itemUnitPrice || '';
            let unitPrice17 = s.invoiceValue[16]?.itemUnitPrice || '';
            let unitPrice18 = s.invoiceValue[17]?.itemUnitPrice || '';
            let unitPrice19 = s.invoiceValue[18]?.itemUnitPrice || '';
            let unitPrice20 = s.invoiceValue[19]?.itemUnitPrice || '';
            let unitPrice21 = s.invoiceValue[20]?.itemUnitPrice || '';
            let unitPrice22 = s.invoiceValue[21]?.itemUnitPrice || '';
            let unitPrice23 = s.invoiceValue[22]?.itemUnitPrice || '';
            let unitPrice24 = s.invoiceValue[23]?.itemUnitPrice || '';
            let unitPrice25 = s.invoiceValue[24]?.itemUnitPrice || '';
            let unitPrice26 = s.invoiceValue[25]?.itemUnitPrice || '';
            let unitPrice27 = s.invoiceValue[26]?.itemUnitPrice || '';
            let unitPrice28 = s.invoiceValue[27]?.itemUnitPrice || '';
            let unitPrice29 = s.invoiceValue[28]?.itemUnitPrice || '';
            let unitPrice30 = s.invoiceValue[29]?.itemUnitPrice || '';
            let unitPrice31 = s.invoiceValue[30]?.itemUnitPrice || '';
            let unitPrice32 = s.invoiceValue[31]?.itemUnitPrice || '';
            let unitPrice33 = s.invoiceValue[32]?.itemUnitPrice || '';
            let unitPrice34 = s.invoiceValue[33]?.itemUnitPrice || '';
            let unitPrice35 = s.invoiceValue[34]?.itemUnitPrice || '';
            let unitPrice36 = s.invoiceValue[35]?.itemUnitPrice || '';
            let unitPrice37 = s.invoiceValue[36]?.itemUnitPrice || '';
            let unitPrice38 = s.invoiceValue[37]?.itemUnitPrice || '';
            let unitPrice39 = s.invoiceValue[38]?.itemUnitPrice || '';
            let unitPrice40 = s.invoiceValue[39]?.itemUnitPrice || '';
            let unitPrice41 = s.invoiceValue[40]?.itemUnitPrice || '';
            let unitPrice42 = s.invoiceValue[41]?.itemUnitPrice || '';
            let unitPrice43 = s.invoiceValue[42]?.itemUnitPrice || '';
            let unitPrice44 = s.invoiceValue[43]?.itemUnitPrice || '';
            let unitPrice45 = s.invoiceValue[44]?.itemUnitPrice || '';
            let unitPrice46 = s.invoiceValue[45]?.itemUnitPrice || '';
            let unitPrice47 = s.invoiceValue[46]?.itemUnitPrice || '';
            let unitPrice48 = s.invoiceValue[47]?.itemUnitPrice || '';
            let unitPrice49 = s.invoiceValue[48]?.itemUnitPrice || '';
            let unitPrice50 = s.invoiceValue[49]?.itemUnitPrice || '';
            let unitPrice51 = s.invoiceValue[50]?.itemUnitPrice || '';
            let unitPrice52 = s.invoiceValue[51]?.itemUnitPrice || '';
            let unitPrice53 = s.invoiceValue[52]?.itemUnitPrice || '';
            let unitPrice54 = s.invoiceValue[53]?.itemUnitPrice || '';
            let unitPrice55 = s.invoiceValue[54]?.itemUnitPrice || '';
            let unitPrice56 = s.invoiceValue[55]?.itemUnitPrice || '';
            let unitPrice57 = s.invoiceValue[56]?.itemUnitPrice || '';
            let unitPrice58 = s.invoiceValue[57]?.itemUnitPrice || '';
            let unitPrice59 = s.invoiceValue[58]?.itemUnitPrice || '';
            let unitPrice60 = s.invoiceValue[59]?.itemUnitPrice || '';
            let unitPrice61 = s.invoiceValue[60]?.itemUnitPrice || '';
            let unitPrice62 = s.invoiceValue[61]?.itemUnitPrice || '';
            let unitPrice63 = s.invoiceValue[62]?.itemUnitPrice || '';
            let unitPrice64 = s.invoiceValue[63]?.itemUnitPrice || '';
            let unitPrice65 = s.invoiceValue[64]?.itemUnitPrice || '';
            let unitPrice66 = s.invoiceValue[65]?.itemUnitPrice || '';
            let unitPrice67 = s.invoiceValue[66]?.itemUnitPrice || '';
            let unitPrice68 = s.invoiceValue[67]?.itemUnitPrice || '';
            let unitPrice69 = s.invoiceValue[68]?.itemUnitPrice || '';
            let unitPrice70 = s.invoiceValue[69]?.itemUnitPrice || '';
            let unitPrice71 = s.invoiceValue[70]?.itemUnitPrice || '';
            let unitPrice72 = s.invoiceValue[71]?.itemUnitPrice || '';
            let unitPrice73 = s.invoiceValue[72]?.itemUnitPrice || '';
            let unitPrice74 = s.invoiceValue[73]?.itemUnitPrice || '';
            let unitPrice75 = s.invoiceValue[74]?.itemUnitPrice || '';
            let unitPrice76 = s.invoiceValue[75]?.itemUnitPrice || '';
            let unitPrice77 = s.invoiceValue[76]?.itemUnitPrice || '';
            let unitPrice78 = s.invoiceValue[77]?.itemUnitPrice || '';
            let unitPrice79 = s.invoiceValue[78]?.itemUnitPrice || '';
            let unitPrice80 = s.invoiceValue[79]?.itemUnitPrice || '';
            let unitPrice81 = s.invoiceValue[80]?.itemUnitPrice || '';
            let unitPrice82 = s.invoiceValue[81]?.itemUnitPrice || '';
            let unitPrice83 = s.invoiceValue[82]?.itemUnitPrice || '';
            let unitPrice84 = s.invoiceValue[83]?.itemUnitPrice || '';
            let unitPrice85 = s.invoiceValue[84]?.itemUnitPrice || '';
            let unitPrice86 = s.invoiceValue[85]?.itemUnitPrice || '';
            let unitPrice87 = s.invoiceValue[86]?.itemUnitPrice || '';
            let unitPrice88 = s.invoiceValue[87]?.itemUnitPrice || '';
            let unitPrice89 = s.invoiceValue[88]?.itemUnitPrice || '';
            let unitPrice90 = s.invoiceValue[89]?.itemUnitPrice || '';
            let unitPrice91 = s.invoiceValue[90]?.itemUnitPrice || '';
            let unitPrice92 = s.invoiceValue[91]?.itemUnitPrice || '';
            let unitPrice93 = s.invoiceValue[92]?.itemUnitPrice || '';
            let unitPrice94 = s.invoiceValue[93]?.itemUnitPrice || '';
            let unitPrice95 = s.invoiceValue[94]?.itemUnitPrice || '';
            let unitPrice96 = s.invoiceValue[95]?.itemUnitPrice || '';
            let unitPrice97 = s.invoiceValue[96]?.itemUnitPrice || '';
            let unitPrice98 = s.invoiceValue[97]?.itemUnitPrice || '';
            let unitPrice99 = s.invoiceValue[98]?.itemUnitPrice || '';
            let unitPrice100 = s.invoiceValue[99]?.itemUnitPrice || '';
            let itemGrossWeight = +s.totalCharge / s.invoiceValue.length;
            let itemGrossWeight2 = s.invoiceValue?.length >= 2 ? +s.totalCharge / s.invoiceValue.length : '';
            let itemGrossWeight3 = s.invoiceValue?.length >= 3 ? +s.totalCharge / s.invoiceValue.length : '';
            let itemGrossWeight4 = s.invoiceValue?.length >= 4 ? +s.totalCharge / s.invoiceValue.length : '';
            let itemGrossWeight5 = s.invoiceValue?.length >= 5 ? +s.totalCharge / s.invoiceValue.length : '';
            let itemGrossWeight6 = s.invoiceValue?.length >= 6 ? +s.totalCharge / s.invoiceValue.length : '';
            let itemGrossWeight7 = s.invoiceValue?.length >= 7 ? +s.totalCharge / s.invoiceValue.length : '';
            let itemGrossWeight8 = s.invoiceValue?.length >= 8 ? +s.totalCharge / s.invoiceValue.length : '';
            let itemGrossWeight9 = s.invoiceValue?.length >= 9 ? +s.totalCharge / s.invoiceValue.length : '';
            let itemGrossWeight10 = s.invoiceValue?.length >= 10 ? +s.totalCharge / s.invoiceValue.length : '';
            let itemGrossWeight11 = s.invoiceValue?.lnegth >= 11 ? +s.totalCharge / s.invoiceValue.length : '';
            let itemGrossWeight12 = s.invoiceValue?.length >= 12 ? +s.totalCharge / s.invoiceValue.length : '';
            let itemGrossWeight13 = s.invoiceValue?.length >= 13 ? +s.totalCharge / s.invoiceValue.length : '';
            let itemGrossWeight14 = s.invoiceValue?.length >= 14 ? +s.totalCharge / s.invoiceValue.length : '';
            let itemGrossWeight15 = s.invoiceValue?.length >= 15 ? +s.totalCharge / s.invoiceValue.length : '';
            let itemGrossWeight16 = s.invoiceValue?.length >= 16 ? +s.totalCharge / s.invoiceValue.length : '';
            let itemGrossWeight17 = s.invoiceValue?.length >= 17 ? +s.totalCharge / s.invoiceValue.length : '';
            let itemGrossWeight18 = s.invoiceValue?.length >= 18 ? +s.totalCharge / s.invoiceValue.length : '';
            let itemGrossWeight19 = s.invoiceValue?.length >= 19 ? +s.totalCharge / s.invoiceValue.length : '';
            let itemGrossWeight20 = s.invoiceValue?.length === 20 ? +s.totalCharge / s.invoiceValue.length : '';
            let itemNetWeight = itemGrossWeight - 0.5;
            let itemNetWeight2 = s.invoiceValue?.length >= 2 ? itemGrossWeight - 0.5 : '';
            let itemNetWeight3 = s.invoiceValue?.length >= 3 ? itemGrossWeight - 0.5 : '';
            let itemNetWeight4 = s.invoiceValue?.length >= 4 ? itemGrossWeight - 0.5 : '';
            let itemNetWeight5 = s.invoiceValue?.length >= 5 ? itemGrossWeight - 0.5 : '';
            let itemNetWeight6 = s.invoiceValue?.length >= 6 ? itemGrossWeight - 0.5 : '';
            let itemNetWeight7 = s.invoiceValue?.length >= 7 ? itemGrossWeight - 0.5 : '';
            let itemNetWeight8 = s.invoiceValue?.length >= 8 ? itemGrossWeight - 0.5 : '';
            let itemNetWeight9 = s.invoiceValue?.length >= 9 ? itemGrossWeight - 0.5 : '';
            let itemNetWeight10 = s.invoiceValue?.length >= 10 ? itemGrossWeight - 0.5 : '';
            let itemNetWeight11 = s.invoiceValue?.length >= 11 ? itemGrossWeight - 0.5 : '';
            let itemNetWeight12 = s.invoiceValue?.length >= 12 ? itemGrossWeight - 0.5 : '';
            let itemNetWeight13 = s.invoiceValue?.length >= 13 ? itemGrossWeight - 0.5 : '';
            let itemNetWeight14 = s.invoiceValue?.length >= 14 ? itemGrossWeight - 0.5 : '';
            let itemNetWeight15 = s.invoiceValue?.length >= 15 ? itemGrossWeight - 0.5 : '';
            let itemNetWeight16 = s.invoiceValue?.length >= 16 ? itemGrossWeight - 0.5 : '';
            let itemNetWeight17 = s.invoiceValue?.length >= 17 ? itemGrossWeight - 0.5 : '';
            let itemNetWeight18 = s.invoiceValue?.length >= 18 ? itemGrossWeight - 0.5 : '';
            let itemNetWeight19 = s.invoiceValue?.length >= 19 ? itemGrossWeight - 0.5 : '';
            let itemNetWeight20 = s.invoiceValue?.length === 20 ? itemGrossWeight - 0.5 : '';
            let hsCode = s.invoiceValue[0]?.itemHsCode;
            let hsCode2 = s.invoiceValue[1]?.itemHsCode || '';
            let hsCode3 = s.invoiceValue[2]?.itemHsCode || '';
            let hsCode4 = s.invoiceValue[3]?.itemHsCode || '';
            let hsCode5 = s.invoiceValue[4]?.itemHsCode || '';
            let hsCode6 = s.invoiceValue[5]?.itemHsCode || '';
            let hsCode7 = s.invoiceValue[6]?.itemHsCode || '';
            let hsCode8 = s.invoiceValue[7]?.itemHsCode || '';
            let hsCode9 = s.invoiceValue[8]?.itemHsCode || '';
            let hsCode10 = s.invoiceValue[9]?.itemHsCode || '';
            let hsCode11 = s.invoiceValue[10]?.itemHsCode || '';
            let hsCode12 = s.invoiceValue[11]?.itemHsCode || '';
            let hsCode13 = s.invoiceValue[12]?.itemHsCode || '';
            let hsCode14 = s.invoiceValue[13]?.itemHsCode || '';
            let hsCode15 = s.invoiceValue[14]?.itemHsCode || '';
            let hsCode16 = s.invoiceValue[15]?.itemHsCode || '';
            let hsCode17 = s.invoiceValue[16]?.itemHsCode || '';
            let hsCode18 = s.invoiceValue[17]?.itemHsCode || '';
            let hsCode19 = s.invoiceValue[18]?.itemHsCode || '';
            let hsCode20 = s.invoiceValue[19]?.itemHsCode || '';
            let hsCode21 = s.invoiceValue[20]?.itemHsCode || '';
            let hsCode22 = s.invoiceValue[21]?.itemHsCode || '';
            let hsCode23 = s.invoiceValue[22]?.itemHsCode || '';
            let hsCode24 = s.invoiceValue[23]?.itemHsCode || '';
            let hsCode25 = s.invoiceValue[24]?.itemHsCode || '';
            let hsCode26 = s.invoiceValue[25]?.itemHsCode || '';
            let hsCode27 = s.invoiceValue[26]?.itemHsCode || '';
            let hsCode28 = s.invoiceValue[27]?.itemHsCode || '';
            let hsCode29 = s.invoiceValue[28]?.itemHsCode || '';
            let hsCode30 = s.invoiceValue[29]?.itemHsCode || '';
            let hsCode31 = s.invoiceValue[30]?.itemHsCode || '';
            let hsCode32 = s.invoiceValue[31]?.itemHsCode || '';
            let hsCode33 = s.invoiceValue[32]?.itemHsCode || '';
            let hsCode34 = s.invoiceValue[33]?.itemHsCode || '';
            let hsCode35 = s.invoiceValue[34]?.itemHsCode || '';
            let hsCode36 = s.invoiceValue[35]?.itemHsCode || '';
            let hsCode37 = s.invoiceValue[36]?.itemHsCode || '';
            let hsCode38 = s.invoiceValue[37]?.itemHsCode || '';
            let hsCode39 = s.invoiceValue[38]?.itemHsCode || '';
            let hsCode40 = s.invoiceValue[39]?.itemHsCode || '';
            let hsCode41 = s.invoiceValue[40]?.itemHsCode || '';
            let hsCode42 = s.invoiceValue[41]?.itemHsCode || '';
            let hsCode43 = s.invoiceValue[42]?.itemHsCode || '';
            let hsCode44 = s.invoiceValue[43]?.itemHsCode || '';
            let hsCode45 = s.invoiceValue[44]?.itemHsCode || '';
            let hsCode46 = s.invoiceValue[45]?.itemHsCode || '';
            let hsCode47 = s.invoiceValue[46]?.itemHsCode || '';
            let hsCode48 = s.invoiceValue[47]?.itemHsCode || '';
            let hsCode49 = s.invoiceValue[48]?.itemHsCode || '';
            let hsCode50 = s.invoiceValue[49]?.itemHsCode || '';
            let hsCode51 = s.invoiceValue[50]?.itemHsCode || '';
            let hsCode52 = s.invoiceValue[51]?.itemHsCode || '';
            let hsCode53 = s.invoiceValue[52]?.itemHsCode || '';
            let hsCode54 = s.invoiceValue[53]?.itemHsCode || '';
            let hsCode55 = s.invoiceValue[54]?.itemHsCode || '';
            let hsCode56 = s.invoiceValue[55]?.itemHsCode || '';
            let hsCode57 = s.invoiceValue[56]?.itemHsCode || '';
            let hsCode58 = s.invoiceValue[57]?.itemHsCode || '';
            let hsCode59 = s.invoiceValue[58]?.itemHsCode || '';
            let hsCode60 = s.invoiceValue[59]?.itemHsCode || '';
            let hsCode61 = s.invoiceValue[60]?.itemHsCode || '' || '';
            let hsCode62 = s.invoiceValue[61]?.itemHsCode || '';
            let hsCode63 = s.invoiceValue[62]?.itemHsCode || '';
            let hsCode64 = s.invoiceValue[63]?.itemHsCode || '';
            let hsCode65 = s.invoiceValue[64]?.itemHsCode || '';
            let hsCode66 = s.invoiceValue[65]?.itemHsCode || '';
            let hsCode67 = s.invoiceValue[66]?.itemHsCode || '';
            let hsCode68 = s.invoiceValue[67]?.itemHsCode || '';
            let hsCode69 = s.invoiceValue[68]?.itemHsCode || '';
            let hsCode70 = s.invoiceValue[69]?.itemHsCode || '';
            let hsCode71 = s.invoiceValue[70]?.itemHsCode || '';
            let hsCode72 = s.invoiceValue[71]?.itemHsCode || '';
            let hsCode73 = s.invoiceValue[72]?.itemHsCode || '';
            let hsCode74 = s.invoiceValue[73]?.itemHsCode || '';
            let hsCode75 = s.invoiceValue[74]?.itemHsCode || '';
            let hsCode76 = s.invoiceValue[75]?.itemHsCode || '';
            let hsCode77 = s.invoiceValue[76]?.itemHsCode || '';
            let hsCode78 = s.invoiceValue[77]?.itemHsCode || '';
            let hsCode79 = s.invoiceValue[78]?.itemHsCode || '';
            let hsCode80 = s.invoiceValue[79]?.itemHsCode || '';
            let hsCode81 = s.invoiceValue[80]?.itemHsCode || '';
            let hsCode82 = s.invoiceValue[81]?.itemHsCode || '';
            let hsCode83 = s.invoiceValue[82]?.itemHsCode || '';
            let hsCode84 = s.invoiceValue[83]?.itemHsCode || '';
            let hsCode85 = s.invoiceValue[84]?.itemHsCode || '';
            let hsCode86 = s.invoiceValue[85]?.itemHsCode || '';
            let hsCode87 = s.invoiceValue[86]?.itemHsCode || '';
            let hsCode88 = s.invoiceValue[87]?.itemHsCode || '';
            let hsCode89 = s.invoiceValue[88]?.itemHsCode || '';
            let hsCode90 = s.invoiceValue[89]?.itemHsCode || '';
            let hsCode91 = s.invoiceValue[90]?.itemHsCode || '';
            let hsCode92 = s.invoiceValue[91]?.itemHsCode || '';
            let hsCode93 = s.invoiceValue[92]?.itemHsCode || '';
            let hsCode94 = s.invoiceValue[93]?.itemHsCode || '';
            let hsCode95 = s.invoiceValue[94]?.itemHsCode || '';
            let hsCode96 = s.invoiceValue[95]?.itemHsCode || '';
            let hsCode97 = s.invoiceValue[96]?.itemHsCode || '';
            let hsCode98 = s.invoiceValue[97]?.itemHsCode || '';
            let hsCode99 = s.invoiceValue[98]?.itemHsCode || '';
            let hsCode100 = s.invoiceValue[99]?.itemHsCode || '';
            let desc = s.description.split(/[,]+/);
            let lengthValue = s.packageValue[0]?.packageHeight || '';
            let widthValue = s.packageValue[0]?.packageWidth || '';
            let heightValue = s.packageValue[0]?.packageLength || '';

            let lengthValue2 = s.packageValue[1]?.packageHeight || '';
            let widthValue2 = s.packageValue[1]?.packageWidth || '';
            let heightValue2 = s.packageValue[1]?.packageLength || '';

            let lengthValue3 = s.packageValue[2]?.packageHeight || '';
            let widthValue3 = s.packageValue[2]?.packageWidth || '';
            let heightValue3 = s.packageValue[2]?.packageLength || '';

            let lengthValue4 = s.packageValue[3]?.packageHeight || '';
            let widthValue4 = s.packageValue[3]?.packageWidth || '';
            let heightValue4 = s.packageValue[3]?.packageLength || '';

            let lengthValue5 = s.packageValue[4]?.packageHeight || '';
            let widthValue5 = s.packageValue[4]?.packageWidth || '';
            let heightValue5 = s.packageValue[4]?.packageLength || '';

            let lengthValue6 = s.packageValue[5]?.packageHeight || '';
            let widthValue6 = s.packageValue[5]?.packageWidth || '';
            let heightValue6 = s.packageValue[5]?.packageLength || '';

            let lengthValue7 = s.packageValue[6]?.packageHeight || '';
            let widthValue7 = s.packageValue[6]?.packageWidth || '';
            let heightValue7 = s.packageValue[6]?.packageLength || '';

            let lengthValue8 = s.packageValue[7]?.packageHeight || '';
            let widthValue8 = s.packageValue[7]?.packageWidth || '';
            let heightValue8 = s.packageValue[7]?.packageLength || '';

            let lengthValue9 = s.packageValue[8]?.packageHeight || '';
            let widthValue9 = s.packageValue[8]?.packageWidth || '';
            let heightValue9 = s.packageValue[8]?.packageLength || '';

            let lengthValue10 = s.packageValue[9]?.packageHeight || '';
            let widthValue10 = s.packageValue[9]?.packageWidth || '';
            let heightValue10 = s.packageValue[9]?.packageLength || '';

            let lengthValue11 = s.packageValue[10]?.packageHeight || '';
            let widthValue11 = s.packageValue[10]?.packageWidth || '';
            let heightValue11 = s.packageValue[10]?.packageLength || '';

            let lengthValue12 = s.packageValue[11]?.packageHeight || '';
            let widthValue12 = s.packageValue[11]?.packageWidth || '';
            let heightValue12 = s.packageValue[11]?.packageLength || '';

            let lengthValue13 = s.packageValue[12]?.packageHeight || '';
            let widthValue13 = s.packageValue[12]?.packageWidth || '';
            let heightValue13 = s.packageValue[12]?.packageLength || '';

            let lengthValue14 = s.packageValue[13]?.packageHeight || '';
            let widthValue14 = s.packageValue[13]?.packageWidth || '';
            let heightValue14 = s.packageValue[13]?.packageLength || '';

            let lengthValue15 = s.packageValue[14]?.packageHeight || '';
            let widthValue15 = s.packageValue[14]?.packageWidth || '';
            let heightValue15 = s.packageValue[14]?.packageLength || '';

            let lengthValue16 = s.packageValue[15]?.packageHeight || '';
            let widthValue16 = s.packageValue[15]?.packageWidth || '';
            let heightValue16 = s.packageValue[15]?.packageLength || '';

            let lengthValue17 = s.packageValue[16]?.packageHeight || '';
            let widthValue17 = s.packageValue[16]?.packageWidth || '';
            let heightValue17 = s.packageValue[16]?.packageLength || '';

            let lengthValue18 = s.packageValue[17]?.packageHeight || '';
            let widthValue18 = s.packageValue[17]?.packageWidth || '';
            let heightValue18 = s.packageValue[17]?.packageLength || '';

            let lengthValue19 = s.packageValue[18]?.packageHeight || '';
            let widthValue19 = s.packageValue[18]?.packageWidth || '';
            let heightValue19 = s.packageValue[18]?.packageLength || '';

            let lengthValue20 = s.packageValue[19]?.packageHeight || '';
            let widthValue20 = s.packageValue[19]?.packageWidth || '';
            let heightValue20 = s.packageValue[19]?.packageLength || '';
            const originalDateTime = new Date(s.pickupScheduled);
            const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
            const formattedDateTime = originalDateTime.toLocaleDateString(undefined, options);

            const date = s.timestamp.toDate();

            // Months array for mapping month numbers to month names
            const months = [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December',
            ];

            // Get the month, day, year, hours, and minutes
            const month = months[date.getMonth()];
            const day = date.getDate();
            const year = date.getFullYear();
            let hours = date.getHours();
            let minutes = date.getMinutes();

            // Format hours to have leading zero if needed
            hours = hours < 10 ? `0${hours}` : hours;

            // Format minutes to have leading zero if needed
            minutes = minutes < 10 ? `0${minutes}` : minutes;
            const mergeDate = `${month} ${day}, ${year} - ${hours}:${minutes}`;
            const mergeAddress = `${s.toAddress3}, ${s.toCity}`;
            return {
              updated: updated,
              select: select,
              descArr: desc,
              no: i + 1,
              cancel: s.cancel || 'booking',
              booked: s.booked,
              account: s.account || '',
              currency: s.currency || '',
              service: s.service || 'SKD',
              date: s.createAt || '',
              dateTime: mergeDate,
              cleDate: s.createAt.replaceAll(/-/g, ''),
              hawb: s.hawb || '',
              mawb: s.mawb || '',
              makh: s.makh || '',
              incoterm: 'DAP',
              satDev: 'N',
              numberOfLine: s.shipmentType === 'WPX' ? s.invoiceValue?.length : 1,
              removeSkd: removeSkd,
              negative: 1,
              company: s.importer || '',
              contact: s.toName || '',
              type: s.shipmentType || '',
              status: s.status || '',
              term: s.term || '',
              time: s.time || '',
              fromAddress: s.fromAddress || '',
              fromAddress2: s.fromAddress2 || '.',
              fromAddress3: s.fromAddress3 || '.',
              address: s.toAddress || '',
              address2: s.toAddress2 || '.',
              address3: s.toAddress3 || '.',
              city: s.toCity || '',
              country: s.toCountry || '',
              bookedBy: s.bookedBy || 'Label not created',
              fromCountry: s.fromCountry || '',
              phone: s.toPhone.replace(/[&\/\\#^+()$~%.'":*?<>{}!@]/g, '') || '',
              postal: s.toPostal || '',
              toState: s.toState || '',
              cleState: s.stateLabel,
              term: s.term,
              deliverySign: s.deliverySign,
              pickupScheduled: s.pickupScheduled === 'T:00' ? 'Pending' : formattedDateTime || 'Pending',
              fromPhoneType: 'O',
              town: s.toTown || '',
              chargeable: (Math.round(s.totalCharge * 100) / 100).toFixed(2).toString() || '',
              amount: s.totalPackage || '',
              amountPcs: `${s.totalPackage} pcs`,
              totalItem: s.totalItem || '',
              transport: s.transportation || '',
              description: s.description || '',
              descriptionCut: s.description.slice(0, 60).replaceAll(',', ''),
              cleDesc: s.shipmentType === 'WPX' ? s.description.slice(0, 168) : 'Documents - general business',
              controller: s.controller || '',
              eamFormat: eamFormat,
              toPhoneCode: s.phoneCode || '',
              toPhoneType: 'M',
              value: s.shipmentType === 'DOX' ? '0.00' : value,
              airline: s.airline || '',
              reweight: s.reweight || '',
              cleShipmentType: s.shipmentType === 'WPX' ? 'P' : 'D',
              agent: s.agent || '',
              etd: s.etd || '',
              subStatus: s.subStatus || '',
              sin: s.sin === 'Null' ? 'Pending' : s.sin,
              sender: removeAccents(s.exporter) || '',
              note: s.note || '',
              perform: s.perform || '',
              rate: s.rate || '',
              fsc: s.fsc || '',
              vat: s.vat || '',
              remote: s.remote || '',
              comestic: s.comestic || '',
              isMerged: s.isMerged,
              addressCorrection: s.addressCorrection || '',
              totalValue: s.totalValue || '',
              surcharge: s.surcharge || '',
              surcharge2: s.surcharge2 || '',
              surcharge3: s.surcharge3 || '',
              vatValue: s.vatValue || '',
              sellNote: s.sellNote || '',
              paymentPickup: s.paymentPickup || '',
              paymentAcc: s.paymentAcc || '',
              paymentCompany: s.paymentCompany || '',
              fromEmail: s.fromEmail || '',
              fromCity: s.fromCity || '',
              com: s.com || '',
              rateUpdate: s.rateUpdate || '',
              paymentStatus: s.paymentStatus || '',
              paymentSubStatus: s.paymentSubStatus || '',
              docs: s.shipmentType === 'WPX' ? `${s.invoiceValue.length} items` : 'DOX',
              code: s.code || '',
              eamService: s.service === 'DHL' ? 'INT' : s.service,
              fromPhoneCode: '84',
              dutyPaid: 0,
              pack: s.pack || '',
              d3pSenderName: `${s.exporter} c/o Asendia Singapore`,
              d3pConsigneeName: s.contact,
              serviceType: 'EPAQSCT-CUP-PMDA_SG',
              plan: s.plan || 'Pending',
              invoiceValue: s.shipmentType === 'WPX' ? s.invoiceValue : [],
              packageValue: s.shipmentType === 'WPX' ? s.packageValue : [],
              term: s.term || '.',
              reason: s.usage?.charAt(0).toUpperCase() + s.usage?.slice(1).toLowerCase() || '.',
              fromName: s.fromName || '',
              fromPostal: s.fromPostal || '',
              fromCode: 'VN',
              fromPhone: s.fromPhone,
              invoiceValue: s.invoiceValue,
              packageValue: s.packageValue,
              pickupBy: s.pickupBy,
              receiveBy: s.receiveBy,
              processBy: s.processBy,
              clearanceBy: s.clearanceBy,
              clearedBy: s.clearedBy,
              holdBy: s.holdBy,
              toEmail: s.toEmail,
              transportation: s.transportation,
              fromAddress2: s.fromAddress2 || '.',
              fromTown: s.fromTown,
              fromState: s.fromState,
              stateCode: s.stateCode,
              loading: false,
              eori: s.eori,
              ref: 'Pending',
              auCode: 'AU',
              firstPcs: s.shipmentType === 'WPX' ? pcsWeight / s.packageValue.length : s.weight,
              firstLength: s.shipmentType === 'WPX' ? pcsLength / s.packageValue.length : 1,
              firstWidth: s.shipmentType === 'WPX' ? pcsWidth / s.packageValue.length : 1,
              firstHeight: s.shipmentType === 'WPX' ? pcsHeight / s.packageValue.length : 1,
              weightCode: 'KG',
              dimCode: 'CM',
              dus: s.dus || [],
              labelUrl: s.labelUrl,
              dhlAccount: dhlAccount,
              cleNumberOfLine: s.invoiceValue.length,
              cleName: cleName,
              cleAddress: '#02-18',
              cleAddress2: 'CARGO AGENT BUILDING C',
              cleAddress3: 'CHANGI AIRFREIGHT CENTRE',
              clePostal: '819466',
              cleCode: 'SG',
              lengthValue: lengthValue,
              widthValue: widthValue,
              heightValue: heightValue,

              lengthValue2: lengthValue2,
              widthValue2: widthValue2,
              heightValue2: heightValue2,

              lengthValue3: lengthValue3,
              widthValue3: widthValue3,
              heightValue3: heightValue3,

              lengthValue4: lengthValue4,
              widthValue4: widthValue4,
              heightValue4: heightValue4,

              lengthValue5: lengthValue5,
              widthValue5: widthValue5,
              heightValue5: heightValue5,

              lengthValue6: lengthValue6,
              widthValue6: widthValue6,
              heightValue6: heightValue6,

              lengthValue7: lengthValue7,
              widthValue7: widthValue7,
              heightValue7: heightValue7,

              lengthValue8: lengthValue8,
              widthValue8: widthValue8,
              heightValue8: heightValue8,

              lengthValue9: lengthValue9,
              widthValue9: widthValue9,
              heightValue9: heightValue9,

              lengthValue10: lengthValue10,
              widthValue10: widthValue10,
              heightValue10: heightValue10,

              lengthValue11: lengthValue11,
              widthValue11: widthValue11,
              heightValue11: heightValue11,

              lengthValue12: lengthValue12,
              widthValue12: widthValue12,
              heightValue12: heightValue12,

              lengthValue13: lengthValue13,
              widthValue13: widthValue13,
              heightValue13: heightValue13,

              lengthValue14: lengthValue14,
              widthValue14: widthValue14,
              heightValue14: heightValue14,

              lengthValue15: lengthValue15,
              widthValue15: widthValue15,
              heightValue15: heightValue15,

              lengthValue16: lengthValue16,
              widthValue16: widthValue16,
              heightValue16: heightValue16,

              lengthValue17: lengthValue17,
              widthValue17: widthValue17,
              heightValue17: heightValue17,

              lengthValue18: lengthValue18,
              widthValue18: widthValue18,
              heightValue18: heightValue18,

              lengthValue19: lengthValue19,
              widthValue19: widthValue19,
              heightValue19: heightValue19,

              lengthValue20: lengthValue20,
              widthValue20: widthValue20,
              heightValue20: heightValue20,
              uom: 'PCS',
              uom2: s.invoiceValue.length >= 2 ? 'PCS' : '',
              uom3: s.invoiceValue.length >= 3 ? 'PCS' : '',
              uom4: s.invoiceValue.length >= 4 ? 'PCS' : '',
              uom5: s.invoiceValue.length >= 5 ? 'PCS' : '',
              uom6: s.invoiceValue.length >= 6 ? 'PCS' : '',
              uom7: s.invoiceValue.length >= 7 ? 'PCS' : '',
              uom8: s.invoiceValue.length >= 8 ? 'PCS' : '',
              uom9: s.invoiceValue.length >= 9 ? 'PCS' : '',
              uom10: s.invoiceValue.length >= 10 ? 'PCS' : '',
              uom11: s.invoiceValue.length >= 11 ? 'PCS' : '',
              uom12: s.invoiceValue.length >= 12 ? 'PCS' : '',
              uom13: s.invoiceValue.length >= 13 ? 'PCS' : '',
              uom14: s.invoiceValue.length >= 14 ? 'PCS' : '',
              uom15: s.invoiceValue.length >= 15 ? 'PCS' : '',
              uom16: s.invoiceValue.length >= 16 ? 'PCS' : '',
              uom17: s.invoiceValue.length >= 17 ? 'PCS' : '',
              uom18: s.invoiceValue.length >= 18 ? 'PCS' : '',
              uom19: s.invoiceValue.length >= 19 ? 'PCS' : '',
              uom20: s.invoiceValue.length >= 20 ? 'PCS' : '',
              cleCity: 'SINGAPORE',
              cleEmail: cleEmail,
              clePhoneType: 'O',
              clePhoneCountryCode: '65',
              clePhone: clePhone,
              item_value: itemValue / quantity,
              item_value2: itemValue2 / quantity2,
              item_value3: itemValue3 / quantity3,
              item_value4: itemValue4 / quantity4,
              item_value5: itemValue5 / quantity5,
              item_value6: itemValue6 / quantity6,
              item_value7: itemValue7 / quantity7,
              item_value8: itemValue8 / quantity8,
              item_value9: itemValue9 / quantity9,
              item_value10: itemValue10 / quantity10,
              item_value11: itemValue11 / quantity11,
              item_value12: itemValue12 / quantity12,
              item_value13: itemValue13 / quantity13,
              item_value14: itemValue14 / quantity14,
              item_value15: itemValue15 / quantity15,
              item_value16: itemValue16 / quantity16,
              item_value17: itemValue17 / quantity17,
              item_value18: itemValue18 / quantity18,
              item_value19: itemValue19 / quantity19,
              item_value20: itemValue20 / quantity20,
              item_number: itemNo,
              item_number2: itemNo2,
              item_number3: itemNo3,
              item_number4: itemNo4,
              item_number5: itemNo5,
              item_number6: itemNo6,
              item_number7: itemNo7,
              item_number8: itemNo8,
              item_number9: itemNo9,
              item_number10: itemNo10,
              item_number11: itemNo11,
              item_number12: itemNo12,
              item_number13: itemNo13,
              item_number14: itemNo14,
              item_number15: itemNo15,
              item_number16: itemNo16,
              item_number17: itemNo17,
              item_number18: itemNo18,
              item_number19: itemNo19,
              item_number20: itemNo20,
              item_number21: itemNo21,
              item_number22: itemNo22,
              item_number23: itemNo23,
              item_number24: itemNo24,
              item_number25: itemNo25,
              item_number26: itemNo26,
              item_number27: itemNo27,
              item_number28: itemNo28,
              item_number29: itemNo29,
              item_number30: itemNo30,
              item_number31: itemNo31,
              item_number32: itemNo32,
              item_number33: itemNo33,
              item_number34: itemNo34,
              item_number35: itemNo35,
              item_number36: itemNo36,
              item_number37: itemNo37,
              item_number38: itemNo38,
              item_number39: itemNo39,
              item_number40: itemNo40,
              item_number41: itemNo41,
              item_number42: itemNo42,
              item_number43: itemNo43,
              item_number44: itemNo44,
              item_number45: itemNo45,
              item_number46: itemNo46,
              item_number47: itemNo47,
              item_number48: itemNo48,
              item_number49: itemNo49,
              item_number50: itemNo50,
              item_number51: itemNo51,
              item_number52: itemNo52,
              item_number53: itemNo53,
              item_number54: itemNo54,
              item_number55: itemNo55,
              item_number56: itemNo56,
              item_number57: itemNo57,
              item_number58: itemNo58,
              item_number59: itemNo59,
              item_number60: itemNo60,
              item_number61: itemNo61,
              item_number62: itemNo62,
              item_number63: itemNo63,
              item_number64: itemNo64,
              item_number65: itemNo65,
              item_number66: itemNo66,
              item_number67: itemNo67,
              item_number68: itemNo68,
              item_number69: itemNo69,
              item_number70: itemNo70,
              item_number71: itemNo71,
              item_number72: itemNo72,
              item_number73: itemNo73,
              item_number74: itemNo74,
              item_number75: itemNo75,
              item_number76: itemNo76,
              item_number77: itemNo77,
              item_number78: itemNo78,
              item_number79: itemNo79,
              item_number80: itemNo80,
              item_number81: itemNo81,
              item_number82: itemNo82,
              item_number83: itemNo83,
              item_number84: itemNo84,
              item_number85: itemNo85,
              item_number86: itemNo86,
              item_number87: itemNo87,
              item_number88: itemNo88,
              item_number89: itemNo89,
              item_number90: itemNo90,
              item_number91: itemNo91,
              item_number92: itemNo92,
              item_number93: itemNo93,
              item_number94: itemNo94,
              item_number95: itemNo95,
              item_number96: itemNo96,
              item_number97: itemNo97,
              item_number98: itemNo98,
              item_number99: itemNo99,
              item_number100: itemNo100,
              quantity: quantity,
              quantity2: quantity2,
              quantity3: quantity3,
              quantity4: quantity4,
              quantity5: quantity5,
              quantity6: quantity6,
              quantity7: quantity7,
              quantity8: quantity8,
              quantity9: quantity9,
              quantity10: quantity10,
              quantity11: quantity11,
              quantity12: quantity12,
              quantity13: quantity13,
              quantity14: quantity14,
              quantity15: quantity15,
              quantity16: quantity16,
              quantity17: quantity17,
              quantity18: quantity18,
              quantity19: quantity19,
              quantity20: quantity20,
              quantity21: quantity21,
              quantity22: quantity22,
              quantity23: quantity23,
              quantity24: quantity24,
              quantity25: quantity25,
              quantity26: quantity26,
              quantity27: quantity27,
              quantity28: quantity28,
              quantity29: quantity29,
              quantity30: quantity30,
              quantity31: quantity31,
              quantity32: quantity32,
              quantity33: quantity33,
              quantity34: quantity34,
              quantity35: quantity35,
              quantity36: quantity36,
              quantity37: quantity37,
              quantity38: quantity38,
              quantity39: quantity39,
              quantity40: quantity40,
              quantity41: quantity41,
              quantity42: quantity42,
              quantity43: quantity43,
              quantity44: quantity44,
              quantity45: quantity45,
              quantity46: quantity46,
              quantity47: quantity47,
              quantity48: quantity48,
              quantity49: quantity49,
              quantity50: quantity50,
              quantity51: quantity51,
              quantity52: quantity52,
              quantity53: quantity53,
              quantity54: quantity54,
              quantity55: quantity55,
              quantity56: quantity56,
              quantity57: quantity57,
              quantity58: quantity58,
              quantity59: quantity59,
              quantity60: quantity60,
              quantity61: quantity61,
              quantity62: quantity62,
              quantity63: quantity63,
              quantity64: quantity64,
              quantity65: quantity65,
              quantity66: quantity66,
              quantity67: quantity67,
              quantity68: quantity68,
              quantity69: quantity69,
              quantity70: quantity70,
              quantity71: quantity71,
              quantity72: quantity72,
              quantity73: quantity73,
              quantity74: quantity74,
              quantity75: quantity75,
              quantity76: quantity76,
              quantity77: quantity77,
              quantity78: quantity78,
              quantity79: quantity79,
              quantity80: quantity80,
              quantity81: quantity81,
              quantity82: quantity82,
              quantity83: quantity83,
              quantity84: quantity84,
              quantity85: quantity85,
              quantity86: quantity86,
              quantity87: quantity87,
              quantity88: quantity88,
              quantity89: quantity89,
              quantity90: quantity90,
              quantity91: quantity91,
              quantity92: quantity92,
              quantity93: quantity93,
              quantity94: quantity94,
              quantity95: quantity95,
              quantity96: quantity96,
              quantity97: quantity97,
              quantity98: quantity98,
              quantity99: quantity99,
              quantity100: quantity100,
              unit: unit,
              unit2: unit2,
              unit3: unit3,
              unit4: unit4,
              unit5: unit5,
              unit6: unit6,
              unit7: unit7,
              unit8: unit8,
              unit9: unit9,
              unit10: unit10,
              unit11: unit11,
              unit12: unit12,
              unit13: unit13,
              unit14: unit14,
              unit15: unit15,
              unit16: unit16,
              unit17: unit17,
              unit18: unit18,
              unit19: unit19,
              unit20: unit20,
              unit21: unit21,
              unit22: unit22,
              unit23: unit23,
              unit24: unit24,
              unit25: unit25,
              unit26: unit26,
              unit27: unit27,
              unit28: unit28,
              unit29: unit29,
              unit30: unit30,
              unit31: unit31,
              unit32: unit32,
              unit33: unit33,
              unit34: unit34,
              unit35: unit35,
              unit36: unit36,
              unit37: unit37,
              unit38: unit38,
              unit39: unit39,
              unit40: unit40,
              unit41: unit41,
              unit42: unit42,
              unit43: unit43,
              unit44: unit44,
              unit45: unit45,
              unit46: unit46,
              unit47: unit47,
              unit48: unit48,
              unit49: unit49,
              unit50: unit50,
              unit51: unit51,
              unit52: unit52,
              unit53: unit53,
              unit54: unit54,
              unit55: unit55,
              unit56: unit56,
              unit57: unit57,
              unit58: unit58,
              unit59: unit59,
              unit60: unit60,
              unit61: unit61,
              unit62: unit62,
              unit63: unit63,
              unit64: unit64,
              unit65: unit65,
              unit66: unit66,
              unit67: unit67,
              unit68: unit68,
              unit69: unit69,
              unit70: unit70,
              unit71: unit71,
              unit72: unit72,
              unit73: unit73,
              unit74: unit74,
              unit75: unit75,
              unit76: unit76,
              unit77: unit77,
              unit78: unit78,
              unit79: unit79,
              unit80: unit80,
              unit81: unit81,
              unit82: unit82,
              unit83: unit83,
              unit84: unit84,
              unit85: unit85,
              unit86: unit86,
              unit87: unit87,
              unit88: unit88,
              unit89: unit89,
              unit90: unit90,
              unit91: unit91,
              unit92: unit92,
              unit93: unit93,
              unit94: unit94,
              unit95: unit95,
              unit96: unit96,
              unit97: unit97,
              unit98: unit98,
              unit99: unit99,
              unit100: unit100,
              itemName: itemName,
              itemName2: itemName2,
              itemName3: itemName3,
              itemName4: itemName4,
              itemName5: itemName5,
              itemName6: itemName6,
              itemName7: itemName7,
              itemName8: itemName8,
              itemName9: itemName9,
              itemName10: itemName10,
              itemName11: itemName11,
              itemName12: itemName12,
              itemName13: itemName13,
              itemName14: itemName14,
              itemName15: itemName15,
              itemName16: itemName16,
              itemName17: itemName17,
              itemName18: itemName18,
              itemName19: itemName19,
              itemName20: itemName20,
              itemName21: itemName21,
              itemName22: itemName22,
              itemName23: itemName23,
              itemName24: itemName24,
              itemName25: itemName25,
              itemName26: itemName26,
              itemName27: itemName27,
              itemName28: itemName28,
              itemName29: itemName29,
              itemName30: itemName30,
              itemName31: itemName31,
              itemName32: itemName32,
              itemName33: itemName33,
              itemName34: itemName34,
              itemName35: itemName35,
              itemName36: itemName36,
              itemName37: itemName37,
              itemName38: itemName38,
              itemName39: itemName39,
              itemName40: itemName40,
              itemName41: itemName41,
              itemName42: itemName42,
              itemName43: itemName43,
              itemName44: itemName44,
              itemName45: itemName45,
              itemName46: itemName46,
              itemName47: itemName47,
              itemName48: itemName48,
              itemName49: itemName49,
              itemName50: itemName50,
              itemName51: itemName51,
              itemName52: itemName52,
              itemName53: itemName53,
              itemName54: itemName54,
              itemName55: itemName55,
              itemName56: itemName56,
              itemName57: itemName57,
              itemName58: itemName58,
              itemName59: itemName59,
              itemName60: itemName60,
              itemName61: itemName61,
              itemName62: itemName62,
              itemName63: itemName63,
              itemName64: itemName64,
              itemName65: itemName65,
              itemName66: itemName66,
              itemName67: itemName67,
              itemName68: itemName68,
              itemName69: itemName69,
              itemName70: itemName70,
              itemName71: itemName71,
              itemName72: itemName72,
              itemName73: itemName73,
              itemName74: itemName74,
              itemName75: itemName75,
              itemName76: itemName76,
              itemName77: itemName77,
              itemName78: itemName78,
              itemName79: itemName79,
              itemName80: itemName80,
              itemName81: itemName81,
              itemName82: itemName82,
              itemName83: itemName83,
              itemName84: itemName84,
              itemName85: itemName85,
              itemName86: itemName86,
              itemName87: itemName87,
              itemName88: itemName88,
              itemName89: itemName89,
              itemName90: itemName90,
              itemName91: itemName91,
              itemName92: itemName92,
              itemName93: itemName93,
              itemName94: itemName94,
              itemName95: itemName95,
              itemName96: itemName96,
              itemName97: itemName97,
              itemName98: itemName98,
              itemName99: itemName99,
              itemName100: itemName100,
              unitPrice: unitPrice,
              unitPrice2: unitPrice2,
              unitPrice3: unitPrice3,
              unitPrice4: unitPrice4,
              unitPrice5: unitPrice5,
              unitPrice6: unitPrice6,
              unitPrice7: unitPrice7,
              unitPrice8: unitPrice8,
              unitPrice9: unitPrice9,
              unitPrice10: unitPrice10,
              unitPrice11: unitPrice11,
              unitPrice12: unitPrice12,
              unitPrice13: unitPrice13,
              unitPrice14: unitPrice14,
              unitPrice15: unitPrice15,
              unitPrice16: unitPrice16,
              unitPrice17: unitPrice17,
              unitPrice18: unitPrice18,
              unitPrice19: unitPrice19,
              unitPrice20: unitPrice20,
              unitPrice21: unitPrice21,
              unitPrice22: unitPrice22,
              unitPrice23: unitPrice23,
              unitPrice24: unitPrice24,
              unitPrice25: unitPrice25,
              unitPrice26: unitPrice26,
              unitPrice27: unitPrice27,
              unitPrice28: unitPrice28,
              unitPrice29: unitPrice29,
              unitPrice30: unitPrice30,
              unitPrice31: unitPrice31,
              unitPrice32: unitPrice32,
              unitPrice33: unitPrice33,
              unitPrice34: unitPrice34,
              unitPrice35: unitPrice35,
              unitPrice36: unitPrice36,
              unitPrice37: unitPrice37,
              unitPrice38: unitPrice38,
              unitPrice39: unitPrice39,
              unitPrice40: unitPrice40,
              unitPrice41: unitPrice41,
              unitPrice42: unitPrice42,
              unitPrice43: unitPrice43,
              unitPrice44: unitPrice44,
              unitPrice45: unitPrice45,
              unitPrice46: unitPrice46,
              unitPrice47: unitPrice47,
              unitPrice48: unitPrice48,
              unitPrice49: unitPrice49,
              unitPrice50: unitPrice50,
              unitPrice51: unitPrice51,
              unitPrice52: unitPrice52,
              unitPrice53: unitPrice53,
              unitPrice54: unitPrice54,
              unitPrice55: unitPrice55,
              unitPrice56: unitPrice56,
              unitPrice57: unitPrice57,
              unitPrice58: unitPrice58,
              unitPrice59: unitPrice59,
              unitPrice60: unitPrice60,
              unitPrice61: unitPrice61,
              unitPrice62: unitPrice62,
              unitPrice63: unitPrice63,
              unitPrice64: unitPrice64,
              unitPrice65: unitPrice65,
              unitPrice66: unitPrice66,
              unitPrice67: unitPrice67,
              unitPrice68: unitPrice68,
              unitPrice69: unitPrice69,
              unitPrice70: unitPrice70,
              unitPrice71: unitPrice71,
              unitPrice72: unitPrice72,
              unitPrice73: unitPrice73,
              unitPrice74: unitPrice74,
              unitPrice75: unitPrice75,
              unitPrice76: unitPrice76,
              unitPrice77: unitPrice77,
              unitPrice78: unitPrice78,
              unitPrice79: unitPrice79,
              unitPrice80: unitPrice80,
              unitPrice81: unitPrice81,
              unitPrice82: unitPrice82,
              unitPrice83: unitPrice83,
              unitPrice84: unitPrice84,
              unitPrice85: unitPrice85,
              unitPrice86: unitPrice86,
              unitPrice87: unitPrice87,
              unitPrice88: unitPrice88,
              unitPrice89: unitPrice89,
              unitPrice90: unitPrice90,
              unitPrice91: unitPrice91,
              unitPrice92: unitPrice92,
              unitPrice93: unitPrice93,
              unitPrice94: unitPrice94,
              unitPrice95: unitPrice95,
              unitPrice96: unitPrice96,
              unitPrice97: unitPrice97,
              unitPrice98: unitPrice98,
              unitPrice99: unitPrice99,
              unitPrice100: unitPrice100,
              itemGrossWeight: itemGrossWeight / quantity,
              itemGrossWeight2: itemGrossWeight2 / quantity2,
              itemGrossWeight3: itemGrossWeight3 / quantity3,
              itemGrossWeight4: itemGrossWeight4 / quantity4,
              itemGrossWeight5: itemGrossWeight5 / quantity5,
              itemGrossWeight6: itemGrossWeight6 / quantity6,
              itemGrossWeight7: itemGrossWeight7 / quantity7,
              itemGrossWeight8: itemGrossWeight8 / quantity8,
              itemGrossWeight9: itemGrossWeight9 / quantity9,
              itemGrossWeight10: itemGrossWeight10 / quantity10,
              itemGrossWeight11: itemGrossWeight11 / quantity11,
              itemGrossWeight12: itemGrossWeight12 / quantity12,
              itemGrossWeight13: itemGrossWeight13 / quantity13,
              itemGrossWeight14: itemGrossWeight14 / quantity14,
              itemGrossWeight15: itemGrossWeight15 / quantity15,
              itemGrossWeight16: itemGrossWeight16 / quantity16,
              itemGrossWeight17: itemGrossWeight17 / quantity17,
              itemGrossWeight18: itemGrossWeight18 / quantity18,
              itemGrossWeight19: itemGrossWeight19 / quantity19,
              itemGrossWeight20: itemGrossWeight20 / quantity20,
              itemNetWeight: itemNetWeight / quantity,
              itemNetWeight2: itemNetWeight2 / quantity2,
              itemNetWeight3: itemNetWeight3 / quantity3,
              itemNetWeight4: itemNetWeight4 / quantity4,
              itemNetWeight5: itemNetWeight5 / quantity5,
              itemNetWeight6: itemNetWeight6 / quantity6,
              itemNetWeight7: itemNetWeight7 / quantity7,
              itemNetWeight8: itemNetWeight8 / quantity8,
              itemNetWeight9: itemNetWeight9 / quantity9,
              itemNetWeight10: itemNetWeight10 / quantity10,
              itemNetWeight11: itemNetWeight11 / quantity11,
              itemNetWeight12: itemNetWeight12 / quantity12,
              itemNetWeight13: itemNetWeight13 / quantity13,
              itemNetWeight14: itemNetWeight14 / quantity14,
              itemNetWeight15: itemNetWeight15 / quantity15,
              itemNetWeight16: itemNetWeight16 / quantity16,
              itemNetWeight17: itemNetWeight17 / quantity17,
              itemNetWeight18: itemNetWeight18 / quantity18,
              itemNetWeight19: itemNetWeight19 / quantity19,
              itemNetWeight20: itemNetWeight20 / quantity20,
              itemCurrency: 'USD',
              itemCurrency2: s.invoiceValue.length >= 2 ? 'USD' : '',
              itemCurrency3: s.invoiceValue.length >= 3 ? 'USD' : '',
              itemCurrency4: s.invoiceValue.length >= 4 ? 'USD' : '',
              itemCurrency5: s.invoiceValue.length >= 5 ? 'USD' : '',
              itemCurrency6: s.invoiceValue.length >= 6 ? 'USD' : '',
              itemCurrency7: s.invoiceValue.length >= 7 ? 'USD' : '',
              itemCurrency8: s.invoiceValue.length >= 8 ? 'USD' : '',
              itemCurrency9: s.invoiceValue.length >= 9 ? 'USD' : '',
              itemCurrency10: s.invoiceValue.length >= 10 ? 'USD' : '',
              itemCurrency11: s.invoiceValue.length >= 11 ? 'USD' : '',
              itemCurrency12: s.invoiceValue.length >= 12 ? 'USD' : '',
              itemCurrency13: s.invoiceValue.length >= 13 ? 'USD' : '',
              itemCurrency14: s.invoiceValue.length >= 14 ? 'USD' : '',
              itemCurrency15: s.invoiceValue.length >= 15 ? 'USD' : '',
              itemCurrency16: s.invoiceValue.length >= 16 ? 'USD' : '',
              itemCurrency17: s.invoiceValue.length >= 17 ? 'USD' : '',
              itemCurrency18: s.invoiceValue.length >= 18 ? 'USD' : '',
              itemCurrency19: s.invoiceValue.length >= 19 ? 'USD' : '',
              itemCurrency20: s.invoiceValue.length >= 20 ? 'USD' : '',
              countryManufactured: 'VN',
              countryManufactured2: s.invoiceValue.length >= 2 ? 'VN' : '',
              countryManufactured3: s.invoiceValue.length >= 3 ? 'VN' : '',
              countryManufactured4: s.invoiceValue.length >= 4 ? 'VN' : '',
              countryManufactured5: s.invoiceValue.length >= 5 ? 'VN' : '',
              countryManufactured6: s.invoiceValue.length >= 6 ? 'VN' : '',
              countryManufactured7: s.invoiceValue.length >= 7 ? 'VN' : '',
              countryManufactured8: s.invoiceValue.length >= 8 ? 'VN' : '',
              countryManufactured9: s.invoiceValue.length >= 9 ? 'VN' : '',
              countryManufactured10: s.invoiceValue.length >= 10 ? 'VN' : '',
              countryManufactured11: s.invoiceValue.length >= 11 ? 'VN' : '',
              countryManufactured12: s.invoiceValue.length >= 12 ? 'VN' : '',
              countryManufactured13: s.invoiceValue.length >= 13 ? 'VN' : '',
              countryManufactured14: s.invoiceValue.length >= 14 ? 'VN' : '',
              countryManufactured15: s.invoiceValue.length >= 15 ? 'VN' : '',
              countryManufactured16: s.invoiceValue.length >= 16 ? 'VN' : '',
              countryManufactured17: s.invoiceValue.length >= 17 ? 'VN' : '',
              countryManufactured18: s.invoiceValue.length >= 18 ? 'VN' : '',
              countryManufactured19: s.invoiceValue.length >= 19 ? 'VN' : '',
              countryManufactured20: s.invoiceValue.length >= 20 ? 'VN' : '',
              hsCode: hsCode || 'acb',
              hsCode2: hsCode2 || 'acb',
              hsCode3: hsCode3 || 'acb',
              hsCode4: hsCode4 || 'acb',
              hsCode5: hsCode5 || 'acb',
              hsCode6: hsCode6 || 'acb',
              hsCode7: hsCode7 || 'acb',
              hsCode8: hsCode8 || 'acb',
              hsCode9: hsCode9 || 'acb',
              hsCode10: hsCode10 || 'acb',
              hsCode11: hsCode11 || 'acb',
              hsCode12: hsCode12 || 'acb',
              hsCode13: hsCode13 || 'acb',
              hsCode14: hsCode14 || 'acb',
              hsCode15: hsCode15 || 'acb',
              hsCode16: hsCode16 || 'acb',
              hsCode17: hsCode17 || 'acb',
              hsCode18: hsCode18 || 'acb',
              hsCode19: hsCode19 || 'acb',
              hsCode20: hsCode20 || 'acb',
              hsCode21: hsCode21 || 'acb',
              hsCode22: hsCode22 || 'acb',
              hsCode23: hsCode23 || 'acb',
              hsCode24: hsCode24 || 'acb',
              hsCode25: hsCode25 || 'acb',
              hsCode26: hsCode26 || 'acb',
              hsCode27: hsCode27 || 'acb',
              hsCode28: hsCode28 || 'acb',
              hsCode29: hsCode29 || 'acb',
              hsCode30: hsCode30 || 'acb',
              hsCode31: hsCode31 || 'acb',
              hsCode32: hsCode32 || 'acb',
              hsCode33: hsCode33 || 'acb',
              hsCode34: hsCode34 || 'acb',
              hsCode35: hsCode35 || 'acb',
              hsCode36: hsCode36 || 'acb',
              hsCode37: hsCode37 || 'acb',
              hsCode38: hsCode38 || 'acb',
              hsCode39: hsCode39 || 'acb',
              hsCode40: hsCode40 || 'acb',
              hsCode41: hsCode41 || 'acb',
              hsCode42: hsCode42 || 'acb',
              hsCode43: hsCode43 || 'acb',
              hsCode44: hsCode44 || 'acb',
              hsCode45: hsCode45 || 'acb',
              hsCode46: hsCode46 || 'acb',
              hsCode47: hsCode47 || 'acb',
              hsCode48: hsCode48 || 'acb',
              hsCode49: hsCode49 || 'acb',
              hsCode50: hsCode50 || 'acb',
              hsCode51: hsCode51 || 'acb',
              hsCode52: hsCode52 || 'acb',
              hsCode53: hsCode53 || 'acb',
              hsCode54: hsCode54 || 'acb',
              hsCode55: hsCode55 || 'acb',
              hsCode56: hsCode56 || 'acb',
              hsCode57: hsCode57 || 'acb',
              hsCode58: hsCode58 || 'acb',
              hsCode59: hsCode59 || 'acb',
              hsCode60: hsCode60 || 'acb',
              hsCode61: hsCode61 || 'acb',
              hsCode62: hsCode62 || 'acb',
              hsCode63: hsCode63 || 'acb',
              hsCode64: hsCode64 || 'acb',
              hsCode65: hsCode65 || 'acb',
              hsCode66: hsCode66 || 'acb',
              hsCode67: hsCode67 || 'acb',
              hsCode68: hsCode68 || 'acb',
              hsCode69: hsCode69 || 'acb',
              hsCode70: hsCode70 || 'acb',
              hsCode71: hsCode71 || 'acb',
              hsCode72: hsCode72 || 'acb',
              hsCode73: hsCode73 || 'acb',
              hsCode74: hsCode74 || 'acb',
              hsCode75: hsCode75 || 'acb',
              hsCode76: hsCode76 || 'acb',
              hsCode77: hsCode77 || 'acb',
              hsCode78: hsCode78 || 'acb',
              hsCode79: hsCode79 || 'acb',
              hsCode80: hsCode80 || 'acb',
              hsCode81: hsCode81 || 'acb',
              hsCode82: hsCode82 || 'acb',
              hsCode83: hsCode83 || 'acb',
              hsCode84: hsCode84 || 'acb',
              hsCode85: hsCode85 || 'acb',
              hsCode86: hsCode86 || 'acb',
              hsCode87: hsCode87 || 'acb',
              hsCode88: hsCode88 || 'acb',
              hsCode89: hsCode89 || 'acb',
              hsCode90: hsCode90 || 'acb',
              hsCode91: hsCode91 || 'acb',
              hsCode92: hsCode92 || 'acb',
              hsCode93: hsCode93 || 'acb',
              hsCode94: hsCode94 || 'acb',
              hsCode95: hsCode95 || 'acb',
              hsCode96: hsCode96 || 'acb',
              hsCode97: hsCode97 || 'acb',
              hsCode98: hsCode98 || 'acb',
              hsCode99: hsCode99 || 'acb',
              hsCode100: hsCode100 || 'acb',
              taxIT: '',
              slash: '#item',
              dimSlash: '#dim',
              dimSlash2: lengthValue2 === '' ? '' : '#dim',
              dimSlash3: lengthValue3 === '' ? '' : '#dim',
              dimSlash4: lengthValue4 === '' ? '' : '#dim',
              dimSlash5: lengthValue5 === '' ? '' : '#dim',
              dimSlash6: lengthValue6 === '' ? '' : '#dim',
              dimSlash7: lengthValue7 === '' ? '' : '#dim',
              dimSlash8: lengthValue8 === '' ? '' : '#dim',
              dimSlash9: lengthValue9 === '' ? '' : '#dim',
              dimSlash10: lengthValue10 === '' ? '' : '#dim',
              dimSlash11: lengthValue11 === '' ? '' : '#dim',
              dimSlash12: lengthValue12 === '' ? '' : '#dim',
              dimSlash13: lengthValue13 === '' ? '' : '#dim',
              dimSlash14: lengthValue14 === '' ? '' : '#dim',
              dimSlash15: lengthValue15 === '' ? '' : '#dim',
              dimSlash16: lengthValue16 === '' ? '' : '#dim',
              dimSlash17: lengthValue17 === '' ? '' : '#dim',
              dimSlash18: lengthValue18 === '' ? '' : '#dim',
              dimSlash19: lengthValue19 === '' ? '' : '#dim',
              dimSlash20: lengthValue20 === '' ? '' : '#dim',
              slash2: itemNo2 === '' ? '' : '#item',
              slash3: itemNo3 === '' ? '' : '#item',
              slash4: itemNo4 === '' ? '' : '#item',
              slash5: itemNo5 === '' ? '' : '#item',
              slash6: itemNo6 === '' ? '' : '#item',
              slash7: itemNo7 === '' ? '' : '#item',
              slash8: itemNo8 === '' ? '' : '#item',
              slash9: itemNo9 === '' ? '' : '#item',
              slash10: itemNo10 === '' ? '' : '#item',
              slash11: itemNo11 === '' ? '' : '#item',
              slash12: itemNo12 === '' ? '' : '#item',
              slash13: itemNo13 === '' ? '' : '#item',
              slash14: itemNo14 === '' ? '' : '#item',
              slash15: itemNo15 === '' ? '' : '#item',
              slash16: itemNo16 === '' ? '' : '#item',
              slash17: itemNo17 === '' ? '' : '#item',
              slash18: itemNo18 === '' ? '' : '#item',
              slash19: itemNo19 === '' ? '' : '#item',
              slash20: itemNo20 === '' ? '' : '#item',
              slash21: itemNo21 === '' ? '' : '#item',
              slash22: itemNo22 === '' ? '' : '#item',
              slash23: itemNo23 === '' ? '' : '#item',
              slash24: itemNo24 === '' ? '' : '#item',
              slash25: itemNo25 === '' ? '' : '#item',
              slash26: itemNo26 === '' ? '' : '#item',
              slash27: itemNo27 === '' ? '' : '#item',
              slash28: itemNo28 === '' ? '' : '#item',
              slash29: itemNo29 === '' ? '' : '#item',
              slash30: itemNo30 === '' ? '' : '#item',
              slash31: itemNo31 === '' ? '' : '#item',
              slash32: itemNo32 === '' ? '' : '#item',
              slash33: itemNo33 === '' ? '' : '#item',
              slash34: itemNo34 === '' ? '' : '#item',
              slash35: itemNo35 === '' ? '' : '#item',
              slash36: itemNo36 === '' ? '' : '#item',
              slash37: itemNo37 === '' ? '' : '#item',
              slash38: itemNo38 === '' ? '' : '#item',
              slash39: itemNo39 === '' ? '' : '#item',
              slash40: itemNo40 === '' ? '' : '#item',
              slash41: itemNo41 === '' ? '' : '#item',
              slash42: itemNo42 === '' ? '' : '#item',

              slash43: itemNo43 === '' ? '' : '#item',
              slash44: itemNo44 === '' ? '' : '#item',
              slash45: itemNo45 === '' ? '' : '#item',
              slash46: itemNo46 === '' ? '' : '#item',
              slash47: itemNo47 === '' ? '' : '#item',
              slash48: itemNo48 === '' ? '' : '#item',
              slash49: itemNo49 === '' ? '' : '#item',
              slash50: itemNo50 === '' ? '' : '#item',
              slash51: itemNo51 === '' ? '' : '#item',
              slash52: itemNo52 === '' ? '' : '#item',
              slash53: itemNo53 === '' ? '' : '#item',
              slash54: itemNo54 === '' ? '' : '#item',
              slash55: itemNo55 === '' ? '' : '#item',
              slash56: itemNo56 === '' ? '' : '#item',
              slash57: itemNo57 === '' ? '' : '#item',
              slash58: itemNo58 === '' ? '' : '#item',
              slash59: itemNo59 === '' ? '' : '#item',
              slash60: itemNo60 === '' ? '' : '#item',
              slash61: itemNo61 === '' ? '' : '#item',
              slash62: itemNo62 === '' ? '' : '#item',
              slash63: itemNo63 === '' ? '' : '#item',
              slash64: itemNo64 === '' ? '' : '#item',
              slash65: itemNo65 === '' ? '' : '#item',
              slash66: itemNo66 === '' ? '' : '#item',
              slash67: itemNo67 === '' ? '' : '#item',
              slash68: itemNo68 === '' ? '' : '#item',
              slash69: itemNo69 === '' ? '' : '#item',
              slash70: itemNo70 === '' ? '' : '#item',
              slash71: itemNo71 === '' ? '' : '#item',
              slash72: itemNo72 === '' ? '' : '#item',
              slash73: itemNo73 === '' ? '' : '#item',
              slash74: itemNo74 === '' ? '' : '#item',
              slash75: itemNo75 === '' ? '' : '#item',
              slash76: itemNo76 === '' ? '' : '#item',
              slash77: itemNo77 === '' ? '' : '#item',
              slash78: itemNo78 === '' ? '' : '#item',
              slash79: itemNo79 === '' ? '' : '#item',
              slash80: itemNo80 === '' ? '' : '#item',
              slash81: itemNo81 === '' ? '' : '#item',
              slash82: itemNo82 === '' ? '' : '#item',
              slash83: itemNo83 === '' ? '' : '#item',
              slash84: itemNo84 === '' ? '' : '#item',
              slash85: itemNo85 === '' ? '' : '#item',
              slash86: itemNo86 === '' ? '' : '#item',
              slash87: itemNo87 === '' ? '' : '#item',
              slash88: itemNo88 === '' ? '' : '#item',
              slash89: itemNo89 === '' ? '' : '#item',
              slash90: itemNo90 === '' ? '' : '#item',
              slash91: itemNo91 === '' ? '' : '#item',
              slash92: itemNo92 === '' ? '' : '#item',
              slash93: itemNo93 === '' ? '' : '#item',
              slash94: itemNo94 === '' ? '' : '#item',
              slash95: itemNo95 === '' ? '' : '#item',
              slash96: itemNo96 === '' ? '' : '#item',
              slash97: itemNo97 === '' ? '' : '#item',
              slash98: itemNo98 === '' ? '' : '#item',
              slash99: itemNo99 === '' ? '' : '#item',
              slash100: itemNo100 === '' ? '' : '#item',
              shortAddress: s.fromAddress.slice(0, 30),
              timestamp: s.timestamp,
            };
          })
        );
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchShipment();
  }, [startDate, endDate]);

  const roleBase = shipments.filter((s) => {
    if (user?.role === 'Sales') {
      return s.controller === user?.displayName;
    } else {
      return s;
    }
  });
  const sortedData = roleBase.sort((a, b) => b.timestamp - a.timestamp);

  const finalShipment = sortedData.filter((s) => {
    let plan = s.plan || '';
    let chargeable = s.chargeable.toString() || '';
    let reweight = s.reweight.toString() || '';
    let amount = s.amount.toString() || '';
    return (
      s.date >= startDate &&
      s.date <= endDate &&
      s.hawb.toLowerCase().includes(searchInput.toLowerCase()) &&
      s.booked.toLowerCase().includes(bookingType.toLowerCase()) &&
      s.sender.toLowerCase().includes(searchCus.toLowerCase()) &&
      s.booked.toLowerCase().includes(bookingStatus.toLowerCase()) &&
      plan.toLowerCase().includes(searchClass.toLowerCase()) &&
      s.country.toLowerCase().startsWith(searchCountry.toLowerCase()) &&
      s.type.toLowerCase().includes(searchType.toLowerCase()) &&
      s.code.toLowerCase().startsWith(searchCode.toLowerCase()) &&
      amount.toLowerCase().includes(searchPcs.toLowerCase()) &&
      s.service.toLowerCase().includes(searchService.toLowerCase()) &&
      s.controller.toLowerCase().includes(searchSale.toLowerCase()) &&
      s.hawb.toLowerCase().includes(searchHawb.toLowerCase()) &&
      s.description.toLowerCase().includes(searchDescription.toLowerCase()) &&
      s.sin.toLowerCase().includes(searchSin.toLowerCase()) &&
      s.bookedBy.toLowerCase().includes(searchOps.toLowerCase())
    );
  });

  const sortedShipment = finalShipment.sort((x, y) => {
    if (sortClass) {
      return x.plan.localeCompare(y.plan);
    } else if (sortCustomer) {
      return x.sender.localeCompare(y.sender);
    } else if (sortHawb) {
      return x.hawb.localeCompare(y.hawb);
    } else if (sortDate) {
      return x.date.localeCompare(y.date) || x.time.localeCompare(y.time);
    } else if (sortCountry) {
      return x.toCountry.localeCompare(y.toCountry);
    } else if (sortService) {
      return x.service.localeCompare(y.service);
    } else if (sortType) {
      return x.shipmentType.localeCompare(y.shipmentType);
    } else if (sortWeight) {
      return x.chargeable.localeCompare(y.chargeable);
    } else if (sortPcs) {
      return x.amount.localeCompare(y.amount);
    } else if (sortDescription) {
      return x.description.localeCompare(y.description);
    } else if (sortValue) {
      return x.value.localeCompare(y.value);
    } else if (sortSin) {
      return x.sin.localeCompare(y.sin);
    } else if (sortSale) {
      return x.controller.localeCompare(y.controller);
    } else if (sortOps) {
      return x.bookedBy.localeCompare(y.bookedBy);
    }
  });

  const getWeight = sortedShipment.filter((s) => {
    if (searchWeight !== '') {
      return s.chargeable === searchWeight;
    } else {
      return s;
    }
  });

  const duplicateWeight = [...new Set(sortedShipment.map((s) => s.chargeable))];
  const duplicateCode = [...new Set(sortedShipment.map((s) => s.code))];
  const dus = sortedShipment.filter((s) => {
    return s.service === 'DUS';
  });
  const pages = [];
  for (let i = 1; i <= Math.ceil(sortedShipment.length / itemPerPage); i++) {
    pages.push(i);
  }
  const handleChangePage = (e) => {
    setCurrentPage(+e.target.id);
  };
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageLimit) {
      setMaxPageLimit(maxPageLimit + pageNumberLimit);
      setMinPageLimit(minPageLimit + pageNumberLimit);
    }
  };
  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setMaxPageLimit(maxPageLimit - pageNumberLimit);
      setMinPageLimit(minPageLimit - pageNumberLimit);
    }
  };
  const handleFirstPage = () => {
    setCurrentPage(1);
    setMaxPageLimit(10);
    setMinPageLimit(0);
  };
  const handleLastPage = () => {
    setCurrentPage(pages[pages.length - 1]);
    setMaxPageLimit(pages.length);
    setMinPageLimit(pages.length - 5);
  };
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentShipment = getWeight.slice(indexOfFirstItem, indexOfLastItem);
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageLimit + 1 && number > minPageLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleChangePage}
          className={currentPage == number ? 'active-page' : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  /// Array to resize
  const columns = useMemo(
    () => [
      { Header: 'No.', minWidth: 50, width: 50, accessor: 'no', key: 'no', active: true, id: 1, context: false },
      { Header: 'Class', minWidth: 50, width: 90, accessor: 'plan', key: 'plan', active: true, id: 2, context: false },
      {
        Header: 'Customer & Exporter',
        width: 250,
        minWidth: 190,
        accessor: 'sender',
        key: 'sender',
        active: true,
        id: 3,
        context: false,
      },
      { Header: 'HAWB', minWidth: 190, accessor: 'hawb', active: true, id: 4, key: 'hawb', context: false, width: 190 },
      { Header: 'Date - Time', minWidth: 50, accessor: 'dateTime', active: true, id: 5, key: 'date', context: false },
      { Header: 'Destination', minWidth: 50, accessor: 'country', active: true, id: 6, key: 'country', context: false },
      {
        Header: 'Service',
        minWidth: 50,
        width: 80,
        accessor: 'service',
        active: true,
        id: 12,
        key: 'service',
        context: false,
      },
      { Header: 'Type', minWidth: 50, width: 70, accessor: 'type', active: true, id: 7, key: 'type', context: false },
      {
        Header: 'Chargeable',
        minWidth: 50,
        width: 100,
        accessor: 'chargeable',
        active: true,
        id: 8,
        key: 'chargeable',
        context: false,
      },
      {
        Header: 'PCS',
        minWidth: 50,
        width: 70,
        accessor: 'amountPcs',
        active: true,
        id: 9,
        key: 'amount',
        context: false,
      },
      {
        Header: 'Description',
        minWidth: 50,
        accessor: 'description',
        active: true,
        id: 10,
        key: 'description',
        context: false,
      },
      { Header: 'Item Value', minWidth: 50, accessor: 'value', active: true, id: 11, key: 'value', context: false },
      { Header: 'Docs', minWidth: 50, accessor: 'docs', active: true, id: 13, key: 'docs', context: false },
      { Header: 'Intl AWB', minWidth: 50, accessor: 'sin', active: true, id: 14, key: 'sin', context: false },
      {
        Header: 'Sales / Controller',
        minWidth: 50,
        accessor: 'controller',
        active: true,
        id: 15,
        key: 'controller',
        context: false,
      },
      {
        Header: 'Booked By',
        minWidth: 50,
        accessor: 'bookedBy',
        active: true,
        id: 16,
        key: 'bookedBy',
        context: false,
      },
      {
        Header: 'Scheduled pick up',
        minWidth: 50,
        accessor: 'pickupScheduled',
        active: true,
        id: 17,
        key: 'pickupScheduled',
        context: false,
      },
    ],
    []
  );
  const data = React.useMemo(() => currentShipment);
  const { headerGroups, rows, prepareRow, state, getTableProps, getTableBodyProps, setColumnOrder } = useTable(
    { columns, data },
    useResizeColumns,
    useFlexLayout,
    useBlockLayout,
    useColumnOrder
  );
  const isColumnResizing = !!state.columnResizing.isResizingColumn;
  const selected = shipments
    .sort((a, b) => {
      return b.timestamp - a.timestamp;
    })
    .filter((s) => {
      return s.select;
    });

  const handleYesterday = () => {
    if (yesterday === 0) {
      setStartDate(`${year}-${prevMonth}-${lastDay.getDate()}`);
      setEndDate(`${year}-${prevMonth}-${lastDay.getDate()}`);
      setDisplayDate(false);
      setDateStatus('Yesterday');
      setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
    } else {
      setStartDate(`${year}-${month}-${yesterday}`);
      setEndDate(`${year}-${month}-${yesterday}`);
      setDisplayDate(false);
      setDateStatus('Yesterday');
      setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
    }
  };

  const handleThisWeek = () => {
    setStartDate(`${year}-${month}-${firstDayOfCurrentWeek}`);
    setEndDate(`${year}-${month}-${lastDayOfCurrentWeek}`);
    setDisplayDate(false);
    setDateStatus('This week');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleLastWeek = () => {
    setStartDate(`${year}-${month}-${firstDayOfLastWeek}`);
    setEndDate(`${year}-${month}-${firstDayOfCurrentWeek}`);
    setDisplayDate(false);
    setDateStatus('Last week');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };

  const handleThisMonth = () => {
    setStartDate(`${year}-${month}-${first}`);
    setEndDate(new Date(currentYear, new Date().getMonth() + 1).toISOString().split('T')[0]);
    setDisplayDate(false);
    setDateStatus('This month');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleLastMonth = () => {
    setStartDate(`${year}-${prevMonth}-01`);
    setEndDate(`${year}-${prevMonth}-${new Date(lastPrevMonthDay).getDate().toString()}`);
    setDisplayDate(false);
    setDateStatus('Last month');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleThisYear = () => {
    setStartDate(`${year}-01-01`);
    setEndDate(`${year}-12-31`);
    setDateStatus('This year');
    setDisplayDate(false);
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  /// Select single shipment
  const handleSelectedShipment = (data, rowId, checkValue) => {
    setSelectedShipment(
      data?.map((sd) => {
        if (rowId === sd.id) {
          sd.original.select = checkValue;
        }
        return sd;
      })
    );
  };
  const handleSelectedAllSign = () => {
    setSelectedShipment(
      shipments
        .filter((s) => {
          return s.deliverySign;
        })
        .map((item) => {
          item.select = !item.select;
          return item;
        })
    );
  };
  /// Select multiple shipment
  const handleSelectedAllShipment = (e) => {
    setSelectedShipment(
      getWeight.map((sd) => {
        sd.select = e.target.checked;
      })
    );
  };
  const handleSelectedType = (propsValue) => {
    if (propsValue === 'DOX') {
      setSelectedShipment(
        getWeight
          .filter((s) => {
            return s.type === propsValue;
          })
          .map((sd) => {
            sd.select = !sd.select;
          })
      );
    } else if (propsValue === 'WPX') {
      setSelectedShipment(
        getWeight
          .filter((s) => {
            return s.type === propsValue;
          })
          .map((sd) => {
            sd.select = !sd.select;
          })
      );
    } else if (propsValue === 'DUS') {
      setSelectedShipment(
        getWeight
          .filter((s) => {
            return s.service === propsValue;
          })
          .map((sd) => {
            sd.select = !sd.select;
          })
      );
    } else if (propsValue === 'CurrentPage') {
      setSelectedShipment(
        currentShipment.map((sd) => {
          sd.select = !sd.select;
        })
      );
    } else if (propsValue === 'All') {
      setSelectedShipment(
        getWeight.map((sd) => {
          sd.select = !sd.select;
        })
      );
    }
  };

  // Handle single column
  const handleSelectedHeader = (headerGroupObject, headerId, headerActive) => {
    setSelectedHeader(
      headerGroupObject?.headers?.map((sd) => {
        if (sd.id === headerId) {
          if (headerActive) {
            sd.active = false;
          } else {
            sd.active = true;
          }
        }
        return sd;
      })
    );
  };
  const handleSortShipmentColumn = (index, hawb) => {
    if (index === 1) {
      setSortClass(!sortClass);
    } else if (index === 2) {
      setSortCustomer(!sortCustomer);
    } else if (index === 3) {
      setSortHawb(!sortHawb);
    } else if (index === 4) {
      setSortDate(!sortDate);
    } else if (index === 5) {
      setSortCountry(!sortCountry);
    } else if (index === 6) {
      setSortService(!sortService);
    } else if (index === 7) {
      setSortType(!sortType);
    } else if (index === 8) {
      setSortWeight(!sortWeight);
    } else if (index === 9) {
      setSortPcs(!sortPcs);
    } else if (index === 10) {
      setSortDescription(!sortDescription);
    } else if (index === 11) {
      setSortValue(!sortValue);
    } else if (index === 13) {
      setSortSin(!sortSin);
    } else if (index === 14) {
      setSortSale(!sortSale);
    } else if (index === 15) {
      setSortOps(!sortOps);
    }
  };
  // Handle context column

  const handleSelectedContext = (headerGroupObject, headerId, headerActive) => {
    setSelectedHeader(
      headerGroupObject?.headers?.map((sd) => {
        if (sd.id === headerId) {
          sd.context = !sd.context;
        } else {
          sd.context = false;
        }
        return sd;
      })
    );
  };
  const handleSelectedContextMenu = (hawb) => {
    setSelectedShipment(
      shipments.map((sd) => {
        if (sd.hawb === hawb) {
          sd.contextMenu = !sd.contextMenu;
        } else {
          sd.contextMenu = false;
        }
        return sd;
      })
    );
  };
  const handleCloseContextMenu = (hawb) => {
    setSelectedShipment(
      shipments.map((sd) => {
        if (sd.hawb === hawb) {
          sd.contextMenu = false;
        }
        return sd;
      })
    );
  };
  const handleCloseContext = (headerGroupObject, headerId, headerActive) => {
    setSelectedHeader(
      headerGroupObject?.headers?.map((sd) => {
        if (sd.id === headerId) {
          sd.context = false;
        }
        return sd;
      })
    );
  };
  const handleCancelDate = () => {
    setDisplayDate(false);
  };

  // Handle multiple column

  const handleSelectedAllHeader = () => {
    setSelectedHeader(
      headerGroups[0]?.headers?.map((sd) => {
        sd.active = true;
      })
    );
  };

  const resetSearch = () => {
    setSearchInput('');
  };
  const totalWeight = getWeight.reduce((a, v) => (a = +a + +v.chargeable), 0);
  const totalDus = dus.reduce((a, v) => (a = +a + +v.chargeable), 0);

  const onSelectingEdit = (shipmentObject) => {
    setSelectedEdit(shipmentObject);
    setDisplayEditScreen(true);
  };

  const handleShowShipmentLabel = (shipmentValue) => {
    setSelectedEdit(shipmentValue);
    setDisplayShipmentLabel(!displayShipmentLabel);
  };
  const handleCloseShipmentLabel = () => {
    setSelectedEdit({});
    setDisplayShipmentLabel(false);
  };

  return (
    <div
      style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
      className="mx-10 overflow-y-hidden bg-white w-full shadow-[rgba(0,0,0,0.1)_0px_10px_15px_-3px,rgba(0,0,0,0.05)_0px_4px_6px_-2px]"
    >
      {displayShipmentLabel && (
        <DashboardEdit handleCloseShipment={handleCloseShipmentLabel} shipmentData={selectedEdit} />
      )}
      <NewBookingFilterBar
        searchInput={searchInput}
        handleSearchShipment={handleSearchShipment}
        fetchMultipleSearched={fetchMultipleSearched}
        handleBookingType={handleBookingType}
        handleBookingStatus={handleBookingStatus}
        handleCloseBookingStatus={handleCloseBookingStatus}
        displayBookingStatus={displayBookingStatus}
        bookingTypeList={bookingTypeList}
        bookingType={bookingType}
        onSearchFocus={onSearchFocus}
        setSelectedShipment={setSelectedShipment}
        loading={loading}
        shipments={shipments}
        onCloseSearch={onCloseSearch}
        displaySearchOption={displaySearchOption}
        headerGroups={headerGroups}
        handleSelectedHeader={handleSelectedHeader}
        handleSelectedAllHeader={handleSelectedAllHeader}
        selected={selected}
        resetSearch={resetSearch}
        startDay={startDay}
        endDay={endDay}
        user={user}
        fetchShipment={fetchShipment}
      />
      <NewBookingHeader
        dateStatus={dateStatus}
        startDay={startDay}
        endDay={endDay}
        startDate={startDate}
        endDate={endDate}
        selected={selected}
        finalShipment={finalShipment}
        displayDate={displayDate}
        totalWeight={((totalWeight * 100) / 100).toFixed(2)}
        totalDus={((totalDus * 100) / 100).toFixed(2)}
        year={year}
        month={month}
        date={date}
        handleToday={handleToday}
        handleYesterday={handleYesterday}
        handleThisWeek={handleThisWeek}
        handleLastWeek={handleLastWeek}
        handleThisMonth={handleThisMonth}
        handleLastMonth={handleLastMonth}
        handleThisYear={handleThisYear}
        focusedDate={focusedDate}
        isDateFocused={isDateFocused}
        isDateSelected={isDateSelected}
        isDateHovered={isDateHovered}
        isDateBlocked={isDateBlocked}
        onDateSelect={onDateSelect}
        onDateFocus={onDateFocus}
        onDateHover={onDateHover}
        activeMonths={activeMonths}
        goToPreviousMonths={goToPreviousMonths}
        goToNextMonths={goToNextMonths}
        isFirstOrLastSelectedDate={isFirstOrLastSelectedDate}
        firstDayOfWeek={firstDayOfWeek}
        dateRange={dateRange}
        handleCancelDate={handleCancelDate}
        handleApplyDate={handleApplyDate}
        handleShowDate={handleShowDate}
        handleCloseDate={handleCloseDate}
      />
      <div className="relative">
        {loading && (
          <div className="absolute z-[9999] top-0 w-[100%] left-0">
            <LinearProgress style={{ height: 7 }} />
          </div>
        )}
        <NewBookingGrid
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
          itemPerPage={itemPerPage}
          setItemPerPage={setItemPerPage}
          handleSelectedAllSign={handleSelectedAllSign}
          onSelectingEdit={onSelectingEdit}
          handleSelectedShipment={handleSelectedShipment}
          handleSelectedType={handleSelectedType}
          getTableBodyProps={getTableBodyProps}
          isColumnResizing={isColumnResizing}
          handleSelectedHeader={handleSelectedHeader}
          handleSortShipmentColumn={handleSortShipmentColumn}
          handleSelectedAllShipment={handleSelectedAllShipment}
          handleSelectedContextMenu={handleSelectedContextMenu}
          handleShowShipmentLabel={handleShowShipmentLabel}
          handleCloseContextMenu={handleCloseContextMenu}
          renderPageNumbers={renderPageNumbers}
          pages={pages}
          currentPage={currentPage}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
          handleFirstPage={handleFirstPage}
          handleLastPage={handleLastPage}
          handleSelectedContext={handleSelectedContext}
          handleCloseContext={handleCloseContext}
          handleChangeInputColumn={handleChangeInputColumn}
          handleResetSearchColumn={handleResetSearchColumn}
          searchCus={searchCus}
          searchClass={searchClass}
          searchHawb={searchHawb}
          searchCountry={searchCountry}
          searchService={searchService}
          searchType={searchType}
          searchWeight={searchWeight}
          searchPcs={searchPcs}
          searchDescription={searchDescription}
          searchSin={searchSin}
          searchSale={searchSale}
          searchOps={searchOps}
          searchCode={searchCode}
          handleChangeCode={handleChangeCode}
          duplicateWeight={duplicateWeight}
          duplicateCode={duplicateCode}
        />
        {displayEditScreen && <EditShipment handleCloseEdit={handleCloseEdit} selectedEdit={selectedEdit} />}
      </div>
    </div>
  );
}
