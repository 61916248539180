export const doxConfirm = [
  'Hiểu rồi, bạn đang muốn gửi hồ sơ đi từ Vietnam đến USA, tôi sẽ tiến hành phân tích yêu cầu của bạn và thông báo nếu có vấn đề gì ngay bây giờ.',
  'Bạn muốn gửi tài liệu từ Vietnam đến USA, tôi sẽ kiểm tra thông tin và hỗ trợ bạn ngay lập tức.',
  'Tôi đã nhận được yêu cầu gửi thư mời của bạn đến USA, tôi sẽ xử lý và cập nhật thông tin sớm nhất.',
  'Bạn muốn gửi thư từ Vietnam sang USA, tôi sẽ tiến hành kiểm tra và xác nhận với bạn nếu có bất kỳ vấn đề gì.',
  'Tôi hiểu rằng bạn cần gửi chứng từ sang USA, tôi sẽ hỗ trợ kiểm tra quy trình ngay bây giờ.',
  'Bạn đang có nhu cầu gửi hồ sơ từ Vietnam đến USA, tôi sẽ tiến hành xác minh thông tin và cập nhật ngay.',
  'Tôi sẽ kiểm tra yêu cầu gửi tài liệu đi USA của bạn và thông báo lại nếu có điều gì cần lưu ý.',
  'Yêu cầu gửi thư mời của bạn từ Vietnam sang USA đã được tiếp nhận, tôi sẽ kiểm tra và phản hồi ngay.',
  'Bạn đang muốn gửi thư từ Vietnam sang USA, tôi sẽ xác nhận thông tin và xử lý nhanh chóng.',
  'Tôi sẽ tiến hành kiểm tra hồ sơ vận chuyển chứng từ từ Vietnam đến USA cho bạn ngay bây giờ.',
  'Tôi đã nhận được thông tin về lô hàng tài liệu của bạn đi USA, tôi sẽ hỗ trợ kiểm tra chi tiết.',
  'Bạn cần gửi chứng từ sang USA? Tôi sẽ xem xét các yêu cầu vận chuyển và cập nhật ngay cho bạn.',
  'Tôi đang tiến hành xác minh thông tin gửi thư mời của bạn sang USA, tôi sẽ phản hồi sớm nhất có thể.',
  'Bạn có yêu cầu gửi hồ sơ từ Vietnam đến USA? Tôi sẽ kiểm tra và đảm bảo quy trình diễn ra suôn sẻ.',
  'Bạn muốn gửi thư đi USA? Tôi sẽ hỗ trợ kiểm tra thông tin và xác nhận ngay cho bạn.',
  'Tôi đã tiếp nhận yêu cầu gửi chứng từ của bạn, tôi sẽ kiểm tra và phản hồi nếu có bất kỳ vấn đề gì.',
  'Bạn muốn gửi tài liệu từ Vietnam đến USA? Tôi đang tiến hành xử lý và sẽ cập nhật cho bạn sớm nhất.',
  'Tôi sẽ kiểm tra tình trạng gửi hồ sơ sang USA của bạn và thông báo ngay nếu có thay đổi.',
  'Tôi đã nhận yêu cầu gửi thư mời của bạn từ Vietnam đi USA, tôi sẽ xem xét và xác nhận ngay.',
  'Bạn cần gửi thư từ Vietnam đến USA? Tôi đang kiểm tra thông tin vận chuyển và sẽ phản hồi sớm.',
  'Bạn muốn gửi hồ sơ sang USA? Tôi sẽ tiến hành kiểm tra chi tiết và hỗ trợ ngay khi cần thiết.',
  'Tôi sẽ xem xét yêu cầu gửi tài liệu đi USA của bạn và đảm bảo mọi thứ được xử lý đúng quy trình.',
  'Yêu cầu gửi chứng từ của bạn đi USA đã được tiếp nhận, tôi sẽ kiểm tra tình trạng ngay bây giờ.',
  'Bạn đang có nhu cầu gửi thư mời từ Vietnam đến USA? Tôi sẽ hỗ trợ kiểm tra quy trình ngay lập tức.',
  'Tôi đã nhận thông tin về lô hàng thư của bạn đi USA, tôi sẽ xem xét các điều kiện vận chuyển ngay.',
  'Bạn cần gửi chứng từ sang USA? Tôi sẽ tiến hành kiểm tra quy trình vận chuyển và phản hồi ngay.',
  'Tôi đã tiếp nhận yêu cầu gửi hồ sơ từ Vietnam sang USA, tôi sẽ hỗ trợ kiểm tra và xác nhận với bạn.',
  'Bạn đang muốn gửi tài liệu đi USA? Tôi sẽ xem xét yêu cầu và thông báo lại nếu có bất kỳ thay đổi nào.',
  'Tôi hiểu rằng bạn đang cần gửi thư mời từ Vietnam sang USA, tôi sẽ kiểm tra và xác nhận thông tin ngay.',
  'Bạn có nhu cầu gửi thư đi USA? Tôi sẽ hỗ trợ kiểm tra thông tin và cập nhật tình trạng vận chuyển sớm.',
  'Tôi sẽ tiến hành kiểm tra quy trình gửi chứng từ đi USA của bạn ngay bây giờ và phản hồi sớm.',
  'Bạn muốn gửi hồ sơ từ Vietnam sang USA? Tôi sẽ xem xét chi tiết và cập nhật thông tin vận chuyển ngay.',
  'Tôi đã nhận được thông tin về lô hàng tài liệu của bạn đi USA, tôi sẽ tiến hành xác minh và thông báo sớm nhất.',
  'Bạn cần gửi thư từ Vietnam đến USA? Tôi sẽ kiểm tra các điều kiện vận chuyển và hỗ trợ bạn ngay.',
  'Tôi hiểu rằng bạn muốn gửi chứng từ sang USA, tôi sẽ đảm bảo quy trình diễn ra thuận lợi và không có sai sót.',
  'Bạn muốn gửi thư mời đi USA? Tôi sẽ tiến hành xác minh thông tin và phản hồi nếu có bất kỳ vấn đề gì.',
  'Tôi đã tiếp nhận yêu cầu gửi hồ sơ của bạn đi USA, tôi sẽ kiểm tra tình trạng đơn hàng ngay lập tức.',
  'Bạn đang có nhu cầu gửi tài liệu từ Vietnam đến USA? Tôi sẽ kiểm tra và cập nhật tình hình vận chuyển.',
  'Tôi sẽ xem xét yêu cầu gửi thư của bạn đi USA và hỗ trợ xử lý nếu cần thiết.',
  'Bạn muốn gửi chứng từ từ Vietnam sang USA? Tôi sẽ đảm bảo mọi thủ tục vận chuyển được hoàn thành đúng hạn.',
  'Tôi đã nhận được thông tin về lô hàng hồ sơ của bạn đi USA, tôi sẽ kiểm tra và xác nhận quy trình.',
  'Bạn đang muốn gửi thư mời sang USA? Tôi sẽ xem xét yêu cầu của bạn và hỗ trợ sớm nhất có thể.',
  'Tôi sẽ tiến hành kiểm tra tình trạng gửi tài liệu của bạn đi USA và thông báo ngay nếu có vấn đề.',
  'Bạn có nhu cầu gửi chứng từ sang USA? Tôi sẽ hỗ trợ bạn kiểm tra thông tin vận chuyển và xác minh ngay.',
  'Tôi hiểu rằng bạn đang cần gửi thư đi USA, tôi sẽ tiến hành xác minh thông tin và phản hồi sớm nhất.',
  'Bạn muốn gửi hồ sơ từ Vietnam sang USA? Tôi sẽ hỗ trợ kiểm tra và đảm bảo quá trình vận chuyển suôn sẻ.',
  'Tôi đã tiếp nhận yêu cầu gửi tài liệu đi USA của bạn, tôi sẽ kiểm tra tình trạng đơn hàng ngay bây giờ.',
  'Bạn đang có nhu cầu gửi thư mời từ Vietnam sang USA? Tôi sẽ xem xét thông tin vận chuyển ngay lập tức.',
  'Tôi sẽ xem xét yêu cầu gửi chứng từ của bạn đi USA và đảm bảo mọi thứ được xử lý đúng quy trình.',
  'Bạn có muốn gửi hồ sơ từ Vietnam đến USA? Tôi sẽ hỗ trợ kiểm tra thông tin vận chuyển ngay lập tức.',
  'Cảm ơn bạn đã cung cấp thông tin, tôi sẽ tiến hành xác minh yêu cầu gửi chứng từ từ Vietnam đến USA và phản hồi ngay khi có cập nhật.',
  'Tôi đã tiếp nhận yêu cầu vận chuyển hồ sơ từ Vietnam đi USA, tôi sẽ kiểm tra chi tiết và thông báo sớm nhất có thể.',
  'Tôi sẽ tiến hành phân tích yêu cầu gửi tài liệu của bạn sang USA và thông báo nếu có bất kỳ vấn đề nào cần lưu ý.',
  'Tôi đã ghi nhận thông tin về lô hàng thư mời của bạn đi USA, tôi sẽ kiểm tra quy trình vận chuyển và phản hồi sớm.',
  'Tôi hiểu rằng bạn cần gửi thư từ Vietnam đến USA, tôi sẽ đảm bảo quy trình vận chuyển diễn ra thuận lợi và cập nhật cho bạn.',
  'Tôi sẽ tiến hành rà soát thông tin về đơn hàng chứa chứng từ gửi đến USA và hỗ trợ bạn trong trường hợp cần thiết.',
  'Cảm ơn bạn đã gửi yêu cầu, tôi sẽ xác minh chi tiết việc gửi hồ sơ đi USA và phản hồi sớm nếu có thông tin quan trọng.',
  'Tôi đang kiểm tra các yêu cầu vận chuyển tài liệu của bạn sang USA, nếu có điều gì cần lưu ý, tôi sẽ thông báo ngay.',
  'Bạn có nhu cầu gửi thư mời đến USA? Tôi sẽ kiểm tra các quy định liên quan và cập nhật cho bạn trong thời gian sớm nhất.',
  'Tôi sẽ tiến hành xác nhận tình trạng đơn hàng thư gửi đi USA và thông báo đến bạn nếu có bất kỳ trở ngại nào.',
  'Tôi đang kiểm tra yêu cầu gửi chứng từ của bạn từ Vietnam đến USA để đảm bảo đúng quy trình, bạn sẽ nhận được cập nhật sớm.',
  'Yêu cầu gửi hồ sơ đi USA của bạn đã được tiếp nhận, tôi sẽ tiến hành rà soát và đảm bảo không có sai sót trong quá trình xử lý.',
  'Tôi sẽ kiểm tra chi tiết đơn hàng tài liệu của bạn và đảm bảo rằng quy trình gửi đi USA diễn ra đúng lịch trình.',
  'Tôi đã tiếp nhận thông tin về đơn hàng thư mời đi USA của bạn và sẽ cập nhật ngay nếu có bất kỳ thay đổi nào.',
  'Tôi sẽ tiến hành kiểm tra thông tin vận chuyển thư của bạn đến USA và thông báo ngay khi có kết quả.',
  'Tôi đang kiểm tra trạng thái vận đơn chứa chứng từ đi USA của bạn, bạn sẽ sớm nhận được thông tin chi tiết.',
  'Tôi sẽ xem xét các yêu cầu gửi hồ sơ của bạn để đảm bảo quy trình vận chuyển đến USA diễn ra chính xác và hiệu quả.',
  'Tôi đã ghi nhận yêu cầu gửi tài liệu đi USA, tôi sẽ rà soát quy trình và phản hồi sớm nếu có bất kỳ vấn đề nào cần xử lý.',
  'Yêu cầu gửi thư mời từ Vietnam đến USA của bạn đã được tiếp nhận, tôi sẽ kiểm tra tình trạng vận chuyển ngay lập tức.',
  'Tôi sẽ tiến hành kiểm tra tình trạng lô hàng thư của bạn và xác nhận xem có vấn đề gì ảnh hưởng đến tiến độ gửi đi USA không.',
  'Tôi sẽ rà soát thông tin chứng từ cần gửi đi USA của bạn để đảm bảo tất cả các quy trình được thực hiện đúng quy định.',
  'Tôi đang xác minh yêu cầu vận chuyển hồ sơ của bạn đến USA và sẽ gửi thông tin cập nhật ngay khi có kết quả.',
  'Bạn có nhu cầu gửi tài liệu đến USA? Tôi sẽ tiến hành kiểm tra thông tin vận chuyển và đảm bảo không có trở ngại nào.',
  'Tôi đã tiếp nhận thông tin gửi thư mời của bạn đến USA và sẽ theo dõi sát sao quá trình vận chuyển.',
  'Tôi sẽ tiến hành kiểm tra lại đơn hàng thư của bạn đi USA và đảm bảo tất cả thông tin đều chính xác trước khi gửi đi.',
  'Tôi đã ghi nhận yêu cầu gửi chứng từ của bạn, tôi sẽ tiến hành rà soát quy trình và thông báo nếu cần bổ sung thông tin.',
  'Tôi sẽ kiểm tra xem yêu cầu gửi hồ sơ đi USA của bạn có đáp ứng các tiêu chuẩn vận chuyển quốc tế hay không.',
  'Bạn cần gửi tài liệu đến USA? Tôi sẽ rà soát quy trình và thông báo nếu có bất kỳ giấy tờ nào cần bổ sung.',
  'Tôi đã tiếp nhận yêu cầu gửi thư mời đi USA của bạn, tôi sẽ kiểm tra các thủ tục hải quan liên quan và cập nhật thông tin ngay.',
  'Tôi sẽ rà soát tình trạng đơn hàng thư của bạn gửi đến USA và thông báo nếu có bất kỳ vấn đề nào xảy ra.',
  'Tôi đang kiểm tra các yêu cầu vận chuyển chứng từ của bạn đi USA và đảm bảo quy trình diễn ra chính xác.',
  'Tôi sẽ tiến hành rà soát thông tin đơn hàng hồ sơ của bạn để đảm bảo đúng tiến độ vận chuyển đến USA.',
  'Bạn có nhu cầu gửi tài liệu sang USA? Tôi sẽ kiểm tra các yêu cầu đóng gói và hướng dẫn bạn nếu cần thiết.',
  'Tôi đã nhận thông tin gửi thư mời đi USA của bạn, tôi sẽ xác nhận tình trạng đơn hàng ngay bây giờ.',
  'Bạn cần gửi thư đi USA? Tôi sẽ xem xét tình trạng đơn hàng và thông báo ngay nếu có bất kỳ thay đổi nào.',
  'Tôi sẽ kiểm tra yêu cầu gửi chứng từ của bạn và đảm bảo rằng lô hàng đến USA đúng thời gian dự kiến.',
  'Tôi đã tiếp nhận yêu cầu gửi hồ sơ của bạn sang USA và sẽ theo dõi chặt chẽ quá trình vận chuyển.',
  'Bạn có nhu cầu gửi tài liệu đến USA? Tôi sẽ kiểm tra tình trạng đơn hàng của bạn ngay bây giờ.',
  'Tôi sẽ rà soát đơn hàng thư mời của bạn gửi đi USA và đảm bảo tuân thủ tất cả các quy định vận chuyển.',
  'Tôi đang kiểm tra lại yêu cầu gửi thư của bạn đến USA và sẽ cập nhật cho bạn nếu có bất kỳ điều gì cần chú ý.',
  'Tôi sẽ tiến hành rà soát quy trình gửi chứng từ đi USA của bạn để đảm bảo đúng thủ tục hải quan và vận chuyển.',
  'Bạn có nhu cầu gửi hồ sơ sang USA? Tôi sẽ kiểm tra các điều kiện vận chuyển và cập nhật cho bạn ngay khi có kết quả.',
  'Tôi đã nhận được yêu cầu gửi tài liệu đi USA của bạn, tôi sẽ theo dõi quá trình vận chuyển và báo cáo lại khi có cập nhật.',
  'Tôi đang kiểm tra thông tin vận đơn thư mời đi USA của bạn và sẽ thông báo ngay khi có bất kỳ thay đổi nào.',
  'Tôi sẽ rà soát đơn hàng thư của bạn để đảm bảo tuân thủ các quy định vận chuyển trước khi gửi đến USA.',
  'Tôi đã tiếp nhận thông tin về đơn hàng chứng từ đi USA của bạn, tôi sẽ kiểm tra trạng thái và cập nhật ngay.',
  'Tôi sẽ tiến hành xác minh yêu cầu gửi hồ sơ của bạn và đảm bảo tất cả các thủ tục vận chuyển được hoàn tất đúng hạn.',
  'Tôi đã nhận thông tin về đơn hàng tài liệu của bạn đi USA, tôi sẽ đảm bảo quá trình vận chuyển được theo dõi sát sao.',
  'Tôi đang kiểm tra các yêu cầu gửi thư mời của bạn và sẽ hỗ trợ bạn trong trường hợp cần thêm thông tin bổ sung.',
];
