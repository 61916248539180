export const dimDescription = [
  'Loại trọng lượng thứ hai là trọng lượng thể tích, được tính dựa trên kích thước của lô hàng.',
  'Trọng lượng thể tích phản ánh không gian mà lô hàng của bạn chiếm, dựa trên chiều dài, chiều rộng và chiều cao.',
  'Hãng vận chuyển cũng tính trọng lượng thể tích, dựa trên kích thước tổng thể của lô hàng.',
  'Trọng lượng thể tích được đo bằng kích thước của lô hàng, bao gồm cả chiều dài, rộng và cao tính bằng xentimét.',
  'Loại trọng lượng thứ hai, trọng lượng thể tích, dựa trên kích thước thực tế của lô hàng chứ không phải cân nặng.',
  'Trọng lượng thể tích tính theo không gian mà lô hàng chiếm, sử dụng chiều dài, rộng, cao và tỷ lệ mật độ.',
  'Trong vận chuyển, trọng lượng thể tích thể hiện kích thước tổng thể của lô hàng, bao gồm cả bao bì.',
  'Hãng sẽ đo trọng lượng thể tích dựa trên chiều dài, rộng và cao của lô hàng để tính phí vận chuyển.',
  'Trọng lượng thể tích là cách tính dựa trên kích thước lô hàng, không chỉ đơn thuần là trọng lượng cân được.',
  'Khi tính trọng lượng thể tích, hãy nhớ bao gồm cả lớp bao bì trong các số đo của bạn.',
  'Trọng lượng thể tích được đo bằng chiều dài, rộng và cao, áp dụng tỷ lệ mật độ để quy đổi ra kilôgam.',
  'Loại trọng lượng thứ hai, trọng lượng thể tích, dựa trên kích thước hàng hóa và tỷ lệ mật độ.',
  'Trong vận chuyển, trọng lượng thể tích được tính dựa trên không gian mà lô hàng chiếm, tính cả bao bì.',
  'Hãng vận chuyển sẽ so sánh trọng lượng thể tích, dựa trên kích thước, với trọng lượng thực tế để tính phí.',
  'Trọng lượng thể tích được đo bằng cách nhân chiều dài, rộng, cao của lô hàng, sau đó áp dụng tỷ lệ mật độ.',
  'Loại trọng lượng thứ hai, trọng lượng thể tích, thường cao hơn khi lô hàng của bạn có kích thước lớn nhưng nhẹ.',
  'Hãy nhớ rằng trọng lượng thể tích dựa trên không gian tổng thể lô hàng, không chỉ khối lượng thực tế.',
  'Trọng lượng thể tích đo không gian lô hàng chiếm trong khoang chứa, tính cả các lớp bao bì bên ngoài.',
  'Hãng vận chuyển sẽ tính trọng lượng thể tích dựa trên các số đo chiều dài, rộng và cao của lô hàng.',
  'Trọng lượng thể tích giúp xác định chi phí vận chuyển cho lô hàng cồng kềnh nhưng không quá nặng.',
  'Trọng lượng thể tích dựa trên kích thước, sử dụng các số đo chiều dài, rộng và cao tính bằng xentimét.',
  'Khi tính trọng lượng thể tích, hãy đo chiều dài, rộng và cao của lô hàng, kể cả bao bì bên ngoài.',
  'Loại trọng lượng này phản ánh kích thước của lô hàng, sử dụng tỷ lệ mật độ để tính toán chi phí vận chuyển.',
  'Hãng vận chuyển sẽ tính trọng lượng thể tích bằng cách đo kích thước tổng thể lô hàng, tính cả bao bì.',
  'Trọng lượng thể tích là cách đo không gian mà lô hàng chiếm, không chỉ trọng lượng vật lý.',
  'Trong vận chuyển, trọng lượng thể tích dựa trên không gian lô hàng sử dụng trong khoang chứa.',
  'Hãy đo chiều dài, rộng và cao để tính trọng lượng thể tích của lô hàng trước khi vận chuyển.',
  'Trọng lượng thể tích thường được áp dụng khi lô hàng có kích thước lớn nhưng trọng lượng thực lại nhẹ.',
  'Hãng vận chuyển so sánh trọng lượng thể tích, tính từ kích thước lô hàng, với trọng lượng thực tế.',
  'Trọng lượng thể tích giúp xác định chi phí vận chuyển dựa trên kích thước và không gian chiếm chỗ.',
  'Loại trọng lượng này được tính bằng cách đo các kích thước chính và áp dụng tỷ lệ mật độ phù hợp.',
  'Trong vận chuyển, trọng lượng thể tích phản ánh kích thước lô hàng và ảnh hưởng đến chi phí vận chuyển.',
  'Hãng vận chuyển sử dụng trọng lượng thể tích để tính chi phí cho lô hàng cồng kềnh nhưng không nặng.',
  'Khi tính trọng lượng thể tích, đừng quên đo tất cả kích thước lô hàng, bao gồm cả lớp bao bì.',
  'Trọng lượng thể tích thể hiện không gian tổng thể mà lô hàng của bạn chiếm trong khoang vận chuyển.',
  'Hãng vận chuyển đo trọng lượng thể tích dựa trên kích thước của lô hàng, áp dụng tỷ lệ mật độ.',
  'Khi vận chuyển hàng hóa lớn, trọng lượng thể tích thường là yếu tố quyết định chi phí vận chuyển.',
  'Trọng lượng thể tích tính theo kích thước của lô hàng, bao gồm chiều dài, rộng và cao, quy đổi ra kilôgam.',
  'Loại trọng lượng này sử dụng tỷ lệ mật độ để tính toán dựa trên không gian mà lô hàng chiếm.',
  'Trọng lượng thể tích được áp dụng khi kích thước của lô hàng lớn hơn so với trọng lượng thực tế.',
  'Hãy nhớ rằng trọng lượng thể tích phản ánh không gian mà lô hàng chiếm, không chỉ cân nặng vật lý.',
  'Trong vận chuyển, trọng lượng thể tích dựa trên kích thước của lô hàng, tính cả bao bì bên ngoài.',
  'Trọng lượng thể tích được tính từ kích thước tổng thể, áp dụng tỷ lệ mật độ để xác định chi phí.',
  'Hãng vận chuyển sử dụng trọng lượng thể tích để xác định chi phí cho lô hàng có kích thước lớn.',
  'Trọng lượng thể tích được đo bằng cách tính toán các kích thước chính, áp dụng quy tắc mật độ.',
  'Khi tính trọng lượng thể tích, hãy đo chính xác các số đo lô hàng, bao gồm mọi lớp bao bì.',
  'Trọng lượng thể tích giúp hãng vận chuyển xác định chi phí dựa trên không gian mà lô hàng chiếm.',
  'Trong vận chuyển, trọng lượng thể tích được tính bằng cách nhân các số đo kích thước và áp dụng tỷ lệ.',
  'Loại trọng lượng này phản ánh kích thước thực tế của lô hàng, thường áp dụng cho hàng cồng kềnh.',
];
